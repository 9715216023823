import * as types from './types';

export const togglePasswordVisible = () => {
  return {
    type: types.TOGGLE_PASSWORD_VISIBILITY
  };
}

export const setLoginFormValid = () => {
  return {
    type: types.SET_LOGIN_VALID
  }
}

export const setLoginFormInvalid = () => {
  return {
    type: types.SET_LOGIN_INVALID
  }
}

export const setShakeAnimationOff = () => {
  return {
    type: types.SET_SHAKE_ANIMATION_OFF
  }
}