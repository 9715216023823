import { io } from 'socket.io-client';
import { WEBSOCKET_URL_TRACK_UPLOADER } from '../../config';
const ClientWebsocket = () => {
  const socket = io(`${WEBSOCKET_URL_TRACK_UPLOADER}/events`, {
    transports: ['websocket', 'polling'],
  });

  return socket;
};

export default ClientWebsocket;
