import * as types from '../actions/types';

import { DEFAULT_FZ_MESSAGE } from '../config';

const initalState = {
  fzMessage: DEFAULT_FZ_MESSAGE,
  fzCurFileNo: 0,
  fzTotalFileNo: 0,
  fzShowProgress: false,
  isOverFz: false,
  fzFileNames: [''],
  existingTracks: [],
  showEditTrack: false,
  showDismissTrack: false,
  showDismissAllPopup : false,
  filesToConfirm : [],
  selectedTrackForEdit: {}
};

export default (state = initalState, action) => {
  switch (action.type) {

    case types.CHANGE_FZMESSAGE:
      return Object.assign({}, state, {
        fzMessage: action.payload
      });

    case types.CHANGE_FZCURFILENO:
      return Object.assign({}, state, {
        fzCurFileNo: action.payload
      });

    case types.CHANGE_FZTOTALFILENO:
      return Object.assign({}, state, {
        fzTotalFileNo: action.payload
      });

    case types.CHANGE_FZSHOWPROGRESS:
      return Object.assign({}, state, {
        fzShowProgress: action.payload
      });

    case types.CHANGE_IS_OVER_FZ:
      return Object.assign({}, state, {
        isOverFz: action.payload
      });

    case types.CHANGE_FZFILENAMES:
      return Object.assign({}, state, {
        fzFileNames: action.payload
      });

    case types.CHANGE_EXISTING_TRACKS:
      return Object.assign({}, state, {
        existingTracks: action.payload
      });

    case types.CHANGE_SHOW_EDIT_TRACK:
      return Object.assign({}, state, {
        showEditTrack: action.payload
      });

    case types.CHANGE_SHOW_DISMISS_TRACK:
      return Object.assign({}, state, {
        showDismissTrack: action.payload
      });

    case types.CHANGE_SELECTED_TRACK_FOR_EDIT:
      return Object.assign({}, state, {
        selectedTrackForEdit: Object.assign({}, state.selectedTrackForEdit, action.payload)
      });

    case types.RESET_SELECTED_TRACK_FOR_EDIT:
      return Object.assign({}, state, {
        selectedTrackForEdit: {}
      });

    case types.OPEN_DISMISS_ALL_POPUP :
      return Object.assign({}, state, {
        showDismissAllPopup: true
      });

    case types.CLOSE_DISMISS_ALL_POPUP :
      return Object.assign({}, state, {
        showDismissAllPopup: false
      });

    case types.ADD_TO_FILE_TO_CONFIRM: {
      let filesToConfirm = Object.assign([],state.filesToConfirm);
      filesToConfirm.push(action.payload);
      return Object.assign({}, state, {
        filesToConfirm
      });
    }

    case types.REMOVE_FROM_FILE_TO_CONFIRM: {
      let filesToConfirm = Object.assign([],state.filesToConfirm);
      filesToConfirm.splice(filesToConfirm.indexOf(action.payload), 1);
      return Object.assign({}, state, {
        filesToConfirm
      });
    }


  }
  return state;
};
