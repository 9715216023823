import React, {Component} from 'react';


export default (props) => {

	return (
    <React.Fragment>
			<p>
				Are you sure you want to dismiss all the uploaded tracks?
			</p>
      <React.Fragment>
        <button
          type="danger"
          className="btn btn-danger btn-sm"
          onClick={props.handleCancel}
        >Cancel</button>
        <button
          type="danger"
          className="btn btn-success btn-sm"
          onClick={props.handleDismiss}
        >Dismiss All</button>
      </React.Fragment>
    </React.Fragment>
	);

}
