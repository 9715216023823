import * as React from 'react';

import moment from 'moment';
import { connect } from 'react-redux';
import { CSVLink } from 'react-csv';
import Pagination from 'react-js-pagination';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import faAngleRight from '@fortawesome/fontawesome-free-solid/faAngleRight';
import faAngleLeft from '@fortawesome/fontawesome-free-solid/faAngleLeft';
import faAngleDoubleRight from '@fortawesome/fontawesome-free-solid/faAngleDoubleRight';
import faAngleDoubleLeft from '@fortawesome/fontawesome-free-solid/faAngleDoubleLeft';
import { TiArrowMinimise, TiArrowMaximise } from 'react-icons/ti';
import { FaDownload } from 'react-icons/fa';
import { Tooltip } from 'react-tippy';
import { isEqual } from 'lodash';
import 'react-tippy/dist/tippy.css';

import SearchBar from '@common/searchbar';
import store from '@store';
import { selectTableRows, toggleSelectTableRow, deselectAllTableRows, changeTableSearchQuery } from '@actions/common-actions';
import '@stylesheets/pagination.scss';

class Table extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      itemsCountPerPage: props.itemsCountPerPage || 50,
      sortBy: props.sortBy || 0,
      sortByAscending: typeof props.sortByAscending !== 'undefined' ? props.sortByAscending : true,
      isExact: true,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const prevRowData = prevProps && prevProps.rowData ? [...prevProps.rowData] : [];
    const rowData = this.props.rowData ? [...this.props.rowData] : [];
    const prevData = prevRowData.map((row) => row.data).map((row) => row.filter((cell) => !React.isValidElement(cell)));
    const data = rowData.map((row) => row.data).map((row) => row.filter((cell) => !React.isValidElement(cell)));
    if (this.props.paginate && JSON.stringify(prevData) !== JSON.stringify(data) && data.length > 0) {
      this.handlePageChange(1);
    }
    if (
      rowData.length !== prevRowData.length ||
      (this.state.itemsCountPerPage > this.filteredRows.length && this.state.itemCountPerPage !== this.filteredRows.length) ||
      this.props.searchQuery !== prevProps.searchQuery
    ) {
      const itemsCountPerPage = this.filteredRows.length > this.props.itemsCountPerPage ? this.props.itemsCountPerPage || 50 : this.filteredRows.length;
      this.handleItemPerPageChange(itemsCountPerPage);
    }
    if (typeof this.props.forceUpdate !== 'undefined' && !isEqual(prevProps.forceUpdate, this.props.forceUpdate)) {
      this.forceUpdate();
    }
  }

  componentWillUnmount() {
    store.dispatch(deselectAllTableRows());
    store.dispatch(changeTableSearchQuery(''));
  }

  get isCompact() {
    return typeof this.props.compact === 'undefined' ? true : this.props.compact; // || document.documentElement.clientHeight <= 768;
  }

  get csvData() {
    const headings = this.props.headings && this.props.headings.length > 0 ? this.props.headings.map((heading) => heading.title) : [];
    const rows = this.props.rowData && this.props.rowData.length > 0 ? this.props.rowData.map((row) => row.data) : [];
    return [[...headings], ...rows];
  }

  get inactiveTracksCsvData() {
    const headings = ['Artist', 'Track'];
    const rows =
      this.props.rowData && this.props.rowData.length > 0
        ? this.props.rowData
            .filter((row) => row.data[4] === 'No')
            .map((row) => {
              return [row.data[5], row.data[6]];
            })
        : [];
    return [headings, ...rows];
  }

  get placeholder() {
    return (
      <tr className="text-center d-flex align-items-center justify-content-center">
        {this.props.loadingMessage ? (
          <td style={{ '--steps': this.props.loadingMessage.length }} className="animated infinite typewriter table-placeholder">
            {this.props.loadingMessage || 'No data'}
          </td>
        ) : (
          <td className="table-placeholder">{this.props.noDataMessage || 'No data'}</td>
        )}
      </tr>
    );
  }

  rowClass = (row) => {
    const borders = this.props.borders || false;
    let rowClass = this.isRowSelected(row) || row.isSelected ? row.isSelectedClass || 'row-highlight' : row.class ? row.class : '';
    rowClass = borders ? `${rowClass} borders` : rowClass;
    return rowClass;
  };

  get start() {
    return (this.state.activePage - 1) * this.state.itemsCountPerPage;
  }

  get end() {
    const itemCount = !this.props.paginate && this.filteredRows.length > this.state.itemsCountPerPage ? this.filteredRows.length : this.state.itemsCountPerPage;
    return this.start + itemCount;
  }

  get filteredRows() {
    if (!this.props.rowData) {
      return [];
    }
    const searchTerms = this.props.searchQuery && !this.state.isExact ? this.props.searchQuery.split(' ') : [this.props.searchQuery || ''];
    return this.props.rowData
      .filter((row) => {
        if (searchTerms.length === 0) return row;
        const data = row.data.filter((cell) => typeof cell === 'string' && cell !== '');
        return data.some((cell) => searchTerms.some((term) => cell.toLowerCase().includes(term.toLowerCase())));
      })
      .sort((a, b) => {
        const ayy = a.data[this.state.sortBy];
        const bee = b.data[this.state.sortBy];
        if (ayy && moment(ayy).isValid() && bee && moment(bee).isValid()) {
          return this.state.sortByAscending ? moment(ayy).diff(bee) : moment(bee).diff(ayy);
        }
        if (typeof ayy === 'string' && typeof bee === 'string') {
          return this.state.sortByAscending ? ayy.localeCompare(bee) : bee.localeCompare(ayy);
        }
        return this.state.sortByAscending ? ayy - bee : bee - ayy;
      });
  }

  get paginatedRows() {
    return this.props.paginate && (this.props.itemsCountPerPage || this.state.itemsCountPerPage)
      ? this.filteredRows.slice(this.start, this.end)
      : this.filteredRows;
  }

  get rowData() {
    if (!this.props.rowData) return this.placeholder;
    const rowData = this.paginatedRows.map((row, i) => (
      <tr key={'row-' + i} className={this.rowClass(row)} onClick={() => this.handleClickRow(row)}>
        {row.data.map((cell, j) => (
          <td
            style={{ ...this.styles.td, ...this.props.styles }}
            className={this.props.borders ? 'borders' : ''}
            width={this.props.headings[j].width}
            key={'row-' + i + '-cell-' + j}
          >
            {cell && moment(cell).isValid() && this.props.headings && this.props.headings[j] && this.props.headings[j].date
              ? moment(cell).format(this.props.headings[j].format || 'DD-MM-YYYY, HH:mm')
              : cell}
          </td>
        ))}
      </tr>
    ));
    if (this.start > this.filteredRows.length) {
      this.handlePageChange(1);
    }
    return rowData;
  }

  get footer() {
    const footerItems = {
      left: <p className="table-footer-section"></p>,
      center: <p className="table-footer-section"></p>,
      right: <p className="table-footer-section"></p>,
      ...this.props.footerItems,
    };
    return (
      <div className="flex full-width">
        <div className="flex padding-top-sm full-width align-items-center justify-content-space-between">
          <div className="table-footer-section">{footerItems.left}</div>
          <div className="table-footer-section flex-space-evenly">{footerItems.center}</div>
          <div className="table-footer-section">{footerItems.right}</div>
        </div>
      </div>
    );
  }

  get csvDownloadButton() {
    const disableButton = !this.props.rowData || !this.props.rowData.length ? true : false;
    const textColor = disableButton ? 'grey' : 'white';
    return (
      <Tooltip title="Download as CSV" position="top" trigger="mouseenter">
        <button className="btn btn-xs btn-primary" disabled={disableButton}>
          <CSVLink
            data={this.csvData}
            filename={`${this.props.csvName}-all-tracks.csv` || window.location.pathname.replace(/\//g, '')}
            style={{ display: 'flex', gap: '5px', alignItems: 'center' }}
          >
            <p style={{ fontSize: 11, margin: 0, color: `${textColor}` }}>All Tracks</p>
            <FaDownload size={16} color={textColor} />
          </CSVLink>
        </button>
      </Tooltip>
    );
  }

  get inactiveTracksButton() {
    const disableButton = this.props.rowData && this.props.rowData.length && this.props.rowData.filter((row) => row.data[4] === 'No').length ? false : true;
    const textColor = disableButton ? 'grey' : 'white';
    return (
      <Tooltip title="Download Inactive Tracks as CSV" position="top" trigger="mouseenter">
        <button className="btn btn-xs btn-primary" disabled={disableButton}>
          <CSVLink
            data={this.inactiveTracksCsvData}
            filename={`${this.props.csvName}-inactive-tracks.csv` || window.location.pathname.replace(/\//g, '')}
            style={{ display: 'flex', gap: '5px', alignItems: 'center' }}
          >
            <p style={{ fontSize: 11, margin: 0, color: `${textColor}` }}>Inactive Tracks</p>
            <FaDownload size={16} color={textColor} />
          </CSVLink>
        </button>
      </Tooltip>
    );
  }

  get pagination() {
    return (
      <div className="flex-center-center" style={{ color: 'white' }}>
        <Pagination
          firstPageText={<FontAwesomeIcon icon={faAngleDoubleLeft} />}
          lastPageText={<FontAwesomeIcon icon={faAngleDoubleRight} />}
          prevPageText={<FontAwesomeIcon icon={faAngleLeft} />}
          nextPageText={<FontAwesomeIcon icon={faAngleRight} />}
          pageRangeDisplayed={3}
          activePage={this.state.activePage}
          itemsCountPerPage={this.state.itemsCountPerPage}
          totalItemsCount={this.filteredRows.length}
          onChange={this.handlePageChange}
        />
        <p className="margin-none" style={{ marginLeft: '10px' }}>
          Showing
          <input
            style={{ width: '50px', color: 'black' }}
            type="number"
            min={1}
            max={this.filteredRows.length}
            value={this.state.itemsCountPerPage}
            onChange={(e) => this.handleItemPerPageChange(e.target.value)}
          />
          of <strong>{this.filteredRows.length}</strong>
        </p>
      </div>
    );
  }

  get selectAllButtons() {
    return (
      <React.Fragment>
        <Tooltip title="Deselect All" position="top" trigger="mouseenter">
          <button disabled={this.props.selectedRows.length === 0} className="btn btn-xs btn-dark" title="Deselect All" onClick={this.handleSelectNone}>
            <TiArrowMinimise color="black" size={20} />
          </button>
        </Tooltip>
        <Tooltip title="Select All" position="top" trigger="mouseenter">
          <button
            disabled={this.props.selectedRows.length === this.props.rowData.length}
            className="btn btn-xs btn-dark"
            title="Select All"
            onClick={this.handleSelectAll}
          >
            <TiArrowMaximise color="black" size={20} />
          </button>
        </Tooltip>
      </React.Fragment>
    );
  }

  get searchBar() {
    return (
      <SearchBar
        inputId="table-search"
        placeholder={`Search by ${this.props.headings.map((heading) => heading.title.toLowerCase()).join(', ')}...`}
        searchHandler={this.handleSearchChange}
        onChange={this.onChangeSearchInput}
        value={this.props.searchQuery}
        checkbox
        checked={this.state.isExact}
        checkboxTitle="Exact (match whole phrase)"
        checkboxToggle={this.handleToggleIsLoose}
        width="90%"
        maxWidth={this.searchBarWidth}
        buttonStyle={{
          marginLeft: '-9px',
          zIndex: '99',
        }}
        clearButton
      />
    );
  }

  get searchBarWidth() {
    if (this.props.paginate && (this.props.csv || this.props.selectAll)) {
      return window.innerWidth / 2.5;
    }
    if (this.props.paginate || this.props.csv || this.props.selectAll) {
      return window.innerWidth / 2;
    }
    return window.innerWidth;
  }

  get tableActions() {
    const showSectionOne =
      this.props.searchbar || this.props.csv || this.props.selectAll || (this.props.paginate && this.props.rowData && this.props.rowData.length > 0);
    const showSectionTwo =
      this.props.actions || (this.props.selectAll && this.props.selectedRows.length > 0) || (this.props.showFooter && this.props.footerItems);
    return showSectionOne || showSectionTwo ? (
      <div style={{ ...this.styles.td, background: 'darkslategrey' }} className="flex-column text-white table-actions">
        {showSectionOne ? (
          <div
            style={{ background: 'darkslategrey', borderBottom: '1px solid rgb(175, 175, 175)' }}
            className="flex-space-between padding-bottom-sm text-white"
          >
            <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
              {this.props.csv || this.props.selectAll ? (
                <div className={this.props.selectAll ? 'flex-space-evenly' : 'flex'} style={{ justifySelf: 'flex-this.start', flex: '0 1 150px' }}>
                  {this.props.csv ? this.csvDownloadButton : null}
                  {this.props.selectAll ? this.selectAllButtons : null}
                </div>
              ) : null}
              {this.props.downloadInactiveTracks && this.props.rowData ? this.inactiveTracksButton : null}
            </div>
            {this.props.searchbar ? (
              <div className="flex-center-center" style={{ justifySelf: 'center', flex: '1 0 auto' }}>
                {this.searchBar}
              </div>
            ) : null}
            {this.props.paginate && this.props.rowData && this.props.rowData.length > 0 ? (
              <div className="flex" style={{ justifySelf: 'flex-this.end' }}>
                {this.pagination}
              </div>
            ) : null}
          </div>
        ) : null}
        {this.props.selectAll && this.props.selectedRows.length > 0 ? (
          <p style={{ paddingLeft: '5px' }} className="text-left">
            <strong>{this.props.selectedRows.length}</strong> rows selected
          </p>
        ) : null}
        {!this.props.actions ? null : (
          <div style={{ background: 'darkslategrey' }} className="flex-space-evenly text-white padding-bottom-sm padding-top-sm">
            {this.props.actions}
          </div>
        )}
        {this.props.showFooter && this.props.footerItems ? (
          <div style={{ background: 'darkslategrey' }} className="flex-space-between text-white">
            {this.footer}
          </div>
        ) : null}
      </div>
    ) : null;
  }

  get styles() {
    const propStyles = this.props.style || {};
    return {
      table: {
        ...propStyles,
        width: this.props.width || '100%',
        maxWidth: this.props.maxWidth || '100%',
      },
      td: {
        padding: this.isCompact ? '10px' : '15px',
        fontSize: this.isCompact ? '11px' : '13px',
        textAlign: this.props.textAlign || 'center',
        wordBreak: 'break-word',
      },
      tbody: {
        height: typeof this.props.height === 'number' ? `${this.props.height}px` : this.props.height || 'auto',
      },
    };
  }

  handleClickRow = (row) => {
    if (row.onClick) {
      row.onClick();
    } else if (this.props.selectRowOnClick) {
      this.handleSelectRow(row);
    }
  };

  handleSelectAll = () => {
    // const rows = this.paginatedRows.map(row => row.data);
    store.dispatch(deselectAllTableRows());
    store.dispatch(selectTableRows(this.paginatedRows));
  };

  handleSelectRow = (row) => store.dispatch(toggleSelectTableRow(row));

  handleSelectNone = () => store.dispatch(deselectAllTableRows());

  // TODO: implement. could be tricky alongside handleClickRow
  handleClickCell = (cell) => {};

  handleToggleIsLoose = () => {
    const isExact = !this.state.isExact;
    this.setState({
      ...this.state,
      isExact,
    });
  };

  onChangeSearchInput = (e) => {
    store.dispatch(changeTableSearchQuery(e.target.value));
    this.handlePageChange(1);
    if (e.target.value === '') {
      const itemsCountPerPage = this.props.itemsCountPerPage > this.props.rowData.length ? this.props.rowData.length : this.props.itemsCountPerPage;
      this.handleItemPerPageChange(itemsCountPerPage);
    }
  };

  handlePageChange = (activePage) => {
    this.setState({ ...this.state, activePage });
    store.dispatch(deselectAllTableRows());
  };

  handleItemPerPageChange = (value) => {
    const itemsCountPerPage = parseInt(value, 10);
    this.setState({
      ...this.state,
      itemsCountPerPage,
    });
  };

  handleChangeSortOrder = (index) => {
    if (this.state.sortBy === index) {
      this.setState({
        ...this.state,
        sortByAscending: !this.state.sortByAscending,
      });
    } else {
      this.setState({
        ...this.state,
        sortBy: index,
        sortByAscending: true,
      });
    }
  };

  isRowSelected = (row) => {
    return this.props.selectedRows.some((selectedRow) => JSON.stringify(selectedRow) === JSON.stringify(row));
  };

  render() {
    return (
      <React.Fragment>
        <div className="table-container margin-top-md">
          <table
            ref={(table) => {
              this.tableRef = table;
            }}
            style={this.styles.table}
            className="table-hover table-shadow table-fixed-head animated-no-fill delay04s fadeInDown margin-bottom-md"
          >
            {this.tableActions}
            <thead className="table-header">
              <tr>
                {this.props.headings.map((heading, i) => (
                  <th
                    onClick={() => this.handleChangeSortOrder(i)}
                    style={{ ...this.styles.td, cursor: 'pointer' }}
                    width={heading.width}
                    key={`table-header-${heading.title}-${i}`}
                  >
                    {heading.title}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody style={this.styles.tbody}>{this.props.rowData && this.props.rowData.length === 0 ? this.placeholder : this.rowData}</tbody>
          </table>
        </div>
      </React.Fragment>
    );
  }
}

const storeToProps = (store) => {
  return {
    searchQuery: store.commonState.table.searchQuery,
    selectedRows: store.commonState.table.selectedRows,
  };
};

export default connect(storeToProps)(Table);
