//WP_* are defined inside the WEBPACK config file

export const HOST = WP_HOST;
export const HOST_CF = WP_HOST_CF;
export const HOST_CF_FRONTEND = WP_HOST_CF_FRONTEND;
export const HOST_ROCK_API = WP_HOST_ROCK_API;
export const PORT = WP_PORT;
export const SF_DOMAIN = WP_SF_DOMAIN;
export const PREFIX = WP_PREFIX;
export const OLD_ADMIN_ROOT = WP_OLD_ADMIN_ROOT;
export const OLD_ADMIN_URL = WP_OLD_ADMIN_URL;
export const OLD_ADMIN_URL_TRACKS = WP_OLD_ADMIN_URL_TRACKS;
export const AUDIO_PREFIX = WP_AUDIO_PREFIX;
export const PAPERTRAIL_ENV = AMBIE_ENV === 'dev' ? 'staging' : AMBIE_ENV === 'preproduction' ? 'production' : AMBIE_ENV;
export const DEFAULT_TIMEZONE = 'Europe/London';

export const WEBSOCKET_STREAMING_URL = WP_STREAMING_WEBSOCKET_URL;
export const WEBSOCKET_URL_TRACK_UPLOADER = WP_WEBSOCKET_URL;
// export const WEBSOCKET_STREAMING_URL = window.location.hostname === 'localhost' ? `//${window.location.hostname}:21011` : WP_STREAMING_WEBSOCKET_URL;
export const WEBSOCKET_URL = WP_WEBSOCKET_URL;

export const ENVIRONMENT = AMBIE_ENV.toLowerCase();
export const TRACK_NOT_IN_USE_ENDPOINT = WP_TRACKS_ENDPOINT;
export const LOCAL_STORAGE_USER = 'AMBIE_USER';
export const LOCAL_STORAGE_USER_TOKEN = 'AMBIE_USER_TOKEN';
export const LOCAL_STORAGE_USER_REFRESH_TOKEN = 'AMBIE_USER_REFRESH_TOKEN';
export const S3_UPLOAD_SUCCESS_MESSAGE = 'Your changes have been successfully saved';
export const GENERAL_SUCCESS_MESSAGE = 'Your changes have been successfully saved';
export const GENERAL_ERROR_MESSAGE = 'There was an error while trying to save your changes';
export const GENERAL_REQUEST_ERROR_MESSAGE = 'There was an error while fetching the data';
export const AUTH_ERROR_MESSAGE = 'You are not authorized to complete the request';
export const DEFAULT_NOTIFICATION_HIDE_TIMEOUT = 5 * 1000;
export const DEFAULT_FZ_MESSAGE = 'Drag tracks anywhere on the page';
export const DEFAULT_FZ_DROP_MESSAGE = 'You can drop the files now';
export const DEFAULT_FZ_UPLOAD_MESSAGE = 'Loading track';
export const MEDIA_COVER_CF_URL = WP_MEDIA_CF_URL;
export const COGNITO_IDENTITY_POOL = WP_COGNITO_IDENTITY_POOL;
export const AWS_REGION = WP_AWS_REGION;
export const IOT_HOST = WP_IOT_HOST;
export const IOT_POLICY = WP_IOT_POLICY;
export const STATIC_ASSET_ROOT = '/assets';
export const CLONE_OPTIONS = {
  SCHEDULE: 'schedule',
  AREA: 'area',
};
export const DEFAULT_TIMEOUT = 1000 * 5;
export const DEFAULT_TIMEOUT_INTECEPTORS = 1000 * 20;
