import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
// style components
import { LOCAL_STORAGE_USER_TOKEN, LOCAL_STORAGE_USER_REFRESH_TOKEN, DEFAULT_TIMEOUT_INTECEPTORS, HOST_ROCK_API } from '../config';
import { logout } from '../api/auth-api';

const requestHandler = axios.create({
  baseURL: '',
  timeout: DEFAULT_TIMEOUT_INTECEPTORS,
  responseType: 'json',
});

const refreshAuthLogic = (failedRequest) => {
  const refreshToken = localStorage.getItem(LOCAL_STORAGE_USER_REFRESH_TOKEN);
  console.log('token expired, asking for a new one from', HOST_ROCK_API);
  return axios
    .post(`${HOST_ROCK_API}/auth/refresh-token`, { refreshToken })
    .then((tokenRefreshResponse) => {
      console.log('new access token received');
      localStorage.setItem(LOCAL_STORAGE_USER_TOKEN, tokenRefreshResponse.data.accessToken, {});
      failedRequest.response.config.headers['Authorization'] = 'Bearer ' + tokenRefreshResponse.data.accesToken;
      return Promise.resolve();
    })
    .catch(() => logout());
};

/*
 Instantiate the interceptor
 Additional options - AxiosAuthRefreshOptions - to change the default behaviour, can be passed
 to the `createAuthRefreshInterceptor` function -> docs in https://www.npmjs.com/package/axios-auth-refresh
*/
createAuthRefreshInterceptor(requestHandler, refreshAuthLogic);

requestHandler.interceptors.request.use(
  async function (config) {
    const token = localStorage.getItem(LOCAL_STORAGE_USER_TOKEN);
    const multipartFormData = config.headers.multipartFormData;

    config.headers['x-ambie-token'] = token;
    config.headers['Authorization'] = 'Bearer ' + token;
    config.headers['Content-Type'] = `${multipartFormData || 'application/json'}`;
    return config;
  },

  async function (error) {
    return await Promise.reject(error);
  },
);

export const appendArgsToUrl = (url, queryParams) => {
  const queryString = [];
  Object.keys(queryParams).forEach(function (key) {
    if (queryParams[key] !== null) {
      queryString.push(`${key}=${encodeURIComponent(queryParams[key])}`);
    }
  });
  if (!queryString.length) {
    return url;
  }
  return `${url}?${queryString.join('&')}`;
};

function post(url, data, config) {
  return requestHandler.post(url, data, config);
}
function get(url, parameter, config) {
  const finalUrl = appendArgsToUrl(url, parameter);
  return requestHandler.get(finalUrl, config);
}

function put(url, data, config) {
  return requestHandler.put(url, data, config);
}

function remove(url, config) {
  return requestHandler.delete(url, config);
}

function patch(url, data, config) {
  return requestHandler.patch(url, data, config);
}

export default Object.freeze({
  get,
  post,
  put,
  remove,
  patch,
});
