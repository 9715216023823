import * as types from './types';

export const  changeTracks = (payload) => {
  return {
    type: types.CHANGE_TRACKS,
    payload: payload
  }
}

export const changeShowDetails = (payload) => {
  return {
    type: types.CHANGE_SHOW_DETAILS,
    payload: payload
  }
}

export const changeDevices = (payload) => {
  return {
    type: types.CHANGE_DEVICES,
    payload: payload
  }
}

export const changeSelectedTrack = (payload) => {
  return {
    type: types.CHANGE_SELECTED_TRACK,
    payload: payload
  }
}

export  const changeFilters = (payload) => {
  return {
    type: types.CHANGE_FILTERS,
    payload: payload
  }
}