import moment from 'moment-timezone';

import * as types from '@actions/types';

const initalState = {
  playlist: {
    name: '',
    description: '',
    cover: '',
    allTheRotations: [],
    composition: [],
    viewableBy: {
      businesses: [],
      clients: [],
    },
    rotationRules: {
      recurrence: 'biweekly',
      value: 10,
      start: moment
        .tz('Europe/London')
        .toDate()
        .getTime(),
      active: false,
    },
    energy_level: '',
    rules: {
      artist: [],
      category: [],
      tracks: [],
      commercial: [],
      error: null,
    },
    tracks: [],
  },
  blankCommercialRule: {
    core_channel_id: '',
    repetition: 5,
    repetition_unit: 'tracks',
    pick_type: 'random',
    startDate: null,
    endDate: null,
    injection100: false,
    order: 1,
  },
};

export default (state = initalState, action) => {
  switch (action.type) {
    case types.CHANGE_PLAYLIST_INFO:
      const existingCommercialRule = action.payload.rules.commercial
        ? action.payload.rules.commercial
        : [];
      return {
        ...state,
        playlist: {
          ...state.playlist,
          ...action.payload,
          rules: {
            ...action.payload.rules,
            existingCommercialRule,
          },
        },
      };
    case types.RESET_PLAYLIST_INFO:
      return {
        ...state,
        playlist: {
          ...initalState.playlist,
        },
      };
    case types.CHANGE_PLAYLIST_INFO_COMPOSITION:
      return {
        ...state,
        playlist: {
          ...state.playlist,
          composition: action.payload,
        },
      };
    case types.CHANGE_PLAYLIST_INFO_TRACKS_RULES:
      return {
        ...state,
        playlist: {
          ...state.playlist,
          rules: {
            ...state.playlist.rules,
            tracks: action.payload,
          },
        },
      };
    case types.CHANGE_PLAYLIST_INFO_ARTIST_RULES:
      return {
        ...state,
        playlist: {
          ...state.playlist,
          rules: {
            ...state.playlist.rules,
            artist: action.payload,
          },
        },
      };
    case types.CHANGE_PLAYLIST_INFO_CATEGORY_RULES:
      return {
        ...state,
        playlist: {
          ...state.playlist,
          rules: {
            ...state.playlist.rules,
            category: action.payload,
          },
        },
      };
    case types.CHANGE_PLAYLIST_INFO_LOCATIONS:
      return {
        ...state,
        playlist: {
          ...state.playlist,
          viewableBy: {
            businesses: action.payload,
            clients: state.playlist.clients,
          },
        },
      };
    case types.CHANGE_PLAYLIST_INFO_COMMERCIAL_RULES:
      return {
        ...state,
        playlist: {
          ...state.playlist,
          rules: {
            ...state.playlist.rules,
            commercial: action.payload,
          },
        },
      };
    case types.CHANGE_PLAYLIST_INFO_COMMERCIAL_RULES_DATES:
      const checkDateOverlap = (rules) => {
        let isDateOverlap = false;
        const orderedRules = [...rules];

        orderedRules.sort(function (a, b) { return a.startDate - b.startDate });
        for (let i = 1; i < orderedRules.length; i++) {
          if (orderedRules[i].startDate <= orderedRules[i - 1].endDate) {
            isDateOverlap = true;
            break;
          }
        }
        return isDateOverlap;
      };

      const isDateOverlap = checkDateOverlap(action.payload);

      return {
        ...state, playlist: {
          ...state.playlist, rules: {
            ...state.playlist.rules,
            commercial: action.payload,
            error: isDateOverlap
              ? "Dates are overlapping!"
              : null
          }
        }
      };

    case types.CHANGE_PLAYLIST_INFO_TRACKS:
      return {
        ...state,
        playlist: {
          ...state.playlist,
          tracks: action.payload,
        },
      };
    default:
      return state;
  }
};
