import axios from 'axios';
import store from '../store';

import { HOST_CF } from '../config';
import * as sfAction from '@actions/sf-actions';
import * as locationsApi from './locations-api';
import browserHistory from '../history';
import * as authApi from './auth-api';

export const getDeviceInfo = (deviceID, userToken) => {
  return axios.get(`${HOST_CF}/salesforce/device/${deviceID}`, {
    headers: { 'x-ambie-token': userToken },
    validateStatus: status => authApi.handleUnauthorized(status)
  })
    .then(response => {
      //debugger
      if (response.data.device.Id.includes(deviceID))
        store.dispatch(sfAction.deviceLoaded(response.data.device));
      else
        console.error("McFacey, try harder!", response.data, deviceID);
    })
    .catch(e => {
      console.error("I hate you McFacey!", e);
    });
};

export const saveDeviceOnOlympus = (deviceInfo, userToken, selectedBusiness, selectedArea) => {
  //debugger
  store.dispatch(sfAction.savingDevice());
  return axios.post(`${HOST_CF}/salesforce/device/${deviceInfo.Id}`, {
    area_id: deviceInfo.AmbieArea.id || selectedArea,
    business_id: deviceInfo.AmbieBusiness.id || selectedBusiness,
    device_serial: deviceInfo.SerialNumber,
    parent_sf_id: deviceInfo.ParentAccountSfId,
  }, {
    headers: { 'x-ambie-token': userToken },
    validateStatus: status => authApi.handleUnauthorized(status)
  })
    .then(response => {
      store.dispatch(sfAction.deviceSaved())
    })
    .catch(e => {
      console.error("I hate you McFacey!", e);
    });
}

export const getAccountInfo = (sfAccountId, userToken) => {
  //return axios.get(`http://127.0.0.1:3000/salesforce/account/${accountId}`, {
  return axios.get(`${HOST_CF}/salesforce/account/${sfAccountId}`, {
    headers: { 'x-ambie-token': userToken },
    validateStatus: status => authApi.handleUnauthorized(status)
  })
    .then(response => {
      if ((response.status === 500) && (response.data.error === 'Not a parent account')) {
        store.dispatch(sfAction.notAParentAccount())
      } else if (response.data.account.Id.includes(sfAccountId))
        store.dispatch(sfAction.accountLoaded(response.data))
      else
        console.error("McFacey, try harder!", response.data.account.Id, sfAccountId);
    })
    .catch(e => {
      console.error("I hate you McFacey!", e);
    });
};

export const getParentAccountByName = (clientName, userToken) => {
  // console.log('Starting get account by name function:', clientName, userToken);
  return axios.get(`${HOST_CF}/salesforce/account`, {
    headers: { 'x-ambie-token': userToken },
    params: { clientName },
    validateStatus: status => authApi.handleUnauthorized(status)
  })
    .then(response => {
      // console.log('Response:', response.data);
      return response.data
    })
    .catch(e => {
      console.error("API Gateway error", e.message);
    });
};

export const startExpansionProcess = (clientId, emailAddresses, emailText, links, userToken) => {
  console.log('Starting get account by name function:', clientId);
  return axios.post(`${HOST_CF}/salesforce/account/${clientId}`, {
    emailAddresses, emailText, links
  }, {
    headers: { 'x-ambie-token': userToken },
    validateStatus: status => authApi.handleUnauthorized(status)
  })
    .then(response => response.data)
    .catch(e => {
      console.error("API Gateway error", e.message);
    });
};

export const saveAccountInfo = (accountInfo, thingsToUpdate, userToken) => {
  //debugger
  store.dispatch(sfAction.savingAccount());
  return axios.post(`${HOST_CF}/salesforce/account`, {
    accountInfo,
    thingsToUpdate,
  }, {
    headers: { 'x-ambie-token': userToken },
    validateStatus: status => authApi.handleUnauthorized(status)
  })
    .then(response => {
      store.dispatch(sfAction.accountsCreatedOnOlympus())
    })
    .catch(e => {
      console.error("I hate you McFacey!", e);
    });
}

export const updateParentAccount = (linkType, payload, userToken) => {
  store.dispatch(sfAction.savingAccount());
  //return axios.post(`http://127.0.0.1:3000/salesforce/account`, {
  return axios.post(`${HOST_CF}/salesforce/account`, {
    linkType,
    accountType: 'parent',
    payload,
  }, {
    headers: { 'x-ambie-token': userToken },
    validateStatus: status => authApi.handleUnauthorized(status)
  })
    .then(response => {
      //debugger;
      if (response.status === 200) {
        store.dispatch(sfAction.changeLinkedClient({
          id: response.data.newClient.id,
          contact_name: response.data.newClient.contact_name,
          name: response.data.newClient.name,
        }));
        store.dispatch(sfAction.accountsCreatedOnOlympus())
      } else {
        store.dispatch(sfAction.accountsCreationError(response.data.error));
      }
    })
    .catch(e => {
      console.error("I hate you McFacey!", e);
    });
}

export const updateBusinessAccount = (linkType, payload, userToken) => {
  //debugger;
  store.dispatch(sfAction.savingAccount());
  //return axios.post(`http://127.0.0.1:3000/salesforce/account`, {
  return axios.post(`${HOST_CF}/salesforce/account`, {
    linkType,
    accountType: 'business',
    payload,
  }, {
    headers: { 'x-ambie-token': userToken },
    validateStatus: status => authApi.handleUnauthorized(status)
  })
    .then(response => {
      //debugger;
      if (response.status === 200) {
        store.dispatch(sfAction.changeLinkedBusiness({
          sfID: payload.sfID,
          ambieId: response.data.newClient.id,
          contactName: response.data.newClient.contact_name,
          name: response.data.newClient.name,
        }));
        store.dispatch(sfAction.accountsCreatedOnOlympus());
      } else {
        store.dispatch(sfAction.accountsCreationError(response.data.error));
      }
    })
    .catch(e => {
      console.error("I hate you McFacey!", e);
    });
}

export const updateAreaAccount = (linkType, payload, userToken) => {
  //debugger;
  store.dispatch(sfAction.savingAccount());
  //return axios.post(`http://127.0.0.1:3000/salesforce/account`, {
  return axios.post(`${HOST_CF}/salesforce/account`, {
    linkType,
    accountType: 'area',
    payload,
  }, {
    headers: { 'x-ambie-token': userToken },
    validateStatus: status => authApi.handleUnauthorized(status)
  })
    .then(response => {
      //debugger;
      if (response.status === 200) {
        store.dispatch(sfAction.changeLinkedArea({
          sfID: payload.sfID,
          ambieId: response.data.newClient.id,
          name: response.data.newClient.name,
        }));
        store.dispatch(sfAction.accountsCreatedOnOlympus());
      } else {
        store.dispatch(sfAction.accountsCreationError(response.data.error));
      }
    })
    .catch(e => {
      console.error("I hate you McFacey!", e);
    });
}