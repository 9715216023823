//auth actions
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const CHANGE_CURRENT_CLIENT_ID = 'CHANGE_CURRENT_CLIENT_ID';
export const CHANGE_CLIENTS_LIST = 'CHANGE_CLIENTS_LIST';
export const AUTH_DENIED_ERROR = 'AUTH_DENIED_ERROR';
export const UPDATE_CLIENT_COMMERCIAL_RULE = 'UPDATE_CLIENT_COMMERCIAL_RULE';
export const CHANGE_CLIENT_EMAIL = 'CHANGE_CLIENT_EMAIL';
export const AUTH_CLIENT_NAME_UPDATED = 'AUTH_CLIENT_NAME_UPDATED';

//login actions
export const TOGGLE_PASSWORD_VISIBILITY = 'TOGGLE_PASSWORD_VISIBILITY';
export const SET_LOGIN_VALID = 'SET_LOGIN_VALID';
export const SET_LOGIN_INVALID = 'SET_LOGIN_INVALID';
export const SET_SHAKE_ANIMATION_ON = 'SET_SHAKE_ANIMATION_ON';
export const SET_SHAKE_ANIMATION_OFF = 'SET_SHAKE_ANIMATION_OFF';

//layout actions
export const CHANGE_CONTAINER_HEIGHT = 'CHANGE_CONTAINER_HEIGHT';
export const SHOW_SPINNER = 'SHOW_SPINNER';
export const HIDE_SPINNER = 'HIDE_SPINNER';
export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';
export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION';
export const SHOW_FLASH_MESSAGE = 'SHOW_FLASH_MESSAGE';
export const HIDE_FLASH_MESSAGE = 'HIDE_FLASH_MESSAGE';
export const CHANGE_FORGOT = 'CHANGE_FORGOT';
export const CHANGE_USER_MENU = 'CHANGE_USER_MENU';
export const CHANGE_MORE_MENU = 'CHANGE_MORE_MENU';
export const CHANGE_CLIENT_MENU = 'CHANGE_CLIENT_MENU';
export const CHANGE_APHRODITE_SELECTED_MENU = 'CHANGE_APHRODITE_SELECTED_MENU';
export const CHANGE_EUTERPE_MENU = 'CHANGE_EUTERPE_MENU';
export const CHANGE_MINERVA_MENU = 'CHANGE_MINERVA_MENU';
export const CHANGE_MOBILE_MENU = 'CHANGE_MOBILE_MENU';
export const CHANGE_SHOW_CHANGE_SCHEDULE_DIALOG = 'CHANGE_SHOW_CHANGE_SCHEDULE_DIALOG';
export const CHANGE_JOKE = 'CHANGE_JOKE';
export const CHANGE_IS_MEDIA_PLAYING = 'CHANGE_IS_MEDIA_PLAYING';

//locations actions
export const CHANGE_LOCATIONS_SEARCH_QUERY = 'CHANGE_LOCATIONS_SEARCH_QUERY';
export const CHANGE_LOCATIONS = 'CHANGE_LOCATIONS';
export const CHANGE_AREAS = 'CHANGE_AREAS';
export const CHANGE_AREA_SCHEDULE_INFO = 'CHANGE_AREA_SCHEDULE_INFO';
export const CHANGE_LOCATION_TIMEZONE = 'CHANGE_LOCATION_TIMEZONE';
export const UPDATE_AREA_COMMERCIAL_RULE = 'UPDATE_AREA_COMMERCIAL_RULE';
export const SHOW_ACTIVATION_MODAL = 'SHOW_ACTIVATION_MODAL';
export const HIDE_ACTIVATION_MODAL = 'HIDE_ACTIVATION_MODAL';
export const UPDATE_ACTIVATION_MODAL = 'UPDATE_ACTIVATION_MODAL';
export const HIDE_ACTIVATION_MODAL_BUTTONS = 'HIDE_ACTIVATION_MODAL_BUTTONS';
export const UPDATE_AREA_ACTIVE_STATE = 'UPDATE_AREA_ACTIVE_STATE';
export const UPDATE_LOCATION_ACTIVE_STATE = 'UPDATE_LOCATION_ACTIVE_STATE';
export const UPDATE_AREA_STREAMING_STATE = 'UPDATE_AREA_STREAMING_STATE';
export const CHANGE_LOCATION_EMAIL = 'CHANGE_LOCATION_EMAIL';
export const UPDATE_DEVICE_DONTSKIPDUPLICATES_STATE = 'UPDATE_DEVICE_DONTSKIPDUPLICATES_STATE';
export const CHANGE_SELECTED_OVERRIDE_PLAYLIST = 'CHANGE_SELECTED_OVERRIDE_PLAYLIST';
export const CHANGE_SELECTED_OVERRIDE_DURATION = 'CHANGE_SELECTED_OVERRIDE_DURATION';
export const LOCATION_BUSINESS_NAME_UPDATED = 'LOCATION_BUSINESS_NAME_UPDATED';
export const LOCATION_AREA_NAME_UPDATED = 'LOCATION_AREA_NAME_UPDATED';

//schedules actions
export const CHANGE_SCHEDULES = 'CHANGE_SCHEDULES';
export const CHANGE_SELECTED_SCHEDULE = 'CHANGE_SELECTED_SCHEDULE';
export const CHANGE_SCHEDULE_EDIT_MODE = 'CHANGE_SCHEDULE_EDIT_MODE';
export const CHANGE_SHOW_SCHEDULE_POPUP = 'CHANGE_SHOW_SCHEDULE_POPUP';
export const CHANGE_EDIT_SCHEDULE_OBJ = 'CHANGE_EDIT_SCHEDULE_OBJ';
export const CHANGE_SHOW_SAVE_SCHEDULE_POPUP = 'CHANGE_SHOW_SAVE_SCHEDULE_POPUP';
export const CHANGE_SHOW_SCHEDULE_LOCATIONS_POPUP = 'CHANGE_SHOW_SCHEDULE_LOCATIONS_POPUP';
export const CHANGE_SHOW_SCHEDULE_DELETE_CONFIRMATION_POPUP = 'CHANGE_SHOW_SCHEDULE_DELETE_CONFIRMATION_POPUP';
export const CHANGE_SHOW_DISCARD_SCHEDULE_EDIT = 'CHANGE_SHOW_DISCARD_SCHEDULE_EDIT';
export const CHANGE_IS_SCHEDULE_EDITED = 'CHANGE_IS_SCHEDULE_EDITED';
export const CHANGE_SHOW_SCHEDULE_OVERLAPS_CONFIRMATION_POPUP = 'CHANGE_SHOW_SCHEDULE_OVERLAPS_CONFIRMATION_POPUP';
export const CHANGE_IS_EDIT_PLAYLIST = 'CHANGE_IS_EDIT_PLAYLIST';
export const CHANGE_IS_ADD_EDIT_SCHED_VALID = 'CHANGE_IS_ADD_EDIT_SCHED_VALID';
export const CHANGE_CHANNELS_FOR_CLIENT = 'CHANGE_CHANNELS_FOR_CLIENT';
export const CHANGE_SHOW_ADD_NEW_SCHEDULES_POPUP = 'CHANGE_SHOW_ADD_NEW_SCHEDULES_POPUP';
export const CHANGE_SHOW_DELETE_SCHEDULE_POPUP = 'CHANGE_SHOW_DELETE_SCHEDULE_POPUP';
export const CHANGE_TIMEZONES = 'CHANGE_TIMEZONES';
export const CHANGE_TIMEZONE = 'CHANGE_TIMEZONE';
export const CHANGE_TIMEZONE_OFFSET = 'CHANGE_TIMEZONE_OFFSET';
export const CHANGE_TIMES = 'CHANGE_TIMES';
export const CHANGE_EXPANDED_VIEW = 'CHANGE_EXPANDED_VIEW';

//channels actions
export const CHANGE_CHANNELS = 'CHANGE_CHANNELS';
export const CHANGE_SHOW_PREVIEW_SCREEN = 'CHANGE_SHOW_PREVIEW_SCREEN';
export const CHANGE_CURRENT_CHANNEL_INDEX = 'CHANGE_CURRENT_CHANNEL_INDEX';
export const CHANGE_SHOW_CURATOR_CONTACT_FORM = 'CHANGE_SHOW_CURATOR_CONTACT_FORM';

//feedback actions
export const SET_TABLE_CONFIG = 'SET_TABLE_CONFIG';
export const CHANGE_FEEDBACK = 'CHANGE_FEEDBACK';
export const SELECT_FEEDBACK = 'SELECT_FEEDBACK';
export const DESELECT_FEEDBACK = 'DESELECT_FEEDBACK';
export const DESELECT_ALL_FEEDBACK = 'DESELECT_ALL_FEEDBACK';
export const CREATING_FEEDBACK_CSV = 'CREATING_FEEDBACK_CSV';
export const FEEDBACK_CSV_CREATED = 'FEEDBACK_CSV_CREATED';
export const FEEDBACK_CSV_CREATION_FAILED = 'FEEDBACK_CSV_CREATION_FAILED';
export const FEEDBACK_CSV_CLOSE_MODAL = 'FEEDBACK_CSV_CLOSE_MODAL';

//track repeats actions
export const CHANGE_TRACKS = 'CHANGE_TRACKS';
export const CHANGE_SHOW_DETAILS = 'CHANGE_SHOW_DETAILS';
export const CHANGE_SELECTED_TRACK = 'CHANGE_SELECTED_TRACK';
export const CHANGE_DEVICES = 'CHANGE_DEVICES';
export const CHANGE_FILTERS = 'CHANGE_FILTERS';

//not used tracks
export const CHANGE_NOT_USED_TRACKS = 'CHANGE_NOT_USED_TRACKS';
export const CHANGE_NOT_USED_FILTERS = 'CHANGE_NOT_USED_FILTERS';
export const DELETE_NOT_USED_TRACKS = 'DELETE_NOT_USED_TRACKS';
export const OPEN_DELETE_CONFIRMATION_MODAL = 'OPEN_DELETE_CONFIRMATION_MODAL';
export const CLOSE_DELETE_CONFIRMATION_MODAL = 'CLOSE_DELETE_CONFIRMATION_MODAL';
export const SHOW_CONFIRMATION_DELETION_ALL_TRACKS_NOT_USED = 'SHOW_CONFIRMATION_DELETION_ALL_TRACKS_NOT_USED';
export const HIDE_CONFIRMATION_DELETION_ALL_TRACKS_NOT_USED = 'HIDE_CONFIRMATION_DELETION_ALL_TRACKS_NOT_USED';
export const OPEN_CSV_POPUP = 'OPEN_CSV_POPUP';
export const CLOSE_CSV_POPUP = 'CLOSE_CSV_POPUP';
export const UPDATE_CSV_DATA = 'UPDATE_CSV_DATA';
export const TOGGLE_DISPLAY_DELETE = 'TOGGLE_DISPLAY_DELETE';
export const TOGGLE_MONTH_FILTER = 'TOGGLE_MONTH_FILTER';

//track upload messages
export const CHANGE_FZMESSAGE = 'CHANGE_FZMESSAGE';
export const CHANGE_FZCURFILENO = 'CHANGE_FZCURFILENO';
export const CHANGE_FZTOTALFILENO = 'CHANGE_FZTOTALFILENO';
export const CHANGE_FZSHOWPROGRESS = 'CHANGE_FZSHOWPROGRESS';
export const CHANGE_FZFILENAMES = 'CHANGE_FZFILENAMES';
export const CHANGE_IS_OVER_FZ = 'CHANGE_IS_OVER_FZ';
export const CHANGE_EXISTING_TRACKS = 'CHANGE_EXISTING_TRACKS';
export const CHANGE_SHOW_DISMISS_TRACK = 'CHANGE_SHOW_DISMISS_TRACK';
export const CHANGE_SHOW_EDIT_TRACK = 'CHANGE_SHOW_EDIT_TRACK';
export const CHANGE_SELECTED_TRACK_FOR_EDIT = 'CHANGE_SELECTED_TRACK_FOR_EDIT';
export const RESET_SELECTED_TRACK_FOR_EDIT = 'RESET_SELECTED_TRACK_FOR_EDIT';
export const OPEN_DISMISS_ALL_POPUP = 'OPEN_DISMISS_ALL_POPUP';
export const CLOSE_DISMISS_ALL_POPUP = 'CLOSE_DISMISS_ALL_POPUP';
export const ADD_TO_FILE_TO_CONFIRM = 'ADD_TO_FILE_TO_CONFIRM';
export const REMOVE_FROM_FILE_TO_CONFIRM = 'REMOVE_FROM_FILE_TO_CONFIRM';

// tracks
export const CHANGE_ALL_TRACKS_LIST = 'CHANGE_ALL_TRACKS_LIST';
export const CHANGE_ALL_ARTISTS_LIST = 'CHANGE_ALL_ARTISTS_LIST';
export const CHANGE_ALL_CATEGORIES_LIST = 'CHANGE_ALL_CATEGORIES_LIST';
export const CHANGE_IS_SEARCHING_TRACKS = 'CHANGE_IS_SEARCHING_TRACKS';
export const CLOSE_EDIT_TRACKS_TITLE = 'CLOSE_EDIT_TRACKS_TITLE';
export const OPEN_EDIT_TRACKS_TITLE = 'OPEN_EDIT_TRACKS_TITLE';
export const UPDATE_TRACKS_TITLE = 'UPDATE_TRACK_TITLE';
export const TRACK_TITLE_SAVED = 'TRACK_TITLE_SAVED';
export const CLEAR_TRACKS = 'CLEAR_TRACKS';

//device status
export const CHANGE_DEVICE_STATUS_LIST = 'CHANGE_DEVICE_STATUS_LIST';
export const UPDATE_DEVICE = 'UPDATE_DEVICE';
export const UPDATING_DEVICE = 'UPDATING_DEVICE';

//web leads
export const UPDATE_WEB_LEADS_LIST = 'UPDATE_WEB_LEADS_LIST';

//core channels
export const GET_SELECTED_CORE_CHANNEL_TRACKS = 'GET_SELECTED_CORE_CHANNEL_TRACKS';
export const GET_CORE_CHANNELS = 'GET_CORE_CHANNELS';
export const GET_CORE_CHANNEL_INFO = 'GET_CORE_CHANNEL_INFO';
export const CHANGE_CORE_CHANNEL_SAMPLERS = 'CHANGE_CORE_CHANNEL_SAMPLERS';
export const CHANGE_CORE_CHANNEL_FILTER = 'CHANGE_CORE_CHANNEL_FILTER';
export const START_CHECK_FOR_BATCH_UPDATE_FILE = 'START_CHECK_FOR_BATCH_UPDATE_FILE';
export const CHECK_FOR_BATCH_UPDATE_FILE_DONE = 'CHECK_FOR_BATCH_UPDATE_FILE_DONE';
export const UPDATE_XLS_JSON_DATA = 'UPDATE_XLS_JSON_DATA';
export const UPDATE_XLS_FILE_NAME = 'UPDATE_XLS_FILE_NAME';
export const BATCH_UPDATE_DONE = 'BATCH_UPDATE_DONE';
export const BATCH_UPDATE_STARTED = 'BATCH_UPDATE_STARTED';
export const BATCH_UPDATE_SENT = 'BATCH_UPDATE_SENT';
export const ADD_TRACK_TO_CORE_CHANNEL = 'ADD_TRACK_TO_CORE_CHANNEL';
export const TOGGLE_ACTIVE_TRACK = 'TOGGLE_ACTIVE_TRACK';
export const GET_SELECTED_CORE_CHANNEL_INFO = 'GET_SELECTED_CORE_CHANNEL_INFO';
export const ACTIVATE_CORE_CHANNEL = 'ACTIVATE_CORE_CHANNEL';
export const DEACTIVATE_CORE_CHANNEL = 'DEACTIVATE_CORE_CHANNEL';
export const RESET_SELECTED_CORE_CHANNEL = 'RESET_SELECTED_CORE_CHANNEL';
export const SAVING_CORE_CHANNEL = 'SAVING_CORE_CHANNEL';
export const SAVED_CORE_CHANNEL = 'SAVED_CORE_CHANNEL';
export const DISMISS_SAVED_CORE_CHANNEL = 'DISMISS_SAVED_CORE_CHANNEL';
export const ADD_TRACK_TO_SAMPLERS = 'ADD_TRACK_TO_SAMPLERS';
export const CHANGE_CORE_CHANNEL_NAME = 'CHANGE_CORE_CHANNEL_NAME';
export const CHANGE_CORE_CHANNEL_DESCRIPTION = 'CHANGE_CORE_CHANNEL_DESCRIPTION';
export const CHANGE_CORE_CHANNEL_ENERGY = 'CHANGE_CORE_CHANNEL_ENERGY';
export const REMOVE_SAMPLER = 'REMOVE_SAMPLER';
export const CREATING_NEW_CORE_CHANNEL = 'CREATING_NEW_CORE_CHANNEL';
export const NEW_CORE_CHANNEL_CREATED = 'NEW_CORE_CHANNEL_CREATED';
export const NEW_CORE_CHANNEL_FAILED = 'NEW_CORE_CHANNEL_FAILED';
export const RESET_CORE_CHANNEL_CREATION = 'RESET_CORE_CHANNEL_CREATION';
export const UPDATE_ENERGY_LEVELS = 'UPDATE_ENERGY_LEVELS';
export const NEW_CORE_CHANNEL_NAME_IN_USE = 'NEW_CORE_CHANNEL_NAME_IN_USE';
export const SHOW_TRACK_SEARCH_MODAL = 'SHOW_TRACK_SEARCH_MODAL';
export const HIDE_TRACK_SEARCH_MODAL = 'HIDE_TRACK_SEARCH_MODAL';
export const BATCH_UPDATE_SUCCESS = 'BATCH_UPDATE_SUCCESS';

// commercial rules
export const COMMERCIALRULES_STORE_CHANNELS = 'COMMERCIALRULES_STORE_CHANNELS';
export const COMMERCIALRULES_OPEN_MODAL = 'COMMERCIALRULES_OPEN_MODAL';
export const COMMERCIALRULES_CLOSE_MODAL = 'COMMERCIALRULES_CLOSE_MODAL';
export const COMMERCIALRULES_CHANNEL_SEARCH = 'COMMERCIALRULES_CHANNEL_SEARCH';
export const CHANGE_AREA_COMMERCIAL_RULES = 'CHANGE_AREA_COMMERCIAL_RULES';
export const CHANGE_CLIENT_COMMERCIAL_RULES = 'CHANGE_CLIENT_COMMERCIAL_RULES';
export const CHANGE_PLAYLIST_COMMERCIAL_RULES = 'CHANGE_PLAYLIST_COMMERCIAL_RULES';
export const CHANGE_ALL_COMMERCIAL_RULES = 'CHANGE_ALL_COMMERCIAL_RULES';

//playlists
export const GET_PLAYLISTS = 'GET_PLAYLISTS';
export const SAVED_PLAYLISTS = 'SAVED_PLAYLISTS';
export const SELECT_PLAYLIST = 'SELECT_PLAYLIST';
export const DESELECT_PLAYLIST = 'DESELECT_PLAYLIST';
export const UNPUBLISH_PLAYLISTS = 'UNPUBLISH_PLAYLISTS';
export const PUBLISH_PLAYLISTS = 'PUBLISH_PLAYLISTS';
export const HIDE_YESNO_POPUP = 'HIDE_YESNO_POPUP';
export const SHOW_YESNO_POPUP = 'SHOW_YESNO_POPUP';
export const UPDATE_PLAYLIST_FILTERS = 'UPDATE_PLAYLIST_FILTERS';
export const DESELECT_ALL_PLAYLISTS = 'DESELECT_ALL_PLAYLISTS';
export const UPDATE_LATEST_QUERY = 'UPDATE_LATEST_QUERY';
export const UPDATE_PLAYLIST_DETAIL = 'UPDATE_PLAYLIST_DETAIL';
export const CLEAR_PLAYLIST_DETAILS = 'CLEAR_PLAYLIST_DETAILS';
export const ROTATION_START = 'ROTATION_START';
export const ROTATION_DONE = 'ROTATION_DONE';
export const SAVING_PLAYLIST = 'SAVING_PLAYLIST';
export const DISMISS_SAVED_PLAYLIST = 'DISMISS_SAVED_PLAYLIST';
export const UPDATE_PLAYLIST_SUGGESTIONS = 'UPDATE_PLAYLIST_SUGGESTIONS';
export const UPDATE_LATEST_QUERY_SUGGESTION = 'UPDATE_LATEST_QUERY_SUGGESTION';
export const START_SEARCHING_PLAYLIST_SUGGESTIONS = 'START_SEARCHING_PLAYLIST_SUGGESTIONS';
export const GETTING_PLAYLIST_FROM_API = 'GETTING_PLAYLIST_FROM_API';
export const GETTING_PLAYLIST_TRACKS_FROM_API = 'GETTING_PLAYLIST_TRACKS_FROM_API';
export const UPDATE_PLAYLIST_TRACKS_DETAIL = 'UPDATE_PLAYLIST_TRACKS_DETAIL';
export const GETTING_PLAYLIST_ROTATION_FROM_API = 'GETTING_PLAYLIST_ROTATION_FROM_API';
export const UPDATE_PLAYLIST_ROTATION_DETAIL = 'UPDATE_PLAYLIST_ROTATION_DETAIL';

// playlist info/edit
export const CHANGE_PLAYLIST_INFO = 'CHANGE_PLAYLIST_INFO';
export const RESET_PLAYLIST_INFO = 'RESET_PLAYLIST_INFO';
export const CHANGE_PLAYLIST_INFO_LOCATIONS = 'CHANGE_PLAYLIST_INFO_LOCATIONS';
export const CHANGE_PLAYLIST_INFO_COMPOSITION = 'CHANGE_PLAYLIST_INFO_COMPOSITION';
export const CHANGE_PLAYLIST_INFO_TRACKS_RULES = 'CHANGE_PLAYLIST_INFO_TRACKS_RULES';
export const CHANGE_PLAYLIST_INFO_ARTIST_RULES = 'CHANGE_PLAYLIST_INFO_ARTIST_RULES';
export const CHANGE_PLAYLIST_INFO_CATEGORY_RULES = 'CHANGE_PLAYLIST_INFO_CATEGORY_RULES';
export const CHANGE_PLAYLIST_INFO_COMMERCIAL_RULES = 'CHANGE_PLAYLIST_INFO_COMMERCIAL_RULES';
export const CHANGE_PLAYLIST_INFO_TRACKS = 'CHANGE_PLAYLIST_INFO_TRACKS';
export const CHANGE_PLAYLIST_INFO_COMMERCIAL_RULES_DATES = 'CHANGE_PLAYLIST_INFO_COMMERCIAL_RULES_DATES'

//websocketconnection
export const WS_CONNECT = 'WS_CONNECTED';
export const WS_DISCONNECT = 'WS_DISCONNECTED';
export const WS_CONNECTING = 'WS_CONNECTING';

//Accounts
export const ACCOUNT_UPDATE_ACCOUNT_INFO = 'ACCOUNT_UPDATE_ACCOUNT_INFO';
export const ACCOUNT_UPDATE_BUSINESS_INFO = 'ACCOUNT_UPDATE_BUSINESS_INFO';
export const ACCOUNT_UPDATE_AREA_INFO = 'ACCOUNT_UPDATE_AREA_INFO';
export const ACCOUNT_UPDATE_DEVICE_INFO = 'ACCOUNT_UPDATE_DEVICE_INFO';
export const ACCOUNT_UPDATE_CHILD_DEVICE_INFO = 'ACCOUNT_UPDATE_CHILD_DEVICE_INFO';
export const ACCOUNT_ACCOUNT_LOADED = 'ACCOUNT_ACCOUNT_LOADED';
export const ACCOUNT_ACCOUNT_SAVING = 'ACCOUNT_ACCOUNT_SAVING';
export const ACCOUNT_ACCOUNT_SAVED = 'ACCOUNT_ACCOUNT_SAVED';
export const ACCOUNT_SELECT_CLIENT = 'ACCOUNT_SELECT_CLIENT';
export const ACCOUNT_NOT_A_PARENT_ACCOUNT = 'ACCOUNT_NOT_A_PARENT_ACCOUNT';
export const ACCOUNT_ACCOUNT_CREATED_ON_OLYMPUS = 'ACCOUNT_ACCOUNT_CREATED_ON_OLYMPUS';
export const ACCOUNT_DEVICE_LOADED = 'ACCOUNT_DEVICE_LOADED';
export const ACCOUNT_SAVING_DEVICE = 'ACCOUNT_SAVING_DEVICE';
export const ACCOUNT_DEVICE_SAVED = 'ACCOUNT_DEVICE_SAVED';
export const ACCOUNT_CHANGE_FORM_TYPE = 'ACCOUNT_CHANGE_FORM_TYPE';
export const ACCOUNT_CHANGE_LINKED_CLIENT = 'ACCOUNT_CHANGE_LINKED_CLIENT';
export const ACCOUNT_CONFIRM_NEW_CLIENT = 'ACCOUNT_CONFIRM_NEW_CLIENT';
export const ACCOUNT_CHANGE_LINKED_BUSINESS = 'ACCOUNT_CHANGE_LINKED_BUSINESS';
export const ACCOUNT_CONFIRM_AREA = 'ACCOUNT_CONFIRM_AREA';
export const ACCOUNT_CHANGE_LINKED_AREA = 'ACCOUNT_CHANGE_LINKED_AREA';
export const ACCOUNT_CONFIRM_BUSINESS = 'ACCOUNT_CONFIRM_BUSINESS';
export const ACCOUNT_ACCOUNT_CREATION_ERROR = 'ACCOUNT_ACCOUNT_CREATION_ERROR';
export const CREATE_NEW_BUSINESS = 'CREATE_NEW_BUSINESS';
export const CREATE_NEW_AREA = 'CREATE_NEW_AREA';

//SF
export const SF_UPDATE_ACCOUNT_INFO = 'SF_UPDATE_ACCOUNT_INFO';
export const SF_UPDATE_BUSINESS_INFO = 'SF_UPDATE_BUSINESS_INFO';
export const SF_UPDATE_AREA_INFO = 'SF_UPDATE_AREA_INFO';
export const SF_UPDATE_DEVICE_INFO = 'SF_UPDATE_DEVICE_INFO';
export const SF_UPDATE_CHILD_DEVICE_INFO = 'SF_UPDATE_CHILD_DEVICE_INFO';
export const SF_ACCOUNT_LOADED = 'SF_ACCOUNT_LOADED';
export const SF_ACCOUNT_SAVING = 'SF_ACCOUNT_SAVING';
export const SF_ACCOUNT_SAVED = 'SF_ACCOUNT_SAVED';
export const SF_SELECT_CLIENT = 'SF_SELECT_CLIENT';
export const SF_NOT_A_PARENT_ACCOUNT = 'SF_NOT_A_PARENT_ACCOUNT';
export const SF_ACCOUNT_CREATED_ON_OLYMPUS = 'SF_ACCOUNT_CREATED_ON_OLYMPUS';
export const SF_DEVICE_LOADED = 'SF_DEVICE_LOADED';
export const SF_SAVING_DEVICE = 'SF_SAVING_DEVICE';
export const SF_DEVICE_SAVED = 'SF_DEVICE_SAVED';
export const SF_CHANGE_FORM_TYPE = 'SF_CHANGE_FORM_TYPE';
export const SF_CHANGE_LINKED_CLIENT = 'SF_CHANGE_LINKED_CLIENT';
export const SF_CONFIRM_NEW_CLIENT = 'SF_CONFIRM_NEW_CLIENT';
export const SF_CHANGE_LINKED_BUSINESS = 'SF_CHANGE_LINKED_BUSINESS';
export const SF_CONFIRM_AREA = 'SF_CONFIRM_AREA';
export const SF_CHANGE_LINKED_AREA = 'SF_CHANGE_LINKED_AREA';
export const SF_CONFIRM_BUSINESS = 'SF_CONFIRM_BUSINESS';
export const SF_ACCOUNT_CREATION_ERROR = 'SF_ACCOUNT_CREATION_ERROR';

// COMMON
export const TOGGLE_SELECT_TABLE_ROW = 'TOGGLE_SELECT_TABLE_ROW';
export const SELECT_TABLE_ROWS = 'SELECT_TABLE_ROWS';
export const DESELECT_ALL_TABLE_ROWS = 'DESELECT_ALL_TABLE_ROWS';
export const CHANGE_TABLE_SEARCH_QUERY = 'CHANGE_TABLE_SEARCH_QUERY';
export const CHANGE_TABLE_ROW_DATA = 'CHANGE_TABLE_ROW_DATA';

//tester actions
export const CHANGE_BATCHES = 'CHANGE_BATCHES';
export const GET_AVAILABLE_PLAYER = 'GET_AVAILABLE_PLAYER';
export const UPDATE_BATCH_DEVICES = 'UPDATE_BATCH_DEVICES';
export const OPEN_PAPERTRAIL = 'OPEN_PAPERTRAIL';
export const CLOSE_PAPERTRAIL = 'CLOSE_PAPERTRAIL';
export const ADD_NEW_BATCH = 'ADD_NEW_BATCH';
export const UPDATE_BATCH_NAME = 'UPDATE_BATCH_NAME';
export const MARK_BATCH_DONE = 'MARK_BATCH_DONE';
export const MARK_BATCH_IN_PROGRESS = 'MARK_BATCH_IN_PROGRESS';
export const MARK_BATCH_ERRORED = 'MARK_BATCH_ERRORED';
export const SAVE_BATCHES = 'SAVE_BATCHES';
export const LOAD_BATCHES = 'LOAD_BATCHES';

//TA
export const TA_PICKING_TRACKLIST = 'TA_PICKING_TRACKLIST';
export const TA_PICK_DEVICE = 'TA_PICK_DEVICE';
export const TA_PICK_DATES = 'TA_PICK_DATES';
export const TA_SEARCHING = 'TA_SEARCHING';
export const TA_SEARCH_DONE = 'TA_SEARCH_DONE';
export const TA_NEW_TRACKLIST = 'TA_NEW_TRACKLIST';
export const TA_TRACKLIST_DETAIL = 'TA_TRACKLIST_DETAIL';
export const TA_REPICK_TRACKLIST = 'TA_REPICK_TRACKLIST';


//Login Tokens
export const CHANGE_LOGIN_TOKEN_LIST = 'CHANGE_LOGIN_TOKEN_LIST';
export const UPDATE_LOGIN_TOKEN = 'UPDATE_LOGIN_TOKEN';
export const UPDATING_LOGIN_TOKEN = 'UPDATING_LOGIN_TOKEN';
export const LOGIN_TOKEN_HIDE_DELETE_POPUP = 'LOGIN_TOKEN_HIDE_DELETE_POPUP';
export const LOGIN_TOKEN_SHOW_DELETE_POPUP = 'LOGIN_TOKEN_SHOW_DELETE_POPUP';

//Artists
export const ARTISTS_UPDATED = 'ARTISTS_UPDATED';
export const ARTISTS_CREATED = 'ARTISTS_CREATED';
export const ARTISTS_DELETED = 'ARTISTS_DELETED';
export const ARTISTS_GOT_NEW = 'ARTISTS_GOT_NEW';
export const ARTIST_START_SEARCHING = 'ARTIST_START_SEARCHING';
export const ARTIST_OPEN_MODAL = 'ARTIST_OPEN_MODAL';
export const ARTIST_CLOSE_MODAL = 'ARTIST_CLOSE_MODAL';