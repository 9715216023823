import io from 'socket.io-client';

import { WEBSOCKET_STREAMING_URL } from '@config';
import WebSocketEmitter from '@helpers/WebSocketHelper/WebSocketEmitter';
// import WebSocketListener from '@helpers/WebSocketHelper/WebSocketListener';

class WebSocketHelper {

  static set userToken(userToken) {
    WebSocketHelper._userToken = userToken;
  }
  
  static get userToken() {
    return WebSocketHelper._userToken;
  }

  static set socket(socket) {
    WebSocketHelper._socket = socket;
  }
  
  static get socket() {
    return WebSocketHelper._socket;
  }

  // static set listener(listener) {
  //   WebSocketHelper._listener = listener;
  // }
  
  // static get listener() {
  //   return WebSocketHelper._listener;
  // }

  static set emitter(emitter) {
    WebSocketHelper._emitter = emitter;
  }
  
  static get emitter() {
    return WebSocketHelper._emitter;
  }
  
  static get isConnected() {
    return WebSocketHelper.socket ? WebSocketHelper.socket.connected : false;
  }

  static init = (userToken) => {
    const query = { query: `token=${userToken}&userType=olympus` };
    if (WebSocketHelper.socket) {
      WebSocketHelper.socket.disconnect();
    }
    WebSocketHelper.socket = io(WEBSOCKET_STREAMING_URL, query);
    WebSocketHelper.userToken = userToken;
    WebSocketHelper.emitter = new WebSocketEmitter(WebSocketHelper.socket, userToken);
    // WebSocketHelper.listener = new WebSocketListener(WebSocketHelper.socket, userToken);
    // WebSocketHelper.listener.connect();
  }

  // static get listen() {
  //   WebSocketHelper.listener.socket = WebSocketHelper.socket;
  //   const listeners = {
  //     disconnect: WebSocketHelper.listener.disconnect,
  //     disconnectOnMultipleLogins: WebSocketHelper.listener.disconnectOnMultipleLogins,
  //     streamingAllowed: WebSocketHelper.listener.streamingAllowed,
  //     streamingNotAllowed: WebSocketHelper.listener.streamingNotAllowed,
  //     streamingStatus: WebSocketHelper.listener.streamingStatus,
  //     playingTrack: WebSocketHelper.listener.playingTrack,
  //     volume: WebSocketHelper.listener.volume,
  //   }
  //   return {
  //     ...listeners,
  //     connect: WebSocketHelper.listener.connect,
  //     reconnect: WebSocketHelper.listener.reconnect,
  //     listen: (event) => WebSocketHelper.listener.listen(event),
  //     all: () => {
  //       Object.keys(listeners).forEach(listenerName => listeners[listenerName]());
  //     },
  //   }
  // }

  // use this if you want to control the ambie player via the websocket server
  // static get emit() {
  //   WebSocketHelper.emitter.socket = WebSocketHelper.socket;
  //   return {
  //     webPlayer: {
  //       send: (topic, areaId, deviceSerial, data) => WebSocketHelper.emitter.emit(topic, data),
  //       disconnect: () => WebSocketHelper.emitter.disconnect(),
  //       play: (areaId, deviceSerial) => WebSocketHelper.emitter.play(areaId, deviceSerial),
  //       pause: (areaId, deviceSerial) => WebSocketHelper.emitter.pause(areaId, deviceSerial),
  //       skip: (areaId, deviceSerial) => WebSocketHelper.emitter.skip(areaId, deviceSerial),
  //       volume: (areaId, deviceSerial, volume) => WebSocketHelper.emitter.volume(areaId, deviceSerial, volume),
  //     },
  //     player: {
  //       skip: (areaId, deviceSerial) => WebSocketHelper.emitter.playerSkip(areaId, deviceSerial),
  //       override: (areaId, deviceSerial, playlistId, duration) => WebSocketHelper.emitter.playerOverride(areaId, deviceSerial, playlistId, duration),
  //       play: (areaId, deviceSerial, volume) => WebSocketHelper.emitter.playerPlay(areaId, deviceSerial, volume),
  //       pause: (areaId, deviceSerial, volume) => WebSocketHelper.emitter.playerPause(areaId, deviceSerial, volume),
  //       volume: (areaId, deviceSerial, volume, status) => WebSocketHelper.emitter.playerVolume(areaId, deviceSerial, volume, status),
  //     }
  //   }
  // }

  static get emit() {
    WebSocketHelper.emitter.socket = WebSocketHelper.socket;
    return {
      send: (topic, areaId, deviceSerial, data) => WebSocketHelper.emitter.emit(topic, data),
      disconnect: () => WebSocketHelper.emitter.disconnect(),
      play: (areaId, deviceSerial) => WebSocketHelper.emitter.play(areaId, deviceSerial),
      pause: (areaId, deviceSerial) => WebSocketHelper.emitter.pause(areaId, deviceSerial),
      skip: (areaId, deviceSerial) => WebSocketHelper.emitter.skip(areaId, deviceSerial),
      volume: (areaId, deviceSerial, volume) => WebSocketHelper.emitter.volume(areaId, deviceSerial, volume),
    }
  }
  
}

export default WebSocketHelper;
