import * as types from '../actions/types';

const initalState = {
  tracks: null,
  groupByRepeats : false,
  searchTerm: '',
  showDetails: false,
  selectedTrack: { plays: [] },
  devices: [],
  filters: {
    startDate: null,
    endDate: null,
    device: null
  }
};

export default (state = initalState, action) => {
  switch (action.type) {
    case types.CHANGE_TRACKS:
      return Object.assign({}, state, {
        tracks: action.payload.tracks,
        groupByRepeats: action.payload.groupByRepeats
      });
    case types.CHANGE_SHOW_DETAILS:
      return Object.assign({}, state, {
        showDetails: action.payload
      });
    case types.CHANGE_SELECTED_TRACK:
      return Object.assign({}, state, {
        selectedTrack: action.payload
      });
    case types.CHANGE_DEVICES:
      return Object.assign({}, state, {
        devices: action.payload
      });
    case types.CHANGE_FILTERS:
      return Object.assign({}, state, {
        filters: { ...state.filters, ...action.payload } 
      })
  }

  return state;

};
