import React, { Component } from 'react';
import moment from 'moment';

import Collapsible from 'react-collapsible';
import { FaCalendar, FaUnlock, FaTimes } from 'react-icons/fa';
import { MdHourglassFull } from 'react-icons/md';
import { MdSettingsRemote, MdEdit, MdClose, MdSave } from 'react-icons/md';

import { DEFAULT_TIMEZONE } from '@config';
import { playerSkip } from '@api/locations-api';

import MiniPlayer from './mini-player/mini-player';

import WebSocketHelper from '@helpers/WebSocketHelper/WebSocketHelper';
import PlayingBars from '@common/playing-bars';
import AreaActionButtons from './area-action-buttons';

const MINI_PLAYER_TRANSITION_TIME = 200;

class AreasListItem extends Component {

  state = {
    isPlayerMiniPlayerOpen: false,
    isWebPlayerMiniPlayerOpen: false,
    isEditingAreaName: false,
    newAreaName: '',
  }

  streamingStatus = () => {
    if (this.props.area.streaming && this.props.area.streaming.status) {
      switch (this.props.area.streaming.status) {
        case 'play':
          return '';
        case 'pause':
          return 'Paused';
        default:
          return 'Not Playing';
      }
    } else {
      return 'Not Playing';
    }
  }

  handleSkip = async () => {
    if (this.state.isWebPlayerMiniPlayerOpen) {
      WebSocketHelper.emit.skip(this.props.area.area_id, this.props.area.device.serial);
    } else {
      await playerSkip(this.props.userToken, this.props.area.device.serial);
    }
    this.props.getLocations();
  }

  handlePlayPause = async (status, volume, deviceSerial) => {
    if (this.state.isWebPlayerMiniPlayerOpen) {
      if (status) {
        WebSocketHelper.emit.play(this.props.area.area_id, deviceSerial);
      } else {
        WebSocketHelper.emit.pause(this.props.area.area_id, deviceSerial);
      }
    } else {
      await this.props.handlePlayPause(status, volume, deviceSerial);
    }
    this.props.getLocations();
  }

  handleUpdateVolume = async (status, volume, deviceSerial) => {
    if (this.state.isWebPlayerMiniPlayerOpen) {
      WebSocketHelper.emit.volume(this.props.area.area_id, deviceSerial, volume);
    } else {
      await this.props.handleUpdatePlayerStatus(status, volume, deviceSerial);
    }
    this.props.getLocations();
  }

  handleTogglePlayerMiniPlayer = () => {
    const isPlayerMiniPlayerOpen = !this.state.isPlayerMiniPlayerOpen;
    if (this.state.isWebPlayerMiniPlayerOpen) {
      this.expandButton.click();
      setTimeout(() => {
        this.setState({
          ...this.state,
          isPlayerMiniPlayerOpen,
          isWebPlayerMiniPlayerOpen: false,
        }, () => {
          this.expandButton.click();
        });
      }, MINI_PLAYER_TRANSITION_TIME * 2);
    } else {
      this.setState({
        ...this.state,
        isPlayerMiniPlayerOpen,
        isWebPlayerMiniPlayerOpen: false,
      }, () => {
        this.expandButton.click();
      });
    }
  }

  handleToggleWebPlayerMiniPlayer = () => {
    const isWebPlayerMiniPlayerOpen = !this.state.isWebPlayerMiniPlayerOpen;
    if (this.state.isPlayerMiniPlayerOpen) {
      this.expandButton.click();
      setTimeout(() => {
        this.setState({
          ...this.state,
          isWebPlayerMiniPlayerOpen,
          isPlayerMiniPlayerOpen: false,
        }, () => {
          this.expandButton.click();
        });
      }, MINI_PLAYER_TRANSITION_TIME * 2);
    } else {
      this.setState({
        ...this.state,
        isWebPlayerMiniPlayerOpen,
        isPlayerMiniPlayerOpen: false,
      }, () => {
        this.expandButton.click();
      });
    }
  }

  get isPlaying() {
    if (!this.isActive || (this.isActive && !this.props.area.device.last_played)) {
      return false;
    }
    try {
      const now = moment().tz(DEFAULT_TIMEZONE);
      const lastPlayed = moment(this.props.area.device.last_played).tz(DEFAULT_TIMEZONE);
      return this.props.area.device.status === 'play' && now.diff(lastPlayed, 'minutes') <= 8;
    } catch (e) {
      return false;
    }
  }

  get isPaused() {
    if (!this.isActive || (this.isActive && !this.props.area.device.last_played)) {
      return false;
    }
    return this.props.area.device.status === 'pause';
  }

  get isWebPlayerPlaying() {
    if (!this.props.area.streaming || (this.props.area.streaming && !this.props.area.streaming.online)) {
      return false;
    }
    try {
      const now = moment().tz(DEFAULT_TIMEZONE);
      const lastPlayed = moment(this.props.area.streaming.track.start_time).tz(DEFAULT_TIMEZONE);
      return this.props.area.streaming.status === 'play' && now.diff(lastPlayed, 'minutes') <= 10;
    } catch (e) {
      return false;
    }
  }

  get isWebPlayerPaused() {
    if (!this.props.area.streaming || (this.props.area.streaming && !this.props.area.streaming.online)) {
      return false;
    }
    return this.props.area.streaming.status === 'stop';
  }

  get isOnline() {
    if (!this.isActive || (this.isActive && !this.props.area.device.last_played)) {
      return false;
    }
    try {
      const now = moment().tz(DEFAULT_TIMEZONE);
      const lastSeen = moment(this.props.area.device.last_seen).tz(DEFAULT_TIMEZONE);
      return now.diff(lastSeen, 'minutes') <= 5 && now.isAfter(lastSeen);
    } catch (e) {
      return false;
    }
  }

  get isWebPlayerOnline() {
    if (!this.props.area.streaming || (this.props.area.streaming && !this.props.area.streaming.online)) {
      return false;
    }
    try {
      const now = moment().tz(DEFAULT_TIMEZONE);
      const lastPlayed = moment(this.props.area.streaming.track.start_time).tz(DEFAULT_TIMEZONE);
      return this.props.area.streaming.online && now.diff(lastPlayed, 'minutes') <= 10;
    } catch (e) {
      return false;
    }
  }

  get isActive() {
    return this.props.area && this.props.area.device && this.props.area.device.device_type;
  }

  get papertrailStreamingEnv() {
    switch (AMBIE_ENV) {
      case 'dev': {
        return 'staging';
      }
      case 'preproduction': {
        return 'production';
      }
      default: {
        return AMBIE_ENV;
      }
    }
  }

  get isRuleActive() {
    const rules = this.props.area.commercialRule
    if (rules.length === 0 || rules === undefined) {
      return <MdHourglassFull
        size={25}
        style={{ color: 'grey' }} />
    }

    let isActive = false;
    const now = Date.now();

    for (let rule of rules) {
      const { startDate, endDate } = rule;
      if (startDate && endDate) {
        const start = new Date(startDate.toString().length === 10 ? startDate * 1000 : startDate);
        const end = new Date(endDate.toString().length === 10 ? endDate * 1000 : endDate);
        if (start < now && now < end) {
          isActive = true;
          break;
        };
      }
    }
    return isActive
      ? <MdHourglassFull style={{ color: 'forestgreen' }} />
      : <MdHourglassFull style={{ color: '#f26d80' }} />
  }

  closeEditAreaName = () => {
    this.setState({
      isEditingAreaName: false,
    })
  }

  openEditAreaName = (area) => {
    // debugger
    this.setState({
      isEditingAreaName: true,
      newAreaName: area.name,
      areaNameInputBorderColor: area.name.length > 0 ? '#CCC' : 'red',
    })
  }

  saveAreaName = () => {
    if (this.state.newAreaName.length > 1) {
      this.props.handleSaveAreaName(this.props.area.area_id, this.props.locationId, this.state.newAreaName);
      this.setState({
        isEditingAreaName: false,
      })
    } else {
      this.setState({
        areaNameInputBorderColor: 'red',
      });
    }
  }

  onAreaClientNameChange = (e) => {
    this.setState({
      newAreaName: e.currentTarget.value,
      areaNameInputBorderColor: e.currentTarget.value.length > 0 ? '#CCC' : 'red',
    });
  }

  render() {
    const isActive = this.isActive;
    const isOnline = this.state.isWebPlayerMiniPlayerOpen ? this.isWebPlayerOnline : this.isOnline;
    const isPlaying = this.state.isWebPlayerMiniPlayerOpen ? this.isWebPlayerPlaying : this.isPlaying;
    const isPaused = this.state.isWebPlayerMiniPlayerOpen ? this.isWebPlayerPaused : this.isPaused;
    return (
      <React.Fragment>
        {this.props.area.is_active === false
          ? (
            <div className="col-sm-12 areas-list-item" style={{ backgroundColor: "lightgray", color: "tomato" }}>
              <div className="location__section flex-1-1-0" style={{ color: "tomato" }}>
                Area inactive: {this.props.area.name}
              </div>
              <a
                onClick={(e) => this.props.handleActivateArea(this.props.area.area_id)}
                title="Activate Area"
                className="location__schedule-button">
                <FaUnlock className="button__icon" />
              </a>
            </div>
          )
          :
          (
            <div className="col-sm-12 areas-list-item">
              <div
                style={{
                  flex: '1 1 60%',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  justifySelf: 'flex-start',
                }}
                className="flex-start flex-column"
              >
                <div className="flex-0-1-auto margin-bottom-xs">
                  {IS_OLYMPUS ?
                    this.state.isEditingAreaName ?
                      <React.Fragment>
                        <input type="text" style={{ width: '200px', borderColor: this.state.areaNameInputBorderColor }} className="form-control" value={this.state.newAreaName} onChange={this.onAreaClientNameChange} />
                        <MdClose
                          size={22}
                          color="black"
                          onClick={this.closeEditAreaName}
                          style={{ cursor: 'hand' }}
                        />
                        {this.state.newAreaName.length > 1 && <MdSave
                          size={22}
                          color="black"
                          style={{ cursor: 'hand' }}
                          onClick={this.saveAreaName}
                        />}
                      </React.Fragment>
                      :
                      <React.Fragment>
                        {this.props.area.name}
                        <MdEdit
                          size={18}
                          color="black"
                          onClick={e => { this.openEditAreaName(this.props.area) }}
                          style={{ cursor: 'hand', marginTop: '-6px' }}
                        />
                      </React.Fragment>
                    : null
                  }
                  {IS_SUPPORT_PORTAL ?
                    <React.Fragment>
                      {this.props.area.name}
                    </React.Fragment>
                    : null}
                </div>
                <button
                  className="highlight margin-bottom-xs btn-clear"
                  style={{ cursor: 'pointer', textDecoration: 'underline' }}
                  onClick={() => this.props.handleOpenPapertrail(this.props.area.device.serial, false)}
                // onClick={() => this.expandButton.click()}
                >
                  {this.props.area.device.serial}
                </button>
                <div>
                  <select
                    style={{ maxWidth: '150px' }}
                    value={this.props.area.week_schedule_id || ''}
                    onChange={(e) => this.props.handleChangeSchedule(e, this.props.area, this.props.locationId)}
                  >
                    <option key={this.props.area.name + 'def'} value="">
                      Choose Schedule
                    </option>
                    {this.props.schedules.map((item, index) => <option key={this.props.area.name + index} value={item._id}>{item.name}</option>)}
                  </select>
                  <button
                    onClick={() => this.props.handleViewSchedule(this.props.area.week_schedule_id, this.props.timezone)}
                    title="View Schedule"
                    style={{ display: "flex" }}
                    className="location__schedule-button btn-clear"
                  >
                    <FaCalendar size={18} color="darkslategrey" />
                  </button>
                </div>
              </div>
              <div style={{ flex: '1 1 100%', justifyContent: 'space-between' }}>
                <div className="location__section-container">
                  <div className="flex margin-bottom-xs full-width">
                    <div className="flex" style={{ whiteSpace: "nowrap", marginRight: "10px", flex: '1 0 33%' }}>
                      <button className="btn-clear margin-right-xs" onClick={this.handleTogglePlayerMiniPlayer}>
                        {this.state.isPlayerMiniPlayerOpen ? <FaTimes size={22} color="darkslategrey" /> : <MdSettingsRemote size={22} color="darkslategrey" />}
                      </button>
                      <button style={{ color: 'dodgerblue', width: '100px' }} className="btn-clear" onClick={() => this.props.handleOpenPapertrail(this.props.area.device.serial, false)}>Ambie Player:</button>
                    </div>
                    <div className="section__playing-indicator">
                      <div className="flex">
                        <div className={`indicator indicator--${!isActive ? 'inactive' : isOnline ? 'online' : 'offline'}`}>
                          <p style={{ display: 'inline-block', margin: 0 }}>{!isActive ? 'Inactive' : isOnline ? 'Online' : 'Offline'}</p>
                        </div>
                      </div>
                    </div>
                    <div style={{ whiteSpace: "nowrap", marginLeft: "25px", width: '100%' }}>
                      <div style={{ display: 'flex' }}>
                        {isPlaying && isActive && this.props.area.device.status !== 'pause'
                          ? <PlayingBars />
                          : null
                        }
                        <span>{!isActive ? 'Inactive' : isOnline && this.props.area.device.status === 'pause' ? 'Paused' : isPlaying ? '' : 'Not Playing'}</span>
                      </div>
                    </div>
                  </div>
                  {
                    this.props.area.streaming && this.props.area.streaming.enabled
                      ? (
                        <div className="flex full-width">
                          <div style={{ whiteSpace: "nowrap", marginRight: "10px", flex: '1 0 33%' }}>
                            <button className="btn-clear margin-right-xs" onClick={this.handleToggleWebPlayerMiniPlayer}>
                              {this.state.isWebPlayerMiniPlayerOpen ? <FaTimes size={22} color="darkslategrey" /> : <MdSettingsRemote size={22} color="darkslategrey" />}
                            </button>
                            <button style={{ color: 'dodgerblue', width: '100px' }} className="btn-clear" onClick={() => this.props.handleOpenPapertrail(`${this.papertrailStreamingEnv}-area-${this.props.area.area_id}`, true)} href="#">Web Player:</button>
                          </div>
                          <div className="section__playing-indicator">
                            <div style={{ display: 'flex' }}>
                              <div className={`indicator indicator--${this.props.area.streaming && this.props.area.streaming.online ? 'online' : 'offline'}`}>
                                <p style={{ display: 'inline-block', margin: 0 }}>{this.props.area.streaming && this.props.area.streaming.online ? 'Online' : 'Offline'}</p>
                              </div>
                            </div>
                          </div>
                          <div style={{ whiteSpace: "nowrap", marginLeft: "25px", width: '100%' }}>
                            <div style={{ display: 'flex' }}>
                              {this.props.area.streaming && this.props.area.streaming.status === 'play' && isActive
                                ? <PlayingBars />
                                : null
                              }
                              <span>{this.streamingStatus()}</span>
                            </div>
                          </div>
                        </div>
                      )
                      : null
                  }
                </div>
                <AreaActionButtons
                  area={this.props.area}
                  isActive={this.isActive}
                  handleResendSchedule={this.props.handleResendSchedule}
                  handleToggleStreamingModal={this.props.handleToggleStreamingModal}
                  handleToggleDuplicatePlayModal={this.props.handleToggleDuplicatePlayModal}
                  handleCommercialRulesPopup={this.props.handleCommercialRulesPopup}
                  handleChangeDevice={this.props.handleChangeDevice}
                  handleDeactivateArea={this.props.handleDeactivateArea}
                  handleShowUpdateSoftwareModal={this.props.handleShowUpdateSoftwareModal}
                  handleShowRemoveDeviceModal={this.props.handleShowRemoveDeviceModal}
                  isRuleActive={this.isRuleActive}
                />
              </div>
            </div>
          )
        }
        <Collapsible
          classParentString="mini-player-collapsible"
          transitionTime={MINI_PLAYER_TRANSITION_TIME}
          trigger={<button className="hidden" ref={el => { this.expandButton = el }} />}
        >
          <MiniPlayer
            isControllingWebPlayer={this.state.isWebPlayerMiniPlayerOpen}
            area={this.props.area}
            device={this.props.area.device}
            current_track={this.props.area.current_track}
            isOnline={isOnline}
            isPlaying={isPlaying}
            isPaused={isPaused}
            isActive={isActive}
            clientId={this.props.clientId}
            playlists={this.props.playlists}
            handlePlayPause={this.handlePlayPause}
            handleSkip={this.handleSkip}
            handleToggleOverrideModal={this.props.handleToggleOverrideModal}
            handleUpdateVolume={this.handleUpdateVolume}
          />
        </Collapsible>
      </React.Fragment>
    );
  }
}

export default AreasListItem;
