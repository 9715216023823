import * as types from '@actions/types';

export const changeAllTracksList = payload => ({
  type: types.CHANGE_ALL_TRACKS_LIST,
  payload,
});

export const changeAllArtistsList = payload => ({
  type: types.CHANGE_ALL_ARTISTS_LIST,
  payload,
});

export const changeAllCategoriesList = payload => ({
  type: types.CHANGE_ALL_CATEGORIES_LIST,
  payload,
});

export const changeIsSearchingTracks = payload => ({
  type: types.CHANGE_IS_SEARCHING_TRACKS,
  payload,
});

export const openEditTrackTile = payload => ({
  type: types.OPEN_EDIT_TRACKS_TITLE,
  payload,
});

export const closeEditTrackTile = payload => ({
  type: types.CLOSE_EDIT_TRACKS_TITLE,
  payload,
});

export const updateTitle = (trackId, title) => ({
  type: types.UPDATE_TRACKS_TITLE,
  payload: {
    title,
    trackId,
  }
});

export const trackTitleSaved = payload => ({
  type: types.TRACK_TITLE_SAVED,
  payload,
});

export const clearTracks = () => ({
  type: types.CLEAR_TRACKS,
});