import React from "react";

export default ({
  isVisible,
  isError,
  isSticky,
  handleHideNotification,
  hideAfter,
  message,
}) => {
  let notificationClass = "notification-bubble";
  if (isVisible) {
    notificationClass += isError
      ? " notification-error"
      : " notification-success";
  }

  if (!isSticky) {
    let notificationTimeoutHandler = window.setTimeout(() => {
      if (isVisible) {
        handleHideNotification();
      }
    }, hideAfter);
  }

  return (
    <div onClick={handleHideNotification} className={notificationClass}>
      {message.toUpperCase()}
    </div>
  );
};
