import React from 'react'
import { TiDelete } from 'react-icons/ti'

const EmailRecipient = ({ emailAddress, deleteEmail }) => {
  return (
    <>
      <div style={listStyle}>
        <TiDelete
          style={icon}
          size={20}
          onClick={deleteEmail}
        />{emailAddress}
      </div>
    </>
  )
};

export default EmailRecipient;

const listStyle = {
  display: "flex",
  alignItems: "center",
  listStyleType: "none",
  border: "1px solid rgba(19, 1, 1, 0.3)",
  borderRadius: "5px",
  padding: "3px 3px 3px 3px",
  margin: "3px 2px 3px 5px",
  background: "rgba(195, 195, 195, 0.3)"
};

const icon = {
  cursor: "pointer",
  margin: 0,
  color: 'red',
  padding: 0
}

{/* <input disabled={!this.state.sfInfoLoaded} type="text" name="emailAddress" value={emailAddress} onChange={this.handleTextChange} /> */ }

// className="flex-align-items-center"