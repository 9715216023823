import * as types from '@actions/types';

const initalState = {
  pickingTracklist:true,
  dates: {
    startDate: null,
    startEnd: null,
  },
  device: null,
  searching: false,
  searchError: false,
  tracklists:[],
  tracklist:[],
  tables: [
    {
      pickingTracklist:true,
      dates: {
        startDate: null,
        startEnd: null,
      },
      device: null,
      searching: false,
      searchError: false,
      tracklists:[],
      tracklist:[],
    },
    {
      pickingTracklist:true,
      dates: {
        startDate: null,
        startEnd: null,
      },
      device: null,
      searching: false,
      searchError: false,
      tracklists:[],
      tracklist:[],
    },
  ],
};

export default (state = initalState, action) => {
  switch (action.type) {
    case types.TA_PICKING_TRACKLIST: {
      return {
        ...state,
      };
    }
    case types.TA_SEARCHING: {
      return Object.assign({}, state, {
        searching: true
      });
    }
    case types.TA_PICK_DATES: {
      return Object.assign({}, state, {
        dates: action.payload.dates
      });
    }
    case types.TA_PICK_DEVICE: {
      return Object.assign({}, state, {
        device: action.payload.device,
        searchError: false
      });
    }
    case types.TA_SEARCH_DONE: {
      return Object.assign({}, state, {
        searching : false,
        searchError : action.payload.searchError,
      });
    }

    case types.TA_NEW_TRACKLIST: {
      return Object.assign({}, state, {
        tracklists: action.payload.tracklists
      });
    }

    case types.TA_TRACKLIST_DETAIL: {
      return Object.assign({}, state, {
        tracklist : action.payload.tracklist,
        pickingTracklist : false,
      });
    }

    case types.TA_REPICK_TRACKLIST: {
      return Object.assign({}, state, {
        tracklist : [],
        pickingTracklist : true,
      });
    }

    default:
      return state;
  }
};
