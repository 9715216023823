import * as types from "../actions/types";

const initalState = {
  coreChannels: [],
  xlsJsonData: [],
  xlsFileNane: '',
  displaySendUpdateButton: false,
  savingCoreChannel: false,
  savedCoreChannel: false,
  creatingCoreChannel: false,
  coreChannelCreated: false,
  displayCoreChannelCreationError: false,
  energyLevels: null,
  displayButtons: false,
  loadingXls: false,
  showTrackSearchModal: false,
  searchTrackInfo: null,
};
let localCorechannels = [],
  localChannel = [],
  localNewSamplers,
  coreChannelFilter,
  checkingBatchUpdateFile = false,
  updateDone = false,
  updateSent = false,
  updateSentStatus = true,
  updateDoneStatus = true,
  updatingBatch = false,
  updateMessage = '';

export default (state = initalState, action) => {
  let selectedCoreChannel = state.selectedCoreChannel;
  switch (action.type) {
    case types.CHANGE_CORE_CHANNEL_FILTER:
      return Object.assign({}, state, {
        coreChannelFilter: action.payload
      });
      break;
    case types.GET_CORE_CHANNELS: //debugger;
      const coreChannels = [...action.payload];
      coreChannels.forEach(cc => cc.playlists = cc.playlists.map(p => p.name).join(", "));
      return Object.assign({}, state, {
        coreChannels
      });
      break;
    case types.GET_SELECTED_CORE_CHANNEL_TRACKS: {
      // debugger
      let s = Object.assign({}, state.selectedCoreChannel);
      s.tracks = action.payload.tracks;
      return Object.assign({}, state, {
        selectedCoreChannel: s
      });
    }
      break
    case types.GET_SELECTED_CORE_CHANNEL_INFO:
      // debugger
      let s = action.payload.coreChannelInfo;
      s.tracksToAdd = [];
      s.trackToActivate = [];
      s.trackToDeactivate = [];
      return Object.assign({}, state, {
        selectedCoreChannel: s
      });
    case types.GET_CORE_CHANNEL_INFO:
      localCorechannels = Object.assign([], state.coreChannels);
      localChannel = localCorechannels.filter(CC => {
        return (CC.id === action.payload.coreChannelId)
      });
      if (localChannel.length === 1) {
        localChannel[0].tracks = action.payload.coreChannelInfo.tracks;
        localChannel[0].tracks_samplers = action.payload.coreChannelInfo.tracks_samplers;
        localChannel[0].coreInfo = action.payload.coreChannelInfo.coreInfo;
      } else {
        localCorechannels.push({
          id: action.payload.coreChannelId,
          tracks: action.payload.coreChannelInfo.tracks,
          tracks_samplers: action.payload.coreChannelInfo.tracks_samplers,
          coreInfo: action.payload.coreChannelInfo.coreInfo,
        })
      }
      return Object.assign({}, state, {
        coreChannels: localCorechannels
      });
      break;
    case types.CHANGE_CORE_CHANNEL_SAMPLERS:
      localCorechannels = [...state.coreChannels];
      localChannel = localCorechannels.filter(CC => CC.id === action.payload.coreChannelId);
      localChannel[0].tracks_samplers = [...action.payload.samplers];
      return Object.assign({}, state, {
        coreChannels: localCorechannels
      });
      break;
    case types.CHECK_FOR_BATCH_UPDATE_FILE_DONE:
      return Object.assign({}, state, {
        checkingBatchUpdateFile: false,
        displayButtons: true,
        loadingXls: false,
      });
      break;
    case types.START_CHECK_FOR_BATCH_UPDATE_FILE:
      return Object.assign({}, state, {
        checkingBatchUpdateFile: true,
        displayButtons: false,
        loadingXls: true,
      });
      break;
    case types.BATCH_UPDATE_DONE:
      return Object.assign({}, state, {
        updateSent: false,
        updateDone: true,
        updateDoneStatus: Boolean(action.payload.result),
        updateMessage: action.payload.message,
      });
      break;
    case types.BATCH_UPDATE_SENT:
      return Object.assign({}, state, {
        updatingBatch: false,
        updateSent: true,
        updateSentStatus: Boolean(action.payload)
      });
      break;
    case types.BATCH_UPDATE_SUCCESS: {
      return Object.assign({}, state, {
        checkingBatchUpdateFile: false,
        updateDone: false,
        updateSent: false,
        updateSentStatus: true,
        updateDoneStatus: true,
        updatingBatch: false,
        updateMessage: '',
        xlsJsonData: Boolean(action.payload)
          ? []
          : [...state.xlsJsonData],
        loadingXls: false,
        displaySendUpdateButton: false,
      });
    }
    case types.ADD_TRACK_TO_SAMPLERS: {
      //debugger
      if (typeof selectedCoreChannel.tracks_samplers === 'undefined') selectedCoreChannel.tracks_samplers = []
      selectedCoreChannel.tracks_samplers.push({
        title: action.payload[0].title,
        artist: action.payload[0].artist,
        id: action.payload[0].id,
      });
      return Object.assign({}, state, {
        selectedCoreChannel,
      });
    }
    case types.REMOVE_SAMPLER: {
      if (typeof selectedCoreChannel.tracks_samplers === 'undefined') selectedCoreChannel.tracks_samplers = []
      // debugger
      let samplerIndex = selectedCoreChannel.tracks_samplers.findIndex(S => S.id === action.payload.id);
      selectedCoreChannel.tracks_samplers.splice(samplerIndex, 1);
      return Object.assign({}, state, {
        selectedCoreChannel,
      });
    }
    case types.CHANGE_CORE_CHANNEL_NAME: {
      selectedCoreChannel.coreInfo.name = action.payload;
      return Object.assign({}, state, {
        selectedCoreChannel,
      });
    }
    case types.CHANGE_CORE_CHANNEL_ENERGY: {
      const levelId = {
        high: 1,
        medium: 2,
        low: 3
      }
      selectedCoreChannel.coreInfo.energy_level = action.payload;
      selectedCoreChannel.coreInfo.energy_level_id = levelId[action.payload];
      return Object.assign({}, state, {
        selectedCoreChannel,
      });
    }
    case types.CHANGE_CORE_CHANNEL_DESCRIPTION: {
      selectedCoreChannel.coreInfo.description = action.payload;
      selectedCoreChannel.coreInfo
      return Object.assign({}, state, {
        selectedCoreChannel,
      });
    }
    case types.ADD_TRACK_TO_CORE_CHANNEL: {
      if (action.payload.length === 1) {
        selectedCoreChannel.tracks.push({
          title: action.payload[0].title,
          artist: action.payload[0].artist,
          id: action.payload[0].id,
          added: new Date(),
          is_active: true,
          audio: action.payload[0].audio,
          cdn_mono: action.payload[0].cdn_mono,
          cdn_stereo: action.payload[0].cdn_stereo,
          cdn_hifi: action.payload[0].cdn_hifi,
          is_320: action.payload[0].is_320,
          deleted_at: null,
        });
        selectedCoreChannel.tracksToAdd.push(action.payload[0].id);
        return Object.assign({}, state, {
          selectedCoreChannel,
        });
      } else {
        return state
      }
    }
    case types.TOGGLE_ACTIVE_TRACK: {
      //debugger;
      let track = selectedCoreChannel.tracks.filter(T => T.id === action.payload);
      track[0].is_active = !track[0].is_active;
      if (track[0].is_active) {
        let deactiveLink = selectedCoreChannel.trackToDeactivate.indexOf(action.payload);
        if (deactiveLink > -1) selectedCoreChannel.trackToActivate.splice(deactiveLink, 1);
        selectedCoreChannel.trackToActivate.push(action.payload);
      } else {
        let activeLink = selectedCoreChannel.trackToActivate.indexOf(action.payload);
        if (activeLink > -1) selectedCoreChannel.trackToActivate.splice(activeLink, 1);
        selectedCoreChannel.trackToDeactivate.push(action.payload);
      }
      return Object.assign({}, state, {
        selectedCoreChannel,
      });
    }
    case types.ACTIVATE_CORE_CHANNEL: {
      selectedCoreChannel.coreInfo.is_active = true;
      return Object.assign({}, state, {
        selectedCoreChannel,
      });
    }
    case types.DEACTIVATE_CORE_CHANNEL: {
      selectedCoreChannel.coreInfo.is_active = false;
      return Object.assign({}, state, {
        selectedCoreChannel,
      });
    }
    case types.RESET_SELECTED_CORE_CHANNEL: {
      return Object.assign({}, state, {
        selectedCoreChannel: null,
      });
    }
    case types.BATCH_UPDATE_STARTED:
      return Object.assign({}, state, {
        updatingBatch: true
      });
      break;
    case types.UPDATE_XLS_JSON_DATA:
      return Object.assign({}, state, {
        xlsJsonData: action.payload,
        displaySendUpdateButton: true,
      });
      break;
    case types.UPDATE_ENERGY_LEVELS: {
      return Object.assign({}, state, {
        energyLevels: action.payload
      });
    }
    case types.UPDATE_XLS_FILE_NAME:
      return Object.assign({}, state, {
        xlsFileNane: action.payload
      });
      break;
    case types.SAVED_CORE_CHANNEL: {
      return Object.assign({}, state, {
        savingCoreChannel: false,
        savedCoreChannel: true,
        savedCoreChannelMessage: action.payload,
      });
    }
    case types.SAVING_CORE_CHANNEL: {
      return Object.assign({}, state, {
        savingCoreChannel: true
      });
    }
    case types.DISMISS_SAVED_CORE_CHANNEL: {
      return Object.assign({}, state, {
        savingCoreChannel: false,
        savedCoreChannel: false,
      });
    }
    case types.CREATING_NEW_CORE_CHANNEL: {
      return Object.assign({}, state, {
        coreChannelCreated: false,
        creatingCoreChannel: true,
        displayCoreChannelCreationError: false,
      });
    }
    case types.NEW_CORE_CHANNEL_CREATED: {
      return Object.assign({}, state, {
        creatingCoreChannel: false,
        coreChannelCreated: true,
        displayCoreChannelCreationError: false,
      });
    }
    case types.NEW_CORE_CHANNEL_FAILED: {
      return Object.assign({}, state, {
        creatingCoreChannel: false,
        coreChannelCreated: false,
        displayCoreChannelCreationError: 1,
      });
    }
    case types.NEW_CORE_CHANNEL_NAME_IN_USE: {
      return Object.assign({}, state, {
        creatingCoreChannel: false,
        coreChannelCreated: false,
        displayCoreChannelCreationError: 2,
      });
    }
    case types.RESET_CORE_CHANNEL_CREATION: {
      return Object.assign({}, state, {
        creatingCoreChannel: false,
        coreChannelCreated: false,
        displayCoreChannelCreationError: false,
      });
    }
    case types.SHOW_TRACK_SEARCH_MODAL: {
      return Object.assign({}, state, {
        showTrackSearchModal: true,
        searchTrackInfo: action.payload,
      });
    }
    case types.HIDE_TRACK_SEARCH_MODAL: {
      return Object.assign({}, state, {
        showTrackSearchModal: false,
        searchTrackInfo: null,
      });
    }
  }
  return state;
};
