import { applyMiddleware, createStore } from 'redux';
import reducers from './reducers';
import { createLogger } from 'redux-logger';
import throttle from 'redux-throttle';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';

//import wsMiddleWare from './middlewares/webSocketClient/AWSIotClient'

const logger = createLogger({
  collapsed: true,
});

const middleware = applyMiddleware(
  promise(),
  thunk,
  // logger,
  // wsMiddleWare
);

const store = createStore(reducers, middleware);

export default store;
