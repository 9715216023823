import * as types from './types';

export const changeDeviceStatusList = (payload) => {
  return {
    type: types.CHANGE_DEVICE_STATUS_LIST,
    payload: payload
  }
};

export const updateDeviceAction = (payload) => {
  return {
    type: types.UPDATE_DEVICE,
    payload: payload
  }
};

export const updatingDeviceAction = () => {
	return {
		type: types.UPDATING_DEVICE,
		payload: true
	}
};