import Loading from '@common/loading';
import Loadable from 'react-loadable';

import React, { Component, Fragment } from 'react';

import autoBind from 'react-autobind';
import { connect } from 'react-redux';

import store from '@app/store';
import {OLD_ADMIN_ROOT} from '@app/config';

import MainContainer from '@layout_2/main-container';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

const CoreChannelsList = Loadable({
  loader: () => import('@euterpe/core-channels/core-channels-list/core-channels-list-main'),
  loading: Loading
});

const CoreChannelBatchUpdates = Loadable({
  loader: () => import('@euterpe/core-channels/batch-updates/core-channels-batch-updates-main'),
  loading: Loading
});

import {
  changeCoreChannelsFilter
} from '@actions/core-channels-actions';

class CoreChannels extends Component {

  constructor(props) {
    super(props);
    autoBind(this);
  }

  state = {
    activePage: 1,
    activeFilter: true,
  }

  componentWillMount() {
    let defaultIndex = 0;
    if (this.props.history.location.pathname.includes('/core-channels/new')) defaultIndex = 0;
    if (this.props.history.location.pathname.includes('/batch-updates')) defaultIndex = 1;
    this.setState({
      tabIndex: defaultIndex,
    });
  }
  
  componentWillReceiveProps() {
    let defaultIndex = 0;
    if (this.props.history.location.pathname.includes('/core-channels/new')) defaultIndex = 0;
    if (this.props.history.location.pathname.includes('/batch-updates')) defaultIndex = 1;
    //console.log(this.props.history.location.pathname, "default index", defaultIndex);
    this.setState({
      tabIndex: defaultIndex,
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return true
  }

  _handlePageChange(activePage) {
    this.setState({ activePage });
  }

  get tabs() {
    return [
      {
        title: 'Core Channels',
        content: <CoreChannelsList 
          history = {this.props.history}
        />,
      },
      {
        title: 'Core channels batch uploader',
        content: <CoreChannelBatchUpdates />,
      },
    ]
  }

  render() {

    // console.log('coreChannelsList', coreChannelsList);
    // console.log('this.props.selectedCoreChannel', this.props.selectedCoreChannel);
    return (
      <Fragment>
        <MainContainer>
          <Tabs 
            selectedIndex={this.state.tabIndex}
            onSelect={tabIndex =>{
              if (tabIndex === 0)
                this.props.history.push('/aphrodite/euterpe/core-channels');
              else
                this.props.history.push('/aphrodite/euterpe/core-channels/batch-updates');
              }
            }
          >
            <TabList>
              {this.tabs.map((tab) => <Tab key={tab.title}>{tab.title}</Tab>)}
            </TabList>
            {this.tabs.map((tab) => <TabPanel key={`${tab.title}-content`}>{tab.content}</TabPanel>)}
          </Tabs>
        </MainContainer>
      </Fragment>
    )
  }
}

const storeToProps = (store) => {
  return {
    coreChannels: store.coreChannelsState.coreChannels,
    selectedCoreChannel: store.coreChannelsState.selectedCoreChannel ? store.coreChannelsState.selectedCoreChannel : null,
    //selectedCoreChannel: store.coreChannelsState.selectedCoreChannel,
    coreChannelFilter: store.coreChannelsState.coreChannelFilter,
    userToken: store.authState.userInfo ? store.authState.userInfo.userToken : '',
    joke: store.layoutState.joke,
  };
};

export default connect(storeToProps)(CoreChannels);