import React, { Fragment } from 'react';

const PageCounter = ({ rows, rpp, page }) => {
    const pageRangeMsg = (rows, rpp, page) => {
        let msg = ''
        if (page === 1 && rpp > rows) {
            msg = `1 - ${rows} of ${rows}`;
        }
        else if (page === 1) {
            msg = `1 - ${rpp} of ${rows}`;
        }
        else if (page * rpp < rows) {
            msg = `${(page - 1) * rpp + 1} - ${page * rpp} of ${rows} `;
        } else {
            msg = `${(page - 1) * rpp + 1} - ${rows} of ${rows} `;
        }
        return msg;
    };

    return (
        <Fragment>
            <p className="margin-none" style={{ marginLeft: '10px' }} > {pageRangeMsg(rows, rpp, page)}</p>
        </Fragment>
    );
};

export default PageCounter;