import axios from 'axios';
import store from '@store';
import { HOST_CF, S3_UPLOAD_SUCCESS_MESSAGE, HOST_ROCK_API } from '@config';

import * as authApi from './auth-api';
import * as layoutActions from '@actions/layout-actions';
import * as coreChannelsActions from '@actions/core-channels-actions';
import * as commercialRulesActions from '@actions/commercial-rules-actions';
import axiosInterceptors from '../utils/interceptors';

import browserHistory from '../history';

/**
 * use rock-api endpoint
 */
export const checkBatchUpdateFile = async (userToken, songsToCheck) => {
  store.dispatch(coreChannelsActions.startCheckForBatchUpdateFile());
  try {
    await axiosInterceptors.post(`${HOST_ROCK_API}/core-channels/batch-updates-check`, { fileData: songsToCheck });
  } catch (error) {
    store.dispatch(coreChannelsActions.checkForBatchUpdateDone(true));
  }
};

/**
 * use rock-api endpoint
 */
export const doBatchUpdateFile = async (userToken, batchUpdate, filename, username) => {
  store.dispatch(coreChannelsActions.batchUpdateStarted());

  try {
    await axiosInterceptors.post(`${HOST_ROCK_API}/core-channels/batch-updates`, { fileData: batchUpdate, filename, username });
    store.dispatch(coreChannelsActions.batchUpdateSent(true));
  } catch (error) {
    store.dispatch(coreChannelsActions.batchUpdateSent(false));
  }
};

/**
 * use rock-api endpoint
 */
export const getCoreChannelsList = async (userToken, insert = false) => {
  try {
    const response = await axiosInterceptors.get(HOST_ROCK_API + `/core-channels/with-playlists-tracks`, {});

    const coreChannels = response.data;
    store.dispatch(coreChannelsActions.getCoreChannels(coreChannels));
    store.dispatch(commercialRulesActions.storeChannels(coreChannels));
  } catch (e) {
    console.error(e);
  }
};

export const sendCommercialRulesToS3 = (userToken, rules) => {
  return axios
    .post(HOST_CF + '/commercial-rules', {
      headers: {
        'x-ambie-token': userToken,
        rules: JSON.stringify(rules),
      },
      validateStatus: (status) => authApi.handleUnauthorized(status),
    })
    .then((response) => {
      store.dispatch(layoutActions.showNotification(S3_UPLOAD_SUCCESS_MESSAGE, true));
    })
    .catch((response) => {
      console.error(response);
    });
};

/**
 * use rock-api endpoint
 */
export const getCoreChannel = async (coreChannelId) => {
  try {
    const response = await axiosInterceptors.get(`${HOST_ROCK_API}/core-channels/playlists/${coreChannelId}`, {});
    const data = response.data;
    let coreChannelInfo = {
      tracks: data.tracks,
      tracks_samplers: data.samplers,
      coreInfo: data.coreInfo,
      usedInPlaylists: data.playlists,
      additions: data.additions,
      removals: data.removals,
    };
    store.dispatch(
      coreChannelsActions.getSelectedCoreChannel({
        coreChannelId: coreChannelId,
        coreChannelInfo: coreChannelInfo,
      }),
    );
  } catch (error) {
    console.error(error);
  }
};

/**
 * use rock-api endpoint
 */
export const saveCoreChannel = async (coreChannel) => {
  store.dispatch(coreChannelsActions.savingCoreChannel());
  try {
    await axiosInterceptors.patch(`${HOST_ROCK_API}/core-channels/tracks/${coreChannel.coreInfo.id}`, {
      coreInfo: coreChannel.coreInfo,
      trackToActivate: coreChannel.trackToActivate,
      trackToDeactivate: coreChannel.trackToDeactivate,
      tracksToAdd: coreChannel.tracksToAdd,
      samplers: coreChannel.tracks_samplers,
    });
    store.dispatch(coreChannelsActions.savedCoreChannel(null));

    return true;
  } catch (error) {
    store.dispatch(coreChannelsActions.savedCoreChannel(error));
  }
};

/**
 * use rock-api endpoint
 */
export const activateCoreChannel = async (coreChannelId, userToken) => {
  try {
    await axiosInterceptors.patch(`${HOST_ROCK_API}/core-channels/activate/${coreChannelId}`, {});
    store.dispatch(coreChannelsActions.activateCoreChannel());
  } catch (error) {
    console.error(error);
  }
};

/**
 * use rock-api endpoint
 */
export const deactivateCoreChannel = async (coreChannelId, userToken) => {
  try {
    await axiosInterceptors.patch(`${HOST_ROCK_API}/core-channels/deactivate/${coreChannelId}`, {});
    store.dispatch(coreChannelsActions.activateCoreChannel());
  } catch (error) {
    console.error(error);
  }
};

/**
 * use rock-api endpoint
 */
export const createNewCoreChannel = async (coreChannelName, coreChannelEnergyLevel, userToken) => {
  store.dispatch(coreChannelsActions.creatingNewCoreChannel());

  try {
    const response = await axiosInterceptors.post(`${HOST_ROCK_API}/core-channels`, {
      name: coreChannelName,
      energy_level_id: parseInt(coreChannelEnergyLevel, 10),
    });
    if (response.status === 200) {
      store.dispatch(coreChannelsActions.newCoreChannelCreated());
      const ccID = response.data.id;
      browserHistory.push(`/aphrodite/euterpe/core-channels/${ccID}`);
    } else if (response.status === 501) {
      store.dispatch(coreChannelsActions.nameInUse());
    } else {
      store.dispatch(coreChannelsActions.newCoreChannelFailed());
    }
  } catch (error) {
    console.error(response);
  }
};

/**
 * use rock-api endpoint
 */
export const getEnergyLevels = async (userToken) => {
  try {
    const response = await axiosInterceptors.get(`${HOST_ROCK_API}/core-channels/energy-levels`, {});
    store.dispatch(coreChannelsActions.updateEnergyLevels(response.data));
  } catch (error) {
    console.error(error);
  }
};
