import * as React from 'react';

export default props => (
  <React.Fragment>
    <p className="text-center">{props.message} <span className="highlight"></span></p>
    <div className="modal-footer">
      <div className="btn btn-default btn-border btn-pill" onClick={props.handleClose}>{props.okText || 'OK'}</div>
    </div>
  </React.Fragment>
);
