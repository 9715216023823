import Loading from '@common/loading';
import Loadable from 'react-loadable';
import React, { Component } from 'react';
import { Link, Route, withRouter, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
const AWS = require('aws-sdk');
const AWSIoTData = require('aws-iot-device-sdk');
import '../../../stylesheets/aphrodite/main.scss';
import store from '@app/store';
import {
  changeAphroditeSelectedMenu,
  hideModal,
} from '@actions/layout-actions';
import * as authApi from '@api/auth-api';
import * as layoutApi from '@api/layout-api';
import * as coreChannelsActions from '@actions/core-channels-actions';

const CustomerMusicReport = Loadable({
  loader: () => import('@minerva/customer-music/customer-music'),
  loading: Loading
});
const SubMenu = Loadable({
  loader: () => import('./top-sub-menu'),
  loading: Loading
});
const TopMenu = Loadable({
  loader: () => import('./top-menu'),
  loading: Loading
});
const Modal = Loadable({
  loader: () => import('@layout_2/Modal/modal'),
  loading: Loading
});
const OkPopup = Loadable({
  loader: () => import('@common/ok-popup'),
  loading: Loading
});
const YesNoPopup = Loadable({
  loader: () => import('@common/generic-yes-no-popup'),
  loading: Loading
});
const FlashMessage = Loadable({
  loader: () => import('@common/flash-message'),
  loading: Loading
});
const Feedback = Loadable({
  loader: () => import('@euterpe/feedback/feedback'),
  loading: Loading
});
const CommercialRulesOverview = Loadable({
  loader: () => import('@euterpe/commercial-rules-overview/commercial-rules-overview'),
  loading: Loading
});
const AccountsMain = Loadable({
  loader: () => import('../accounts/accounts-routes'),
  loading: Loading
});
const SfMain = Loadable({
  loader: () => import('../sf/sf-routes'),
  loading: Loading
});
const Playlists = Loadable({
  loader: () => import('@euterpe/playlists/playlists-routes'),
  loading: Loading
});
const CoreChannels = Loadable({
  loader: () => import('@euterpe/core-channels/core-channels-routes'),
  loading: Loading
});
const Tracks = Loadable({
  loader: () => import('@euterpe/tracks/tracks-main'),
  loading: Loading
});

const MassUpdater = Loadable({
  loader: () => import('@components/mass_updater/updater-main'),
  loading: Loading
});

const Users = Loadable({
  loader: () => import('@app/components/euterpe/users/users-main'),
  loading: Loading
})

const ToolBox = Loadable({
  loader: () => import('@euterpe/tool-box/tool-box-main'),
  loading: Loading
});

import {
  COGNITO_IDENTITY_POOL,
  AWS_REGION,
  IOT_HOST,
  IOT_POLICY,
} from '@app/config';

class MainLayout extends Component {
  constructor(props) {
    super(props);
    if (this.props.joke === '') layoutApi.getAjoke();
    // Configure Cognito identity pool
    AWS.config.region = AWS_REGION;
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: COGNITO_IDENTITY_POOL,
    });
    // Getting AWS creds from Cognito is async, so we need to drive the rest of the mqtt client initialization in a callback
    AWS.config.credentials.refresh((err) => {
      if (err) {
        console.log(err);
        return;
      }
      //debugger;
      const IoT = new AWS.Iot();
      IoT.attachPrincipalPolicy({
        policyName: IOT_POLICY,
        principal: AWS.config.credentials.identityId
      }, (err, res) => {
        if (err) {
        } else {
          //debugger;
          //@todo : this is probably a lidfecycle issue. Move the device on a global or in base.
          if (AWS.config.credentials && AWS.config.credentials.data && AWS.config.credentials.data.Credentials) {
            let credentials = AWS.config.credentials.data.Credentials;
            //debugger;
            var mqttDevice = AWSIoTData.device({
              clientId: 'olympus-browser-' + (Math.floor((Math.random() * 100000) + 1)),
              host: IOT_HOST,
              maximumReconnectTimeMs: 8000,
              debug: true,
              protocol: 'wss',
              accessKeyId: '',
              secretKey: '',
              sessionToken: ''
            });
            mqttDevice.on('connect', () => {
              //console.log("Connected, subscribing to ambie-browser-batch-update");
              mqttDevice.subscribe('ambie-browser-batch-update');
              mqttDevice.subscribe('ambie-browser-check-batch-update');
            });
            mqttDevice.on('message', (topic, payload) => {
              // console.log("message", topic, payload.toString());
              //debugger;
              const message = JSON.parse(payload.toString());
              if (topic === 'ambie-browser-batch-update') {
                console.log('ambie-browser-batch-update:', message);
                if (message.result === "error") {
                  store.dispatch(coreChannelsActions.batchUpdateDone({
                    result: false,
                    error: message.error.detail
                  }));
                } else {
                  store.dispatch(coreChannelsActions.batchUpdateDone({
                    result: true,
                    error: null
                  }));
                }
              } else if (topic === 'ambie-browser-check-batch-update') {
                console.log('ambie-browser-check-batch-update:', message);
                if (message.result === "error") {
                  store.dispatch(coreChannelsActions.checkForBatchUpdateDone(false));
                } else {
                  debugger;
                  store.dispatch(coreChannelsActions.checkForBatchUpdateDone(true));
                  store.dispatch(coreChannelsActions.updateXlsJsonData(message.result));
                }
              }
            });
            mqttDevice.on('error', (error) => {
              console.error("Error", error);
            });
            mqttDevice.updateWebSocketCredentials(credentials.AccessKeyId, credentials.SecretKey, credentials.SessionToken, credentials.Expiration);
          }
        }
      });
    });
  }

  _handleLogout(e) {
    authApi.logout();
  }

  componentWillReceiveProps(nextProps) {
    const path = nextProps.location.pathname.replace(/^\//, '').split('/')[1].toLowerCase(); // TODO: when 'aphrodite' is dropped from the path, remove the split
    if (nextProps.aphroditeSelectedMenu.toLowerCase() !== path) {
      store.dispatch(changeAphroditeSelectedMenu(path));
    }
  }

  handleDismissModal = () => store.dispatch(hideModal());

  get submenuItems() {
    let submenu = this.props.aphroditeSelectedMenu.toLowerCase()[0] === 'undfined' ? [] : this.props.aphroditeMenu.filter(M => M.name.toLowerCase() === this.props.aphroditeSelectedMenu.toLowerCase());
    if (submenu.length === 1) {
      return this.props.aphroditeSelectedMenu.toLowerCase()[0] === 'undfined' ? [] : this.props.aphroditeMenu.filter(M => M.name.toLowerCase() === this.props.aphroditeSelectedMenu.toLowerCase())[0].submenu
    } else {
      return [];
    }
  }

  render() {
    //console.log('this.props.aphroditeMenu', this.props.aphroditeMenu, this.props.aphroditeSelectedMenu);
    return (
      <div>
        <FlashMessage
          duration={this.props.flashMessage.duration || 2000}
          status={this.props.flashMessage.status}
          show={this.props.flashMessage.show}
          message={this.props.flashMessage.message}
          subMessage={this.props.flashMessage.subMessage}
          innerWidth={this.props.flashMessage.width}
        />
        <Modal
          show={this.props.modal.show}
          handleDismiss={this.props.modal.handleDismiss || this.handleDismissModal}
          width="600px"
          height="400px"
        >
          {this.props.modal.title && <h3>{this.props.modal.title}</h3>}
          {
            this.props.modal.type === 'ok'
              ? <OkPopup message={this.props.modal.message} okText={this.props.modal.okText} handleClose={this.props.modal.handleDismiss || this.handleDismissModal} />
              : this.props.modal.type === 'yesNo'
                ? <YesNoPopup message={this.props.modal.message} noText={this.props.modal.noText} yesText={this.props.modal.yesText} handleNo={this.props.modal.handleNo || this.handleDismissModal} handleYes={this.props.modal.handleYes} />
                : null
          }
          {this.props.modal.content}
        </Modal>
        <header className="">
          <TopMenu
            userName={this.props.userName}
            handleLogout={this._handleLogout}
            menu={this.props.aphroditeMenu}
          />
        </header>
        <SubMenu
          items={this.submenuItems.filter(M => M.show)}
        />
        <main>
          <Switch>
            <Route path="/aphrodite/euterpe/core-channels" component={CoreChannels} />
            <Route path="/aphrodite/euterpe/core-channels/batch-updates" component={CoreChannels} />

            <Route path="/aphrodite/euterpe/tracks" component={Tracks} />
            <Route path="/aphrodite/euterpe/track-repeats" component={Tracks} />
            <Route path="/aphrodite/euterpe/track-uploader" component={Tracks} />
            <Route path="/aphrodite/euterpe/tracks-not-in-use" component={Tracks} />
            <Route path="/aphrodite/euterpe/tracks-inactive" component={Tracks} />
            <Route path="/aphrodite/euterpe/tracks-added" component={Tracks} />

            <Route path="/aphrodite/euterpe/artists" component={Tracks} />

            <Route path="/aphrodite/euterpe/feedback" component={Feedback} />

            <Route path="/aphrodite/euterpe/playlists" component={Playlists} />

            <Route path="/aphrodite/euterpe/commercial-rules" component={CommercialRulesOverview} />

            <Route path="/aphrodite/minerva/customer-music" component={CustomerMusicReport} />

            <Route path="/aphrodite/mass-updater" component={MassUpdater} />

            <Route path="/aphrodite/users" component={Users} />

            <Route path="/aphrodite/sf" component={SfMain} />

            <Route path="/aphrodite/accounts/*" component={AccountsMain} />

            <Route path="/aphrodite/tool-box" component={ToolBox} />
            <Route path="/aphrodite/tool-box/tracklist_analizer" component={ToolBox} />

            <Route exact path="*" render={() => (
              <Redirect to="/aphrodite/euterpe/core-channels" />
            )} />
          </Switch>
        </main>
        <footer>
        </footer>
      </div>
    );
  }
}

const storeToProps = (store) => {
  return {
    userToken: store.authState.userInfo ? store.authState.userInfo.userToken : '',
    userName: store.authState.userInfo ? store.authState.userInfo.userName : '',
    aphroditeMenu: store.layoutState.aphroditeMenu ? store.layoutState.aphroditeMenu : [],
    aphroditeSelectedMenu: store.layoutState.aphroditeSelectedMenu ? store.layoutState.aphroditeSelectedMenu : '',
    modal: store.layoutState.modal,
    flashMessage: store.layoutState.flashMessage,
    joke: store.layoutState.joke ? store.layoutState.joke : ''
  };
};

export default withRouter(connect(storeToProps)(MainLayout));
