import React from 'react';

import moment from 'moment-timezone';

export default (props) => {
  const hourHeight = props.hourHeight || 80;
  const hoursOffset = props.timeNow.hour();
  const minutesOffset = props.timeNow.minutes();
  const barTopOffset = (hourHeight * hoursOffset) + ((minutesOffset / 60) * hourHeight);

  const barStyle = {
    top: barTopOffset
  };

  const currentDayIndex = props.timeNow.isoWeekday();

  const barCurDayStyle = {
    '--dayNumber': currentDayIndex
  };

  return (
    <div style={barStyle} className="schedule-current-bar">
      <span className="schedule-current-bar-marker" style={barCurDayStyle}></span>
    </div>
  );
}
