import React, {Component} from 'react';

import Modal from '@layout_2/Modal/modal';

export default (props) => (
  <Modal dark width="600px" height="375px" show={props.isVisible} handleDismiss={props.handleCloseDeleteSchedule}>
		<h3 className="text-center">Are you sure you want to delete:</h3>
		<h3 className="accent-color-text">{props.schedule.name}</h3>
		{(typeof props.affectedAreas !== 'undefined' && props.affectedAreas.length > 0) ?
			<div>
				<p>Deleting this schedule might affect this location(s): </p>
				<ul>
				{props.affectedAreas.map((A,Adx) => <li key={"affectedAreas" + Adx} style={{listStyle: "none",color:"white"}}>{A.name} </li>)}
				</ul>
			</div> : null
		}
		<div className="action-btns">
			<div style={{marginRight: '1vw'}} className="btn btn-default btn-border btn-pill" onClick={props.handleCloseDeleteSchedule}>NO</div>
			<div className="btn btn-primary btn-border btn-pill" onClick={props.handleDeleteSchedule}>YES</div>
		</div>
	</Modal>
);

