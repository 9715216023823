import * as React from 'react';

import { connect } from 'react-redux';

import Table from '@common/table';
import MainContainer from '@layout_2/main-container';
import RadioButtonGroup from '@common/radio-buttons/radio-button-group';

import * as deviceStatusApi from '@api/device-status-api';
import { getAreas } from '@api/locations-api';
import { PAPERTRAIL_ENV } from '@config';

const SHORT_THRESHOLD_MINUTES = 10;
const LONG_THRESHOLD_DAYS = 3;

class DeviceStatusMain extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      devices: this.props.deviceList || [],
      filterQuery: '',
      filterDeviceStatus: 'all',
      curentPlayerId: '',
      isPapertrailVisible: false,
      curentPapertrailUrl: '',
      showWebPlayers: false,
    };
  }

  componentWillMount() {
    getAreas(this.props.userToken);
    deviceStatusApi.getDeviceStatusList(this.props.userToken);
    window.clearInterval(this.refreshDevicesList);
    this.refreshDevicesList = window.setInterval(() => {
      getAreas(this.props.userToken);
      deviceStatusApi.getDeviceStatusList(this.props.userToken);
    }, 2.5 * 60 * 1000);
  }

  componentWillUnmount() {
    window.clearInterval(this.refreshDevicesList);
  }

  get inactiveThreshold() {
    return Date.now() - LONG_THRESHOLD_DAYS * 1000 * 60 * 60 * 24;
  }

  get onlineThreshold() {
    return Date.now() - SHORT_THRESHOLD_MINUTES * 60 * 1000;
  }

  statusFunc(device) {
    switch (this.state.filterDeviceStatus) {
      case 'online':
        return this.isOnline(device);
      case 'inactive':
        return this.isInactive(device);
      case 'offline':
        return this.isOffline(device);
      default:
        return true;
    }
  }

  get devices() {
    const devices = this.state.showWebPlayers === null
      ? this.props.areas.filter(area => area.streaming && area.streaming.enabled).concat(this.props.deviceList)
      : this.state.showWebPlayers
        ? this.props.areas.filter(area => area.streaming && area.streaming.enabled)
        : this.props.deviceList;
    return !this.state.filterQuery
      ? devices.filter(device => this.statusFunc(device))
      : devices.filter(device => {
        const query = this.state.filterQuery.toLowerCase();
        const searchables = [
          device.player_id.toLowerCase(),
          device.area.toLowerCase(),
          device.business.toLowerCase(),
          device.client.toLowerCase(),
        ];
        return searchables.some(searchable => searchable.includes(query) && this.statusFunc(device));
      });
  }

  get tableHeadings() {
    return [
      {
        title: 'Player',
        width: 75,
      },
      {
        title: 'Serial',
        width: 75,
      },
      {
        title: 'Area',
        width: 120,
      },
      {
        title: 'Business',
        width: 120,
      },
      {
        title: 'Client',
        width: 120,
      },
      {
        title: 'Version',
        width: 50,
      },
      {
        title: 'Last Seen',
        width: 80,
        date: true,
      },
      {
        title: 'Last Played',
        width: 80,
        date: true,
      },
    ];
  }

  get rowData() {
    return this.devices.map((device) => ({
      class: this.getRowClassName(device),
      onClick: () => this.handleOpenPapertrail(device),
      data: [
        device.streaming ? 'Web Player' : 'Ambie Player',
        device.player_id,
        device.area,
        device.business,
        device.client,
        device.version,
        device.last_heart_beat,
        device.last_played,
      ]
    }));
  }

  get footerItems() {
    const onlineCount = this.devices.filter(device => this.isOnline(device)).length;
    const inactiveCount = this.devices.filter(device => this.isInactive(device)).length;
    const offlineCount = this.devices.filter(device => this.isOffline(device)).length;
    const ratio = `${Math.round((onlineCount / this.devices.length) * 100)}%`;
    return {
      left: (
        <p>Total Players: {this.devices.length}</p>
      ),
      center: (
        <React.Fragment>
          <p>Online: {onlineCount}</p>
          <p>Inactive: {inactiveCount}</p>
          <p>Offline: {offlineCount}</p>
        </React.Fragment>
      ),
      right: (
        <p>Ratio: {ratio}</p>
      )
    }
  }

  get radioButtons() {
    return (
      <div className="flex" style={{ justifyContent: 'space-between', width: '100%' }}>
        <RadioButtonGroup
          buttons={[
            {
              id: 'all players',
              label: 'All',
              checked: this.state.showWebPlayers === null,
              onChange: (e) => this.handleShowWebPlayers(e, null),
            },
            {
              id: 'ambie player',
              label: 'Ambie Players',
              checked: this.state.showWebPlayers === false,
              onChange: (e) => this.handleShowWebPlayers(e, false),
            },
            {
              id: 'web player',
              label: 'Web Players',
              checked: this.state.showWebPlayers,
              onChange: (e) => this.handleShowWebPlayers(e, true),
            },
          ]}
        />
        <RadioButtonGroup
          buttons={[
            {
              id: 'all',
              label: 'All',
              checked: this.state.filterDeviceStatus === 'all',
              onChange: (e) => this.handleRadioSelect(e, 'all'),
            },
            {
              id: 'online',
              label: 'Online',
              checked: this.state.filterDeviceStatus === 'online',
              onChange: (e) => this.handleRadioSelect(e, 'online'),
            },
            {
              id: 'inactive',
              label: 'Inactive',
              checked: this.state.filterDeviceStatus === 'inactive',
              onChange: (e) => this.handleRadioSelect(e, 'inactive'),
            },
            {
              id: 'offline',
              label: 'Offline',
              checked: this.state.filterDeviceStatus === 'offline',
              onChange: (e) => this.handleRadioSelect(e, 'offline'),
            }
          ]}
        />
      </div>
    )
  }

  getRowClassName = (device) => {
    if (this.isOffline(device)) {
      return 'bg-danger result-item';
    }
    if (this.isInactive(device)) {
      return 'bg-warning result-item';
    }
    return 'bg-success result-item';
  }

  isOnline = (device) => (
    device.streaming
      ? device.streaming.online && (device.last_heart_beat > this.onlineThreshold)
      : device.last_heart_beat > this.onlineThreshold
  );

  isInactive = (device) => device.last_heart_beat > this.inactiveThreshold && device.last_heart_beat < this.onlineThreshold;

  isOffline = (device) => (
    device.streaming
      ? !this.isInactive(device) && device.streaming.online === false || device.last_heart_beat < this.inactiveThreshold && device.last_heart_beat < this.onlineThreshold
      : !this.isInactive(device) && (device.last_heart_beat < this.inactiveThreshold) && (device.last_heart_beat < this.onlineThreshold)
  );

  handleUpdatePlayer = (playerId) => deviceStatusApi.updateDevice(this.props.userToken, playerId);

  handleOpenPapertrail = (device) => {
    const papertrailId = device.streaming
      ? `${PAPERTRAIL_ENV}-area-${device.area_id}`
      : device.player_id;
    window.open(`https://papertrailapp.com/systems/${papertrailId}/events`, '_blank');
  }

  handleOpenNewTab = (playerId) => {
    const device = this.devices.find(device => device.player_id === playerId);
    const papertrailId = device.streaming
      ? `${PAPERTRAIL_ENV}-area-${device.area_id}`
      : device.player_id;
    this.setState({
      ...this.state,
      curentPlayerId: '',
      isPapertrailVisible: false,
      curentPapertrailUrl: ''
    });
    window.open(`https://papertrailapp.com/systems/${papertrailId}/events`, '_blank');
  }

  handleFilter = (e) => {
    this.setState({
      ...this.state,
      filterQuery: e.target.value,
      curentPlayerId: '',
      isPapertrailVisible: false,
      curentPapertrailUrl: ''
    });
  }

  handleRadioSelect = (e, filterDeviceStatus) => {
    this.setState({
      ...this.state,
      filterDeviceStatus,
    });
  }

  handleShowWebPlayers = (e, showWebPlayers) => {
    showWebPlayers
      ? getAreas(this.props.userToken)
      : deviceStatusApi.getDeviceStatusList(this.props.userToken);
    this.setState({
      ...this.state,
      showWebPlayers,
    });
  }

  render() {
    return (
      <React.Fragment>
        <div style={{ marginTop: '58px' }}>
          <MainContainer>
            {this.props.deviceList && this.props.deviceList.length > 0 ?
              <Table
                searchbar
                borders
                sortBy={6}
                sortByAscending={false}
                headings={this.tableHeadings}
                rowData={this.rowData}
                showFooter
                footerItems={this.footerItems}
                noDataMessage="Ain't no devices when she's gone. It's not warm when she's away. Ain't no devices when she's gone, and she's always gone too long, anytime she goes away."
                paginate
                itemsCountPerPage={20}
                csv
                csvFileName="device-status"
                actions={this.radioButtons}
              />
              :
              <h3 className="text-center">Loading...</h3>
            }
          </MainContainer>
        </div>
      </React.Fragment>
    );
  }
};

const storeToProps = (store) => {
  return {
    deviceList: store.deviceStatusState.deviceStatusList,
    areas: store.locationsState.areas,
    userToken: store.authState.userInfo ? store.authState.userInfo.userToken : '',
    sendingUpdate: store.deviceStatusState.deviceUpdating
  };
};

export default connect(storeToProps)(DeviceStatusMain);