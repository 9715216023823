import React, {Component} from 'react';
import Select from 'react-select';

let beautify = (string) => {

	return string.replace(/_/g, " ");

}


export default (props) => {

  //debugger;

  let fullLead = null;

  if (typeof props.lead !== 'undefined') {
  	fullLead = Object.keys(props.lead).map((key,idx) => {

		  return <p key={'lead-' + idx} >
			  <span style={{ fontWeight: 'bold' }}>{beautify(key)}: </span>
			  <span className="text-primary">{props.lead[key]}</span>
		  </p>

	  })
  }


  return (
    <div
	    className="modal-alert animated flipInX"
	    style={{ display: props.isVisible ? 'block' : 'none', marginTop: '-1px', width: '90%', marginLeft: '-45%', height: '70%', overflowY: 'auto' }}>

	    {fullLead}


      <button style={{ marginRight: '10px' }} onClick={props.handleClose} className="btn btn-primary btn-save">Close</button>
    </div>
  );

};
