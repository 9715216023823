import React, { Fragment} from 'react';

import { DebounceInput } from 'react-debounce-input';
import ReactTooltip from 'react-tooltip';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css'

export default (props) => {

  const icons = {
    search: "0xE93E",
    clear: "0xE912"
  }

  const isClearable = props.value && props.clearButton;

  return (
    <Fragment>
      <ReactTooltip className="help-tip-content" globalEventOff='click' />
      <div className="form-group searchbar margin-none" style={{ width: props.width || '100%', maxWidth: props.maxWidth || '500px' }}>
        <div className="form-search-cancellable flex">
          <span
            style={{
              position: 'absolute',
              height: '100%',
              zIndex: '10'
            }}
            className={isClearable ? 'btn-clear clear-icon' : 'btn-clear search-icon'}
            onClick={() => isClearable ? props.onChange({ target: { id: props.inputId, value: '' } }) : null }
            role="button"
            data-icon={isClearable ? String.fromCharCode(icons.clear) : String.fromCharCode(icons.search)}>
          </span>
          <div className="input-group">
            {props.onChange ? 
              <DebounceInput
                onKeyPress={(e) => { e.charCode === 13 ? props.searchHandler() : null }}
                type="text"
                debounceTimeout={props.debounceTimeout || 300}
                id={ props.inputId }
                className="form-control"
                placeholder={props.placeholder}
                onChange={props.onChange}
                value={props.value}
              />
              :
              <input
                type="text"
                id={props.inputId}
                className="form-control"
                placeholder={ props.placeholder }
              />}
            {props.checkbox && props.checkboxTitle
              ? (
                <Tooltip
                  title={props.checkboxTitle}
                  position="top"
                  trigger="mouseenter"
                >
                  <div className="checkbox">
                    <input
                      type="checkbox"
                      id="filter-active"
                      onChange={props.checkboxToggle}
                      checked={props.checked}
                    />
                    <label htmlFor="filter-active">{props.checkboxLabel}</label>
                  </div>
                </Tooltip>
              )
              : null
            }
          </div>
          {props.searchButton && props.searchHandler ? <button style={props.buttonStyle} className="btn btn-primary btn-search" onClick={props.searchHandler}>Search</button> : null}
          {props.children}
        </div>
      </div>
    </Fragment>
  )
}
