import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import store from '../../../store';

import ActionBar from '../../layout_2/action-bar';
import * as CONSTANTS from './track-repeats.constants';
import MainContainer from '../../layout_2/main-container';
import Modal from '../../layout_2/Modal/modal';
import SearchBar from '../../common/searchbar';
import TrackRepeatsDetails from './track-repeats-details';
import TrackRepeatsFilters from './track-repeats-filters';
import TrackRepeatsResultList from './track-repeats-result-list';
import { CSVLink, CSVDownload } from 'react-csv';
import faDownload from "@fortawesome/fontawesome-free-solid/faDownload";
import FontAwesomeIcon from '@fortawesome/react-fontawesome';

import * as trackRepeatsApi from '../../../api/track-repeats-api';

import { changeShowDetails, changeSelectedTrack } from '../../../actions/track-repeats-actions';

class RepeatsMain extends Component {

  constructor() {
    super();
    this._handleDetails = this._handleDetails.bind(this);
    this._handleDismissDetails = this._handleDismissDetails.bind(this);
  }

  componentWillMount() {
    console.log('COMPONENT WILL MOUNT');
    trackRepeatsApi.getDevices(this.props.userToken, this.props.filters.startDate, this.props.filters.endDate);
  }

  _handleDetails(index) {
    store.dispatch(changeSelectedTrack(this.props.tracks[index]));
    store.dispatch(changeShowDetails(true));
  }

  _handleDismissDetails() {
    store.dispatch(changeShowDetails(false));
  }

  render() {
    console.log('RENDERING TRACKS_REPEAT', this.props);
    let csvData = [];
    if (this.props.tracksGroupedByRepeats) {
      console.log('rendering grouped tracks by repeats')
      csvData = !this.props.tracks ? [] : this.props.tracks.map((track, index) => {
        return {
          title: track.title,
          artist: track.artist,
          device_id: track.device_id,
          count: track.count
        }
      });
    } else {
      console.log('rendering tracks', this.props.tracks);
      csvData = !this.props.tracks ? [] : this.props.tracks.map((track, index) => {
        return {
          artist: track.artist,
          title: track.title,
          playlist: track.playlist,
          played_time: new Date(parseInt(track.date)).toString()
        }
      });
    }

    console.log('DATA TO BE RENDERED', csvData)
    return (
      <Fragment>
        <ActionBar cssColClass="flex-center-center">
          <Modal
            show={this.props.showDetails}
            handleDismiss={this._handleDismissDetails}>
            <TrackRepeatsDetails selectedTrack={this.props.selectedTrack} />
          </Modal>
          <TrackRepeatsFilters
            userToken={this.props.userToken}
            devices={this.props.devices}
            filters={this.props.filters} />
          <CSVLink data={csvData} filename={"tracks_report.csv"}>
            <FontAwesomeIcon icon={faDownload} color={"black"} />
          </CSVLink>
        </ActionBar>
        <MainContainer>
          <TrackRepeatsResultList
            tracks={this.props.tracks}
            groupByRepeats={this.props.tracksGroupedByRepeats}
            message={CONSTANTS.NO_DATA_MESSAGE}
            handleDetails={this._handleDetails} />
        </MainContainer>
      </Fragment>
    );
  }
}

const storeToProps = (store) => {
  return {
    tracks: store.trackRepeatsState.tracks,
    tracksGroupedByRepeats: store.trackRepeatsState.groupByRepeats,
    showDetails: store.trackRepeatsState.showDetails,
    selectedTrack: store.trackRepeatsState.selectedTrack,
    devices: store.trackRepeatsState.devices,
    filters: store.trackRepeatsState.filters,
    userToken: store.authState.userInfo.userToken
  };
}

export default connect(storeToProps)(RepeatsMain);
