import React from 'react';

import RadioButton from './radio-button';

export default (props) => (
  <div style={props.styles} className="form-inline">
    {props.buttons.map(btn => <RadioButton
                                key={"radio-button-" + btn.id}
                                id={btn.id}
                                label={btn.label}
                                name={btn.name}
                                checked={btn.checked}
                                onChange={btn.onChange}
                              />
    )}
    { props.children }
  </div>
)
