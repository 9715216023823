export const TABLE_HEADINGS_GROUPED = [
  {
    title: 'Artist',
    width: 125
  },
  {
    title: 'Title',
    width: 125
  },
  {
    title: '# of plays',
    width: 50
  }
];

export const TABLE_HEADINGS_NOT_GROUPED = [
  {
    title: 'Artist',
    width: 110
  },
  {
    title: 'Title',
    width: 110
  },
  {
    title: 'Playlist',
    width: 100
  },
  {
    title: 'Time of play',
    width: 80,
    date: true,
  },
  {
    title: 'Seconds played',
    width: 100
  },
  {
    title: 'Duration',
    width: 100
  },
  {
    title: 'Player',
    width: 80,
    date: true,
  }
]

export const NO_DATA_MESSAGE = 'Please choose a device and date range';
