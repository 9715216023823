import * as types from '../actions/types';

const initalState = {
  accountInfo: {
    Name: "",
    Id: "",
    DashboardID : "",
    DashboardPWD : "",
    Email : "",
    Children : [],
    AmbieAccountInfo : null,
  },
  thingsToUpdateOnAccount : {
    client : {
      action: null,
    },
    business : [],
    areas : [],
  },
  savingAccountOnSF : false,
  savedAccountOnSF : false,
  isAParentAccount : true,
  selectedClient : null,
  savingDeviceInOlympus : false,
  savedDeviceInOlympus : false,
  savingErrorMessage : '',
  deviceInfo : {
    Id: null,
    SerialNumber: null,
    ParentAccount: null,
    ParentAccountSfId: null,
    AmbieId: null,
    AmbieArea: {
        name: null,
        id: null
    },
    AmbieBusiness: {
        name: null,
        id: null
    },
    currentAssignee : null,
  }
};

let getUserId = (Name) => {
  return Name.replace(/[^A-Z0-9]+/ig, "_").toLowerCase();
}

function getPwd() {
  var text = "";
  var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (var i = 0; i < 8; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  return text;
}

let accountLocal = {};
export default (state = initalState, action) => {
  switch (action.type) {
    case types.ACCOUNT_ACCOUNT_SAVING:
      return Object.assign({}, state, {
        savingAccountOnSF: true,
        savingErrorMessage: '',
      }); 
    case types.ACCOUNT_UPDATE_ACCOUNT_INFO:
      accountLocal = Object.assign({},state.accountInfo);
      accountLocal[action.payload.field] = action.payload.value;
      return Object.assign({}, state, {
        accountInfo: accountLocal
      }); 
    case types.ACCOUNT_ACCOUNT_CREATED_ON_OLYMPUS :
      return Object.assign({}, state, {
        savingAccountOnSF: false
      });
      case types.ACCOUNT_ACCOUNT_CREATION_ERROR :{
        //debugger;
        return Object.assign({}, state, {
          savingErrorMessage: action.payload
        });  
      }
    case types.CREATE_NEW_BUSINESS: {
      const accountLocal = Object.assign({},state.accountInfo);
      action.payload.Children = [];
      accountLocal.Children.push(action.payload);
      return Object.assign({}, state, {
        accountInfo: accountLocal
      });
    }
    case types.CREATE_NEW_AREA: {
      const accountLocal = Object.assign({},state.accountInfo);
      const business = accountLocal.Children.filter(B => B.id === action.payload.parentId);
      if (business.length === 1) {
        business[0].Children.push(action.payload);
      }
      return Object.assign({}, state, {
        accountInfo: accountLocal
      });
    }
    case types.ACCOUNT_CHANGE_LINKED_CLIENT: {
      accountLocal = Object.assign({},state.accountInfo);
      accountLocal.AmbieAccountInfo = {
        id: action.payload.id,
        contact_name: action.payload.contact_name,
        name: action.payload.name,
      }
      // debugger
      return Object.assign({}, state, {
        accountInfo: accountLocal,
      });
    }
  }
  return state;
};


