import React from 'react';

import { FaBan, FaSync, FaCopy } from 'react-icons/fa';
import { MdRadio, MdSystemUpdate, MdRemoveShoppingCart } from 'react-icons/md';

import IconButton from './partials/icon-button';
import CommercialRuleButton from './partials/commercial-icon-button';

const AreaActionButtons = ({
  isActive,
  area,
  handleResendSchedule,
  handleToggleStreamingModal,
  handleToggleDuplicatePlayModal,
  handleCommercialRulesPopup,
  handleChangeDevice,
  handleDeactivateArea,
  handleShowUpdateSoftwareModal,
  handleShowRemoveDeviceModal,
  isRuleActive,
}) => (
  <div style={{ justifyContent: "flex-end", flex: '0 1 30%' }} className="areas-list-item-play text-right flex-wrap">
    {area.week_schedule_id && isActive
      ? (
        <React.Fragment>
          <button
            disabled={!area.week_schedule_id || !isActive}
            title="Resend Schedule"
            className="location__button --active"
            onClick={e => handleResendSchedule(e, area.area_id, isActive)}
          >
            <FaSync size={15} className="button__icon" />
          </button>
        </React.Fragment>
      )
      : null
    }

    {isActive && (
      <React.Fragment>
        <IconButton
          icon={<MdRadio size={15} />}
          title="Enable/Disable Streaming"
          disabled={!isActive}
          isActive={area.streaming && area.streaming.enabled}
          onClick={() => handleToggleStreamingModal(area)}
        />
        {IS_OLYMPUS && area.device && (
          <IconButton
            icon={<FaCopy size={15} />}
            disabled={!isActive}
            isActive={area.device && area.device.dont_skip_duplicates}
            onClick={() => handleToggleDuplicatePlayModal(area)}
          />
        )}
        {!area.device && null}
        {IS_OLYMPUS ?
          <CommercialRuleButton
            icon={isRuleActive}
            disabled={!isActive}
            title="Open Commercial Injection Rules"
            rule={area.commercialRule[0]}
            isActive={area.device && area.device.dont_skip_duplicates}
            onClick={() => handleCommercialRulesPopup(area)}
          />
          : null}
        <IconButton
          title="Update software"
          icon={<MdSystemUpdate size={20} />}
          isActive
          onClick={() => handleShowUpdateSoftwareModal(area.device.serial)}
        />
        {IS_OLYMPUS ?
          <IconButton
            title="Remove device from area"
            icon={<MdRemoveShoppingCart size={20} />}
            isActive
            onClick={() => handleShowRemoveDeviceModal(area.device.serial)}
          />
          : null}
      </React.Fragment>
    )}

    {!isActive && null}
    {IS_OLYMPUS ?
      <IconButton
        title="Assign Device"
        icon={<div className="button__icon icon-pi" />}
        isActive
        onClick={(e) => handleChangeDevice(e, area.area_id, area.name, area.device.device_type)}
      />
      : null}
    {IS_OLYMPUS ?
      <IconButton
        title="Deactivate area"
        icon={<FaBan size={15} />}
        isActive
        onClick={() => handleDeactivateArea(area.area_id)}
      />
      : null}
  </div>
);

export default AreaActionButtons;
