import React, {Component} from 'react';

export default (props) => {
  
    return (
        <div style={{ display: props.isVisible ? 'block' : 'none' }} className="confirmation-dialog modal-alert dark animated flipInX">
          <h2 className="text-center">Are you sure you want to assign player <span className="highlight">{props.deviceId}</span> to <span className="highlight">{props.areaName}</span> ?</h2>
          <div className="action-btns">
            <div className="btn btn-default btn-border btn-pill" onClick={props.handleCancel}>NO</div>
            <div className="btn btn-primary btn-border btn-pill" onClick={props.handleConfirmed}>YES</div>
          </div>
        </div>
      );
}
