import * as types from "../actions/types";

const initalState = {
  deviceStatusList: [],
  deviceUpdating : false
};

export default (state = initalState, action) => {
  switch (action.type) {
    case types.CHANGE_DEVICE_STATUS_LIST:
      return Object.assign({}, state, {
        deviceStatusList: action.payload
      });
    break;

    case types.UPDATE_DEVICE:
      return Object.assign({}, state, {
        deviceUpdating: false
      });
    break;

	  case types.UPDATING_DEVICE:
		  return Object.assign({}, state, {
			  deviceUpdating: true
		  });
		  break;
  }
  return state;
};
