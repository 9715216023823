import * as types from './types';

export const storeChannels = (channels) => {
  return {
    type: types.COMMERCIALRULES_STORE_CHANNELS,
    payload: Promise.resolve(channels)
  };
};

export const openCommercialRulesModal = () => {
  return {
    type: types.COMMERCIALRULES_OPEN_MODAL,
    payload: true
  }
};

export const closeCommercialRulesModal = () => {
  return {
    type: types.COMMERCIALRULES_CLOSE_MODAL,
    payload: false
  }
};

export const changeChannelSerachQuery = (value) => {
  return {
    type: types.COMMERCIALRULES_CHANNEL_SEARCH,
    payload: value
  }
};

export const changeAreaCommercialRules = payload => ({
  type: types.CHANGE_AREA_COMMERCIAL_RULES,
  payload,
});

export const changeClientCommercialRules = payload => ({
  type: types.CHANGE_CLIENT_COMMERCIAL_RULES,
  payload,
});

export const changePlaylistCommercialRules = payload => ({
  type: types.CHANGE_PLAYLIST_COMMERCIAL_RULES,
  payload,
});

export const changeAllCommercialRules = payload => {
return {
  type: types.CHANGE_ALL_COMMERCIAL_RULES,
  payload,
}};
