import * as types from './types';

export const changeFzMessage = (payload) => ({
  type: types.CHANGE_FZMESSAGE,
  payload: payload,
});

export const changeFzCurFileNo = (payload) => ({
  type: types.CHANGE_FZCURFILENO,
  payload: payload,
});

export const changeFzTotalFileNo = (payload) => ({
  type: types.CHANGE_FZTOTALFILENO,
  payload: payload,
});

export const changeFzShowProgress = (payload) => ({
  type: types.CHANGE_FZSHOWPROGRESS,
  payload: payload,
});

export const changeIsOverFz = (payload) => ({
  type: types.CHANGE_IS_OVER_FZ,
  payload: payload,
});

export const changeFzFileNames = (payload) => ({
  type: types.CHANGE_FZFILENAMES,
  payload: payload,
});

export const changeExistingTracks = (payload) => {
  return {
    type: types.CHANGE_EXISTING_TRACKS,
    payload: payload,
  };
};

export const changeShowEditTrack = (payload) => ({
  type: types.CHANGE_SHOW_EDIT_TRACK,
  payload: payload,
});

export const changeShowDismissTrack = (payload) => ({
  type: types.CHANGE_SHOW_DISMISS_TRACK,
  payload: payload,
});

export const changeSelectedTrackForEdit = (payload) => ({
  type: types.CHANGE_SELECTED_TRACK_FOR_EDIT,
  payload: payload,
});

export const resetSelectedTrackForEdit = (payload) => ({
  type: types.RESET_SELECTED_TRACK_FOR_EDIT,
  payload: payload,
});

export const openDismissAllTracksPopup = (payload) => ({
  type: types.OPEN_DISMISS_ALL_POPUP,
  payload: payload,
});

export const closeDismissAllTracksPopup = (payload) => ({
  type: types.CLOSE_DISMISS_ALL_POPUP,
  payload: payload,
});

export const addToFileToConfirm = (payload) => ({
  type: types.ADD_TO_FILE_TO_CONFIRM,
  payload: payload,
});

export const removeFromFileToConfirm = (payload) => ({
  type: types.REMOVE_FROM_FILE_TO_CONFIRM,
  payload: payload,
});
