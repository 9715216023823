import React from 'react';

export default (props) => (
  <div className="radio">
    <input
      type="radio"
      id={props.id}
      name={props.name || props.id}
      checked={props.checked}
      onChange={props.onChange}
    />
    <label htmlFor={props.id}>{ props.label }</label>
  </div>
)
