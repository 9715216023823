import * as types from "../actions/types";

let today = new Date();
let yesterday = new Date();
yesterday.setDate(yesterday.getDate() - 1);

const initalState = {
  notUsedTracks: [],
  total_results : 0,
  totalCatalogue : 0,
  notUsedSelectedFilters: {
    page: 1,
    rpp: 50
  },
  showDeleteConfirmationModal: false,
  showConfirmation : false,
  showCSVDownloadPopup : false,
  CSVData : [],
  CSVDataReady : false,
  isDeletedFilterActive : false,
  isMonthFilterActive : false,
};

export default (state = initalState, action) => {
  switch (action.type) {
    case types.TOGGLE_DISPLAY_DELETE :
      return Object.assign({}, state, {
        isDeletedFilterActive: !state.isDeletedFilterActive
      });
    case types.TOGGLE_MONTH_FILTER:
      //console.log(state.isMonthFilterActive);
      return Object.assign({}, state, {
        isMonthFilterActive: !state.isMonthFilterActive
      });
    case types.CHANGE_NOT_USED_TRACKS:
      return Object.assign({}, state, {
        notUsedTracks: action.payload.results,
        total_results: action.payload.total,
        totalCatalogue: action.payload.totalCatalogue
      });
    case types.CHANGE_NOT_USED_FILTERS:
      return Object.assign({}, state, {
        notUsedSelectedFilters: {...state.notUsedSelectedFilters, ...action.payload}
      });
    case types.OPEN_DELETE_CONFIRMATION_MODAL:
      return Object.assign({}, state, {
        showDeleteConfirmationModal: true
      });
    case types.CLOSE_DELETE_CONFIRMATION_MODAL:
      return Object.assign({}, state, {
        showDeleteConfirmationModal: false
      });
    case types.HIDE_CONFIRMATION_DELETION_ALL_TRACKS_NOT_USED:
      return Object.assign({}, state, {
        showConfirmation: false
      });
    case types.SHOW_CONFIRMATION_DELETION_ALL_TRACKS_NOT_USED:
      return Object.assign({}, state, {
        showConfirmation: true,
        showDeleteConfirmationModal : false,
      });
    case types.OPEN_CSV_POPUP:
      return Object.assign({}, state, {
        showCSVDownloadPopup: true,
        CSVDataReady : false,
      });
    case types.CLOSE_CSV_POPUP:
      return Object.assign({}, state, {
        showCSVDownloadPopup: false
      });
    case types.UPDATE_CSV_DATA:
      return Object.assign({}, state, {
        CSVDataReady : true,
        CSVData : action.payload.results,
      });
  }
  return state;

};
