import validator from "email-validator";
/** auxiliary functions */
export const translateFields = (fields, translateFn) => {
  return Object.entries(fields).reduce((all, [key, value]) => {
    return {
      ...all,
      [key]: translateFn(value)
    };
  }, {});
}

export const defaultValidation = (value) => {
  return {
    isValid: value.checks.every(c => c("")),
    error: null
  };
}

export const allFieldsValid = (value) => {
  return {
    isValid: true,
    error: null
  };
}

/** validation functions */
export const isNotNullOrEmpty = (value) => {
  if (value.length > 0) {
    return true
  } else {
    return false
  }
}

export const isLongerThan = (value, length) => {
  if (value.length > length) {
    return true
  } else {
    return false
  }
}

export const isValidEmail = email => validator.validate(email)


export const isEmpty = value => value === '' ? true : false;
