import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Typeahead } from 'react-bootstrap-typeahead';

class TopMenu extends Component {
  render() {
    let userName = this.props.userName ? this.props.userName.toUpperCase() : '';
    let clients = this.props.clientsList ? this.props.clientsList.map((item, index) => ({ value: item.id, label: item.name })) : [];
    return (
      <header id="top-menu">
        <nav className="navbar navbar-inverse navbar-static-top">
          <div className="container">
            <div className="navbar-header">
              <button
                onClick={this.props.handleMobileMenuToggle}
                type="button"
                className="navbar-toggle collapsed"
                data-toggle="collapse"
                data-target="#primary-menu"
              >
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>
              <div className="navbar-brand flex-center-center" id="client-switcher-wrapper">
                <Typeahead
                  ref={(typeahead) => {
                    this.typeheadRef = typeahead;
                  }}
                  className="margin-top-sm margin-bottom-sm"
                  id="header-search-clients"
                  emptyLabel={''}
                  highlightOnlyResult
                  labelKey="label"
                  positionFixed
                  onFocus={() => {
                    this.typeheadRef.clear();
                    this.typeheadRef._showMenu();
                  }}
                  onChange={(selected) => {
                    if (selected && selected[0]) {
                      this.props.handleChangeClient(selected[0]);
                    }
                  }}
                  selected={clients.filter((c) => c.value === this.props.currentClientId)}
                  // defaultSelected={clients.filter(c => c.value === this.props.currentClientId)}
                  options={clients}
                  placeholder="Search clients..."
                  selectHintOnEnter
                />
              </div>
            </div>

            <div className={this.props.isMobileMenuOpen ? ' navbar-collapse animated slideInDown ' : ' navbar-collapse'} id="primary-menu">
              <ul className="nav navbar-nav navbar-right" style={{ marginRight: '300px' }}>
                <li className={this.props.isClientMenuOpen ? 'dropdown open' : 'dropdown'}>
                  <a onClick={this.props.handleClientMenuClick} href="#" className="dropdown-toggle">
                    CLIENT <i className="icon-angle-down"></i>
                  </a>
                  <ul className="dropdown-menu">
                    <li role="separator" className="divider"></li>
                    <li>
                      <Link to="/locations" className="menu-item-link" onClick={this.props.handleClientItemClick}>
                        Locations
                      </Link>
                      <Link to="/schedules" className="menu-item-link" onClick={this.props.handleClientItemClick}>
                        Schedules
                      </Link>
                      <Link to="/device-status" className="menu-item-link" onClick={this.props.handleClientItemClick}>
                        Device Status
                      </Link>
                      {IS_OLYMPUS ? (
                        <Link to="/web-leads" className="menu-item-link" onClick={this.props.handleClientItemClick}>
                          Web Leads
                        </Link>
                      ) : null}
                      {IS_OLYMPUS ? (
                        <a href="/aphrodite/mass-updater" className="menu-item-link">
                          Mass updater
                        </a>
                      ) : null}
                      {IS_OLYMPUS ? (
                        <a href="/aphrodite/users" className="menu-item-link">
                          Users
                        </a>
                      ) : null}
                    </li>
                  </ul>
                </li>

                <li className={this.props.isEuterpeMenuOpen ? 'dropdown open' : 'dropdown'}>
                  <a onClick={this.props.handleEuterpeMenuClick} href="#" className="dropdown-toggle">
                    EUTERPE <i className="icon-angle-down"></i>
                  </a>
                  <ul className="dropdown-menu">
                    <li role="separator" className="divider"></li>
                    <li>
                      {IS_OLYMPUS ? (
                        <a href="/aphrodite/euterpe/core-channels" className="menu-item-link">
                          Core Channels
                        </a>
                      ) : null}
                      {IS_OLYMPUS ? (
                        <a href="/aphrodite/euterpe/core-channels/batch-updates" className="menu-item-link">
                          CC Batch Updates
                        </a>
                      ) : null}
                      {IS_OLYMPUS ? (
                        <a href="/aphrodite/euterpe/tracks" className="menu-item-link">
                          Tracks
                        </a>
                      ) : null}
                      {IS_OLYMPUS ? (
                        <a href="/aphrodite/euterpe/feedback" className="menu-item-link">
                          Feedback
                        </a>
                      ) : null}
                      {IS_OLYMPUS ? (
                        <a href="/aphrodite/euterpe/tracks-not-in-use" className="menu-item-link">
                          Tracks not in use
                        </a>
                      ) : null}
                      <a href="/aphrodite/euterpe/track-repeats" className="menu-item-link">
                        Track Repeats
                      </a>
                      {IS_OLYMPUS ? (
                        <a href="/aphrodite/euterpe/track-uploader" className="menu-item-link">
                          Track Upload
                        </a>
                      ) : null}
                      {IS_OLYMPUS ? (
                        <a href="/aphrodite/euterpe/playlists" className="menu-item-link">
                          Playlists
                        </a>
                      ) : null}
                      {IS_OLYMPUS ? (
                        <a href="/aphrodite/euterpe/commercial-rules" className="menu-item-link">
                          Commercial Rules
                        </a>
                      ) : null}
                      {IS_OLYMPUS ? (
                        <a href="/aphrodite/tool-box" className="menu-item-link">
                          Tool Box
                        </a>
                      ) : null}
                    </li>
                  </ul>
                </li>

                {/* <li className={this.props.MinervaMenuOpen ? 'dropdown open' : 'dropdown'}>
                  <a onClick={this.props.handleMinervaMenuClick} href="#" className="dropdown-toggle">
                    MINERVA <i className="icon-angle-down"></i>
                  </a>
                  <ul className="dropdown-menu">
                    <li role="separator" className="divider"></li>
                    <li>
                      <a href="/aphrodite/minerva/customer-music" className="menu-item-link">Customer Music</a>
                    </li>
                  </ul>
                </li> */}

                {/* {IS_OLYMPUS ?
                  <li className={this.props.isMoreMenuOpen ? 'dropdown open' : 'dropdown'}>
                    <a onClick={this.props.handleMoreMenuClick} href="#" className="dropdown-toggle">
                      MORE <i className="icon-angle-down"></i>
                    </a>
                    <ul className="dropdown-menu">
                      <li role="separator" className="divider"></li>
                      <li>
                        <Link to="old-admin" className="menu-item-link" onClick={this.props.handleMoreItemClick}>Old Admin</Link>
                      </li>
                    </ul>
                  </li>
                : null} */}
              </ul>
              <ul className="nav navbar-nav navbar-right">
                <li className={this.props.isUserMenuOpen ? 'dropdown open' : 'dropdown'}>
                  <a onClick={this.props.handleUserMenuClick} href="#" className="dropdown-toggle">
                    {userName} <i className="icon-angle-down"></i>
                  </a>
                  <ul className="dropdown-menu">
                    <li role="separator" className="divider"></li>
                    <li>
                      <a href="#" id="menu-logout" onClick={this.props.handleLogout}>
                        LOGOUT
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    );
  }
}

export default TopMenu;
