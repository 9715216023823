import React, {Component} from 'react';


class LocationsChangeSchedulePopup extends Component {

  constructor() {
    super();
  }

  render() {

    return (
      <div className={ this.props.isVisible ? 'modal-alert dark animated flipInX' : 'modal-alert dark animated flipOutX' }>
        <p>
          Are you sure you want to change the schedule of
          <br />
          <strong>{this.props.areaScheduleInfo.areaName}</strong> to <strong>{this.props.areaScheduleInfo.scheduleName} ?</strong>
        </p>
        <div className="action-btns">
          <a onClick={this.props.handleCancel} href="#" className="btn btn-transparent btn-pill btn-border btn-upper" title="Cancel">Cancel</a>
          <a onClick={this.props.handleSave} href="#" className="btn btn-primary btn-pill btn-upper" title="Change">Change</a> 
        </div>
      </div>
    );
  }
}

export default LocationsChangeSchedulePopup;
