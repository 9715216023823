import React, {Component} from 'react';

import Modal from '@layout_2/Modal/modal';

export default (props) => (
  <Modal width="600px" height="375px" show={props.isVisible} handleDismiss={props.handleCancelDeleteChannel}>
    <h3 className="text-center">This will delete the scheduled playlist</h3>
    <h3 className="text-center">Are you sure you want to do this?</h3>
    <div className="action-btns">
      <div style={{marginRight: '1rem'}} className="btn btn-default btn-border btn-pill" onClick={props.handleCancelDeleteChannel}>NO</div>
      <div className="btn btn-primary btn-border btn-pill" onClick={props.handleConfirmedDeleteChannel}>YES</div>
    </div>
  </Modal>
)
