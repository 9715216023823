import * as React from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { RiDeleteBin2Line } from 'react-icons/ri'
import { CgSpinner } from 'react-icons/cg';
import moment from 'moment-timezone';
import Modal from '@layout_2/Modal/modal';
import DateRangeCalendar from '@common/date-range-calendar';

class CommercialRulesPopup extends React.Component {
  constructor(props) {
    super(props);
    this.typeheadRef = React.createRef();
    this.state = { rules: [], error: null }
  }

  componentWillReceiveProps(nextProps) {
    const rules = nextProps.rules;
    if (rules !== undefined) this.setState({ rules, error: null });
  }

  get isValid() {
    if (!this.state.rules) return false;
    let passValidation = true;
    for (let rule of this.state.rules) {
      if (
        !Object.keys(rule).every((key) =>
          key === 'injection100' ? true : key === 'active' || rule[key]
        )) {
        passValidation = false;
        break;
      }
    };
    return passValidation;
  }

  isInvalidDate = (idx) => {
    const years = Math.abs(
      moment(this.state.rules[idx].startDate * 1000).diff(moment(), 'years')
    );
    return years >= 20;
  }

  updateRulesItem = (key, value, index) => {
    const newRules = this.state.rules.map((rule, idx) => {
      if (index === idx) {
        return { ...rule, [key]: value }
      } else return rule
    });
    this.setState({ rules: newRules });
    // this.setState({ rules: newRules }, () => console.log('STATE AFTER CHANGE', this.state.rules));
  };

  onRemoveRule = (idx) => {
    const rules = [...this.state.rules];
    rules.splice(idx, 1);
    this.setState({ rules });

  }

  onAddRule = () => {
    // debugger;
    const blankRule = {
      core_channel_id: '',
      repetition: 5,
      repetition_unit: 'tracks',
      order: 1,
      pick_type: 'random',
      startDate: null,
      endDate: null,
      injection100: false,
    };
    if (!this.state.rules) {
      this.setState({ rules: [blankRule] });
    } else {
      const rules = [...this.state.rules];
      rules.push(blankRule);
      this.setState({ rules });
    }
  }

  handleChangeRepetitionValue = (repetition, idx) => {
    const repetitionInt = parseInt(repetition, 10)
    return this.updateRulesItem('repetition', repetitionInt, idx);
  };

  handleChangeRepetitionUnit = (repetitionUnit, idx) =>
    this.updateRulesItem('repetition_unit', repetitionUnit, idx);

  handleChangePickType = (pickType, idx) =>
    this.updateRulesItem('pick_type', pickType, idx);

  momentToStartDate = (momentDate) =>
    !momentDate ? null : momentDate
      .startOf('day')
      .toDate()
      .getTime() / 1000;

  momentToEndDate = (momentDate) =>
    !momentDate ? null : Math.floor(momentDate
      .endOf('day')
      .toDate()
      .getTime() / 1000);

  handleChangeDateRange = (dateRange, idx) => {
    // debugger;
    const { startDate, endDate } = dateRange;
    if (startDate && endDate) {
      const start = this.momentToStartDate(startDate);
      const end = this.momentToEndDate(endDate)

      const newRules = this.state.rules.map((rule, index) => {
        if (index === idx) {
          return {
            ...rule,
            startDate: start,
            endDate: end,
          }
        } else {
          return rule
        };
      });
      this.setState({ rules: newRules });
    } else if (!startDate && endDate) {
      const autoStartDate = moment();
      const newAutoRules = this.state.rules.map((rule, index) => {
        if (index === idx) {
          return {
            ...rule,
            startDate: this.momentToStartDate(autoStartDate),
            endDate: end,
          }
        } else {
          return rule
        };
      });
      this.setState({ rules: newAutoRules });
    }
  }

  handleChangeRuleInjection100 = (idx) => {
    const injection100 = !this.state.rules[idx].injection100;
    return this.updateRulesItem('injection100', injection100, idx);
  };

  handleSave = async () => {
    let isDateOverlap = false;
    const orderedRules = [...this.state.rules];
    orderedRules.sort(function (a, b) { return a.startDate - b.startDate });

    for (let i = 1; i < orderedRules.length; i++) {
      if (orderedRules[i].startDate <= orderedRules[i - 1].endDate) {
        isDateOverlap = true;
        break;
      }
    }

    if (isDateOverlap) {
      this.setState({ error: "Dates are overlapping!" });
      return;
    } else if (this.state.error) {
      this.setState({ error: null });
    }

    const type = this.props.type;
    const id = this.props.id;
    await this.props.handleSave(id, type, this.state.rules);
  };

  handleSelectCoreChannel = (coreChannels, idx) => {
    const ccId = coreChannels[0] && coreChannels[0].id ? coreChannels[0].id : '';
    this.updateRulesItem('core_channel_id', ccId, idx);
  };

  defaultSelectedCoreChannel = (idx) => {
    const selectedCC = this.state.rules[idx].core_channel_id;
    if (selectedCC) {
      return this.props.coreChannels.filter(
        channel => channel.id === selectedCC
      );
    }
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        handleDismiss={this.props.closeModal}
        width="1100px"
        height="500px"
      >
        <div className="flex-column margin-bottom-sm margin-top-sm">
          <h5 style={{ fontSize: '18px' }}>
            {this.props.type.charAt(0).toUpperCase() + this.props.type.slice(1)}{' '}
            Commercial Rules Injection
          </h5>
          <h6 style={{ fontSize: '16px' }}>{this.props.name}</h6>
          <button
            className="btn btn-primary btn-md"
            onClick={this.onAddRule}>
            New Rule
          </button>

          {this.state.rules && this.state.rules.map((rule, idx) => {
            return (
              < div className="align-items-center" key={idx} >
                <RiDeleteBin2Line
                  style={{ cursor: 'pointer', marginLeft: "14px", height: "40px" }}
                  size={70}
                  onClick={() => this.onRemoveRule(idx)}
                />
                < Typeahead
                  ref={typeahead => {
                    this.typeheadRef = typeahead;
                  }}
                  className="margin-sm"
                  bsSize="sm"
                  id="commercial-rules-typeahead"
                  clearButton
                  maxResults={30}
                  emptyLabel=""
                  highlightOnlyResult
                  selected={this.defaultSelectedCoreChannel(idx)}
                  labelKey="name"
                  onChange={cc => this.handleSelectCoreChannel(cc, idx)}
                  options={this.props.coreChannels}
                  placeholder="Select a core channel..."
                  selectHintOnEnter
                />

                <label
                  style={{ margin: "0 7px 0 0" }}
                  htmlFor="injection-frequency"
                >Every
                </label>

                <input
                  min={1}
                  type="number"
                  style={numberInputStyle}
                  // className="form-control form-control-md margin-right-xs"
                  id="injection-frequency"
                  placeholder="Value"
                  onChange={e => this.handleChangeRepetitionValue(e.target.value, idx)}
                  value={rule.repetition || 5}
                />

                <select
                  // className="form-control form-control-md width-auto margin-right-xs"
                  style={selectBoxesStyle}
                  value={rule.repetition_unit}
                  onChange={e => this.handleChangeRepetitionUnit(e.target.value, idx)}
                >
                  <option value="tracks">Tracks</option>
                  <option value="minutes">Minutes</option>
                </select>

                <select
                  // className="form-control form-control-md width-auto margin-right-xs"
                  style={selectBoxesStyle}
                  value={rule.pick_type}
                  onChange={e => this.handleChangePickType(e.target.value, idx)}
                >
                  <option value="random-exclusive">No Repeats</option>
                  <option value="random">Allow Repeats</option>
                </select>
                <div style={{ flex: 'none', marginRight: '7px' }}>
                  <DateRangeCalendar
                    // index={idx}
                    small
                    showClearDates
                    withPortal
                    clearOnOpen={this.isInvalidDate(idx)}
                    numberOfMonths={1}
                    startDateId={`start-date-${idx}`}
                    startDate={
                      rule.startDate
                        ? moment(rule.startDate * 1000).startOf('day')
                        : null
                    }
                    endDate={
                      rule.endDate
                        ? moment(rule.endDate * 1000).startOf('day')
                        : null
                    }
                    endDateId={`end-date-${idx}`}
                    allowFutureStartDate
                    allowPastDate
                    onDatesChange={e => this.handleChangeDateRange(e, idx)}
                  />
                </div>
                <div style={{ display: 'flex', alignItems: 'center', width: '400px' }}>
                  <input style={{ margin: 0 }}
                    type="checkbox"
                    className="form-control form-control-lg width-auto"
                    id="filter-active"
                    onChange={e => this.handleChangeRuleInjection100(idx)}
                    checked={rule.injection100}
                  />
                  <label style={{ margin: '3px 0 0 5px' }} htmlFor="filter-active">100% Injection</label>
                </div>
              </div>
            )
          })}

          {this.props.saving
            ? <button
              className="btn btn-primary btn-md"
              style={{ padding: "3px" }}
              disabled>
              <CgSpinner size={30} className="spin" /></button>
            : <button
              className="btn btn-primary btn-md"
              onClick={this.handleSave}
              disabled={!this.isValid}>
              Save</button>
          }

          {this.state.error &&
            <div
              className="bg-danger"
              style={{ margin: "7px", color: "white", borderRadius: "5px" }}
            >
              {this.state.error}
            </div>
          }
        </div>
      </Modal >
    );
  }
}

const selectBoxesStyle = {
  height: "35px",
  borderRadius: "3px",
  borderColor: "lightGrey",
  margin: "0 7px 0 0",
}

const numberInputStyle = {
  width: "60px",
  height: "35px",
  border: "1px solid lightGrey",
  borderRadius: "3px",
  margin: "0 7px 0 0",
  padding: "7px",
}

export default CommercialRulesPopup;
