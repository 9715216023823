import React, {Component} from 'react';
import { connect } from 'react-redux';
import store from '../../../store';

import * as webLeadsAPI from '../../../api/web-leads-api';

import WebLeadsPopup from './web-leads-popup';

class WebLeadsMain extends Component {

  constructor() {
    super();

    this.state = {
      filterQuery: ''
    };
    
    this._handleOpenPopup = this._handleOpenPopup.bind(this);
    this._updateFilerQuery = this._updateFilerQuery.bind(this);
    this._handleClosePopup = this._handleClosePopup.bind(this);
  }

  componentWillMount() {
	  webLeadsAPI.getWebLeads(this.props.userToken);
  }

  componentWillUnmount() {

  }

  _handleOpenPopup(lead) {
    this.setState({
      curentLead: lead,
      isPopupVisible: true,
    });
  }

  _handleClosePopup() {
    this.setState({
      curentLead: '',
      isPopupVisible: false,
    });
  }

  _updateFilerQuery(event) {
    this.setState({
      filterQuery: event.target.value,
      curentLead: '',
      isPopupVisible: false,
    });
  }


  render() {

	  let sendingUpdate = this.props.sendingUpdate?this.props.sendingUpdate:false;

    let threshold = Date.now() - 24 * 60 * 60 * 1000; // 10 minutes in the past
    console.log('threshold is now ', threshold);
/*
    let filteredList = this.props.deviceStatusList.filter((item) => item.player_id.toLowerCase().indexOf(this.state.filterQuery.toLowerCase()) >= 0 || 
                                                                    item.area.toLowerCase().indexOf(this.state.filterQuery.toLowerCase()) >= 0 ||
                                                                    item.business.toLowerCase().indexOf(this.state.filterQuery.toLowerCase()) >= 0 ||
                                                                    item.client.toLowerCase().indexOf(this.state.filterQuery.toLowerCase()) >= 0);*/

	  let filteredList = this.props.webLeads;

    let leadsList = filteredList
      .sort((a,b)=>{
        let date_a = isNaN(parseInt(a.contact_created_date)) ? 0 : parseInt(a.contact_created_date);
        let date_b = isNaN(parseInt(b.contact_created_date)) ? 0 : parseInt(b.contact_created_date);

        console.log(date_b , date_a);
        return date_b - date_a

      })
      .map((item, index) => (
        <tr key={'device-' + index}
            className={parseInt(item.contact_created_date) > threshold ? 'result-item bg-success' : 'result-item '}
            onClick={this._handleOpenPopup.bind(this, item)}
        >
          <td>{item.contact_business_name} ({item.contact_sites})</td>
          <td>{item.contact_full_name}</td>
          <td>{item.contact_email}</td>
          <td>{item.contact_phone_number}</td>          
          <td>{parseInt(item.contact_created_date) > 0 ? new Date(parseInt(item.contact_created_date)).toLocaleDateString() : ''}</td>
        </tr>
      ));

    return (
      <div>
        <div id="ambie-header" className="col-md-12 text-center default-header animated delay02s fadeInDown">Web Leads</div>
        <div id="ambie-body">
	        {/*<input placeholder="Search by device, business, client or area" type="text" value={this.state.filterQuery} onChange={this._updateFilerQuery} style={{ height: '40px', width: '400px', borderRadius: '20px', border: '1px solid #ddd', outline: 'none', paddingLeft: '10px', margin: '15px' }} />*/}
          <table className="table default-table table-hover table-bordered">
            <thead>
              <tr>
                <th>Business (sites)</th>
                <th>Contact Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Created</th>
              </tr>
            </thead>
            <tbody>
              {leadsList}
            </tbody>
          </table>
        </div>
        <WebLeadsPopup
	        lead={this.state.curentLead}
          isVisible={this.state.isPopupVisible}
          handleClose={this._handleClosePopup}
        />
      </div>
    );
  }
};

const storeToProps = (store) => {
  return {
    webLeads: store.webLeadsState.webLeads,
    userToken: store.authState.userInfo ? store.authState.userInfo.userToken : '',
  };
};

export default connect(storeToProps)(WebLeadsMain);