import axios from 'axios';
import store from '../store';
import { showSpinner, hideSpinner, showNotification } from '../actions/layout-actions';

import * as authApi from './auth-api';
import { HOST_CF, GENERAL_SUCCESS_MESSAGE, GENERAL_REQUEST_ERROR_MESSAGE, HOST_ROCK_API } from '../config';

import { changeDeviceStatusList, updateDeviceAction, updatingDeviceAction } from '../actions/device-status-actions';
import axiosInterceptors from '../utils/interceptors';

/**
 * use rock-api endpoint
 */
export const getDeviceStatusList = async (userToken) => {
  store.dispatch(showSpinner());

  try {
    const response = await axiosInterceptors.get(`${HOST_ROCK_API}/devices`, {});
    _detectUnauthorized(response);
    store.dispatch(hideSpinner());
    store.dispatch(changeDeviceStatusList(response.data));
  } catch (error) {
    store.dispatch(hideSpinner());
    store.dispatch(showNotification(GENERAL_REQUEST_ERROR_MESSAGE, true));
    _handleUnauthorized(error.message);
  }
};

const _handleUnauthorized = (error) => {
  if (error === 'Unauthorized') {
    authApi.logout();
  }
};

const _detectUnauthorized = (response) => {
  if (response.data.error && response.data.error == 'Unauthorized') {
    throw new Error(response.data.error);
  }
};

export const updateDevice = async (userToken, playerId) => {
  //debugger;
  store.dispatch(updatingDeviceAction());
  try {
    await axios.put(
      `${HOST_CF}/devices`,
      {
        devices: [playerId],
      },
      {
        headers: { 'x-ambie-token': userToken },
      },
    );
    store.dispatch(updateDeviceAction(false));
    store.dispatch(showNotification(GENERAL_SUCCESS_MESSAGE, true));
  } catch (e) {
    store.dispatch(updateDeviceAction(false));
    store.dispatch(showNotification(GENERAL_REQUEST_ERROR_MESSAGE, true));
    _handleUnauthorized(response.message);
    console.error('Error: ', e);
  }
};
