import axios from 'axios';

import store from '@store';
import {
  HOST,
  PORT,
  PREFIX,
  LOCAL_STORAGE_USER,
  HOST_CF,
  HOST_CF_FRONTEND,
  HOST_ROCK_API,
  LOCAL_STORAGE_USER_TOKEN,
  LOCAL_STORAGE_USER_REFRESH_TOKEN,
} from '@config';
import { loginSuccess, logoutSuccess, changeClientsList, changeCurrentClientId } from '@actions/auth-actions';
import { setLoginFormValid, setLoginFormInvalid } from '@actions/login-actions';

import browserHistory from '../history';
import axiosInterceptors from '../utils/interceptors';

export const login = (credentials) => {
  return axiosInterceptors
    .post(`${HOST_ROCK_API}/auth/login`, {
      username: credentials.username,
      password: credentials.password,
      application: 'olympus',
      validateStatus: (status) => handleUnauthorized(status),
    })
    .then((response) => {
      store.dispatch(setLoginFormValid());
      const userDetail = response.data.user.users_user;
      const clientDetail = response.data.user.businesses_client;
      let userInfo = {
        userToken: response.data.accessToken,
        refreshToken: response.data.refreshToken,
        userName: userDetail.name || 'UNKNOWN',
        created: userDetail.date_joined,
        email: userDetail.email,
        clientId: clientDetail.id,
        businessName: clientDetail.name,
        businessImage: 'https://dashboard.ambie.fm/images/logo-transparent.png',
        userType: response.data.user.permission_level.permission_level,
        username: userDetail.username,
        user: userDetail.user,
        userId: userDetail.id,
      };
      localStorage.setItem(LOCAL_STORAGE_USER, JSON.stringify(userInfo));
      localStorage.setItem(LOCAL_STORAGE_USER_TOKEN, userInfo.userToken);
      localStorage.setItem(LOCAL_STORAGE_USER_REFRESH_TOKEN, userInfo.refreshToken);
      store.dispatch(loginSuccess(userInfo));
      browserHistory.push('/locations');
    })
    .catch((response) => {
      console.error('Error: ', response);
      store.dispatch(setLoginFormInvalid());
    });
};

export const getClientsList = (userToken) => {
  return axiosInterceptors
    .get(HOST_CF + '/clients', {
      validateStatus: (status) => handleUnauthorized(status),
    })
    .then((response) => {
      store.dispatch(changeClientsList(response.data.results));
    })
    .catch((response) => {
      console.error('Error', response);
    });
};

export const requestPasswordReset = async (email, id, type, expiry = 1, sendEmail = true, referrer = window.location.hostname, username) => {
  try {
    let user = { referrer, username };
    try {
      const response = await getClientIpAddress();
      user = { ...user, ...response.data };
    } catch (e) {
      console.log('Error getting client IP Address: ', e);
    }
    const body = { email, id, type, expiry, user, sendEmail };
    const response = await axios.post(`${HOST_CF_FRONTEND}/user/password-reset/request`, body);
    const token = response.data.token;
    return token;
  } catch (e) {
    console.log('Error: ', e);
    return false;
  }
};

export const resetPassword = async (token, password) => {
  try {
    const body = { token, password };
    await axios.post(`${HOST_CF_FRONTEND}/user/password-reset`, body);
    return true;
  } catch (e) {
    console.log('Error: ', e);
    return false;
  }
};

export const validatePasswordResetToken = async (token) => {
  try {
    const params = { token };
    const response = await axios.get(`${HOST_CF_FRONTEND}/user/password-reset/request/validate`, { params });
    return response.data.valid;
  } catch (e) {
    console.log('Error: ', e);
    return false;
  }
};

export const changeEmailAddress = async (userToken, id, email, superuser = false) => {
  try {
    const headers = { 'x-ambie-token': userToken };
    const config = { headers };
    const body = { id, email, superuser };
    const response = await axios.patch(`${HOST_CF}/user`, body, config);
    return true;
  } catch (e) {
    console.log('Error: ', e);
    return false;
  }
};

const getClientIpAddress = () => axios.get('https://api.ipify.org?format=json');

export const handleUnauthorized = (status) => {
  if (status === 401 || status === 403) {
    logout();
    return false;
  }
  return true;
};

export const logout = async () => {
  try {
    const storedUserInfo = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER)) || {};
    // debugger
    await axios.delete(`${HOST_CF}/user/logout`, {
      headers: { 'x-ambie-token': storedUserInfo.userToken },
    });
  } catch (e) {
    // debugger
    console.error(e);
  }
  //debugger
  browserHistory.push('/login');
  localStorage.removeItem(LOCAL_STORAGE_USER);
  localStorage.removeItem(LOCAL_STORAGE_USER_TOKEN);
  localStorage.removeItem(LOCAL_STORAGE_USER_REFRESH_TOKEN);
  // Doesn't the access token need to be removed as well?
  store.dispatch(logoutSuccess());
  window.location.reload();
};
