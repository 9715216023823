import * as types from './types';

export const changeContainerHeight = (payload) => {
  return {
    type: types.CHANGE_CONTAINER_HEIGHT,
    payload,
  };
}

export const changeUserMenu = (payload) => {
  return {
    type: types.CHANGE_USER_MENU,
    payload,
  }
}

export const changeMoreMenu = (payload) => {
  return {
    type: types.CHANGE_MORE_MENU,
    payload,
  }
}

export const changeClientMenu = (payload) => {
  return {
    type: types.CHANGE_CLIENT_MENU,
    payload,
  }
}

export const changeAphroditeSelectedMenu = (payload) => {
  return {
    type: types.CHANGE_APHRODITE_SELECTED_MENU,
    payload,
  }
}

export const changeEuterpeMenu = (payload) => {
  return {
    type: types.CHANGE_EUTERPE_MENU, 
    payload,
  }
}

export const changeMinervaMenu = (payload) => {
  return {
    type: types.CHANGE_MINERVA_MENU, 
    payload,
  }
}

export const changeMobileMenu = (payload) => {
  return {
    type: types.CHANGE_MOBILE_MENU,
    payload,
  }
}

export const showSpinner = () => {
  return {
    type: types.SHOW_SPINNER
  }
}

export const hideSpinner = () => {
  return {
    type: types.HIDE_SPINNER
  }
}

export const showNotification = (message, isError, isSticky) => {
  return {
    type: types.SHOW_NOTIFICATION,
    payload: { message, isError, isSticky }
  }
}

export const hideNotification = () => {
  return {
    type: types.HIDE_NOTIFICATION
  }
}

export const changeForgot = (payload) => {
  return {
    type: types.CHANGE_FORGOT,
    payload,
  }
}

export const changeJoke = (payload) => {
	return {
		type: types.CHANGE_JOKE,
		payload,
	}
}

export const changeIsMediaPlaying = payload => ({
  type: types.CHANGE_IS_MEDIA_PLAYING,
  payload,
});

export const showFlashMessage = payload => ({
  type: types.SHOW_FLASH_MESSAGE,
  payload,
});

export const hideFlashMessage = () => ({
  type: types.HIDE_FLASH_MESSAGE,
});

export const showModal = payload => ({
  type: types.SHOW_MODAL,
  payload,
});

export const hideModal = () => ({
  type: types.HIDE_MODAL,
});
