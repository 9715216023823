import * as types from './types';

export const toggleSelectTableRow = payload => ({
  type: types.TOGGLE_SELECT_TABLE_ROW,
  payload,
});

export const selectTableRows = payload => ({
  type: types.SELECT_TABLE_ROWS,
  payload,
});

export const deselectAllTableRows = () => ({
  type: types.DESELECT_ALL_TABLE_ROWS,
});

export const changeTableSearchQuery = payload => ({
  type: types.CHANGE_TABLE_SEARCH_QUERY,
  payload,
});

export const changeTableRowData = payload => ({
  type: types.CHANGE_TABLE_ROW_DATA,
  payload,
});
