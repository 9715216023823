import axios from 'axios';
import store from '../store';
import { showSpinner,
          hideSpinner,
          showNotification } from '../actions/layout-actions';

import * as authApi from './auth-api';

import { HOST, PORT, PREFIX, GENERAL_SUCCESS_MESSAGE, GENERAL_ERROR_MESSAGE, GENERAL_REQUEST_ERROR_MESSAGE } from '../config';

import { updateWebLeadsList } from '../actions/web-leads-actions';

const _getUrlFromConfig = () => {
  return `${HOST}:${PORT}${PREFIX}`;
};

export const getWebLeads = (userToken) => {
  store.dispatch(showSpinner());
  return axios.get(_getUrlFromConfig() + '/users/web-leads', {
      headers: { 'x-ambie-token': userToken }
    })
    .then(response => {
      _detectUnauthorized(response);
      store.dispatch(hideSpinner());
      store.dispatch(updateWebLeadsList(response.data.result));
    })
    .catch(response => {
      store.dispatch(hideSpinner());
      store.dispatch(showNotification(GENERAL_REQUEST_ERROR_MESSAGE, true));
      _handleUnauthorized(response.message);
      console.error('Error: ', response.message);
    });
};

const _handleUnauthorized = (error) => {
  if (error === 'Unauthorized') {
    authApi.logout();
  }
};

const _detectUnauthorized = (response) => {
  if(response.data.error && response.data.error == "Unauthorized") {
    throw new Error(response.data.error);
  }
};