import React, { Component } from "react";

import PropTypes from "prop-types";
import Slider from "react-rangeslider";
import { FaVolumeMute, FaVolumeUp } from "react-icons/fa";

import "react-rangeslider/lib/index.css";

class VolumeControl extends Component {
  state = {
    volume: this.props.volume >= 0 ? this.props.volume : 50,
  };

  handleAdjustVolume = (volume) => {
    this.setState({
      ...this.state,
      volume,
    });
  };

  render() {
    return (
      <div className="flex-center-center">
        <div className="flex margin-right-md">
          <button
            className="btn-clear flex-center-center margin-right-xs"
            onClick={() => {
              this.handleAdjustVolume(0);
              this.props.handleUpdateVolume(
                this.props.isPlaying,
                0,
                this.props.deviceSerial
              );
            }}
          >
            {this.props.isMuted ? (
              <FaVolumeMute size={28} color="white" />
            ) : (
              <FaVolumeUp size={28} color="white" />
            )}
          </button>
          <div className="slider saturate" disabled>
            <Slider
              min={0}
              max={100}
              tooltip={false}
              value={this.state.volume}
              onChange={this.handleAdjustVolume}
              onChangeComplete={() =>
                this.props.handleUpdateVolume(
                  this.props.playerState,
                  this.state.volume,
                  this.props.deviceSerial
                )
              }
            />
          </div>
        </div>
      </div>
    );
  }
}

VolumeControl.propTypes = {
  isMuted: PropTypes.bool,
  volume: PropTypes.number,
  handleMute: PropTypes.func,
  checkWasMuted: PropTypes.func,
  updateSlider: PropTypes.func,
  updateVolume: PropTypes.func,
  playerState: PropTypes.string,
};

export default VolumeControl;
