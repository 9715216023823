import React, {Component} from 'react';

import Modal from '@layout_2/Modal/modal';

export default (props) => (
  <Modal width="600px" height="375px" show={props.isVisible} handleDismiss={props.handleContinueWithoutSavingEditSchedule}>
    <h3 className="text-center">Are you sure you want to cancel editing?</h3>
    <h3 className="text-center">The changes you have made will be lost, unless you save now</h3>
    <div className="action-btns">
      <div className="btn btn-danger btn-border btn-pill" onClick={props.handleContinueWithoutSavingEditSchedule}>Exit without saving</div>
      <div className="btn btn-primary btn-border btn-pill" onClick={props.handleSaveEditSchedule}>Save changes</div>
      <div className="btn btn-default btn-border btn-pill" onClick={props.handleReturnToEditSchedule}>Continue editing</div>
    </div>
  </Modal>
)
