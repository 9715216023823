import store from '@store';
import { showFlashMessage } from '@actions/layout-actions';

class WebSocketEmitter {

  constructor(socket, userToken) {
    this.socket = socket;
    this.userToken = userToken;
  }

  set userToken(userToken) {
    this._userToken = userToken;
  }

  get userToken() {
    return this._userToken;
  }

  set socket(socket) {
    this._socket = socket;
  }

  get socket() {
    return this._socket;
  }

  addParams = (areaId, deviceSerial, data = {}) => {
    const thingName = deviceSerial;
    const token = this.userToken;
    const userType = 'olympus';
    return {
      token,
      thingName,
      areaId,
      userType,
      ...data,
    }
  }

  emit = (topic, areaId, deviceSerial, data = {}) => {
    console.log('Emitting: ', topic, this.addParams(areaId, deviceSerial, data));
    this.socket.emit(topic, this.addParams(areaId, deviceSerial, data));
  }

  disconnect = (areaId, deviceSerial) => this.emit('disconnect', areaId, deviceSerial);

  play = (areaId, deviceSerial) => {
    this.emit('command:play', areaId, deviceSerial);
    store.dispatch(showFlashMessage({
      status: 'info',
      message: 'Sending play command to web player',
      duration: 3000,
    }));
  }

  pause = (areaId, deviceSerial) => {
    this.emit('command:pause', areaId, deviceSerial);
    store.dispatch(showFlashMessage({
      status: 'info',
      message: 'Sending pause command to web player',
      duration: 3000,
    }));
  }

  skip = (areaId, deviceSerial) => {
    this.emit('command:skip', areaId, deviceSerial);
    store.dispatch(showFlashMessage({
      status: 'info',
      message: 'Sending skip command to web player',
      duration: 3000,
    }));
  }

  volume = (areaId, deviceSerial, volume) => {
    this.emit('command:volume', areaId, deviceSerial, { volume });
    store.dispatch(showFlashMessage({
      status: 'info',
      message: `Sending volume: ${volume} to web player`,
      duration: 3000,
    }));
  }

  // use the below functions if you want to control the ambie player via the websocket server
  playerPlay = (areaId, deviceSerial, volume) => {
    this.emit('player:play', areaId, deviceSerial, { volume });
    store.dispatch(showFlashMessage({
      status: 'info',
      message: `Sending play command to Ambie Player`,
      duration: 3000,
    }));
  }

  playerPause = (areaId, deviceSerial, volume) => {
    this.emit('player:pause', areaId, deviceSerial, { volume });
    store.dispatch(showFlashMessage({
      status: 'info',
      message: `Sending pause command to Ambie Player`,
      duration: 3000,
    }));
  }

  playerVolume = (areaId, deviceSerial, volume, status) => {
    this.emit('player:volume', areaId, deviceSerial, { volume: volume.toString(), status: status ? 'play' : 'pause' });
    store.dispatch(showFlashMessage({
      status: 'info',
      message: `Sending volume: ${volume} to Ambie Player`,
      duration: 3000,
    }));
  }

  playerSkip = (areaId, deviceSerial) => {
    this.emit('player:skip', areaId, deviceSerial);
    store.dispatch(showFlashMessage({
      status: 'info',
      message: `Sending skip command to Ambie Player`,
      duration: 3000,
    }));
  }

  playerOverride = (areaId, deviceSerial, playlistId, duration) => {
    this.emit('player:override', areaId, deviceSerial, { playlistId, duration });
    store.dispatch(showFlashMessage({
      status: 'info',
      message: `Sending override command to Ambie Player`,
      duration: 3000,
    }));
  }

}

export default WebSocketEmitter;
