import store from '../store';
import { HOST_ROCK_API } from '../config';

import { changeTracks, changeSelectedTrack, changeDevices } from '../actions/track-repeats-actions';
import axiosInterceptors from '../utils/interceptors';

/**
 * use rock-api endpoint
 */
export const getTracks = async (userToken, deviceId, start_date, end_date, groupByRepeats, csv = false) => {
  try {
    const startDate = new Date(parseInt(start_date));
    const endDate = new Date(parseInt(end_date));

    const params = {
      itemsPerPage: 500,
      pageNumber: 1,
      deviceId,
      startDate,
      endDate,
      groupByRepeats,
      csv,
    };
    const response = await axiosInterceptors.get(HOST_ROCK_API + `/tracks/repeats`, {
      ...params,
    });

    store.dispatch(
      changeTracks({
        tracks: response.data.tracks,
        groupByRepeats: response.data.groupByRepeats === true,
      }),
    );
    if (response.data.tracks && response.data.tracks.length > 0) {
      store.dispatch(changeSelectedTrack(response.data.tracks[0]));
    }
    if (csv && response.data.csvLink) {
      return response.data.csvLink;
    }
  } catch (e) {
    console.log('Error: ', e);
  }
};

/**
 * use rock-api endpoint
 */
export const getDevices = async () => {
  try {
    const response = await axiosInterceptors.get(`${HOST_ROCK_API}/devices/serials`, {});
    store.dispatch(changeDevices(response.data));
  } catch (error) {
    console.log(error);
  }
};
