import React, {Component} from 'react';

import TimezoneHelper from '@ambie/ambie-npm-timezone-helper';

import Modal from '@layout_2/Modal/modal';
class ScheduleSaveChannelPopup extends Component {

  constructor(props) {
    super(props);
  }

  _isGapsInSchedule() {
    return TimezoneHelper.isGapsInSchedule(this.props.currentSchedule.timezoneAdjustedSchedule);
  }

	_computeAffectedAreas() {
  	if (typeof this.props.affectedAreas !== 'undefined' && this.props.affectedAreas.length > 0) {
      const wording = (this.props.affectedAreas.length === 1) ? "the following location" : "the following locations";
		  return (
        <div>
          <p>Saving changes will affect {wording}: </p>
			    <ul style={{padding: '0'}}>
            {this.props.affectedAreas.map((A,Adx) => {
              return <li key={"affectedAreas" + Adx} style={{listStyle: "none"}}> {A.name} </li>
            })}
          </ul>
		    </div>
      )
	  } else {
  		return null;
	  }
	}

  render() {
    const showWarning = { display: this._isGapsInSchedule() ? 'block' : 'none' };
    const affectedAreasDom = this._computeAffectedAreas();
    const timezoneString = `You are editing this schedule in the timezone: `;
    const timezone = `${this.props.timezoneName} (${this.props.timezone}).`;
    const timezoneStringTwo = `If this is incorrect, please cancel the changes and change the timezone using the timezone button on the top right of the schedules page, before making your changes.`;

    return (
      <Modal width="750px" height="450px" show={this.props.isVisible} handleDismiss={this.props.handleCancel}>
        <h3 className="text-center no-margin">Save changes to Schedule</h3>
        <div className="row save-shcedule-popup-details">
          <div className="col-sm-12">
	          {affectedAreasDom}
            <div style={showWarning} className="alert alert-danger">
              There are some blank gaps in your calendar that will cause interruptions in the music
            </div>
            <p>{timezoneString}<span><strong>{timezone}</strong></span></p>
            <p>{timezoneStringTwo}</p>
            <p><strong>Are you sure you want to proceed?</strong></p>
          </div>
        </div>

        <div className="action-btns">
          <div style={{marginRight: '1rem'}} className="btn btn-default btn-border btn-pill" onClick={this.props.handleCancel}>CANCEL</div>
          <div className="btn btn-primary btn-pill" onClick={this.props.handleConfirm}>SAVE</div>
        </div>
      </Modal>
    );
  }
}

export default ScheduleSaveChannelPopup;
