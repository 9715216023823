import * as types from '../actions/types';

const initalState = {
  showPreviewScreen: false,
  showCuratorContactForm: false,
  currentChannelIndex: -1,
  channels: []
};

export default (state = initalState, action) => {
  switch (action.type) {
    case types.CHANGE_CHANNELS:
      return Object.assign({}, state, {
        channels: action.payload
      });
    case types.CHANGE_SHOW_PREVIEW_SCREEN:
      return Object.assign({}, state, {
        showPreviewScreen: action.payload
      });
    case types.CHANGE_CURRENT_CHANNEL_INDEX:
      return Object.assign({}, state, {
        currentChannelIndex: action.payload
      });
    case types.CHANGE_SHOW_CURATOR_CONTACT_FORM:
      return Object.assign({}, state, {
        showCuratorContactForm: action.payload
      });
  }

  return state;

};