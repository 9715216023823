import * as React from 'react';

const ActionBar = props => (
  <header className={props.cssClass + ' header-control'}>
    <div className="container animated-no-fill delay02s fadeIn">
      <div className="row">
        <div className={props.cssColClass + " full-width"}>
          { props.children }
        </div>
      </div>
    </div>
  </header>
)

export default ActionBar;
