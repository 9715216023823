import * as types from "../actions/types";

const initalState = {
  latestQuery: "",
  latestQuerySuggestions: "",
  loadingPlaylistFromAPI: false,
  loadingPlaylistTracksFromAPI: false,
  loadingPlaylistRotationFromAPI: false,
  playlistsDetails: [],
  playlists: [],
  selectedPlaylists: [],
  playlistSuggestions: [],
  searchingSuggestions: false,
  rotations: new Set(),
  playlistFilters: {
    activeFilter: false,
    genericFilter: "",
    genericFilterType: "",
    pickedClients: new Set(),
    pickedBusinesses: new Set(),
    mainFilter: "filter-all"
  },
  showYesNoPopUp: false,
  savingPlaylist: false,
  savedPlaylist: false,
  yesNoPopUpAction: null
};

export default (state = initalState, action) => {

  let selectedPlaylists = new Set(state.selectedPlaylists);
  let playlists = Object.assign([], state.playlists);
  let rotations = new Set(Object.assign([], state.rotations));
  let playlistsDetails = Object.assign([], state.playlistsDetails);

  switch (action.type) {

    case types.UPDATE_PLAYLIST_SUGGESTIONS:
      if (action.payload.queryHash === state.latestQuerySuggestions)
        return Object.assign({}, state, {
          playlistSuggestions: action.payload.data,
          searchingSuggestions: false
        });
      else return state

    case types.GETTING_PLAYLIST_FROM_API:
      return Object.assign({}, state, {
        loadingPlaylistFromAPI: true
      });

    case types.GETTING_PLAYLIST_TRACKS_FROM_API:
      return Object.assign({}, state, {
        loadingPlaylistTracksFromAPI: true
      });

    case types.UPDATE_PLAYLIST_TRACKS_DETAIL:
      let myPl = playlistsDetails.filter(PL => {
        return parseInt(PL.id) === parseInt(action.payload.playlistId)
      });
      if (myPl.length === 1) {
        myPl[0].tracks = action.payload.tracks;
      }
      return Object.assign({}, state, {
        playlistsDetails: playlistsDetails,
        loadingPlaylistTracksFromAPI: false
      });

    case types.GETTING_PLAYLIST_ROTATION_FROM_API:
      return Object.assign({}, state, {
        loadingPlaylistRotationFromAPI: true
      });

    case types.UPDATE_PLAYLIST_ROTATION_DETAIL:
      let plIndex = -1, rotIndex = -1;
      playlistsDetails.forEach((PL, idx) => {
        if (parseInt(PL.id) === parseInt(action.payload.playlistId)) {
          plIndex = idx;
        }
      });
      if (plIndex !== -1) {
        playlistsDetails[plIndex].allTheRotations.forEach((R, idx) => {
          if ((R._id) === (action.payload.rotationId)) {
            rotIndex = idx;
          }
        });
        if (rotIndex !== -1) {
          playlistsDetails[plIndex].allTheRotations[rotIndex] = action.payload.rotationData
        }
      }
      return Object.assign({}, state, {
        playlistsDetails: playlistsDetails,
        loadingPlaylistRotationFromAPI: false
      });

    case types.START_SEARCHING_PLAYLIST_SUGGESTIONS:
      return Object.assign({}, state, {
        searchingSuggestions: true
      });

    case types.SAVING_PLAYLIST:
      return Object.assign({}, state, {
        savingPlaylist: true,
        savedPlaylist: false
      });

    case types.DISMISS_SAVED_PLAYLIST:
      return Object.assign({}, state, {
        savingPlaylist: false,
        savedPlaylist: false
      });

    case types.ROTATION_START:
      return Object.assign({}, state, {
        rotations: rotations.add(action.payload)
      });

    case types.ROTATION_DONE:
      return Object.assign({}, state, {
        rotations: rotations.delete(action.payload)
      });

    case types.UPDATE_PLAYLIST_FILTERS:
      return Object.assign({}, state, {
        playlistFilters: action.payload,
        selectedPlaylists: []
      });

    case types.UPDATE_LATEST_QUERY_SUGGESTION:
      return Object.assign({}, state, {
        latestQuerySuggestions: action.payload
      });

    case types.UPDATE_LATEST_QUERY:
      return Object.assign({}, state, {
        latestQuery: action.payload
      });

    case types.GET_PLAYLISTS:
      if (action.payload.queryHash === state.latestQuery)
        return Object.assign({}, state, {
          playlists: action.payload.data
        });
      else return state

    case types.SELECT_PLAYLIST:
      selectedPlaylists.add(action.payload);
      return Object.assign({}, state, {
        selectedPlaylists: Array.from(selectedPlaylists)
      });

    case types.DESELECT_PLAYLIST:
      selectedPlaylists.delete(action.payload);
      return Object.assign({}, state, {
        selectedPlaylists: Array.from(selectedPlaylists)
      });

    case types.DESELECT_ALL_PLAYLISTS:
      selectedPlaylists.delete(action.payload);
      return Object.assign({}, state, {
        selectedPlaylists: []
      });

    case types.UNPUBLISH_PLAYLISTS:
      playlists = playlists.map(P => {
        if (action.payload.indexOf(P.id.toString()) > -1) P.is_published = false;
        return P
      });
      return Object.assign({}, state, {
        playlists: playlists,
        showYesNoPopUp: false
      });

    case types.PUBLISH_PLAYLISTS:
      playlists = playlists.map(P => {
        if (
          (action.payload.indexOf(P.id) > -1) ||
          (action.payload.indexOf(P.id.toString()) > -1)
        ) P.is_published = true;
        return P
      });
      return Object.assign({}, state, {
        playlists: playlists,
        showYesNoPopUp: false
      });

    case types.SAVED_PLAYLISTS:

      //update details
      playlistsDetails = playlistsDetails.map(P => {
        if (action.payload.playlistId === P.id) {

          if (typeof action.payload.rotation_rules !== "undefined") {
            P.rotation = action.payload.rotation_rules
          }

          if (typeof action.payload.rules !== "undefined") {
            P.rules = action.payload.rules
          }

        }
        return P
      });
      //update listing

      return Object.assign({}, state, {
        playlists: playlists,
        savingPlaylist: false,
        savedPlaylist: true
      });

    case types.HIDE_YESNO_POPUP:
      //debugger
      return Object.assign({}, state, {
        showYesNoPopUp: false
      });

    case types.SHOW_YESNO_POPUP:
      return Object.assign({}, state, {
        showYesNoPopUp: true,
        yesNoPopUpAction: action.payload
      });

    case types.UPDATE_PLAYLIST_DETAIL:
      let pl = playlistsDetails.filter(PL => {
        return PL.id === action.payload.id
      });
      //check if tracks if defined, if not I set it empty array
      if (typeof action.payload.tracks === "undefined") action.payload.tracks = [];
      if (pl.length === 1) {
        pl[0] = action.payload;
      } else {
        playlistsDetails.push(action.payload)
      }
      return Object.assign({}, state, {
        playlistsDetails: playlistsDetails,
        loadingPlaylistFromAPI: false
      });

    case types.CLEAR_PLAYLIST_DETAILS:
      return Object.assign({}, state, {
        playlistsDetails: []
      });
  }

  return state;
};
