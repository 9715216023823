import store from '../store';
import { showSpinner, hideSpinner } from '@actions/layout-actions';
import { HOST_ROCK_API } from '../config';

import {
  changeFeedback,
  deselectAllFeedback,
  setFeedbackTableConfig,
  creatingFeedbackCSV,
  feedbackCSVCreated,
  feedbackCSVCreationFailed,
} from '../actions/feedback-actions';
import axiosInterceptors from '../utils/interceptors';

export const getFeedback = async (userToken, params = {}) => {
  const paramsData = {
    filter: params.query || null,
    itemsPerPage: params.rpp,
    pageNumber: params.page,
    ack: params.ack === undefined ? null : params.ack,
    ruleCreated: params.ruleCreated === undefined ? null : params.ruleCreated,
    awaitingApproval: params.awaitingApproval === undefined ? null : params.awaitingApproval,
    startDate: params.start_date || 0,
    endDate: params.end_date || 0,
  };
  try {
    store.dispatch(showSpinner());
    const response = await axiosInterceptors.get(HOST_ROCK_API + `/clients/all/feedback`, {
      ...paramsData,
    });
    store.dispatch(changeFeedback(response.data.feedback));
    store.dispatch(setFeedbackTableConfig(response.data));
  } catch (e) {
    console.error(e);
    store.dispatch(changeFeedback([]));
  }
  store.dispatch(hideSpinner());
};

export const getFeedbackCSV = async () => {
  try {
    store.dispatch(creatingFeedbackCSV());
    const response = await axiosInterceptors.get(HOST_ROCK_API + `/clients/feedback/download`, {});
    const csvUrl = response.data.s3Url;
    store.dispatch(feedbackCSVCreated(csvUrl));
  } catch (e) {
    console.error(e);
    store.dispatch(feedbackCSVCreationFailed());
  }
};

export const markMultipleFeedbackAcknowledged = async (feedback) => {
  try {
    await axiosInterceptors.post(`${HOST_ROCK_API}/clients/feedback/mark`, { feedback });
    store.dispatch(deselectAllFeedback());
    getFeedback('', { ack: false, rpp: 50, page: 1 });
  } catch (error) {
    console.error(error);
  }
};

export const createPlaylistsRulesFromFeedback = async (feedback) => {
  try {
    await axiosInterceptors.post(`${HOST_ROCK_API}/clients/feedback/mark/rules`, { feedback: feedback.rules });
    getFeedback('', { ack: false, rpp: 50, page: 1 });
  } catch (error) {
    console.error(error);
  }
};
