import * as React from 'react';
import { Link } from 'react-router-dom';

import { requestPasswordReset } from '@api/auth-api';

class ForgotPassword extends React.Component {

  state = {
    email: '',
    isSubmitted: false,
  }

  get isEmailValid() {
    const re = new RegExp(/.+@.+\.\w{2,}/);
    return this.state.email === '' || re.test(this.state.email);
  }

  handleOnChange = (e) => {
    const email = e.target.value;
    this.setState({
      ...this.state,
      email,
    });
  }

  handleSubmit = () => {
    if (this.state.email) {
      requestPasswordReset(this.state.email);
      const isSubmitted = true;
      this.setState({
        ...this.state,
        isSubmitted,
      });
    }
  }

  handleKeyPress = (e) => {
    if (e.charCode === 13) {
      this.handleSubmit();
    }
  } 

  render() {
    return (
      <div id="login-wrapper" style={{ height: window.innerHeight }} className="login-main-background col-md-12 col-sm-12 col-xs-12">
        <div className="login-container">
          <div id="login-box" className="col-lg-12">
            <div className="login-message">{this.state.isSubmitted ? 'Reset email sent' : 'Reset password' }</div>
              {!this.state.isSubmitted
                ? (
                    <div onKeyPress={this.handleKeyPress}  className="login-inputs-wrapper">
                      <p style={{ margin: '1em 0' }}>Please enter your email address and we'll send you a link to reset your password.</p>
                      <input style={{ margin: '.5em 0' }} onChange={this.handleOnChange} ref="emailField" className={this.isEmailValid ? '' : 'login-input-invalid'} id="email" autoCapitalize="none" type="text" placeholder="Email address" />
                      <div style={{ marginTop: '1em' }}>
                        <button style={{ marginRight: '1em'}} className="btn btn-danger btn-pill"><Link className="text-white" to="/login" href="/login">CANCEL</Link></button>
                        <button disabled={this.isSubmitted || this.state.email.length === 0 || !this.isEmailValid} className="btn btn-primary btn-pill" onClick={this.handleSubmit}>SUBMIT</button>
                      </div>
                    </div>
                  )
                : (
                  <div className="login-inputs-wrapper">
                    <p style={{ margin: '1em 0' }}>Please check your inbox for a link to reset your password. If it doesn't appear within a few minutes, check your spam folder and contact <a href="mailto:support@ambie.fm">support@ambie.fm</a></p>
                    <button style={{ marginRight: '1em'}} className="btn btn-blue btn-pill"><Link className="text-white" to="/login" href="/login">BACK</Link></button>
                  </div>
                )
              }
          </div>
        </div>
      </div>
    )
  }

}

export default ForgotPassword;
