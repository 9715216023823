import React from "react";

import SkipPanel from "./skip-panel";
import TrackControl from "./track-control";
import VolumeControl from "./volume-control";

const MiniPlayer = (props) => (
  <React.Fragment>
    <h6 className="mini-player-title">
      Controlling {props.isControllingWebPlayer ? "Web Player" : "Ambie Player"}
    </h6>
    <div className="control-panel">
      <div className="flex-space-between">
        <div className="flex-space-between flex-1-1-auto">
          <TrackControl
            isControllingWebPlayer={props.isControllingWebPlayer}
            isStreaming={
              props.area.streaming &&
              props.area.streaming.enabled &&
              props.area.streaming.status !== "stop"
            }
            title={
              props.current_track && props.current_track.title
                ? props.current_track.title
                : ""
            }
            artist={
              props.current_track && props.current_track.artist
                ? props.current_track.artist
                : ""
            }
            playlist={
              props.current_track && props.current_track.playlist
                ? props.current_track.playlist
                : ""
            }
            volume={props.device.volume}
            deviceSerial={props.device.serial}
            isPlaying={props.isPlaying}
            isPaused={props.isPaused}
            isOnline={props.isOnline}
            isActive={props.isActive}
            handlePlayPause={props.handlePlayPause}
            playerState={props.device.status}
          />
        </div>
        <div className="flex-space-between flex-1-1-auto">
          <SkipPanel
            isControllingWebPlayer={props.isControllingWebPlayer}
            isStreaming={
              props.area.streaming &&
              props.area.streaming.enabled &&
              props.area.streaming.status !== "stop"
            }
            isOnline={props.isOnline}
            deviceSerial={props.device.serial}
            handleToggleOverrideModal={() =>
              props.handleToggleOverrideModal(
                props.area,
                props.area.device.serial
              )
            }
            handleSkip={props.handleSkip}
          />
          <VolumeControl
            isControllingWebPlayer={props.isControllingWebPlayer}
            isStreaming={
              props.area.streaming &&
              props.area.streaming.enabled &&
              props.area.streaming.status !== "stop"
            }
            isOnline={props.isOnline}
            volume={props.device.volume}
            isPlaying={props.isPlaying}
            deviceSerial={props.device.serial}
            handleUpdateVolume={props.handleUpdateVolume}
            playerState={props.device.status}
          />
        </div>
      </div>
    </div>
  </React.Fragment>
);

export default MiniPlayer;
