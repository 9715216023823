import * as types from '../actions/types';
import { LOCAL_STORAGE_USER } from '../config';
const storedUserInfo = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER)) || {};

const initalState = {
  userInfo: {
    ...storedUserInfo,
  },
  clientsList: [],
  businessesList: [],
  currentClientId: 0 //we are using this to allow ambie staff to impersonate any client user
};

let businessLocal = [];

export default (state = initalState, action) => {
  switch (action.type) {
    case types.AUTH_CLIENT_NAME_UPDATED: {
      let localClientList = JSON.parse(JSON.stringify(state.clientsList));
      const currentClient = localClientList.find(item => item.id == action.payload.clientId);
      currentClient.name = action.payload.newName;
      return Object.assign({}, state, {
        clientsList: localClientList
      });
    }
    case types.LOGIN_SUCCESS:
      return Object.assign({}, state, {
        userInfo: action.payload
      });
    case types.LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        userInfo: undefined
      });
    case types.CHANGE_CLIENTS_LIST:
      action.payload.forEach(C => {
        C.businesses.forEach(B => {
          businessLocal.push({
            name: B.business_name,
            id: B.business_id,
            clientId: C.id
          })
        })
      });
      return Object.assign({}, state, {
        clientsList: action.payload,
        businessesList: businessLocal
      });
    case types.CHANGE_CURRENT_CLIENT_ID:
      return Object.assign({}, state, {
        currentClientId: action.payload
      })
    case types.UPDATE_CLIENT_COMMERCIAL_RULE: {
      const clientsList = [
        ...state.clientsList,
      ]
      const client = clientsList.find(client => client.id === action.payload.id);
      if (client) {
        client.rules = action.payload.rule
      }
      return {
        ...state,
        clientsList,
      }
    }
    case types.CHANGE_CLIENT_EMAIL: {
      const clientsList = [...state.clientsList];
      const client = clientsList.find(client => client.id === action.payload.id);
      if (client) {
        client.email = action.payload.email;
      }
      return {
        ...state,
        clientsList,
      }
    }
  }

  return state;

};