import * as React from 'react';

import {
  resetPassword,
  validatePasswordResetToken
} from '@api/auth-api';

class SetPassword extends React.Component {

  state = {
    token: '',
    password: '',
    passwordConfirm: '',
    showPassword: false,
    isChanged: false,
    isLoaded: false,
    isTokenValid: true,
  }

  async componentWillMount() {
    const token = this.props.location.search ? this.props.location.search.replace('?token=', '') : null;
    if (token) {
      const isTokenValid = await validatePasswordResetToken(token);
      this.setState({
        ...this.state,
        token,
        isTokenValid,
        isLoaded: true,
      });
    } else {
      this.setState({
        ...this.state,
        token: '',
        isTokenValid: false,
        isLoaded: true,
      });
    }
  }

  get jsxContent() {
    return {
      changePassword: (
        <React.Fragment>
          <h1>Ambie Olympus</h1>
          <h2>Choose a new password</h2>
          <div className="login-inputs-wrapper">
            <input style={{ margin: '.5em 0' }} onChange={this.handleOnChangePassword} className={this.state.password !== '' && !this.isPasswordValid(this.state.password) ? 'login-input-invalid' : ''} autoCapitalize="none" type={this.state.showPassword ? 'text' : 'password' } placeholder="New password" />
            <input style={{ margin: '.5em 0' }} onChange={this.handleOnChangePasswordConfirm} className={!this.isPasswordMatching || !this.isPasswordValid(this.state.passwordConfirm) ? 'login-input-invalid' : ''} autoCapitalize="none" type={this.state.showPassword ? 'text' : 'password' } placeholder="Confirm new password" />
            <div className="new-password-toggle">
              <input type="checkbox" id="show-password" className="checkbox-switch" onChange={this.handlePasswordToggle} />
              <label htmlFor="show-password" />
              {this.state.showPassword ? 'HIDE PASSWORD' : 'SHOW PASSWORD' }
            </div>
            {(this.state.password.length > 0 && this.state.password.length < 8) || (this.state.passwordConfirm.length > 0 && !this.isPasswordMatching)
              ? (
                <div className="alert-danger" style={{ padding: '5px', borderRadius: '5px', width: '100%' }}>
                  {this.state.password.length > 0 && this.state.password.length < 8 ? <p style={{ margin: '5px' }}><small>Password must be 8 characters or greater.</small></p> : null}
                  {this.state.passwordConfirm.length > 0 && !this.isPasswordMatching ? <p style={{ margin: '5px' }}><small>Passwords do not match.</small></p> : null}
                </div>
                )
              : null
            }
            <div style={{ marginTop: '1em' }}>
              <button disabled={!this.isFormValid || this.state.isSubmitted} className="btn btn-primary btn-pill" onClick={this.handleSubmit}>CHANGE</button>
            </div>
          </div>
        </React.Fragment>
      ),
      invalidLink: (
        <React.Fragment>
          <h1>Invalid Link</h1>
          <div className="login-inputs-wrapper">
            <p style={{ margin: '1em 0' }}>If you know your password, please log in. Otherwise, create a new password reset request</p>
            <button style={{ marginRight: '1em'}} className="btn btn-blue btn-pill" onClick={this.handleGoToLogin}>GO TO LOGIN</button>
          </div>
        </React.Fragment>
      ),
      passwordChangeSuccess: (
        <React.Fragment>
          <h1>Password successfully changed</h1>
          <div className="login-inputs-wrapper">
            <p style={{ margin: '1em 0' }}>You can now log in with your new password</p>
            <button style={{ marginRight: '1em'}} className="btn btn-blue btn-pill" onClick={this.handleGoToLogin}>GO TO LOGIN</button>
          </div>
        </React.Fragment>
      ),
      passwordChangeFail: (
        <React.Fragment>
          <h1>Error trying to set new password</h1>
          <div className="login-inputs-wrapper">
            <p style={{ margin: '1em 0' }}>Please create a new password reset request and try again</p>
            <button style={{ marginRight: '1em'}} className="btn btn-blue btn-pill" onClick={this.handleGoToLogin}>GO TO LOGIN</button>
          </div>
        </React.Fragment>
      )
    }
  }

  get content() {
    if (!this.state.isTokenValid) {
      return this.jsxContent.invalidLink;
    }
    if (!this.state.isChanged) {
      return this.jsxContent.changePassword;
    }
    if (this.state.isSubmitted && this.state.isChanged) {
      return this.jsxContent.passwordChangeSuccess;
    }
    return this.jsxContent.passwordChangeFail;
  }

  get isPasswordMatching() {
    if (this.state.passwordConfirm === '') return true;
    return this.state.password === this.state.passwordConfirm;
  }

  get isFormValid() {
    return this.isPasswordValid(this.state.password) && this.isPasswordValid(this.state.passwordConfirm) && this.isPasswordMatching;
  }

  isPasswordValid = (password) => {
    const re = new RegExp(/([\d\w\W]){8,}/);
    return password === '' || re.test(password);
  }

  handlePasswordToggle = () => {
    const showPassword = !this.state.showPassword;
    this.setState({
      ...this.state,
      showPassword,
    });
  }

  handleOnChangePassword = (e) => {
    const password = e.target.value;
    this.setState({
      ...this.state,
      password,
    });
  }

  handleOnChangePasswordConfirm = (e) => {
    const passwordConfirm = e.target.value;
    this.setState({
      ...this.state,
      passwordConfirm,
    });
  }

  handleSubmit = async () => {
    const isSubmitted = true;
    this.setState({
      ...this.state,
      isSubmitted,
    });
    const isChanged = await resetPassword(this.state.token, this.state.password);
    this.setState({
      ...this.state,
      isChanged,
    });
  }

  handleKeyPress(e) {
    if (e.charCode === 13) {
      this.handleSubmit();
    }
  }

  handleGoToLogin = () => this.props.history.push('/login');

  render() {
    return (
      <div onKeyPress={this._handleKeyPress} id="login-wrapper" style={{ height: window.innerHeight }} className="login-main-background col-md-12 col-sm-12 col-xs-12">
        {this.state.isLoaded
          ? (
              <div className="login-container">
                <div id="login-box" className="col-lg-12">
                  {this.content}
                </div>
              </div>
            )
          : null}
      </div>
    )
  }

}

export default SetPassword;
