import React, {Component} from 'react';
import { Link, Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import LocationsMain from '../client/locations/locations-main';
import SchedulesMain from '../client/schedules/schedules-main';

import FlashMessage from '@common/flash-message';
import Feedback from '../euterpe/feedback/feedback';
import TrackRepeatsMain from '../euterpe/track-repeats/track-repeats-main';
import TracksNotInUseMain from '../euterpe/tracks-not-in-use/tracks-not-in-use-main';
import OldAdmin from '../more/old-admin';
import TrackUploaderMain from '../euterpe/track-uploader/track-uploader-main';
import DeviceStatusMain from '../client/device-status/device-status-main';
import WebLeadsMain from '../client/web-leads/web-leads-main';
import CoreChannelsMain from '../euterpe/core-channels/core-channels-main';
import Modal from '@layout_2/Modal/modal';
import OkPopup from '@common/ok-popup';
import YesNoPopup from '@common/generic-yes-no-popup';
import { STATIC_ASSET_ROOT } from '@app/config';
//import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../stylesheets/main.scss';
//import 'react-select/dist/react-select.css';

import store from '../../store';

import * as authApi from '../../api/auth-api';

import TopMenu from './top-menu';
import NotificationBubble from './notification';

import {
  changeUserMenu, 
  changeMoreMenu, 
  changeClientMenu,
  changeEuterpeMenu,
  changeMobileMenu, 
  hideNotification,
  hideModal
} from '../../actions/layout-actions';

import { changeCurrentClientId } from '../../actions/auth-actions';

class MainLayout extends Component {
  constructor(props) {
    super(props);

    this._handleLogout = this._handleLogout.bind(this);
    this._handleUserMenuClick = this._handleUserMenuClick.bind(this);
    this._handleMoreMenuClick = this._handleMoreMenuClick.bind(this);
    this._handleClientMenuClick = this._handleClientMenuClick.bind(this);
    this._handleEuterpeMenuClick = this._handleEuterpeMenuClick.bind(this);
    this._handleMobileMenuToggle = this._handleMobileMenuToggle.bind(this);
    this._handleHideNotification = this._handleHideNotification.bind(this);
    this._handleChangeClient = this._handleChangeClient.bind(this);
    this._handleMoreItemClick = this._handleMoreItemClick.bind(this);
    this._handleClientItemClick = this._handleClientItemClick.bind(this);
    this._handleEuterpeItemClick = this._handleEuterpeItemClick.bind(this);
    this._handleCollapseMenus = this._handleCollapseMenus.bind(this);
  }

  componentWillMount() {
    authApi.getClientsList(this.props.userToken);
  }

  componentDidUpdate(prevProps) {
    if (
      (!prevProps.clientsList || (prevProps.clientsList && prevProps.clientsList.length === 0))
      && (this.props.clientsList && this.props.clientsList.length > 0)
      && !this.props.currentClientId) {
        const clientId = this.props.clientsList[0].id;
        store.dispatch(changeCurrentClientId(clientId));
    }
  }

  _handleChangeClient(selected) {
    if (selected && selected.value) {
      store.dispatch(changeCurrentClientId(selected.value));
      this.props.history.push(`/locations/${selected.value}`);
    } else {
      const clientId = this.props.clientsList[0].id;
      store.dispatch(changeCurrentClientId(clientId));
      this.props.history.push(`/locations`);
    }
  }

  _handleLogout(e) {
    e.preventDefault();
    store.dispatch(changeUserMenu(false));
    store.dispatch(changeMoreMenu(false));
    store.dispatch(changeClientMenu(false));
    store.dispatch(changeEuterpeMenu(false));
    authApi.logout();
  }

  _handleUserMenuClick(e) {
    e.preventDefault();
    store.dispatch(changeMoreMenu(false));
    store.dispatch(changeClientMenu(false));
    store.dispatch(changeEuterpeMenu(false));
    store.dispatch(changeUserMenu(!this.props.isUserMenuOpen));
  }

  _handleMoreMenuClick(e) {
    e.preventDefault();
    store.dispatch(changeUserMenu(false));
    store.dispatch(changeClientMenu(false));
    store.dispatch(changeEuterpeMenu(false));
    store.dispatch(changeMoreMenu(!this.props.isMoreMenuOpen));
  }

  _handleClientMenuClick(e) {
    e.preventDefault();
    store.dispatch(changeUserMenu(false));
    store.dispatch(changeMoreMenu(false));
    store.dispatch(changeEuterpeMenu(false));
    store.dispatch(changeClientMenu(!this.props.isClientMenuOpen));
  }

  _handleEuterpeMenuClick(e) {
    e.preventDefault();
    store.dispatch(changeUserMenu(false));
    store.dispatch(changeMoreMenu(false));
    store.dispatch(changeClientMenu(false));
    store.dispatch(changeEuterpeMenu(!this.props.isEuterpeMenuOpen));
  }

  _handleMinervaMenuClick(e) {
    e.preventDefault();
    store.dispatch(changeUserMenu(false));
    store.dispatch(changeMoreMenu(false));
    store.dispatch(changeClientMenu(false));
    store.dispatch(changeMinervaMenu(!this.props.isMinervaMenuOpen));
  }

  _handleCollapseMenus(e) {
    store.dispatch(changeUserMenu(false));
    store.dispatch(changeMoreMenu(false));
    store.dispatch(changeEuterpeMenu(false));
    store.dispatch(changeClientMenu(false));
  }

  _handleMoreItemClick() {
    store.dispatch(changeMoreMenu(false));
  }

  _handleClientItemClick() {
    store.dispatch(changeClientMenu(false));
  }

  _handleEuterpeItemClick() {
    store.dispatch(changeEuterpeMenu(false));
  }

  _handleMobileMenuToggle() {
    store.dispatch(changeMobileMenu(!this.props.isMobileMenuOpen));
  }

  _handleHideNotification() {
    store.dispatch(hideNotification());
  }

  handleDismissModal = () => {
    store.dispatch(hideModal());
  }

  render() {
    let spinnerClass =  this.props.showSpinner ? 'block-overlay overlay-show' : 'block-overlay overlay-hide';
    const SHOUD_FOOTER_BE_STICKY = document.getElementById("app").offsetHeight < Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    let footerStyle = {
      position: SHOUD_FOOTER_BE_STICKY ? "fixed" : "initial"
    }

    return (
      <React.Fragment>
        <FlashMessage
          duration={this.props.flashMessage.duration || 2000}
          status={this.props.flashMessage.status}
          show={this.props.flashMessage.show}
          message={this.props.flashMessage.message}
          subMessage={this.props.flashMessage.subMessage}
          innerWidth={this.props.flashMessage.width}
        />
        <Modal
          show={this.props.modal.show}
          handleDismiss={this.props.modal.handleDismiss || this.handleDismissModal}
          width="600px"
          height="400px"
        >
          {this.props.modal.title && <h3>{this.props.modal.title}</h3>}
          {
            this.props.modal.type === 'ok'
              ? <OkPopup message={this.props.modal.message} okText={this.props.modal.okText} handleClose={this.props.modal.handleDismiss || this.handleDismissModal} />
              : this.props.modal.type === 'yesNo'
              ? <YesNoPopup message={this.props.modal.message} noText={this.props.modal.noText} yesText={this.props.modal.yesText} handleNo={this.props.modal.handleNo || this.handleDismissModal} handleYes={this.props.modal.handleYes} />
              : null
          }
          {this.props.modal.content}
        </Modal>
        <div className="default-main-background">
          <TopMenu handleLogout={this._handleLogout} 
                    userName={this.props.userName}
                    isUserMenuOpen={this.props.isUserMenuOpen}
                    isMoreMenuOpen={this.props.isMoreMenuOpen}
                    isClientMenuOpen={this.props.isClientMenuOpen}
                    isEuterpeMenuOpen={this.props.isEuterpeMenuOpen}
                    isMinervaMenuOpen={this.props.isMinervaMenuOpen}
                    isMobileMenuOpen={this.props.isMobileMenuOpen}
                    handleUserMenuClick={this._handleUserMenuClick}
                    handleMoreMenuClick={this._handleMoreMenuClick}
                    handleClientMenuClick={this._handleClientMenuClick}
                    handleEuterpeMenuClick={this._handleEuterpeMenuClick}
                    handleMinervaMenuClick={this._handleMinervaMenuClick}
                    handleMobileMenuToggle={this._handleMobileMenuToggle}
                    currentClientId={this.props.currentClientId}
                    clientsList={this.props.clientsList}
                    handleChangeClient={this._handleChangeClient}
                    handleMoreItemClick={this._handleMoreItemClick}
                    handleClientItemClick={this._handleClientItemClick}
                    handleEuterpeItemClick={this._handleEuterpeItemClick} />

          <NotificationBubble message={this.props.notification.message} 
                              isError={this.props.notification.isError}
                              isVisible={this.props.notification.isVisible}
                              isSticky={this.props.notification.isSticky}
                              hideAfter={this.props.notification.hideAfter}
                              handleHideNotification={this._handleHideNotification} />
          <main onClick={this._handleCollapseMenus} id="main-content" className={ SHOUD_FOOTER_BE_STICKY ? "stiky-footer" : ""}>
            <Route exact path="/locations/:clientId" component={LocationsMain} />
            <Route exact path="/locations" component={LocationsMain} />
            <Route exact path="/schedules" component={SchedulesMain} />
            <Route exact path="/schedules/:clientId" component={SchedulesMain} />
            <Route exact path="/feedback" component={Feedback} />
            <Route exact path="/repeats" component={TrackRepeatsMain} />
            <Route exact path="/not-in-use" component={TracksNotInUseMain} />
            <Route exact path="/old-admin" component={OldAdmin} />
            <Route exact path="/track-uploader" component={TrackUploaderMain} />
            <Route exact path="/device-status" component={DeviceStatusMain} />
            <Route exact path="/web-leads" component={WebLeadsMain} />
            <Route exact path="/core-channels" component={CoreChannelsMain} />
          </main>

          <div className={spinnerClass}>
            <div className="loading-content">
              <div className="loading-spinner">
                <img src={`${STATIC_ASSET_ROOT}/images/Ring.svg`}/>
              </div>
              <div className="loading-text">
                Loading...
              </div>
            </div>
          </div>

        </div>
      </React.Fragment>
      );
  }
}

const storeToProps = (store) => {
  return {
    userToken: store.authState.userInfo ? store.authState.userInfo.userToken : '',
    userName: store.authState.userInfo ? store.authState.userInfo.userName : '',
    isUserMenuOpen: store.layoutState.isUserMenuOpen,
    isMobileMenuOpen: store.layoutState.isMobileMenuOpen,
    isMoreMenuOpen: store.layoutState.isMoreMenuOpen,
    isClientMenuOpen: store.layoutState.isClientMenuOpen,
    isEuterpeMenuOpen: store.layoutState.isEuterpeMenuOpen,
    showSpinner: store.layoutState.showSpinner,
    notification: store.layoutState.notification,
    currentClientId: store.authState.currentClientId,
    clientsList: store.authState.clientsList,
    modal: store.layoutState.modal,
    flashMessage: store.layoutState.flashMessage,
  };
}

export default withRouter(connect(storeToProps)(MainLayout));
