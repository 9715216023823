import * as types from './types';

export const changeNotUsedTracks = (payload) => {
  return {
    type: types.CHANGE_NOT_USED_TRACKS,
    payload: payload
  }
};

export const changeNotUsedFilters = (payload) => {
  return {
    type: types.CHANGE_NOT_USED_FILTERS,
    payload: payload
  }
};

export const deleteNotUsedTracks = (payload) => {
  return {
    type: types.DELETE_NOT_USED_TRACKS,
    payload: payload
  }
};

export const openDeleteConfirmationModal = (payload) => {
  return {
    type: types.OPEN_DELETE_CONFIRMATION_MODAL,
    payload: payload
  }
};

export const closeDeleteConfirmationModal = (payload) => {
  return {
    type: types.CLOSE_DELETE_CONFIRMATION_MODAL,
    payload: payload
  }
};

export const showConfirmationOfDeletion = () => {
  return {
    type : types.SHOW_CONFIRMATION_DELETION_ALL_TRACKS_NOT_USED
  }
}

export const hideConfirmationOfDeletion = () => {
  return {
    type : types.HIDE_CONFIRMATION_DELETION_ALL_TRACKS_NOT_USED
  }
}

export const openCSVPopUp = () => {
  return {
    type : types.OPEN_CSV_POPUP
  }
}

export const closeCSVPopUp = () => {
  return {
    type : types.CLOSE_CSV_POPUP
  }
}

export const updateCSVData = (payload) => {
  return {
    type: types.UPDATE_CSV_DATA,
    payload: payload
  }
}

export const toggleDisplayDelete = () => {
  return {
    type: types.TOGGLE_DISPLAY_DELETE
  }
}

export const toggleMonthFilter = () => {
  return {
    type: types.TOGGLE_MONTH_FILTER
  }
}
