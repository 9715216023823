import React, { PureComponent } from 'react';

import _ from 'lodash';
import autoBind from 'react-autobind'
import Select from 'react-select';

import Modal from '@layout_2/Modal/modal';

class ScheduleAddEditChannelPopup extends PureComponent {

  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      times: [],
      channelId : props.editScheduleObject.channel.id
    }
  }

  componentDidMount() {
    this._generateTimes();
  }

  componentWillUnmount() {
    this.props.handleChangeIsAddEditSchedValid(true);
  }

  componentDidUpdate(prevProps, prevState) {
    const dayIndex = this.props.editScheduleObject.days.indexOf(true);
    const start = this.props.editScheduleObject.startValue.clone().date();
    const timesDate = this.state.times.length > 0 ? this.state.times[0].value.date() : -1;
    if (this.props.editScheduleObject.days.filter(weekDay => weekDay).length === 1 && start !== timesDate) {
      this._generateTimes(dayIndex);
    }
  }

  _generateTimes(dayIndex) {
    const startDate = this.props.editScheduleObject.startValue.clone().startOf('isoWeek').date() + dayIndex;
    const times = this.props.times.map((time, i, self) => ({
      value: time.clone().set({
        date: i === self.length - 1 ? startDate + 1 : startDate
      }),
      label: i === self.length - 1 ? '24:00' : time.clone().format('HH:mm')
    }));
    this.setState({ ..._.cloneDeep(this.state), times });
  }

  _changeChannelHelper(selected) {
    const channel = this.props.channels.filter(channel => channel.id === selected.value)[0] || this.props.editScheduleObject.channel;
    this.props.handleEditScheduleChannelChange({ channel });
    this.setState({
      channelId: channel.id
    })
  }

  _changeStartHelper(selected) {
    this.props.handleEditScheduleChannelChange({ startValue: selected.value });
  }

  _changeEndHelper(selected) {
    this.props.handleEditScheduleChannelChange({ endValue: selected.value });
  }

  _changeDaysHelper(day, e) {
    const days = _.cloneDeep(this.props.editScheduleObject.days);
    days[day] = !days[day];
    if (days.filter(weekDay => weekDay).length < 1) return;
    let startValue = this.props.editScheduleObject.startValue.clone();
    let endValue = this.props.editScheduleObject.endValue.clone();
    if (days.filter(weekDay => weekDay).length === 1) {
      const startOfWeek = this.props.editScheduleObject.startValue.clone().startOf('isoWeek');
      const startDayIndex = days.indexOf(true);
      const endDayIndex = endValue.clone().startOf('day').isSame(endValue) ? startDayIndex + 1 : startDayIndex;
      startValue = startOfWeek.clone().add(startDayIndex, 'days').set({ hour: startValue.hour(), minute: startValue.minute() });
      endValue = startOfWeek.clone().add(endDayIndex, 'days').set({ hour: endValue.hour(), minute: endValue.minute() });
    }
    this.props.handleEditScheduleChannelChange({ days, startValue, endValue });
  }

  _saveChannel() {
    const channel = {...this.props.editScheduleObject.channel};
    const days = _.cloneDeep(this.props.editScheduleObject.days);
    const day = days.indexOf(true) || 0;
    const startValue = this.props.editScheduleObject.startValue.clone();
    const endValue = this.props.editScheduleObject.endValue.clone();
    if (channel.name && startValue.isBefore(endValue)) {
      this.props.handleEditScheduleChannelChange({ day, channel, startValue, endValue, days, isEditChannel: this.props.isEditChannel });
      this.props.saveChannel();
    } else {
      this.props.handleChangeIsAddEditSchedValid(false);
    }
  }

  render() {
    if (!this.props.times || !this.props.editScheduleObject.startValue) return null;
    const channelsList = this.props.channels
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(channel => ({ value: channel.id, label: channel.name }));
    const times = this.state.times;
    const isErrorAnimation = this.props.isAddEditSchedValid ? '' : 'shake';
    console.log('channelId',this.state.channelId);
    return (
      <Modal animation={isErrorAnimation} width="600px" height="600px" show={this.props.isVisible} handleDismiss={this.props.cancelChannel}>
        <h3 className="text-center">Choose a playlist and set the time</h3>
        <div className="flex full-width">

          <Select
            id="channel-list"
            className="flex-grow-4 time-select channel-list"
            options={channelsList}
            defaultValue={this.state.channelId}
            onChange={this._changeChannelHelper}
            clearable={false}
            searchable={false} />

          <Select
            id="start-time"
            className="flex-grow-1 time-select start-time"
            options={times}
            value={times.filter(time => time.value.isSame(this.props.editScheduleObject.startValue))[0] || times[0] }
            onChange={this._changeStartHelper}
            clearable={false}
            searchable={false} />

          <Select
            id="end-time"
            className="flex-grow-1 time-select end-time"
            options={times}
            value={times.filter(time => time.value.isSame(this.props.editScheduleObject.endValue))[0] || times[3] }
            onChange={this._changeEndHelper}
            clearable={false}
            searchable={false} />

        </div>

        <div className="flex-center-center flex-wrap">

          {this.props.days.map((day, i) => {
            return (
              <div key={day} className="checkbox pill">
                <input onChange={(e) => this._changeDaysHelper(i,e)} checked={this.props.editScheduleObject.days[i]} ref={'day' + i} type="checkbox" id={`check-${day.toLowerCase()}`} />
                <label htmlFor={`check-${day.toLowerCase()}`}>{day}</label>
              </div>
            )
          })}

        </div>

        <div className="flex-center-center">
          {this.props.isEditChannel && (
            <button className="btn btn-danger btn-border btn-pill time-select" onClick={this.props.deleteChannel}>DELETE</button>
          )}
          <button className="btn btn-default btn-border btn-pill time-select" onClick={this.props.cancelChannel}>CANCEL</button>
          <button className="btn btn-primary btn-pill time-select" onClick={this._saveChannel}>SAVE</button>
        </div>
      </Modal>
    );
  }
}

export default ScheduleAddEditChannelPopup;
