import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { cloneDeep, isEqual } from "lodash";
import autoBind from "react-autobind";
import hexToRgba from "hex-to-rgba";
import moment from "moment-timezone";
import { Typeahead } from "react-bootstrap-typeahead";
import { FaClock, FaEdit } from "react-icons/fa";
import { Tooltip } from "react-tippy";
import TimezoneHelper from "@ambie/ambie-npm-timezone-helper";

import store from "@app/store";

import Modal from "@layout_2/Modal/modal";
import ScheduleAddEditChannelPopup from "./schedule-add-edit-channel-popup";
import ScheduleSaveChannelPopup from "./schedule-save-channel-popup";
import ScheduleDeleteConfirmationPopup from "./schedule-delete-confirmation-popup";
import ScheduleOverlapsConfirmationPopup from "./schedule-overlaps-confirmation-popup";
import ScheduleDiscardScheduleEditModePopup from "./schedule-discard-schedule-edit-mode-popup";
import ScheduleCurrentBar from "./schedule-current-bar";
import ScheduleAddSchedulePopup from "./schedule-add-schedule-popup";
import ScheduleDeleteSchedulePopup from "./schedule-delete-schedule-popup";

import * as schedulesApi from "@api/schedules-api";
import * as locationsApi from "@api/locations-api";

import {
  changeSelectedSchedule,
  changeScheduleEditMode,
  changeShowSchedulePopup,
  changeEditScheduleObj,
  changeShowScheduleSavePopup,
  changeShowScheduleDeleteConfirmationPopup,
  changeShowScheduleOverlapsConfirmationPopup,
  changeScheduleDiscardScheduleEditModePopup,
  changeIsScheduleEdited,
  changeSchedules,
  changeIsEditPlaylist,
  changeIsAddEditSchedValid,
  changeShowAddNewSchedulesPopup,
  changeShowDeleteSchedulePopup,
  changeTimezone,
  changeTimezoneOffset,
  changeTimes,
  changeExpandedView,
} from "@actions/schedules-actions";

class SchedulesMain extends PureComponent {
  static formatStartFinishTime = (time) =>
    time.minute() === 0 ? time.format("ha") : time.format("h:mma");
  static formatDuration = (hours, mins) =>
    hours === 0
      ? mins + "mins"
      : mins === 0 && hours === 1
      ? hours + "hr"
      : mins === 0 && hours > 1
      ? hours + "hrs"
      : `${hours}:${mins < 10 ? "0" + mins : mins}`;
  static playlistColor = (playlist) =>
    playlist.colour || playlist.color || "#cd618d";

  constructor(props) {
    super(props);
    autoBind(this);
    moment.tz.setDefault("Europe/London");
    this.state = {
      showTimezoneModal: false,
      showScheduleOverlapErrorModal: false,
      gmtTimes: this.generateTimes("UTC", false),
      startOfUkWeek: moment().tz(props.defaultTimezoneName).startOf("isoWeek"),
    };
  }

  async componentDidMount() {
    this.generateTimes();
    this.generateTimezones();
    if (this.props.userToken) {
      await locationsApi.getLocations(
        this.props.userToken,
        this.props.currentClientId
      );
      await schedulesApi.getSchedules(
        this.props.userToken,
        this.props.currentClientId,
        this.props.selectedSchedule.name
      );
      await schedulesApi.getPlaylistsByClientId(
        this.props.userToken,
        this.props.currentClientId
      );
    }
  }

  async componentDidUpdate(prevProps) {
    if (this.props.currentClientId !== prevProps.currentClientId) {
      store.dispatch(
        changeTimezone({ timezoneName: this.props.defaultTimezoneName })
      );
      await locationsApi.getLocations(
        this.props.userToken,
        this.props.currentClientId
      );
      await schedulesApi.getSchedules(
        this.props.userToken,
        this.props.currentClientId
      );
      await schedulesApi.getPlaylistsByClientId(
        this.props.userToken,
        this.props.currentClientId
      );
      this.generateTimes();
      this.generateTimezones();
      if (this.props.isScheduleEditMode || this.props.isExpandedView) {
        store.dispatch(changeScheduleEditMode(false));
      }
    } else {
      if (
        !prevProps.times ||
        (prevProps.times.length > 0 &&
          !isEqual(prevProps.times[0].tz(), this.props.timezoneName))
      ) {
        this.generateTimes();
      }
      if (
        !isEqual(
          this.props.selectedSchedule.schedule,
          prevProps.selectedSchedule.schedule
        ) ||
        !isEqual(this.props.timezone, prevProps.timezone) ||
        (!TimezoneHelper.isEmptySchedule(
          this.props.selectedSchedule.schedule
        ) &&
          TimezoneHelper.isEmptySchedule(
            this.props.selectedSchedule.timezoneAdjustedSchedule
          ))
      ) {
        this.timezonifySchedule();
      }
    }
  }

  componentWillUnmount() {
    store.dispatch(changeShowSchedulePopup(false));
    store.dispatch(changeScheduleEditMode(false));
    store.dispatch(
      changeTimezone({ timezoneName: this.props.defaultTimezoneName })
    );
  }

  get timezoneAdjustedSchedule() {
    return this.props.selectedSchedule.timezoneAdjustedSchedule;
  }

  get affectedAreas() {
    return [].concat.apply(
      [],
      this.props.locations.map((location) =>
        location.areas
          .filter(
            (area) => area.week_schedule_id === this.props.selectedSchedule._id
          )
          .map((area) => {
            area.location_id = location.id;
            return area;
          })
      )
    );
  }

  generateTimes(
    timezoneName = this.props.timezoneName || this.props.defaultTimezoneName
  ) {
    const times = TimezoneHelper.generateTimes(timezoneName);
    store.dispatch(changeTimes(times));
  }

  generateTimezones() {
    const timezoneName =
      this.props.timezoneName || TimezoneHelper.defaults.timezoneName;
    const timezone = TimezoneHelper.generateTimezoneObj(timezoneName);
    const timezones = TimezoneHelper.generateTimezones();
    const timezoneOffset = TimezoneHelper.timezoneOffset(timezoneName);
    store.dispatch(
      changeTimezone({
        timezone,
        timezones,
        timezoneName,
        timezoneOffset,
      })
    );
  }

  continueScheduleUpdate(newPlaylists) {
    const schedule = [...this.props.selectedSchedule.timezoneAdjustedSchedule];
    const timezoneAdjustedSchedule = TimezoneHelper.makeSpaceInSchedule(
      schedule,
      newPlaylists
    );
    this.finalizeSaveChannel(timezoneAdjustedSchedule);
  }

  finalizeSaveChannel(timezoneAdjustedSchedule) {
    const cleanSchedule =
      this.props.selectedSchedule.cleanSchedule ||
      cloneDeep(this.props.selectedSchedule.schedule);
    const ukSchedule = TimezoneHelper.scheduleToUKDateString([
      ...timezoneAdjustedSchedule,
    ]);
    const selectedSchedule = {
      ...cloneDeep(this.props.selectedSchedule),
      cleanSchedule,
      schedule: ukSchedule,
      timezoneAdjustedSchedule,
    };
    store.dispatch(changeSelectedSchedule(selectedSchedule));
    store.dispatch(changeShowSchedulePopup(false));
    store.dispatch(changeIsScheduleEdited(true));
  }

  showTimezoneModal(shouldShow = true) {
    this.setState({
      ...this.state,
      showTimezoneModal: shouldShow,
    });
  }

  showScheduleOverlapErrorModal(shouldShow = true) {
    this.setState({
      ...this.state,
      showScheduleOverlapErrorModal: shouldShow,
    });
  }

  timezonifySchedule() {
    const times = TimezoneHelper.generateTimes(this.props.timezoneName);
    const timezoneAdjustedSchedule = TimezoneHelper.timezonifySchedule(
      this.props.selectedSchedule.schedule,
      times,
      this.props.timezoneName
    );
    store.dispatch(
      changeSelectedSchedule({
        ...cloneDeep(this.props.selectedSchedule),
        timezoneAdjustedSchedule,
        times,
      })
    );
  }

  handleEditScheduleChannelChange(modifiedObj) {
    store.dispatch(
      changeEditScheduleObj({
        ...cloneDeep(this.props.editScheduleObject),
        ...cloneDeep(modifiedObj),
      })
    );
  }

  handleEnterEditMode() {
    store.dispatch(changeScheduleEditMode(true));
  }

  handleUpdateSchedule() {
    store.dispatch(changeShowScheduleSavePopup(true));
    store.dispatch(changeIsAddEditSchedValid(true));
  }

  handleChangeIsAddEditSchedValid(newValue) {
    store.dispatch(changeIsAddEditSchedValid(newValue));
  }

  handleConfirmedUpdateSchedule() {
    store.dispatch(changeShowScheduleSavePopup(false));
    store.dispatch(changeShowSchedulePopup(false));
    store.dispatch(changeScheduleEditMode(false));
    store.dispatch(changeIsScheduleEdited(false));
    const timezoneAdjustedSchedule = cloneDeep(
      this.props.selectedSchedule.timezoneAdjustedSchedule
    );
    const ukSchedule =
      this.props.timezoneName === TimezoneHelper.defaults.timezoneName
        ? cloneDeep(timezoneAdjustedSchedule)
        : TimezoneHelper.scheduleToUKMoment(
            cloneDeep(timezoneAdjustedSchedule)
          );
    if (TimezoneHelper.isWeeklyScheduleValid(ukSchedule)) {
      this.showScheduleOverlapErrorModal();
    } else {
      const schedule = {
        clientId: this.props.selectedSchedule.clientId,
        _id: this.props.selectedSchedule._id,
        name: this.props.selectedSchedule.name,
        schedule: TimezoneHelper.scheduleToUKDateString(
          timezoneAdjustedSchedule
        ),
      };
      schedulesApi.updateSchedule(
        schedule,
        this.props.userToken,
        this.affectedAreas
      );
    }
  }

  handleCancelUpdateSchedule() {
    store.dispatch(changeShowScheduleSavePopup(false));
  }

  handleAddChannelPopup(time, dayIndex) {
    store.dispatch(changeIsAddEditSchedValid(true));
    if (this.props.isScheduleEditMode) {
      store.dispatch(changeIsEditPlaylist(false));
      const daysArray = [false, false, false, false, false, false, false];
      daysArray[dayIndex] = true;
      const startValue = time.clone().add(dayIndex, "days");
      const endValue =
        startValue.clone().add(1, "hours").date() !== startValue.clone().date()
          ? startValue.clone().add(1, "days").startOf("day")
          : startValue.clone().add(1, "hours");
      store.dispatch(
        changeEditScheduleObj({
          isEditChannel: false,
          day: dayIndex || 0,
          days: daysArray,
          startValue,
          endValue,
          channel: this.props.currentChannelsForClient[0] || {},
        })
      );
      store.dispatch(changeShowSchedulePopup(true));
    }
  }

  handleEditChannelPopup(channel, dayIndex) {
    if (!this.props.isScheduleEditMode) return;
    store.dispatch(changeScheduleEditMode(true));
    store.dispatch(changeIsAddEditSchedValid(true));
    if (this.props.isScheduleEditMode) {
      store.dispatch(changeIsEditPlaylist(true));
      const daysArray = [false, false, false, false, false, false, false];
      daysArray[dayIndex || 0] = true;
      store.dispatch(
        changeEditScheduleObj({
          isEditChannel: true,
          day: dayIndex || 0,
          days: daysArray,
          startValue: channel.start_time,
          endValue: channel.end_time,
          channel: channel.playlist,
          color: channel.playlist.color,
        })
      );
      store.dispatch(changeShowSchedulePopup(true));
    }
  }

  handleCancelChannel() {
    store.dispatch(changeShowSchedulePopup(false));
    store.dispatch(changeIsAddEditSchedValid(true));
  }

  handleChangeSelectedSchedule(scheduleId) {
    store.dispatch(changeIsScheduleEdited(false));
    schedulesApi.getSelectedSchedule(scheduleId, this.props.userToken);
  }

  handleCancelSchedule() {
    store.dispatch(changeIsAddEditSchedValid(true));
    if (this.props.isScheduleEdited) {
      store.dispatch(changeScheduleDiscardScheduleEditModePopup(true));
    } else {
      store.dispatch(changeScheduleDiscardScheduleEditModePopup(false));
      store.dispatch(changeShowSchedulePopup(false));
      store.dispatch(changeScheduleEditMode(false));
      if (this.props.userToken) {
        schedulesApi.getSchedules(
          this.props.userToken,
          this.props.currentClientId,
          this.props.selectedSchedule.name
        );
      }
    }
  }

  handleContinueWithoutSavingEditSchedule() {
    store.dispatch(changeScheduleDiscardScheduleEditModePopup(false));
    store.dispatch(changeShowSchedulePopup(false));
    store.dispatch(changeScheduleEditMode(false));
    store.dispatch(changeIsScheduleEdited(false));
    const timezoneAdjustedSchedule = TimezoneHelper.emptySchedule;
    const selectedSchedule = {
      ...cloneDeep(this.props.selectedSchedule),
      schedule:
        this.props.selectedSchedule.cleanSchedule ||
        this.props.selectedSchedule.schedule,
      timezoneAdjustedSchedule,
      cleanSchedule: null,
    };
    store.dispatch(changeSelectedSchedule(selectedSchedule));
  }

  handleSaveEditSchedule() {
    store.dispatch(changeScheduleDiscardScheduleEditModePopup(false));
    store.dispatch(changeShowSchedulePopup(false));
    store.dispatch(changeShowScheduleSavePopup(true));
  }

  handleReturnToEditSchedule() {
    store.dispatch(changeScheduleDiscardScheduleEditModePopup(false));
  }

  handleDeleteChannel() {
    store.dispatch(changeShowScheduleDeleteConfirmationPopup(true));
    store.dispatch(changeIsAddEditSchedValid(true));
  }

  handleCancelDeleteChannel() {
    store.dispatch(changeShowScheduleDeleteConfirmationPopup(false));
  }

  handleConfirmedDeleteChannel() {
    const schedule = [...this.timezoneAdjustedSchedule];
    const startTime = this.props.editScheduleObject.startValue;
    const endTime = this.props.editScheduleObject.endValue;
    const playlist = this.props.editScheduleObject.channel;
    const timezoneAdjustedSchedule = TimezoneHelper.removePlaylistFromSchedule(
      schedule,
      startTime,
      endTime,
      playlist
    );
    store.dispatch(
      changeSelectedSchedule({
        ...cloneDeep(this.props.selectedSchedule),
        timezoneAdjustedSchedule,
      })
    );
    store.dispatch(changeSchedules(this.props.schedules));
    store.dispatch(changeShowSchedulePopup(false));
    store.dispatch(changeShowScheduleDeleteConfirmationPopup(false));
    store.dispatch(changeIsScheduleEdited(true));
  }

  handleCancelOverlapsChannel() {
    store.dispatch(changeShowScheduleOverlapsConfirmationPopup(false));
  }

  handleConfirmedOverlapsChannel() {
    const newPlaylists = TimezoneHelper.createNewPlaylists(
      this.props.editScheduleObject,
      this.props.times
    );
    store.dispatch(changeShowScheduleOverlapsConfirmationPopup(false));
    this.continueScheduleUpdate(newPlaylists);
  }

  handleSaveChannel() {
    store.dispatch(changeIsAddEditSchedValid(true));
    const newPlaylists = TimezoneHelper.createNewPlaylists(
      this.props.editScheduleObject,
      this.props.times
    );
    const schedule = [...this.props.selectedSchedule.timezoneAdjustedSchedule];
    if (TimezoneHelper.isOverlapInWeek(schedule, newPlaylists)) {
      store.dispatch(changeShowScheduleOverlapsConfirmationPopup(true));
    } else {
      this.continueScheduleUpdate(newPlaylists);
    }
  }

  handleChangeTimezone(timezones) {
    const timezone = timezones[0];
    store.dispatch(
      changeTimezone({
        timezoneName: timezone.name.replace(" ", "_"),
        timezone: timezone.offsetDisplay,
      })
    );
    store.dispatch(
      changeTimezoneOffset({
        hours: timezone.offsetHours,
        minutes: timezone.offsetMins,
      })
    );
    this.setState({ showTimezoneModal: false });
  }

  handleChangeViewMode(shouldExpand) {
    store.dispatch(changeExpandedView(shouldExpand));
  }

  handleAddSchedule(newName, cloneSource, cloneRef) {
    if (!this.props.schedules.some((sched) => sched.name === newName)) {
      if (cloneSource && cloneRef) {
        schedulesApi.addSchedule(
          { name: newName, source: cloneSource, ref: cloneRef },
          this.props.currentClientId,
          this.props.userToken
        );
        store.dispatch(changeShowAddNewSchedulesPopup(false));
      } else {
        schedulesApi.addSchedule(
          { name: newName },
          this.props.currentClientId,
          this.props.userToken
        );
        store.dispatch(changeShowAddNewSchedulesPopup(false));
      }
    } else {
      alert("A schedule with that name already exists for the current client");
    }
  }

  handleOpenAddSchedule() {
    store.dispatch(changeShowAddNewSchedulesPopup(true));
  }

  handleCloseAddSchedule() {
    store.dispatch(changeShowAddNewSchedulesPopup(false));
  }

  handleDeleteSchedule() {
    schedulesApi.deleteSchedule(
      this.props.selectedSchedule._id,
      this.props.currentClientId,
      this.props.userToken
    );
    store.dispatch(changeShowDeleteSchedulePopup(false));
  }

  handleOpenDeleteSchedule() {
    store.dispatch(changeShowDeleteSchedulePopup(true));
  }

  handleCloseDeleteSchedule() {
    store.dispatch(changeShowDeleteSchedulePopup(false));
  }

  get dayColumns() {
    const elementHeight = this.props.isExpandedView ? 20 : 10;
    let playlistStartIndex = Array.isArray(this.props.times)
      ? this.props.times.length - 1
      : 0;
    let playlistEndIndex = playlistStartIndex;
    return this.props.days.map((day, dayIndex) => (
      <div
        className="day-column animated delay02s duration03 slideInDown"
        id={`column-${day.toLowerCase()}`}
        key={`column-${day}`}
      >
        {this.props.times.map((time, timeIndex) => {
          if (timeIndex === 0) {
            playlistStartIndex = this.props.times.length - 1;
            playlistEndIndex = playlistStartIndex;
          }
          const itemStyle = {
            height: `${elementHeight}px`,
            cursor: this.props.isScheduleEditMode ? "pointer" : "auto",
            borderTop:
              this.props.isExpandedView ||
              (!this.props.isExpandedView && timeIndex % 4 === 0)
                ? "1px solid #e6e6e6"
                : "none",
          };
          let element = (
            <div
              key={`column-${day}-cell-${time}`}
              id={`column-${day.toLowerCase()}-cell-${time.format("HH-mm")}`}
              onClick={() => this.handleAddChannelPopup(time, dayIndex)}
              style={itemStyle}
              className="animated fadeInDown calendar-cell schedules-empty-block"
            >
              <div className="schedules-add-playlist">
                <i className="icon-plus"></i> Add Playlist
              </div>
            </div>
          );
          this.props.selectedSchedule.timezoneAdjustedSchedule[dayIndex]
            .sort((a, b) => a.start_time.diff(b.start_time))
            .forEach((playlist, playlistIndex) => {
              const dateTime = time.clone().add(dayIndex, "days");
              if (dateTime.isSame(playlist.start_time)) {
                // check whether the start time of the scheduled playlist matches the time and create schedule block item if so
                const playlistLength =
                  playlist.end_time.diff(playlist.start_time) /
                  (60 * 1000) /
                  15; // playlist length in number of 15 min slots
                playlistStartIndex = timeIndex;
                playlistEndIndex = timeIndex + playlistLength;
                const durationHours = Math.floor(playlistLength / 4);
                const durationMins = ((playlistLength / 4) % 1) * 60;
                const playlistBlockStyle = {
                  height: elementHeight * playlistLength,
                  cursor: this.props.isScheduleEditMode ? "pointer" : "normal",
                  border:
                    elementHeight * playlistLength > 0
                      ? `1px solid ${SchedulesMain.playlistColor(
                          playlist.playlist
                        )}`
                      : "none",
                  background: hexToRgba(
                    SchedulesMain.playlistColor(playlist.playlist),
                    0.7
                  ),
                };
                const isSmallBlock =
                  durationHours + durationMins / 60 <= 1 &&
                  !this.props.isExpandedView;
                const nameStyle = {
                  paddingTop: isSmallBlock ? "0" : "4px",
                  paddingLeft: isSmallBlock ? "3px" : "6px",
                  fontSize:
                    durationHours === 0 && durationMins <= 15 ? "8px" : "14px",
                  width:
                    durationHours + durationMins / 60 >= 0.5
                      ? "calc(100% - 25px)"
                      : "calc(100% - 15px)",
                  "--webkit-line-clamp": isSmallBlock
                    ? "1"
                    : `${durationHours + 1}`,
                  WebkitBoxOrient: "vertical",
                };
                element = (
                  <Fragment key={`fragment-column-${day}-cell-${time}`}>
                    <div
                      key={`column-${day}-cell-${time}`}
                      id={`column-${day.toLowerCase()}-cell-${playlist.start_time.format(
                        "HH-mm"
                      )}-${playlist.end_time.format("HH-mm")}`}
                      onClick={() =>
                        this.handleEditChannelPopup(playlist, dayIndex)
                      }
                      style={playlistBlockStyle}
                      className="schedule-block-item animated fadeInDown"
                    >
                      <p className="schedule-block-item-name" style={nameStyle}>
                        {playlist.playlist.name}
                      </p>
                      <span
                        style={{
                          fontSize:
                            durationHours === 0 &&
                            durationMins <= 15 &&
                            !this.props.isExpandedView
                              ? "6px"
                              : "11px",
                        }}
                        className="schedule-block-item-duration"
                      >
                        {SchedulesMain.formatDuration(
                          durationHours,
                          durationMins
                        )}
                      </span>
                      {(this.props.isExpandedView &&
                        durationHours + durationMins / 60 >= 0.5) ||
                      durationHours + durationMins / 60 >= 1 ? (
                        <span className="schedule-block-item-start-finish">
                          {`${SchedulesMain.formatStartFinishTime(
                            playlist.start_time
                          )} - ${SchedulesMain.formatStartFinishTime(
                            playlist.end_time
                          )}`}
                        </span>
                      ) : null}
                      <div
                        style={{
                          fontSize: elementHeight,
                          background: hexToRgba(
                            SchedulesMain.playlistColor(playlist.playlist),
                            0.8
                          ),
                          boxShadow: `0 0 1px 1px ${hexToRgba(
                            SchedulesMain.playlistColor(playlist.playlist)
                          )}`,
                        }}
                        className="edit-block"
                      >
                        <FaEdit
                          style={{
                            fontSize: elementHeight * 1.5,
                            paddingRight: "0.5rem",
                          }}
                          className="animated fadeIn"
                        />
                        Edit
                      </div>
                    </div>
                  </Fragment>
                );
              }
            });
          element =
            (timeIndex > playlistStartIndex && timeIndex < playlistEndIndex) ||
            timeIndex === this.props.times.length - 1
              ? null
              : element;
          return element;
        })}
      </div>
    ));
  }

  get timeBar() {
    const elementHeight = this.props.isExpandedView ? 20 : 40;
    const fontSize = { small: "11px", large: "14px" };
    return !this.props.times
      ? null
      : this.props.times.map((time, i, self) => {
          const isMidnight = i === self.length - 1;
          if (!this.props.isExpandedView && i % 4 !== 0) {
            return null;
          }
          const prettyTime = isMidnight ? "24:00" : time.format("HH:mm");
          let elementStyle = {
            "--elementHeight": `${elementHeight}px`,
            "--fontSize": i % 4 === 0 ? fontSize.large : fontSize.small,
            "--textColor":
              !this.props.isExpandedView ||
              (this.props.isExpandedView && i % 4 === 0)
                ? "#888"
                : "#a5a5a5",
          };
          if (isMidnight) elementStyle.height = 0;
          return (
            <span
              style={elementStyle}
              className="time-column-segment"
              key={time}
            >
              {prettyTime}
            </span>
          );
        });
  }

  get dayHeadings() {
    const dayIndex = moment().tz(this.props.timezoneName).isoWeekday() - 1;
    const todayStyle = {
      background: "#707d86",
      color: "#fff",
      border: "none",
    };
    const expandIconStyle = {
      transform: this.props.isExpandedView ? "rotate(90deg)" : "rotate(0deg)",
      transition: "0.3s",
    };
    return (
      <div className="day-header">
        <span
          style={expandIconStyle}
          className="expand-collapse"
          onClick={() => this.handleChangeViewMode(!this.props.isExpandedView)}
        >
          <Tooltip
            title={
              this.props.isExpandedView
                ? "Show 1 hour intervals"
                : "Show 15 min intervals"
            }
            size="big"
            position="top"
            trigger="mouseenter"
          >
            <FaClock className="animated tada" />
          </Tooltip>
        </span>
        {this.props.days.map((day, i) => (
          <span
            style={i === dayIndex ? todayStyle : {}}
            className="animated delay02s duration04 bounceInDown day"
            key={`heading-${day}`}
          >
            {day}
          </span>
        ))}
      </div>
    );
  }

  render() {
    const schedulesList = this.props.schedules
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((schedule, index) => (
        <li
          key={"schedule-list-item" + index}
          className={
            this.props.selectedSchedule.name === schedule.name
              ? "schedules-list-item selected-schedule"
              : "schedules-list-item"
          }
          onClick={() => this.handleChangeSelectedSchedule(schedule._id)}
        >
          {schedule.name}
        </li>
      ));
    const editScheduleStyle = {
      display: this.props.isScheduleEditMode ? "none" : "inline",
    };
    const saveScheduleStyle = {
      display: this.props.isScheduleEditMode ? "block" : "none",
    };
    let locationUrl = "/locations";
    if (this.props.currentClientId) {
      locationUrl += "/" + this.props.currentClientId;
    } else if (this.props.match.params.clientId) {
      locationUrl += "/" + this.props.match.params.clientId;
    }
    return (
      <React.Fragment>
        {!this.state.showTimezoneModal ? null : (
          <Modal
            flexSpacing="flex-start"
            show={this.state.showTimezoneModal}
            handleDismiss={() => this.showTimezoneModal(false)}
            width={"300px"}
            height={"400px"}
          >
            <Typeahead
              bsSize="large"
              clearButton
              id="timezone-typeahead"
              maxResults={this.props.timezones.length / 2}
              emptyLabel={""}
              highlightOnlyResult
              labelKey="name"
              autoFocus
              filterBy={["name", "offsetDisplay"]}
              onChange={(timezone) => this.handleChangeTimezone(timezone)}
              options={this.props.timezones
                .filter((t) => !this.props.timezoneOffset !== t.offsetDisplay)
                .map((tz) => {
                  tz.name = tz.name.replace("_", " ");
                  return tz;
                })}
              placeholder="Select a timezone..."
              selectHintOnEnter
              renderMenuItemChildren={(option) => (
                <div>
                  {option.offsetDisplay}
                  <div>
                    <small>{option.name.replace("_", " ")}</small>
                  </div>
                </div>
              )}
            />
          </Modal>
        )}
        {!this.state.showScheduleOverlapErrorModal ? null : (
          <Modal
            dark
            show={this.state.showScheduleOverlapErrorModal}
            handleDismiss={() => this.showScheduleOverlapErrorModal(false)}
            width={"500px"}
            height={"300px"}
          >
            <h2>Warning!</h2>
            <p>Overlaps have been identified in this schedule.</p>
            <p>The schedule could be corrupt.</p>
            <p>
              Please either remove any overlapping playlists, or create a new
              schedule.
            </p>
            <p>The changes made to the schedule have not been saved.</p>
          </Modal>
        )}
        <div
          id="schedules-header"
          className="col-md-12 text-center default-header fix-shadow animated delay02s fadeInDown"
        >
          <div className="container">
            <Link
              to={locationUrl}
              style={{
                float: "left",
                fontSize: "17px",
                color: "#39c9a6",
                textDecoration: "underline",
              }}
            >
              Go to Locations
            </Link>
            <h1 className="schedule-name">
              {this.props.selectedSchedule.name !== ""
                ? this.props.selectedSchedule.name
                : "Select a schedule to edit or  create a new one"}
            </h1>
            {IS_OLYMPUS ? (
              <div
                className="btn btn-default btn-pill edit-schedule-btn"
                onClick={this.handleOpenAddSchedule}
              >
                Create new schedule
              </div>
            ) : null}
            {this.props.selectedSchedule.name && (
              <div
                className="btn btn-default btn-pill edit-schedule-btn"
                style={editScheduleStyle}
                onClick={this.handleEnterEditMode}
              >
                Edit Schedule
              </div>
            )}
            {IS_OLYMPUS
              ? this.props.selectedSchedule.name && (
                  <div
                    className="btn btn-danger btn-pill delete-schedule-btn"
                    onClick={this.handleOpenDeleteSchedule}
                  >
                    Delete Schedule
                  </div>
                )
              : null}
            {this.props.selectedSchedule.name && (
              <div
                className="btn btn-default btn-pill edit-schedule-btn"
                onClick={this.showTimezoneModal}
              >
                {this.props.timezone}
              </div>
            )}
          </div>
        </div>

        <div
          className="edit-schedule-panel animated fadeInDown default-header"
          style={saveScheduleStyle}
        >
          <div className="container flex-center-center">
            <button
              className="col-sm-2 btn btn-primary btn-pill save-btn"
              onClick={this.handleUpdateSchedule}
            >
              Save Schedule
            </button>
            <button
              className="col-sm-2 btn btn-default btn-pill edit-schedule-btn"
              onClick={() => this.handleAddChannelPopup(this.props.times[0], 0)}
            >
              <i className="icon-plus"></i> Add Playlist
            </button>
            <button
              className="btn btn-default btn-pill edit-schedule-btn"
              onClick={this.showTimezoneModal}
            >
              {this.props.timezone}
            </button>
            <h1 className="flex-1-1-auto text-center">
              Editing {this.props.selectedSchedule.name}
            </h1>
            {/*<a href="#" className="btn reset-btn" title="Reset To Original" onClick={this._handleResetSchedule}>Reset To Original</a>*/}
            <div className="cancel-btn" onClick={this.handleCancelSchedule}>
              <i className="icon-close"></i>
            </div>
          </div>
        </div>

        <div
          id="schedules-body"
          className={
            this.props.isScheduleEditMode ? "container edit-mode" : "container"
          }
        >
          <div className="row">
            <div
              className={
                this.props.isScheduleEditMode
                  ? "col-sm-2 schedules-list-wrapper edit-mode"
                  : "col-sm-2 schedules-list-wrapper"
              }
            >
              <div className="schedules-list-name">Select schedule</div>
              <ul className="schedules-list">{schedulesList}</ul>
            </div>
            <div
              className={
                this.props.isScheduleEditMode
                  ? "schedules-detail-wrapper full-width"
                  : "col-sm-10 schedules-detail-wrapper"
              }
            >
              <div
                className={
                  this.props.isScheduleEditMode
                    ? "schedules-inner-wrapper edit-mode"
                    : "schedules-inner-wrapper"
                }
              >
                {this.dayHeadings}
                <div className="calendar-container no-scrollbar">
                  <div className="calendar">
                    <div className="schedules-detail-hour-column-content">
                      {this.timeBar}
                    </div>
                    {this.dayColumns}
                    <ScheduleCurrentBar
                      timeNow={moment().tz(this.props.timezoneName).parseZone()}
                      isScheduleEditMode={this.props.isScheduleEditMode}
                      hourHeight={this.props.isExpandedView ? 80 : 40}
                    />
                  </div>
                </div>
              </div>

              {!this.props.showScheduleEditPopup ? null : (
                <ScheduleAddEditChannelPopup
                  days={this.props.days}
                  times={this.props.times}
                  isVisible={this.props.showScheduleEditPopup}
                  cancelChannel={this.handleCancelChannel}
                  saveChannel={this.handleSaveChannel}
                  deleteChannel={this.handleDeleteChannel}
                  editScheduleObject={this.props.editScheduleObject}
                  channels={this.props.currentChannelsForClient}
                  handleEditScheduleChannelChange={
                    this.handleEditScheduleChannelChange
                  }
                  handleChangeIsAddEditSchedValid={
                    this.handleChangeIsAddEditSchedValid
                  }
                  isAddEditSchedValid={this.props.isAddEditSchedValid}
                  isEditChannel={this.props.isEditPlaylist}
                />
              )}

              {!this.props.showScheduleSavePopup ? null : (
                <ScheduleSaveChannelPopup
                  timezone={this.props.timezone}
                  timezoneName={this.props.timezoneName}
                  isVisible={this.props.showScheduleSavePopup}
                  handleConfirm={this.handleConfirmedUpdateSchedule}
                  handleCancel={this.handleCancelUpdateSchedule}
                  currentSchedule={this.props.selectedSchedule}
                  affectedAreas={this.affectedAreas}
                />
              )}

              {!this.props.showScheduleDeleteConfirmationPopup ? null : (
                <ScheduleDeleteConfirmationPopup
                  isVisible={this.props.showScheduleDeleteConfirmationPopup}
                  handleCancelDeleteChannel={this.handleCancelDeleteChannel}
                  handleConfirmedDeleteChannel={
                    this.handleConfirmedDeleteChannel
                  }
                  affectedAreas={this.affectedAreas}
                />
              )}

              {!this.props.showScheduleOverlapsConfirmationPopup ? null : (
                <ScheduleOverlapsConfirmationPopup
                  isVisible={this.props.showScheduleOverlapsConfirmationPopup}
                  handleCancelOverlapsChannel={this.handleCancelOverlapsChannel}
                  handleConfirmedOverlapsChannel={
                    this.handleConfirmedOverlapsChannel
                  }
                />
              )}

              {!this.props.showDiscardScheduleEditPopup ? null : (
                <ScheduleDiscardScheduleEditModePopup
                  isVisible={this.props.showDiscardScheduleEditPopup}
                  handleContinueWithoutSavingEditSchedule={
                    this.handleContinueWithoutSavingEditSchedule
                  }
                  handleSaveEditSchedule={this.handleSaveEditSchedule}
                  handleReturnToEditSchedule={this.handleReturnToEditSchedule}
                />
              )}

              {!this.props.showAddNewSchedulePopup ? null : (
                <ScheduleAddSchedulePopup
                  isVisible={this.props.showAddNewSchedulePopup}
                  schedules={this.props.schedules}
                  locations={this.props.locations}
                  handleAddSchedule={this.handleAddSchedule}
                  handleCloseAddSchedule={this.handleCloseAddSchedule}
                />
              )}

              {!this.props.showDeleteSchedulePopup ? null : (
                <ScheduleDeleteSchedulePopup
                  affectedAreas={this.affectedAreas}
                  isVisible={this.props.showDeleteSchedulePopup}
                  schedule={this.props.selectedSchedule}
                  handleDeleteSchedule={this.handleDeleteSchedule}
                  handleCloseDeleteSchedule={this.handleCloseDeleteSchedule}
                />
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const storeToProps = (store) => {
  return {
    userToken: store.authState.userInfo
      ? store.authState.userInfo.userToken
      : "",
    currentClientId: store.authState.currentClientId,
    locations: store.locationsState.locations,
    schedules: store.schedulesState.schedules,
    channels: store.channelsState.channels,
    selectedSchedule: store.schedulesState.selectedSchedule,
    isScheduleEditMode: store.schedulesState.isScheduleEditMode,
    showScheduleEditPopup: store.schedulesState.showScheduleEditPopup,
    editScheduleObject: store.schedulesState.editScheduleObject,
    showScheduleSavePopup: store.schedulesState.showScheduleSavePopup,
    showScheduleLocationsPopup: store.schedulesState.showScheduleLocationsPopup,
    showScheduleDeleteConfirmationPopup:
      store.schedulesState.showScheduleDeleteConfirmationPopup,
    showScheduleOverlapsConfirmationPopup:
      store.schedulesState.showScheduleOverlapsConfirmationPopup,
    showDiscardScheduleEditPopup:
      store.schedulesState.showDiscardScheduleEditPopup,
    isScheduleEdited: store.schedulesState.isScheduleEdited,
    isEditPlaylist: store.schedulesState.isEditPlaylist,
    isAddEditSchedValid: store.schedulesState.isAddEditSchedValid,
    currentChannelsForClient: store.schedulesState.currentChannelsForClient,
    showAddNewSchedulePopup: store.schedulesState.showAddNewSchedulePopup,
    showDeleteSchedulePopup: store.schedulesState.showDeleteSchedulePopup,
    areaScheduleInfo: store.locationsState.areaScheduleInfo,
    isExpandedView: store.schedulesState.isExpandedView,
    days: store.schedulesState.days,
    timezone: store.schedulesState.timezone,
    timezoneName: store.schedulesState.timezoneName,
    timezoneOffset: store.schedulesState.timezoneOffset,
    timezones: store.schedulesState.timezones,
    times: store.schedulesState.times,
    defaultTimezone: store.schedulesState.defaultTimezone,
    defaultTimezoneName: store.schedulesState.defaultTimezoneName,
  };
};

export default connect(storeToProps)(SchedulesMain);
