import React, { Component } from 'react';
import Table from '@common/table';

const statusField = (doneProcessing, error) => {
  if (doneProcessing && error) return 'Failed';
  if (doneProcessing) return 'Done';
  return (
    <div>
      Processing<div className="proc-icon"></div>
    </div>
  );
};

export default (props) => {
  return (
    <React.Fragment>
      <Table
        borders
        searchbar
        headings={[
          { title: 'Name', width: 100 },
          { title: 'Status', width: 100 },
          { title: 'Uploaded On', width: 100, date: true },
          { title: 'Error', width: 100 },
          { title: 'Actions', width: 100 },
        ]}
        rowData={props.existingTracks.map((track) => {
          return {
            data: [
              track.name,
              statusField(track.doneProcessing, track.error),
              new Date(track.uploadedOn).getTime(),
              track.error ? track.error : null,
              <React.Fragment>
                {track.doneProcessing ? (
                  <div>
                    {/* <span style={{ cursor: 'pointer' }} className="text-success" onClick={() => props.handleEditTrackPopup(track)}>
                      EDIT
                    </span> */}
                    {/* &nbsp;|&nbsp; */}
                    <span style={{ cursor: 'pointer' }} className="text-danger" onClick={() => props.handleDismsisTrackPopup(track)}>
                      DISMISS
                    </span>
                  </div>
                ) : (
                  <div>
                    <span>Waiting</span>
                    &nbsp;|&nbsp;
                    <span style={{ cursor: 'pointer' }} className="text-danger" onClick={() => props.handleDismsisTrackPopup(track)}>
                      DISMISS
                    </span>
                  </div>
                )}
              </React.Fragment>,
            ],
          };
        })}
        noDataMessage={props.joke}
        paginate={props.existingTracks.length > 20}
        itemsCountPerPage={20}
        showFooter
        footerItems={{
          right: (
            <button type="danger" className="btn btn-success btn-sm" disabled={props.existingTracks.length === 0} onClick={props.handleOpenDismissAllPopup}>
              Dismiss all
            </button>
          ),
        }}
      ></Table>
    </React.Fragment>
  );
};
