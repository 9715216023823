import React from "react";

import { FaPlayCircle, FaPauseCircle } from "react-icons/fa";

const TrackControl = (props) => (
  <div className="flex">
    <button
      className="track-play btn-clear flex-center-center margin-left-sm margin-right-sm"
      onClick={() =>
        props.handlePlayPause(
          props.playerState === "play" ? "pause" : "play",
          props.volume,
          props.deviceSerial
        )
      }
    >
      {props.isOnline && !props.isPlaying && props.isPaused ? (
        <FaPlayCircle size={60} color="white" />
      ) : (
        <FaPauseCircle size={60} color="white" />
      )}
    </button>
    <div className="flex-column">
      <p className={`track-title`}>
        <span className="margin-right-xs text-bold">Track:</span> {props.title}
      </p>
      <p className={`track-artist`}>
        <span className="margin-right-xs text-bold">Artist:</span>{" "}
        {props.artist}
      </p>
      <p className={`track-playlist`}>
        <span className="margin-right-xs text-bold">Playlist:</span>{" "}
        {props.playlist}
      </p>
    </div>
  </div>
);

export default TrackControl;
