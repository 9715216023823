import * as types from "../actions/types";

const initalState = {
  webLeads: []
};

export default (state = initalState, action) => {
  switch (action.type) {

    case types.UPDATE_WEB_LEADS_LIST:
      return Object.assign({}, state, {
	      webLeads: action.payload
      });
    break;

  }

  return state;
};
