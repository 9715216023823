require('buffer');
import React, { Component } from 'react';
import { connect } from 'react-redux';
import store from '@store';
import TrackUploaderList from './track-uploader-list';
import TrackDismissPopup from './track-uploader-dismiss-popup';
import TrackDismissAllPopup from './track-uploader-dismiss-all-popup';
import NotificationBubble from './notification';

import { DEFAULT_FZ_MESSAGE, DEFAULT_FZ_DROP_MESSAGE, DEFAULT_FZ_UPLOAD_MESSAGE, GENERAL_SUCCESS_MESSAGE, GENERAL_ERROR_MESSAGE } from '@config';

import ClientWebsocket from '../../websocket/websocket';

import {
  deleteAllTracksFromProcessedAudioTable,
  deleteTrackFromProcessedAudioTable,
  getStatusForProcessedTracks,
  processTrack,
  startFileUploadConverterTask,
} from '@app/api/tracks-api';

import { showNotification, hideNotification } from '@actions/layout-actions';

import {
  changeFzMessage,
  changeFzCurFileNo,
  changeFzTotalFileNo,
  changeFzShowProgress,
  changeIsOverFz,
  changeFzFileNames,
  changeExistingTracks,
  changeShowDismissTrack,
  changeSelectedTrackForEdit,
  openDismissAllTracksPopup,
  closeDismissAllTracksPopup,
} from '@actions/track-uploader-actions';

import ActionBar from '@layout_2/action-bar';
import MainContainer from '@layout_2/main-container';
import Modal from '@layout_2/Modal/modal';

class TrackUploaderMain extends Component {
  constructor() {
    super();
    this.socket = ClientWebsocket();
    this._handleDrop = this._handleDrop.bind(this);

    this._wsHandleExistingTracks = this._wsHandleExistingTracks.bind(this);
    this._wsHandleTrackUploaded = this._wsHandleTrackUploaded.bind(this);
    this._wsHandleTrackUpdateFinished = this._wsHandleTrackUpdateFinished.bind(this);
    this._wsHandleTrackDismissFinished = this._wsHandleTrackDismissFinished.bind(this);

    this._handleDismsisTrackPopup = this._handleDismsisTrackPopup.bind(this);
    this._handleDismissTrack = this._handleDismissTrack.bind(this);
    this._handleCancelDismissTrack = this._handleCancelDismissTrack.bind(this);

    this._handleOpenDismissAllPopup = this._handleOpenDismissAllPopup.bind(this);
    this._handleCloseDismissAllPopup = this._handleCloseDismissAllPopup.bind(this);
    this._handleDismissAllTracks = this._handleDismissAllTracks.bind(this);
  }

  componentWillMount() {
    this.socket.on('connect', () => {
      console.log('SocketIO connection established');
      getStatusForProcessedTracks().catch((error) => {
        store.dispatch(showNotification(error.response.data.message, true));
      });
    });

    this.socket.on('connect_error', (err) => {
      console.error(err);
      store.dispatch(showNotification('ERROR CONNECTING TO SERVER', true));
    });
    this.socket.on('disconnect', () => console.log('SocketIO disconnected'));
    this.socket.on('refreshProcessedTracksTable', this._wsHandleExistingTracks);
    this.socket.on('uploadTrackToProcess', this._wsHandleTrackUploaded);
    this.socket.on('socket_error', (err) => {
      console.error(err);
      store.dispatch(showNotification('SOCKET SERVER ERROR', true));
    });
  }

  componentDidMount() {
    document.addEventListener('dragenter', this._handleDragEnter);
    document.addEventListener('dragleave', this._handleDragLeave);
    document.addEventListener('dragover', this._handleDragOver);
    document.addEventListener('drop', this._handleDrop);
  }

  componentWillUnmount() {
    document.removeEventListener('dragenter', this._handleDragEnter);
    document.removeEventListener('dragleave', this._handleDragLeave);
    document.removeEventListener('dragover', this._handleDragOver);
    document.removeEventListener('drop', this._handleDrop);
  }

  _wsHandleExistingTracks(tracks) {
    store.dispatch(changeExistingTracks(tracks));
  }

  _handleHideNotification() {
    store.dispatch(hideNotification());
  }

  _wsHandleTrackUploaded() {
    getStatusForProcessedTracks().catch((error) => {
      store.dispatch(showNotification(error.response.data.message, true));
    });

    if (this.props.fzCurFileNo + 1 >= this.props.fzTotalFileNo) {
      store.dispatch(changeFzShowProgress(false));
      store.dispatch(changeFzMessage(DEFAULT_FZ_MESSAGE));
    } else {
      store.dispatch(changeFzCurFileNo(this.props.fzCurFileNo + 1));
    }
  }

  _wsHandleTrackUpdateFinished(response) {
    if (response.success) {
      store.dispatch(showNotification(GENERAL_SUCCESS_MESSAGE));
    } else {
      store.dispatch(showNotification(GENERAL_ERROR_MESSAGE, true));
    }
  }

  _wsHandleTrackDismissFinished(response) {
    if (response.success) {
      store.dispatch(showNotification(GENERAL_SUCCESS_MESSAGE));
    } else {
      store.dispatch(showNotification(GENERAL_ERROR_MESSAGE, true));
    }
  }

  _handleDragEnter(e) {
    e.preventDefault();
    store.dispatch(changeFzMessage(DEFAULT_FZ_DROP_MESSAGE));
    store.dispatch(changeIsOverFz(true));
  }

  _handleDragLeave(e) {
    e.preventDefault();
    store.dispatch(changeIsOverFz(false));
    store.dispatch(changeFzMessage(DEFAULT_FZ_MESSAGE));
  }

  _handleDragOver(e) {
    e.preventDefault();
    store.dispatch(changeFzMessage(DEFAULT_FZ_DROP_MESSAGE));
    store.dispatch(changeIsOverFz(true));
  }

  async _handleDrop(e) {
    // start file upload task definition
    startFileUploadConverterTask();
    e.preventDefault();
    store.dispatch(changeFzMessage(DEFAULT_FZ_UPLOAD_MESSAGE));
    store.dispatch(changeFzCurFileNo(0));
    store.dispatch(changeIsOverFz(false));
    this.files = [];
    const audioFiles = Array.from(e.dataTransfer.files);
    while (audioFiles.length > 0) {
      let f = audioFiles.pop();
      this.files.push(f);
    }
    store.dispatch(changeFzTotalFileNo(this.files.length));
    store.dispatch(changeFzFileNames(this.files.map((f) => f.name)));
    store.dispatch(changeFzShowProgress(true));

    this._uploadFileHelper(this.files);
  }

  async _uploadFileHelper(audioFiles) {
    if (audioFiles.length) {
      for (const file of audioFiles) {
        let fileName = file.name;
        let format = file.type;
        const track = new FormData();
        track.append('name', fileName);
        track.append('format', format);
        track.append('track', file);
        try {
          await processTrack(track);
        } catch (error) {
          store.dispatch(showNotification(error.response.data.message, true));
          store.dispatch(changeFzShowProgress(false));
        }
      }
    }
  }

  _handleDismsisTrackPopup(track) {
    store.dispatch(changeSelectedTrackForEdit(track));
    store.dispatch(changeShowDismissTrack(true));
  }

  _handleOpenDismissAllPopup() {
    store.dispatch(openDismissAllTracksPopup());
  }

  _handleCloseDismissAllPopup() {
    store.dispatch(closeDismissAllTracksPopup());
  }

  _handleDismissAllTracks() {
    store.dispatch(closeDismissAllTracksPopup());
    deleteAllTracksFromProcessedAudioTable().catch((error) => {
      store.dispatch(showNotification(error.response.data.message, true));
    });
  }

  _handleDismissTrack(trackId) {
    store.dispatch(changeShowDismissTrack(false));
    deleteTrackFromProcessedAudioTable(trackId).catch((error) => {
      store.dispatch(showNotification(error.response.data.message, true));
    });
  }

  _handleCancelDismissTrack() {
    store.dispatch(changeShowDismissTrack(false));
  }

  render() {
    const accentColor = '#39c9a6';
    const fileZoneStyle = {
      width: '100%',
      border: this.props.isOverFz ? `1px solid ${accentColor}` : '1px solid black',
      color: this.props.isOverFz ? '#fff' : 'black',
      background: this.props.isOverFz ? accentColor : 'rgba(0,0,0,0)',
      textAlign: 'center',
      margin: 'auto',
    };

    return (
      <React.Fragment>
        <ActionBar cssColClass="text-align-center">
          <div style={fileZoneStyle}>
            <p style={{ marginTop: '14px', fontSize: 20 }}>
              {this.props.fzMessage}
              <span
                style={{
                  color: accentColor,
                  display: this.props.fzShowProgress ? 'inline-block' : 'none',
                }}
              >
                {' '}
                &nbsp; {this.props.fzFileNames[this.props.fzCurFileNo]}{' '}
              </span>
            </p>
            <p
              style={{
                fontSize: '17px',
                display: this.props.fzShowProgress ? 'block' : 'none',
              }}
            >
              (Track <span style={{ color: accentColor }}>{this.props.fzCurFileNo + 1}</span> out of{' '}
              <span style={{ color: accentColor }}>{this.props.fzTotalFileNo}</span>)
            </p>
          </div>
        </ActionBar>
        <NotificationBubble
          message={this.props.notification.message}
          isError={this.props.notification.isError}
          isVisible={this.props.notification.isVisible}
          isSticky={this.props.notification.isSticky}
          hideAfter={this.props.notification.hideAfter}
          handleHideNotification={this._handleHideNotification}
        />
        <MainContainer>
          <TrackUploaderList
            existingTracks={this.props.existingTracks}
            handleEditTrackPopup={this._handleEditTrackPopup}
            handleDismsisTrackPopup={this._handleDismsisTrackPopup}
            handleOpenDismissAllPopup={this._handleOpenDismissAllPopup}
          />
        </MainContainer>
        {this.props.showDismissTrack ? (
          <Modal flexSpacing="flex-start" show={this.props.showDismissTrack} handleDismiss={this._handleCancelEditTrack}>
            <TrackDismissPopup
              selectedTrack={this.props.selectedTrackForEdit}
              handleCancel={this._handleCancelDismissTrack}
              handleSave={this._handleDismissTrack}
            />
          </Modal>
        ) : null}
        {this.props.showDismissAllPopup ? (
          <Modal flexSpacing="flex-start" show={this.props.showDismissAllPopup} handleDismiss={this._handleCancelEditTrack}>
            <TrackDismissAllPopup
              isVisible={this.props.showDismissAllPopup}
              handleCancel={this._handleCloseDismissAllPopup}
              handleDismiss={this._handleDismissAllTracks}
            />
          </Modal>
        ) : null}
      </React.Fragment>
    );
  }
}

const storeToProps = (store) => {
  return {
    userToken: store.authState.userInfo ? store.authState.userInfo.userToken : '',
    fzMessage: store.trackUploaderState.fzMessage,
    fzCurFileNo: store.trackUploaderState.fzCurFileNo,
    fzTotalFileNo: store.trackUploaderState.fzTotalFileNo,
    fzShowProgress: store.trackUploaderState.fzShowProgress,
    isOverFz: store.trackUploaderState.isOverFz,
    fzFileNames: store.trackUploaderState.fzFileNames,
    existingTracks: store.trackUploaderState.existingTracks,
    showEditTrack: store.trackUploaderState.showEditTrack,
    showDismissTrack: store.trackUploaderState.showDismissTrack,
    selectedTrackForEdit: store.trackUploaderState.selectedTrackForEdit,
    showDismissAllPopup: store.trackUploaderState.showDismissAllPopup,
    joke: store.layoutState.joke ? store.layoutState.joke : '',
    notification: store.layoutState.notification,
    filesToConfirm: store.trackUploaderState.filesToConfirm,
  };
};

export default connect(storeToProps)(TrackUploaderMain);
