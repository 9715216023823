import * as types from './../actions/types';
import { AVAILABLE_AREAS, MAX_ALLOWED_BATCH_SIZE } from '../config';

const initalState = {
  ambieDevicePrefix: 'ambieBridgeV2-20160903-',
  batches: [],
  availablePlayers: [],
  //selectedPlayers : [],
  isPapertrailOpen: false,
  papertrailDevice: ''
};

const updaterReducer = (state = initalState, action) => {

  let selectedPlayers = Object.assign([], state.selectedPlayers)
    , availablePlayers = Object.assign([], state.availablePlayers)
    , batches = Object.assign([], state.batches);

  switch (action.type) {

    case types.SAVE_BATCHES:
      localStorage.setItem("BATCHES", JSON.stringify(batches));
      return state;

    case types.LOAD_BATCHES:
      let fromLocalHost = JSON.parse(localStorage.getItem("BATCHES")) || {};
      return Object.assign({}, state, {
        batches: fromLocalHost
      });

    case types.CHANGE_BATCHES:
      return Object.assign({}, state, {
        batches: action.payload
      });

    case types.GET_AVAILABLE_PLAYER:
      return Object.assign({}, state, {
        availablePlayers: action.payload
      });

    case types.UPDATE_BATCH_DEVICES:
      //debugger;
      console.log('UPDATE_BATCH_DEVICES - action.payload', action.payload);
      let newDevices = availablePlayers.filter(P => {
        return action.payload.devices.indexOf(P.player_id) !== -1
      });
      console.log('newDevices', newDevices);
      batches[action.payload.batch_id].selectedPlayers = newDevices;
      return Object.assign({}, state, {
        batches: batches
      });

    case types.ADD_NEW_BATCH:
      //debugger;
      batches.push({
        name: "Batch " + batches.length,
        selectedPlayers: [],
        status: 'NOT_STARTED'
      });
      return Object.assign({}, state, {
        batches: batches
      });

    case types.UPDATE_BATCH_NAME:
      batches[action.payload.batch_id].name = action.payload.name;
      return Object.assign({}, state, {
        batches: batches
      });

    case types.MARK_BATCH_DONE:
      batches[action.payload].status = 'DONE';
      return Object.assign({}, state, {
        batches: batches
      });

    case types.MARK_BATCH_IN_PROGRESS:
      batches[action.payload].status = 'IN_PROGRESS';
      return Object.assign({}, state, {
        batches: batches
      });

    case types.MARK_BATCH_ERRORED:
      batches[action.payload].status = 'ERROR';
      return Object.assign({}, state, {
        batches: batches
      });

  }

  return state;

};

export default updaterReducer;
