import * as authActions from '@actions/auth-actions';
import * as commercialRulesActions from '@actions/commercial-rules-actions';
import { hideSpinner, showNotification, showSpinner } from '@actions/layout-actions';
import * as locationActions from '@actions/locations-actions';
import * as authApi from '@api/auth-api';
import { GENERAL_REQUEST_ERROR_MESSAGE, GENERAL_SUCCESS_MESSAGE, HOST_CF, PORT, PREFIX, HOST_ROCK_API } from '@config';
import store from '@store';
import axios from 'axios';
import axiosInterceptors from '../utils/interceptors';

const isRuleActive = (rules) => {
  let isActive = false;
  const now = Date.now();

  for (let rule of rules) {
    const { startDate, endDate } = rule;
    if (startDate && endDate) {
      const start = new Date(startDate.toString().length === 10 ? startDate * 1000 : startDate);
      const end = new Date(endDate.toString().length === 10 ? endDate * 1000 : endDate);
      if (start < now && now < end) {
        isActive = true;
        break;
      }
    }
  }
  return isActive;
};

export const getCommercialRules = async (userToken, type = 'all', rpp, page, query) => {
  store.dispatch(showSpinner());
  const paramsData = {
    filter: query || null,
    itemsPerPage: rpp || 50,
    pageNumber: page || 1,
    type,
  };
  try {
    const response = await axiosInterceptors.get(HOST_ROCK_API + `/clients/commercial/rules`, { ...paramsData });

    _detectUnauthorized(response);
    store.dispatch(hideSpinner());

    if (!response.data.error) {
      const activeRules = response.data.rules.filter((injection) => isRuleActive(injection.rule));
      const inactiveRules = response.data.rules.filter((injection) => !isRuleActive(injection.rule));
      switch (type) {
        case 'area':
          store.dispatch(
            commercialRulesActions.changeAreaCommercialRules({
              active: activeRules,
              inactive: inactiveRules,
            }),
          );
          break;
        case 'client':
          store.dispatch(
            commercialRulesActions.changeClientCommercialRules({
              active: activeRules,
              inactive: inactiveRules,
            }),
          );
          break;
        case 'playlist':
          store.dispatch(
            commercialRulesActions.changePlaylistCommercialRules({
              active: activeRules,
              inactive: inactiveRules,
            }),
          );
          break;
        case 'all':
          store.dispatch(
            commercialRulesActions.changeAllCommercialRules({
              active: activeRules,
              inactive: inactiveRules,
            }),
          );
          break;
      }

      return response.data;
    } else {
      throw new Error(response.data.error);
    }
  } catch (e) {
    store.dispatch(hideSpinner());
    store.dispatch(showNotification(GENERAL_REQUEST_ERROR_MESSAGE, true));
    _handleUnauthorized(e);
    console.error('Error: ', e);
  }
};

export const postCommercialRule = async (userToken, id, type, rule) => {
  const headers = { 'x-ambie-token': userToken };
  try {
    const response = await axios.put(`${HOST_CF}/commercial-rules`, { id, type, rule }, { headers });
    _detectUnauthorized(response);
    if (!response.data.error) {
      if (type !== 'playlist') {
        if (type === 'area') {
          store.dispatch(locationActions.updateAreaRule(rule, id));
        } else {
          store.dispatch(authActions.updateClientRule(rule, id));
        }
        store.dispatch(showNotification(GENERAL_SUCCESS_MESSAGE, true));
        store.dispatch(commercialRulesActions.closeCommercialRulesModal());
      }
    } else {
      throw new Error(response.data.error);
    }
  } catch (e) {
    store.dispatch(hideSpinner());
    store.dispatch(showNotification(GENERAL_REQUEST_ERROR_MESSAGE, true));
    _handleUnauthorized(e);
    console.error('Error: ', e);
  }
};

const _handleUnauthorized = (error) => {
  if (error === 'Unauthorized') {
    authApi.logout();
  }
};

const _detectUnauthorized = (response) => {
  if (response.data.error && response.data.error == 'Unauthorized') {
    throw new Error(response.data.error);
  }
};
