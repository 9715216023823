import axios from 'axios';
import store from '../store';
import * as authApi from './auth-api';

import { changeJoke, requestingAJoke } from '../actions/layout-actions';

export const getAjoke = () => {

  return axios.get('https://icanhazdadjoke.com/', {
      headers: { 'Accept': 'application/json' },
      validateStatus: status => authApi.handleUnauthorized(status)
    })
    .then(response => {
      store.dispatch(changeJoke(response.data.joke));
    })
    .catch(response => {
    	console.error("Error loading joke, not funny bro",response)
    });

};
