import React, {Component} from 'react';

import { OLD_ADMIN_URL } from '../../config';

export default () => {
    return (
      <div>
        <div id="ambie-header" className="col-md-12 text-center default-header animated delay02s fadeInDown">Old Admin</div>
        <div id="ambie-body">
          <iframe style={{ width: '100%', minHeight: '400px'}} src={OLD_ADMIN_URL}></iframe>
        </div>
      </div>
    );
};