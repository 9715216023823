import * as types from '../actions/types';

const locationsState = {
  locations: [],
  areas: [],
  searchQuery: '',
  showChangeScheduleDialog: false,
  areaScheduleInfo: {
    areaName: '',
    areaId: '',
    scheduleName: '',
    scheduleId: ''
  },
  activationModal: {
    callback: {},
    message: 'You happy now?',
    isVisible: false,
    showButtons: true
  }
};

export default (state = locationsState, action) => {

  let localLocations = state.locations;

  switch (action.type) {

    case types.LOCATION_BUSINESS_NAME_UPDATED: {
      let localLocationsArea = state.locations.map(location => {
        if (action.payload.businessId === location.id) {
          location.name = action.payload.newName
        }
        return location;
      });
      return Object.assign({}, state, {
        locations: localLocationsArea
      });
    }

    case types.LOCATION_AREA_NAME_UPDATED: {
      let localLocationsArea = state.locations.map(location => {
        location.areas === location.areas.map(A => {
          let area = A;
          if (area.area_id === action.payload.areaId) {
            area.name = action.payload.newName;
          }
          return area
        })
        return location;
      });
      return Object.assign({}, state, {
        locations: localLocationsArea
      });
    }

    case types.HIDE_ACTIVATION_MODAL:
      return Object.assign({}, state, {
        activationModal: {
          callback: state.activationModal.callback,
          message: state.activationModal.message,
          isVisible: false,
          showButtons: state.activationModal.hideButtons
        }
      });

    case types.SHOW_ACTIVATION_MODAL:
      return Object.assign({}, state, {
        activationModal: {
          callback: state.activationModal.callback,
          message: state.activationModal.message,
          isVisible: true,
          showButtons: state.activationModal.hideButtons
        }
      });

    case types.UPDATE_ACTIVATION_MODAL:
      return Object.assign({}, state, {
        activationModal: {
          callback: action.payload.callback,
          message: action.payload.message,
          isVisible: true,
          showButtons: action.payload.showButtons
        }
      });

    case types.HIDE_ACTIVATION_MODAL_BUTTONS:
      return Object.assign({}, state, {
        activationModal: {
          callback: state.activationModal.callback,
          message: state.activationModal.message,
          isVisible: state.activationModal.isVisible,
          showButtons: false
        }
      });

    case types.UPDATE_AREA_ACTIVE_STATE:
      let localLocationsArea = state.locations.map(location => {
        //debugger
        location.areas === location.areas.map(A => {
          //debugger
          let area = A;
          if (area.area_id === action.payload.areaId) {
            area.is_active = action.payload.newActiveState;
          }
          return area
        })
        return location;
      });
      return Object.assign({}, state, {
        locations: localLocationsArea
      });

    case types.UPDATE_LOCATION_ACTIVE_STATE:
      let localLocations = state.locations.map(location => {
        //debugger;
        if (location.id === action.payload.locationId) {
          location.is_active = action.payload.newActiveState;
          location.areas = location.areas.map(A => {
            let area = A;
            area.is_active = action.payload.newActiveState;
            return area
          })
        }
        return location;
      });
      return Object.assign({}, state, {
        locations: localLocations
      });

    case types.CHANGE_LOCATIONS_SEARCH_QUERY:
      return Object.assign({}, state, {
        searchQuery: action.payload
      });
    case types.CHANGE_LOCATIONS:
      return Object.assign({}, state, {
        locations: action.payload
      });
    case types.CHANGE_AREAS:
      return Object.assign({}, state, {
        areas: action.payload
      });
    case types.CHANGE_SHOW_CHANGE_SCHEDULE_DIALOG:
      //console.log('action.payload', action.payload)
      return Object.assign({}, state, {
        showChangeScheduleDialog: action.payload
      });
    case types.CHANGE_AREA_SCHEDULE_INFO:
      //console.log('action.payload', action.payload)
      return Object.assign({}, state, {
        areaScheduleInfo: action.payload
      });

    case types.UPDATE_AREA_COMMERCIAL_RULE:
      let ll = Object.assign([], state.locations);
      ll.forEach(location => {
        let area = location.areas.filter(A => {
          return A.area_id === parseInt(action.payload.areaId)
        });
        if (area.length === 1) {
          area[0].commercialRule = action.payload.rule
        }
      });
      return Object.assign({}, state, {
        locations: ll
      });

    case types.CHANGE_LOCATION_TIMEZONE: {
      const locations = state.locations.map(location => {
        if (location.id === action.payload.locationId) {
          location.timezone = action.payload.timezone;
        }
        return location;
      });
      return Object.assign({}, state, {
        locations
      });
    }
    case types.CHANGE_LOCATION_EMAIL: {
      const locations = [...state.locations];
      const location = locations.find(location => location.id === action.payload.id);
      if (location) {
        location.email = action.payload.email;
      }
      return {
        ...state,
        locations,
      }
    }
    case types.UPDATE_AREA_STREAMING_STATE: {
      const locations = state.locations.map(location => {
        location.areas === location.areas.map(area => {
          if (area.area_id === action.payload.areaId) {
            area.streaming = area.streaming || {};
            area.streaming.enabled = action.payload.isEnabled;
          }
          return area
        })
        return location;
      });
      return {
        ...state,
        locations,
      };
    }

    case types.UPDATE_DEVICE_DONTSKIPDUPLICATES_STATE: {
      const locations = state.locations.map(location => {
        location.areas === location.areas.map(area => {
          if (area.area_id === action.payload.areaId) {
            area.device.dont_skip_duplicates = area.device.dont_skip_duplicates || false;
            area.device.dont_skip_duplicates = action.payload.isEnabled;
          }
          return area
        })
        return location;
      });
      return {
        ...state,
        locations,
      };
    }
    case types.CHANGE_SELECTED_OVERRIDE_PLAYLIST:
      return {
        ...state,
        selectedOverridePlaylist: action.payload,
      }
    case types.CHANGE_SELECTED_OVERRIDE_DURATION:
      return {
        ...state,
        selectedOverrideDuration: action.payload,
      }

  }
  return state;
};
