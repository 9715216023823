import React, { Component } from 'react';

import Table from '../../common/table';
import * as CONSTANTS from './track-repeats.constants';

export default (props) => {

  let tracksList;
  const noDataMessage = !props.tracks ? props.message : 'No results for the given range';

  if (props.groupByRepeats) {
    tracksList = !props.tracks ? null : props.tracks.map((track, index) => {
      return {
        class: track.count > 1 ? 'bg-danger result-item' : 'result-item',
        onClick: props.handleDetails.bind(this, index),
        isSelected: false,
        isSelectedClass: 'row-highlight',
        data: [
          track.artist,
          track.title,
          track.count,
        ]
      }
    });
  } else {
    tracksList = !props.tracks ? null : props.tracks
    /*.sort((a,b)=> {
      return parseInt(a.date) - parseInt(b.date)
    })*/.map((track, index) => {
      return {
        class: 'result-item',
        isSelected: false,
        isSelectedClass: 'row-highlight',
        data: [
          track.artist,
          track.title,
          track.playlist,
          track.date,
          track.seconds_played,
          track.duration,
          track.streaming ? 'Web Player' : 'Ambie Player',
        ]
      }
    });
  }
  console.log('RENDER TABLE');
  return (
    <Table
      csv
      csvName="track-plays"
      searchbar
      borders
      headings={props.groupByRepeats ? CONSTANTS.TABLE_HEADINGS_GROUPED : CONSTANTS.TABLE_HEADINGS_NOT_GROUPED}
      rowData={tracksList}
      noDataMessage={noDataMessage}
      sortBy={3}
      sortByAscending={false}
    />
  );

};
