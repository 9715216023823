import * as types from './types';

export const accountLoaded = (payload) => {
  return {
    type: types.SF_ACCOUNT_LOADED,
    payload: payload
  }
}

export const updateAccountInfo = (fieldToUpdate,newValue) => {
  return {
    type: types.SF_UPDATE_ACCOUNT_INFO,
    payload: {
      field:fieldToUpdate, 
      value:newValue
    }
  }
}

export const updateBusinessInfo = (fieldToUpdate, newValue, salesforceId) => {
  return {
    type: types.SF_UPDATE_BUSINESS_INFO,
    payload: {
      field : fieldToUpdate, 
      value : newValue,
      salesforceId : salesforceId
    }
  }
}

export const updateDeviceInfo = (fieldToUpdate, newValue, salesforceId, isChildDevice) => {
  return {
    type: isChildDevice?types.SF_UPDATE_CHILD_DEVICE_INFO:types.SF_UPDATE_DEVICE_INFO,
    payload: {
      field : fieldToUpdate, 
      value : newValue,
      salesforceId : salesforceId
    }
  }
}

export const updateAreaInfo = (fieldToUpdate, newValue, salesforceId) => {
  return {
    type: types.SF_UPDATE_AREA_INFO,
    payload: {
      field : fieldToUpdate, 
      value : newValue,
      salesforceId : salesforceId
    }
  }
}

export const savingAccount = () => {
  return {
    type: types.SF_ACCOUNT_SAVING
  }
}

export const accountSaved = () => {
  return {
    type: types.SF_ACCOUNT_SAVED,
    payload: {
      field : fieldToUpdate, 
      value : newValue,
      salesforceId : salesforceId
    }
  }
}

export const accountsCreatedOnOlympus = () => {
  return {
    type: types.SF_ACCOUNT_CREATED_ON_OLYMPUS,
    payload: []
  }
}

export const accountsCreationError = (error) => {
  return {
    type: types.SF_ACCOUNT_CREATION_ERROR,
    payload: error
  }
}

export const selectClient = (client) => {
  return {
    type: types.SF_SELECT_CLIENT,
    payload: client
  }
}

export const notAParentAccount = () => {
  return {
    type: types.SF_NOT_A_PARENT_ACCOUNT
  }
}

export const deviceLoaded = (device) => {
  return {
    type: types.SF_DEVICE_LOADED,
    payload: device
  }
}

export const savingDevice = (device) => {
  return {
    type: types.SF_SAVING_DEVICE,
    payload: device
  }
}

export const deviceSaved = (device) => {
  return {
    type: types.SF_DEVICE_SAVED,
    payload: device
  }
}

export const changeFormType = (thing,formType,id) => {
  return {
    type: types.SF_CHANGE_FORM_TYPE,
    payload: {
      thing,
      formType,
      id,
    }
  }
}

export const changeLinkedClient = (client) => {
  return {
    type: types.SF_CHANGE_LINKED_CLIENT,
    payload: client
  }
}

export const confirmNewClient = () => {
  return {
    type: types.SF_CONFIRM_NEW_CLIENT,
  }
}

export const changeLinkedBusiness = (business) => {
  return {
    type: types.SF_CHANGE_LINKED_BUSINESS,
    payload: business,
  }
}

export const changeLinkedArea = (area) => {
  return {
    type: types.SF_CHANGE_LINKED_AREA,
    payload: area
  }
}

export const confirmAreaProp = (areaId) => {
  return {
    type: types.SF_CONFIRM_AREA,
    payload : areaId
  }
}

export const confirmBusinessProp = (areaId) => {
  return {
    type: types.SF_CONFIRM_BUSINESS,
    payload : areaId
  }
}
