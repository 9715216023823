import * as React from 'react';
import { CSSTransition } from 'react-transition-group';
import { FaExclamation, FaCheck, FaInfo } from 'react-icons/fa';
import store from '@store';
import { hideFlashMessage } from '@actions/layout-actions';
import '@stylesheets/flash-message.scss';
import { STATIC_ASSET_ROOT } from '@app/config';

class FlashMessage extends React.Component {

  constructor(props) {
    super(props);
    this.icons = {
      loading: <img src={`${STATIC_ASSET_ROOT}/images/loading.svg`} width="100" alt="Loading" />,
      info: <FaInfo color="yellow" size={20} />,
      success: <FaCheck color="green" size={25} />,
      failed: <FaExclamation color="red" size={25} />,
    };
  }
  
  get icon() {
    return this.icons[this.props.status] || this.icons.failed;
  }

  dismiss = () => store.dispatch(hideFlashMessage());

  render() {
    return (
      <CSSTransition
        in={this.props.show}
        timeout={this.props.duration || 3000}
        classNames={this.props.animationClass || 'fade-in'}
        onEntered={() => this.props.dismiss ? this.props.dismiss() : this.dismiss()}
        unmountOnExit
      >
        <div
          style={{'--width': this.props.innerWidth || `${document.documentElement.clientWidth}px`}}
          className="flash-message-box"
        >
          <div className="flash-message-content">
            {this.icon}
            <div className="flash-message-messages">
              <p className="flash-message-message margin-none">{this.props.message}</p>
              {this.props.subMessage ? <p className="flash-message-message">{this.props.subMessage}</p> : null}
            </div>
          </div>
        </div>
      </CSSTransition>
    );
  }
}

export default FlashMessage;
