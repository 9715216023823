import * as types from '@actions/types';

const initalState = {
  tracks: [],
  artists: [],
  categories: [],
  isSearchingTracks: false,
};

export default (state = initalState, action) => {
  switch (action.type) {
    case types.CHANGE_ALL_TRACKS_LIST:
      return {
        ...state,
        tracks: action.payload,
      };
    case types.CHANGE_ALL_ARTISTS_LIST:
      return {
        ...state,
        artists: action.payload,
      };
    case types.CHANGE_ALL_CATEGORIES_LIST:
      return {
        ...state,
        categories: action.payload,
      };
    case types.CHANGE_IS_SEARCHING_TRACKS:
      return {
        ...state,
        isSearchingTracks: action.payload,
      };
    case types.TRACK_TITLE_SAVED:
      {
        let currentTracks = Object.assign([], state.tracks)
        let currentTrack = currentTracks.filter(T => T.id === action.payload)
        currentTrack[0].editing = false
        return {
          ...state,
          tracks: currentTracks
        };
      }
    case types.CLOSE_EDIT_TRACKS_TITLE:
      {
        let currentTracks = Object.assign([], state.tracks)
        let currentTrack = currentTracks.filter(T => T.id === action.payload.id)
        currentTrack[0].editing = false
        currentTrack[0].title = currentTrack[0].originalTitle;
        return {
          ...state,
          tracks: currentTracks
        };
      }
    case types.OPEN_EDIT_TRACKS_TITLE:
      {
        let currentTracks = Object.assign([], state.tracks)
        let currentTrack = currentTracks.filter(T => T.id === action.payload.id)
        currentTrack[0].editing = true
        currentTrack[0].originalTitle = currentTrack[0].title
        return {
          ...state,
          tracks: currentTracks
        };
      }
    case types.UPDATE_TRACKS_TITLE:
      {
        let currentTracks = Object.assign([], state.tracks)
        let currentTrack = currentTracks.filter(T => T.id === action.payload.trackId)
        currentTrack[0].title = action.payload.title
        return {
          ...state,
          tracks: currentTracks
        };
      }
    case types.CLEAR_TRACKS:
      {
        return {
          ...state,
          tracks: []
        };
      }
    default:
      return state;
  }
};
