import * as React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

import MainLayout from './components/layout/main-layout';
import Login from './components/auth/login';

import MainLayout2 from './components/layout_2/main-layout';
import ForgotPassword from './components/auth/forgot-password';
import SetPassword from './components/auth/set-password';

import store from './store';

const _checkIfLoggedIn = (nextState, replace) => {
  return !!store.getState().authState.userInfo.userToken;
};

export default (
  <Switch>
    <Redirect exact from="/" to="locations" />
    <Route path="/forgot-password" render={(props) => (_checkIfLoggedIn() ? <Redirect to="/locations" /> : <ForgotPassword {...props} />)} />
    <Route path="/reset-password" render={(props) => (_checkIfLoggedIn() ? <Redirect to="/locations" /> : <SetPassword {...props} />)} />
    <Route exact path="/login" component={Login} />
    <Route path="/" render={() => (_checkIfLoggedIn() ? <MainLayout2 /> : <Redirect to="/login" />)} />
    {/* <Route path="/" render={() => _checkIfLoggedIn() ? <MainLayout /> : <Redirect to="/login" />} /> */}
  </Switch>
);
