import * as types from '../actions/types';

const initalState = {
  totalResults: null,
  queryHash: -1,
  query: '',
  artists: null,
  isSearching: false,
  page: 1,
  itemsCountPerPage : 50,
  isArtistModalOpen: false,
};

export default (state = initalState, action) => {
  switch (action.type) {
    case types.ARTIST_OPEN_MODAL: {
      return Object.assign({}, state, {
        isSearching: false,
        isArtistModalOpen: true,
      });
    }
    case types.ARTIST_CLOSE_MODAL: {
      return Object.assign({}, state, {
        isSearching: false,
        isArtistModalOpen: false,
      });
    }
    case types.ARTIST_START_SEARCHING: {
      return Object.assign({}, state, {
        isSearching: true,
      });
    }
    case types.ARTISTS_UPDATED: {
      let localResults = [];
      localResults = state.artists;
      localResults.forEach(artistsList => {
        let artists = artistsList.filter(A => A.id === action.payload.artistId);
        if (artists.length === 1) {
          artists[0].name = action.payload.artistName
        }
      })
      return Object.assign({}, state, {
        isSearching: false,
        artists: Object.assign([], localResults),
        isArtistModalOpen: false,
      });
    }
    case types.ARTISTS_CREATED: {
      let localResults = [];
      localResults = state.artists;
      localResults[state.page].unshift({
        id: action.payload.artistId,
        name: action.payload.artistName,
      })
      return Object.assign({}, state, {
        artists: Object.assign([], localResults),
        isSearching: false,
        isArtistModalOpen: false,
      });
    }
    case types.ARTISTS_DELETED: {
      let localResults = [];
      localResults = state.artists;
      const artistRemovedIdx = localResults[state.page].findIndex(A => A.id === action.payload.artistId);
      localResults[state.page].splice(artistRemovedIdx, 1);
      return Object.assign({}, state, {
        isSearching: false,
        isArtistModalOpen: false,
        artists: Object.assign([], localResults),
      });
    }
    case types.ARTISTS_GOT_NEW: {
      if (action.payload.forceRefresh || state.queryHash !== action.payload.queryHash) {
        let localResults = [];
        localResults[parseInt(action.payload.page)] = action.payload.artists;
        return Object.assign({}, state, {
          isSearching: false,
          totalResults: action.payload.totalResults,
          artists: localResults,
          queryHash: action.payload.queryHash,
          page: action.payload.page,
          query: action.payload.query,
          itemsCountPerPage: action.payload.itemsCountPerPage,
        });
      } else {
        let localResults = state.artists;
        localResults[parseInt(action.payload.page)] = action.payload.artists;
        return Object.assign({}, state, {
          isSearching: false,
          artists: localResults,
          page: action.payload.page,
        });
      }
    }
  }
  return state;
};