import React from 'react';

import PropTypes from 'prop-types';

import {
  FaForward,
  FaExchangeAlt,
} from 'react-icons/fa';

const SkipPanel = props => (
  <div className="flex skip-panel">
    <button
      type="button"
      className="btn btn-clear flex-center-center mini-player__button --skip"
      onClick={() => props.handleSkip(props.deviceSerial)}
    >
      <span>Skip</span>
      <FaForward size={20} color="white" className="margin-left-xs" />
    </button>
    <button className="btn btn-clear flex-center-center mini-player__button --override" onClick={props.handleToggleOverrideModal}>
      Override Playlist
      <FaExchangeAlt size={20} color="white" className="margin-left-xs" />
    </button>
  </div>
);

SkipPanel.propTypes = {
  skipCount: PropTypes.number,
  setSkipCount: PropTypes.func,
  handleChangeChannel: PropTypes.func
};

export default SkipPanel;
