import * as React from 'react';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import autoBind from 'react-autobind';

import store from '@store';
import { togglePasswordVisible, setShakeAnimationOff } from '@actions/login-actions';
import { changeContainerHeight, changeForgot } from '@actions/layout-actions';
import * as authApi from '@api/auth-api';

class Login extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      username: '',
      password: '',
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this._handleResize);
    document.getElementById('login-box').addEventListener('animationend', this._handleAnimationEnd);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._handleResize);
    document.getElementById('login-box').removeEventListener('animationend', this._handleAnimationEnd);
  }

  _handlePasswordToggle() {
    store.dispatch(togglePasswordVisible());
  }

  _handleLogin() {
    const username = this.state.username;
    const password = this.state.password;
    const credentials = { username, password };
    authApi.login(credentials);
  }

  _handleResize() {
    store.dispatch(changeContainerHeight(window.innerHeight));
  }

  _handleAnimationEnd() {
    store.dispatch(setShakeAnimationOff());
  }

  _handleKeyPress(e) {
    if (e.charCode === 13) {
      this._handleLogin();
    }
  }

  handleUsernameInputChange = (e) => {
    const username = e.target.value;
    this.setState({
      ...this.state,
      username,
    });
  };

  handlePasswordInputChange = (e) => {
    const password = e.target.value;
    this.setState({
      ...this.state,
      password,
    });
  };

  render() {
    let containerStyle = { height: this.props.containerHeight };
    let paswordFieldType = this.props.passwordIsVisible ? 'text' : 'password';
    let passwordToggleMessage = this.props.passwordIsVisible ? 'HIDE PASSWORD' : 'SHOW PASSWORD';
    let loginFormFieldValidClass = this.props.isLoginValid ? '' : 'login-input-invalid';
    let loginBoxClass = 'col-lg-12';
    loginBoxClass += this.props.showShakeAnimation ? ' shake-input-box' : '';
    let title;
    if (IS_OLYMPUS) {
      title = 'Ambie Olympus';
    }
    if (IS_SUPPORT_PORTAL) {
      title = (
        <div>
          ASP <br /> <h5>Ambie Support Portal</h5>
        </div>
      );
    }
    return (
      <div id="login-wrapper" style={containerStyle} className="login-main-background col-md-12 col-sm-12 col-xs-12">
        <div className="login-container">
          <div id="login-box" className={loginBoxClass}>
            <div className="login-message">{title}</div>
            <div onKeyPress={this._handleKeyPress} className="login-inputs-wrapper">
              <div className="login-label">Username or Email</div>
              <input
                ref="userField"
                className={loginFormFieldValidClass}
                value={this.state.username}
                onChange={this.handleUsernameInputChange}
                id="username"
                autoCapitalize="none"
                type="text"
                placeholder="Type here"
              />
              <div className="login-label">Password</div>
              <input
                ref="passwordField"
                className={loginFormFieldValidClass}
                value={this.state.password}
                onChange={this.handlePasswordInputChange}
                id="password"
                autoCorrect="off"
                autoCapitalize="none"
                type={paswordFieldType}
                placeholder="Type here"
              />
              <label className="password-toggle">
                <input type="checkbox" id="show-password" className="checkbox-switch" onChange={this._handlePasswordToggle} />
                <label htmlFor="show-password" />
                {passwordToggleMessage}
              </label>
              <div className="forgot-link">
                <Link to="/forgot-password" href="/forgot-password">
                  Forgot password?
                </Link>
              </div>
              <button
                disabled={!this.state.username || !this.state.password}
                onClick={this._handleLogin}
                className="btn btn-blue btn-pill btn-block btn-upper btn-lg login-btn"
              >
                Login
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const storeToProps = (store) => {
  return {
    passwordIsVisible: store.loginState.passwordIsVisible,
    isLoginValid: store.loginState.isLoginValid,
    showShakeAnimation: store.loginState.showShakeAnimation,
    containerHeight: store.layoutState.containerHeight,
  };
};

export default connect(storeToProps)(Login);
