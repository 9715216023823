import * as types from './types';

export const changeLocationSerachQuery = (payload) => {
  return {
    type: types.CHANGE_LOCATIONS_SEARCH_QUERY,
    payload: payload
  };
}

export const changeLocations = (payload) => {
  return {
    type: types.CHANGE_LOCATIONS,
    payload: payload
  }
}

export const changeAreas = (payload) => {
  return {
    type: types.CHANGE_AREAS,
    payload: payload
  }
}

export const changeShowChangeScheduleDialog = (payload) => {
  return {
    type: types.CHANGE_SHOW_CHANGE_SCHEDULE_DIALOG,
    payload: payload
  }
}

export const changeAreaScheduleInfo = (payload) => {
  return {
    type: types.CHANGE_AREA_SCHEDULE_INFO,
    payload: payload
  }
}

export const changeLocationTimezone = (payload) => {
  return {
    type: types.CHANGE_LOCATION_TIMEZONE,
    payload: payload
  }
}

export const updateAreaRule = (rule, areaId) => {
  return {
    type: types.UPDATE_AREA_COMMERCIAL_RULE,
    payload: {
      rule,
      areaId,
    }
  }
};

export const showActivationModal = () => {
  return{
    type : types.SHOW_ACTIVATION_MODAL
  }
}

export const hideActivationModal = () => {
  return {
    type : types.HIDE_ACTIVATION_MODAL
  }
}

export const hideActivationModalButtons = () => {
  return {
    type : types.HIDE_ACTIVATION_MODAL_BUTTONS
  }
}

export const updateActivationModal = (message, showButtons, callback) => {
  return {
    type: types.UPDATE_ACTIVATION_MODAL,
    payload: {
      message : message,
      callback : callback,
      showButtons : showButtons
    }
  }
}

export const changeLocationActiveState = (locationId, newActiveState) => {
  return {
    type: types.UPDATE_LOCATION_ACTIVE_STATE,
    payload: {
      locationId : locationId,
      newActiveState : newActiveState
    }
  }
}

export const changeAreaActiveState = (areaId, newActiveState) => {
  return {
    type: types.UPDATE_AREA_ACTIVE_STATE,
    payload: {
      areaId : areaId,
      newActiveState : newActiveState
    }
  }
}

export const changeLocationEmail = (id, email) => ({
  type: types.CHANGE_LOCATION_EMAIL,
  payload: {
    id,
    email,
  }
});

export const updateAreaStreamingState = (areaId, isEnabled) => ({
  type: types.UPDATE_AREA_STREAMING_STATE,
  payload: {
    areaId,
    isEnabled,
  }
});

export const updateDeviceSkipDuplicateState = (areaId, deviceId, isEnabled) => ({
  type: types.UPDATE_DEVICE_DONTSKIPDUPLICATES_STATE,
  payload: {
    areaId,
    deviceId,
    isEnabled,
  }
});

export const changeSelectedOverridePlaylist = payload => ({
  type: types.CHANGE_SELECTED_OVERRIDE_PLAYLIST,
  payload,
});

export const changeSelectedOverrideDuration = payload => ({
  type: types.CHANGE_SELECTED_OVERRIDE_DURATION,
  payload,
});

export const businessNameUpdated = (clientId, businessId, newName) => ({
  type: types.LOCATION_BUSINESS_NAME_UPDATED,
  payload: {
    clientId,
    newName,
    businessId,
  },
});

export const areaNameUpdated = (clientId, businessId, areaId, newName) => ({
  type: types.LOCATION_AREA_NAME_UPDATED,
  payload: {
    clientId,
    newName,
    businessId,
    areaId,
  },
});