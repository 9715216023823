
import React from 'react';

const IconButton = ({ disabled, title, isActive, onClick, icon }) => (
  <button
    disabled={disabled}
    title={title}
    className={`location__button ${isActive ? '--active' : ' --inactive'}`}
    onClick={onClick}
  >
    {icon}
  </button>
);

export default IconButton;
