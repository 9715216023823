import store from '../store';
import { showNotification } from '../actions/layout-actions';
import { GENERAL_REQUEST_ERROR_MESSAGE, HOST_ROCK_API } from '../config';
import { changeNotUsedTracks } from '../actions/tracks-not-in-use-actions';
import axiosInterceptors from '../utils/interceptors';

/**
 * use rock-api endpoint
 */
export const getTracks = async (userToken, page, results_per_page, displayDeleted, filterLastMonth) => {
  const params = {
    itemsPerPage: results_per_page,
    pageNumber: page,
  };

  try {
    const response = await axiosInterceptors.get(HOST_ROCK_API + `/tracks/unused`, {
      ...params,
    });
    store.dispatch(changeNotUsedTracks(response.data));
  } catch (error) {
    store.dispatch(showNotification(GENERAL_REQUEST_ERROR_MESSAGE, true));
  }
};
