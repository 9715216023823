import React, { Component } from 'react';

export default (props) => {
  return (
    <React.Fragment>
      <p>Are you sure you want to dismiss {props.selectedTrack.name} from this admin interface ?</p>
      <React.Fragment>
        <button type="danger" className="btn btn-danger btn-sm" onClick={props.handleCancel}>
          Cancel
        </button>
        <button type="danger" className="btn btn-success btn-sm" onClick={() => props.handleSave(props.selectedTrack.id)}>
          Dismiss
        </button>
      </React.Fragment>
    </React.Fragment>
  );
};
