import * as React from 'react';
import { CgSpinner } from 'react-icons/cg';
import { FaCheckCircle, FaExclamationCircle } from 'react-icons/fa'

import Modal from '@layout_2/Modal/modal';
import { getParentAccountByName, startExpansionProcess } from '@api/sf-api';
import { isValidEmail } from '@app/input-validation'
import EmailRecipient from './partials/email-recipient';


const defaultState = {
  renderDuplicates: false,
  accountInfo: [],
  emailAddresses: [],
  emailText: "Placeholder",
  typedText: "Placeholder",
  links: "Placeholder",
  newMailAddress: '',
  sfInfoLoaded: false,
  error: null,
  sfAccountId: null,
  error: null,
  submitting: false,
  submitted: false,
  submitResult: null,
};
class SfExpansionPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = defaultState;
  }

  async componentDidUpdate(prevProps, prevState) {
    //To avoid fetching if conmponent is not showing
    if (prevProps.show !== this.props.show || prevProps.clientName !== this.props.clientName) {
      defaultState.typedText = `Hi,  As discussed, we are now starting the expansion process for ${this.props.clientName.name}.`;
      this.setState(defaultState);

      const accountInfo = await getParentAccountByName(this.props.clientName.name, this.props.token);
      if (accountInfo.result === "fail") {
        this.closeModal(3000);
        return this.setState({ error: accountInfo.error, sfInfoLoaded: true });
      }

      const { values, result, error } = accountInfo;

      if (result === "fail") {
        this.closeModal(3000);
        return this.setState({ error, sfInfoLoaded: true, emailText: "", links: "", emailAddresses: "" });
      }

      if (values.length > 1) {
        return this.setState({ accountInfo: values, sfInfoLoaded: true, renderDuplicates: true });
      }

      const { sfAccountId, emailText, typedText, links, emailAddresses } = this.generateFields(values[0]);;

      return this.setState({
        sfInfoLoaded: true,
        sfAccountId,
        accountInfo,
        emailAddresses,
        typedText,
        emailText,
        links
      });
    }
  }

  closeModal = (milliseconds) => {
    return setTimeout(() => {
      this.props.closeModal();
    }, milliseconds);
  }

  generateFields = (account) => {
    const emailAddresses = account.contacts.map(contact => contact.Email);
    const links = account.linkToAccountExpansionForm;
    const typedText = `Hi,  As discussed, we are now starting the expansion process for ${this.props.clientName.name}.`;
    const emailText = this.generateEmailText(typedText);
    return { sfAccountId: account.Id, typedText, emailText, links, emailAddresses }
  }

  generateEmailText = (text) => {
    const parsedText = text.split('  ');
    return (
      <div style={emailAreaStyle}>
        {parsedText.map((paragraph, idx) => <p key={idx}>{paragraph}</p>)}
        <p>Please complete the form in the attached link: <a href={this.state.links}>{this.state.links}</a></p>
        <p>If you have any questions, please contact <a href="mailto: support@ambie.fm">support@ambie.fm</a> </p>
        <p>Thanks,</p>
        <p>Team Ambie</p>
      </div>
    )
  }

  isValid = () => {
    const { emailText, emailAddresses, links } = this.state;
    let validated = true
    if (emailText.length < 20 || links.length < 10 || !emailAddresses.length) {
      validated = false;
    }

    for (const email of emailAddresses) {
      if (!isValidEmail(email)) {
        console.error('email not valid:', email);
        validated = false;
        break;
      }
    }
    return validated;
  }

  handleSubmit = async () => {
    this.setState({ submitting: true, sfInfoLoaded: false });
    const { sfAccountId, emailAddresses, typedText, links } = this.state;
    const submitResult = await startExpansionProcess(sfAccountId, emailAddresses, typedText, links, this.props.token);
    this.setState({ submitting: false, submitted: true, submitResult, sfInfoLoaded: true });
    this.closeModal(5000);
  }

  handleTextChange = (e) => this.setState({ [e.target.name]: e.target.value });

  deleteEmail = (index) => {
    const emailAddresses = [...this.state.emailAddresses];
    emailAddresses.splice(index, 1);
    return this.setState({ emailAddresses });
  };

  addEmail = (e) => {
    e.preventDefault();
    const emailAddresses = [...this.state.emailAddresses];
    emailAddresses.push(this.state.newMailAddress);
    return this.setState({ emailAddresses, newMailAddress: '' });
  };

  chooseAccount = (accountId) => {
    const account = this.state.accountInfo.filter(account => account.Id === accountId);
    const { emailText, links, emailAddresses } = this.generateFields(account[0]);
    return this.setState({ sfAccountId: accountId, renderDuplicates: false, emailText, links, emailAddresses })
  }

  //RENDER BLOCKS
  renderForm = () => {
    const { error, emailAddresses, typedText, emailText, links, newMailAddress } = this.state;
    if (error) return (
      <h4
        className="bg-danger"
        style={{ padding: "5px", margin: "7px", color: "white", borderRadius: "5px" }}
      >
        {this.state.error}
      </h4>
    );

    return (
      <form onSubmit={this.handleSubmit}>
        <div>
          <div style={{ display: "block" }} className="align-items-center">
            <input style={emailInput} type="text" name="newMailAddress" value={newMailAddress} onChange={this.handleTextChange} />
            <button
              className="btn btn-primary btn-lg mr-3 margin-xs"
              disabled={!newMailAddress}
              onClick={this.addEmail}
            >Add email
            </button>
          </div>
          <div className="align-items-center">
            <label style={label}>To:</label>
            <div className="flex-row-wrap" style={emailAddressContainer}>
              {emailAddresses.map((email, idx) =>
                <EmailRecipient key={idx} emailAddress={email} deleteEmail={() => this.deleteEmail(idx)} />)}
            </div>
          </div>
          <p style={{ textAlign: "left", margin: "7px 0 2px 50px" }}>Note: Add double space for a new paragraph</p>
          <div className="flex-row">
            <label style={label}>Text:</label>
            <textarea
              style={textAreaStyle}
              disabled={!this.state.sfInfoLoaded}
              name="typedText"
              value={typedText}
              onChange={this.handleTextChange}
            />
          </div >
          <div className="flex-row">
            <label style={label}>Link:</label>
            <input style={linkInput} disabled={!this.state.sfInfoLoaded} type="text" name="links" value={links} onChange={this.handleTextChange} />
          </div>
          <div className="flex-row">
            <label style={label}>Email:</label>
            {this.generateEmailText(typedText)}
          </div >
        </div >
        <input
          className="btn btn-primary btn-lg mr-3 margin-xs"
          disabled={!this.isValid()} type="submit" value="Send" />
      </form >
    )
  };

  renderDuplicates = () => (
    <div>
      <h4>More than one account</h4>
      <ol>
        {this.state.accountInfo.map((account, idx) => {
          return (
            <li key={idx} style={listItem} className="flex-center-between">
              <div>{account.Name}</div>
              <div>Acc: {account.Id}</div>
              <button
                className="btn btn-primary mr-2 margin-xs"
                onClick={() => this.chooseAccount(account.Id)}>
                Choose me
              </button>
            </li>
          )
        })}
      </ol>
    </div>
  );

  renderSubmission = () => {
    const { addressCorrectionResponse, networkCorrectionResponse, deleteSnapshotResponse,
      resetAccountResponse, mailingResponse, error, message } = this.state.submitResult;
    const messages = message ? message.split('/') : null;
    const errors = new Array(5).fill("Not processed");

    if (error) {
      if (error.includes('address Error')) errors[0] = error;
      if (error.includes('network Error')) errors[1] = error;
      if (error.includes('snapshot Error')) errors[2] = error;
      if (error.includes('reset Error')) errors[3] = error;
      if (error.includes('email Error')) errors[4] = error;
    }

    return (
      <div className="flex-column margin-top-md">
        <span className="align-items-center margin-bottom-md">
          {addressCorrectionResponse
            ? <FaCheckCircle style={checkIcon(true)} size={20} />
            : <FaExclamationCircle style={checkIcon(false)} size={20} />
          }
          <b style={{ marginRight: '5px' }}>Shipping address changes in Salesforce:</b>
          {addressCorrectionResponse ? messages[0] : errors[0]}
        </span>

        <span className="align-items-center margin-bottom-md">
          {networkCorrectionResponse
            ? <FaCheckCircle style={checkIcon(true)} size={20} />
            : <FaExclamationCircle style={checkIcon(false)} size={20} />
          }
          <b style={{ marginRight: '5px' }}>Network info changes in Salesforce:</b>
          {networkCorrectionResponse ? messages[1] : errors[1]}
        </span>

        <span className="align-items-center margin-bottom-md">
          {deleteSnapshotResponse
            ? <FaCheckCircle style={checkIcon(true)} size={20} />
            : <FaExclamationCircle style={checkIcon(false)} size={20} />
          }
          <b style={{ marginRight: '5px' }}>Account snapshot deletion from database:</b>
          {deleteSnapshotResponse ? messages[2] : errors[2]}
        </span>

        <span className="align-items-center margin-bottom-md">
          {resetAccountResponse
            ? <FaCheckCircle style={checkIcon(true)} size={20} />
            : <FaExclamationCircle style={checkIcon(false)} size={20} />
          }
          <b style={{ marginRight: '5px' }}>Expansion setup in salesforce:</b>
          {resetAccountResponse ? messages[3] : errors[3]}
        </span>

        <span className="align-items-center margin-bottom-md">
          {mailingResponse
            ? <FaCheckCircle style={checkIcon(true)} size={20} />
            : <FaExclamationCircle style={checkIcon(false)} size={20} />
          }
          <b style={{ marginRight: '5px' }}>Expansion emails:</b>
          {mailingResponse ? messages[4] : errors[4]}
        </span>
      </div>
    )
  }

  render() {
    const { renderDuplicates, sfInfoLoaded, submitting, submitted } = this.state;
    const clientName = this.props.clientName ? this.props.clientName.name : null
    return (
      <Modal
        show={this.props.show}
        handleDismiss={this.props.closeModal}
        width="800px"
        height="550px"
      >
        <section>
          <h1>{`Expansion for ${clientName}`}</h1>
          {!sfInfoLoaded &&
            <div className="flex-column-center-center">
              <CgSpinner size={100} className="spin" />
              {submitting
                ? <h2>Submitting data</h2>
                : <h2>Loading Salesforce data</h2>
              }
            </div>
          }

          {(!submitted && sfInfoLoaded && !renderDuplicates) && this.renderForm()}

          {(!submitted && sfInfoLoaded && renderDuplicates) && this.renderDuplicates()}

          {submitted && this.renderSubmission()}
        </section>
      </Modal >
    );
  }
}

const label = {
  marginTop: "7px",
  display: "inline-block",
  width: "50px"
}

const linkInput = {
  width: "100%",
  maxWidth: "610px",
  padding: "3px",
  margin: "4px 0 16px 0",
  border: "1px solid lightGrey",
  borderRadius: "4px",
}

const emailInput = {
  width: "400px",
  border: "1px solid lightGrey",
  borderRadius: "4px",
  padding: "4px",
  margin: "4px 4px 4px 45px"
}

const emailAddressContainer = {
  width: "100%",
  maxWidth: "610px",
  minHeight: "36px",
  border: "1px solid lightGrey",
  borderRadius: "4px",
  margin: "0 0 4px 0",
  padding: 0,
}

const textAreaStyle = {
  width: "100%",
  maxWidth: "610px",
  height: "50px",
  border: "1px solid lightGrey",
  borderRadius: "4px",
  margin: "4px 0 0 0",
  padding: "4px",
}

const emailAreaStyle = {
  textAlign: "left",
  width: "100%",
  maxWidth: "610px",
  height: "200px",
  border: "1px solid lightGrey",
  background: "rgba(195, 195, 195, 0.3)",
  borderRadius: "4px",
  margin: "4px 0 0 0",
  padding: "4px",
}

const listItem = {
  border: "1px solid lightGrey",
  borderRadius: "4px",
  paddingLeft: "4px",
  margin: "2px",
}

const checkIcon = (bool) => {
  return {
    color: bool ? 'green' : 'red',
    marginRight: '5px',
  }
}

export default SfExpansionPopup;
