export const TABLE_HEADINGS = [
  {
    width: 100,
    title: 'Type',
    sortable: true
  },
  {
    width: 100,
    title: 'Venue',
    sortable: true
  },
  {
    width: 100,
    title: 'Track',
    sortable: true
  },
  {
    width: 100,
    title: 'Artist',
    sortable: true
  },
  {
    width: 100,
    title: 'Playlist',
    sortable: true
  },
  {
    width: 100,
    title: 'Message',
    sortable: true
  },
  {
    width: 100,
    title: 'Date',
    date: true,
    sortable: true
  },
  {
    width: 100,
    title: 'Rule',
    sortable: false
  },
];

export const INPUT_IDS = {
  search: 'search',
  all: 'all',
  marked: 'marked',
  unmarked: 'unmarked',
  unapproved: 'unapproved',
}

export const NO_DATA_MSG = 'These aren\'t the droids you\'re looking for...';

export const TOOLTIP_MSG = `You can search for a date range using an asterix (*) between two dates.<br>
                            e.g. 1/9/2017 * 01/12/2017<br>
                            If the second date in the range is omitted (or if it's an incorrect date format), today's date will be used.<br>
                            e.g. 1/1/2017 *<br>
                            You can also search for any part of a date, such as the year.<br>
                            e.g. 2017`