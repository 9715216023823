import React, { Component, Fragment } from 'react';

import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-dates/lib/css/_datepicker.css';
//import 'react-datepicker/dist/react-datepicker.css'
import autoBind from 'react-autobind';
import { DateRangePicker, isInclusivelyBeforeDay, isInclusivelyAfterDay } from 'react-dates';
import moment from 'moment';
import { Typeahead } from 'react-bootstrap-typeahead';

import '@stylesheets/aphrodite/track-repeats.scss';
import '@stylesheets/aphrodite/react-datepicker.scss';
import * as trackRepeatsApi from '@api/track-repeats-api';

import store from '@app/store';
import { changeFilters } from '@actions/track-repeats-actions';

import DatePicker from 'react-datepicker';

class TrackRepeatsFilters extends Component {

  constructor() {
    super();
    autoBind(this);
    moment.locale('en-gb');
  }

  state = {
    focusedInput: null,
    startDate: null,
    endDate: null,
    endDate: null,
    device: null,
    groupByRepeats : false
  }

  componentWillUpdate(nextProps, nextState) {
    if (this.isSearchable(nextState) && this.state !== nextState) {
      //this._handleSearch({...nextState}); 
    }
  }

  componentDidUpdate(nextProps, nextState) {
    if (!nextState.startDate && nextState.focusedInput === 'endDate') {
      this._resetState('startDate');
    }
  }

  get isSearchDisabled() {
    if (!this.state.startDate || !this.state.endDate || !this.state.device) return true;
    return Math.abs(moment(this.state.startDate).diff(this.state.endDate, 'days')) >= 31;
  }

  _isNoResults() {
    return this.state.startDate && this.state.endDate && this.state.device && this.props.tracks.length === 0;
  }

  _onDatesChange({ startDate, endDate }) {
    this.setState({ startDate, endDate });
  }

  _onFocusChange(focusedInput) {
    if (this.state.startDate && this.state.endDate) {
      this._resetState('startDate');
    } else if (focusedInput === 'startDate') {
      this._resetState(focusedInput);
    } else {
      this.setState({ focusedInput });
    }
  }

  _resetState(focusedInput) {
    this.setState({
      focusedInput,
      startDate: null,
      endDate: null
    });
  }
  
  _handleSearch(filters) {
    trackRepeatsApi.getTracks(
      this.props.userToken,
      filters.device.device_id || filters.device,
      filters.startDate.toDate().getTime(),
      filters.endDate.toDate().getTime(),
      filters.groupByRepeats
    );
  }

  isSearchable(filters) {
    return filters.startDate && filters.endDate && filters.device;
  }

  _handeDeviceChange(devices) {
    this.setState({
      ...this.state,
      device: devices[0]
    })
  }

  _handleSearchClick() {
    this._handleSearch({...this.state});
  }

  isOutsideRange = (day) => {
    const today = moment().add(1, 'days');
    if (!this.state.startDate) {
      return isInclusivelyAfterDay(day, today);
    }
    const maximumDays = 7;
    const maximumEndDate = this.state.startDate.clone().add(maximumDays + 1, 'days');
    return isInclusivelyAfterDay(day, today) || isInclusivelyAfterDay(day, maximumEndDate) || isInclusivelyBeforeDay(day, this.state.startDate);
  }

  handleChangeStart(startDate) {    
    this.setState({ startDate: startDate });
  }

  handleChangeEnd (endDate) {    
    this.setState({ endDate: endDate });
  }

  _handleChangeGroupByOption() {

    this.setState({
      groupByRepeats : !this.state.groupByRepeats
    })

  }

  handleSearchAndDownload = async () => {
    const filters = {...this.state};
    const csvLink = await trackRepeatsApi.getTracks(
      this.props.userToken,
      filters.device.device_id || filters.device,
      filters.startDate.toDate().getTime(),
      filters.endDate.toDate().getTime(),
      filters.groupByRepeats,
      true,
    );
    if (csvLink) {
      window.open(csvLink, '_blank');
    }
  }

  render() {
    return (
      <Fragment>
        <Typeahead
          className="track-repeats-typeahead"
          bsSize="large"
          // clearButton
          // defaultSelected={this.props.devices.slice(0, 1)}
          maxResults={20}
          emptyLabel={''}
          // filterBy=""
          highlightOnlyResult
          labelKey="device_id"
          // multiple
          onChange={(device) => this._handeDeviceChange(device)}
          options={this.props.devices}
          placeholder="Choose a device..."
          selectHintOnEnter
          // renderMenuItemChildren={(option) => (
          //   <div>
          //     {option.name}
          //     <div>
          //       <small>Capital: {option.capital}</small>
          //     </div>
          //   </div>
          // )}
        />				
       
        <input
          type="checkbox"
          id="filter-active"
          style={{
              marginLeft: "-130px",
              zIndex: "12"
          }}
          onChange={this._handleChangeGroupByOption}
          defaultChecked={this.state.groupByRepeats}
        />
        <label htmlFor="filter-active" style={{
          zIndex: "12",
          width: "100px",
          marginTop: "6px",
          marginLeft: "4px",
          marginRight: "10px"
        }}>show repeats</label>
     
        
{/*}
<div className="date-range">
          <DateRangePicker
            startDate={this.state.startDate}
            startDateId="start-date"
            endDate={this.state.endDate}
            endDateId="end-date"
            onDatesChange={({ startDate, endDate }) => this._onDatesChange({ startDate, endDate })}
            focusedInput={this.state.focusedInput}
            onFocusChange={focusedInput => this._onFocusChange(focusedInput)}
            isOutsideRange={this.isOutsideRange}
          />
           </div>
*/}
      <div className="form-group tracks-repeats-form-group">
        <div className="form-search-cancellable flex" style={{marginTop: "-3px"}}>
          <DatePicker
              selected={this.state.startDate}
              onChange={this.handleChangeStart}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              showTimeSelect
              selectsStart
              timeFormat="HH:mm"
              timeIntervals={15}
              dateFormat="LLL"
              timeCaption="time"
              placeholderText="Start time"
              isClearable={false}
              maxDate={moment()}
          />
          <DatePicker
              selected={this.state.endDate}
              onChange={this.handleChangeEnd}
              endDate={this.state.endDate}
              startDate={this.state.startDate}
              isClearable={false}
              showTimeSelect
              selectsEnd
              timeFormat="HH:mm"
              timeIntervals={15}
              dateFormat="LLL"
              timeCaption="time"
              placeholderText="End time"
              maxDate={moment()}
          />
        </div>
      </div>

      <div className="form-search-cancellable flex" style={{marginTop: "-3px"}}>
        <button 
          style={{
            height: "51px",
            padding: "0",
            borderRadius: "8px",
            paddingLeft: "13px",
            marginLeft: "10px",
            paddingRight: "10px",
            marginRight: "5px",
            marginTop: "3px"
          }} 
          type="button" disabled={this.isSearchDisabled} className="btn btn-primary header-control__search-container__checkbox__search" onClick={this._handleSearchClick} >Search</button>
          <button
            style={{
              height: "51px",
              padding: "0",
              borderRadius: "8px",
              paddingLeft: "13px",
              marginLeft: "10px",
              paddingRight: "10px",
              marginRight: "5px",
              marginTop: "3px"
            }} 
            disabled={this.state.groupByRepeats}
            type="button"
            className="btn btn-primary"
            onClick={this.handleSearchAndDownload}
          >
            Download
          </button>
      </div>
       
      </Fragment>
    );
  }
}

export default TrackRepeatsFilters;
