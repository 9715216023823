import moment from 'moment-timezone';

import * as types from '@actions/types';

const initialState = {
  isFulfilled: false,
  isRejected: false,
  error: null,
  coreChannels: [],
  modalIsVisible: false,
  channelSearchQuery: '',
  commercialRules: {
    all: {
      active: [],
      inactive: [],
    },
    area: {
      active: [],
      inactive: [],
    },
    client: {
      active: [],
      inactive: [],
    },
    playlist: {
      active: [],
      inactive: [],
    },
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case `${types.COMMERCIALRULES_STORE_CHANNELS}_PENDING`:
      return state;
    case `${types.COMMERCIALRULES_STORE_CHANNELS}_FULFILLED`:
      return Object.assign({}, state, {
        isFulfilled: true,
        coreChannels: action.payload
      });
    case `${types.COMMERCIALRULES_STORE_CHANNELS}_REJECTED`:
      return Object.assign({}, state, {
        isRejected: true,
        error: action.payload
      });
    case types.COMMERCIALRULES_OPEN_MODAL:
      return Object.assign({}, state, {
        modalIsVisible: true
      });
    case types.COMMERCIALRULES_CLOSE_MODAL:
      return Object.assign({}, state, {
        modalIsVisible: false
      });
    case types.COMMERCIALRULES_CHANNEL_SEARCH:
      return Object.assign({}, state, {
        channelSearchQuery: action.payload
      });
    case types.CHANGE_AREA_COMMERCIAL_RULES:
      return {
        ...state,
        commercialRules: {
          ...state.commercialRules,
          area: action.payload,
        }
      }
    case types.CHANGE_CLIENT_COMMERCIAL_RULES:
      return {
        ...state,
        commercialRules: {
          ...state.commercialRules,
          client: action.payload,
        }
      }
    case types.CHANGE_PLAYLIST_COMMERCIAL_RULES:
      return {
        ...state,
        commercialRules: {
          ...state.commercialRules,
          playlist: action.payload,
        }
      }    
    case types.CHANGE_ALL_COMMERCIAL_RULES:
      return {
        ...state,
        commercialRules: {
          ...state.commercialRules,
          all: action.payload,
        }
      }
    default:
      return state;
  };

};
