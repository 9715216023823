import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as tracksNotInUseApi from '@api/tracks-not-in-use-api';

import ActionBar from '@layout_2/action-bar';
import MainContainer from '@layout_2/main-container';
import { CSVLink } from 'react-csv';
import Table from '@common/table';
import { AUDIO_PREFIX, OLD_ADMIN_URL_TRACKS } from '../../../config';
import * as TracksNotInUseActions from '@actions/tracks-not-in-use-actions';
import store from '../../../store';
import YesNoPopUp from '../../common/generic-yes-no-popup';
import Modal from '../../layout_2/Modal/modal';
class TracksNotInUseMain extends Component {
  constructor() {
    super();
    this._handleSearch = this._handleSearch.bind(this);
    this.handleChangeDeleteFilter = this.handleChangeDeleteFilter.bind(this);
    this.handleChangeMonthFilter = this.handleChangeMonthFilter.bind(this);
  }

  componentWillMount() {
    tracksNotInUseApi.getTracks(this.props.userToken, 1, 100, this.props.isDeletedFilterActive);
  }

  _handleSearch(e) {
    store.dispatch(TracksNotInUseActions.changeNotUsedFilters({ page: parseInt(e.currentTarget.value) }));
    tracksNotInUseApi.getTracks(this.props.userToken, parseInt(e.currentTarget.value), 100, this.props.isDeletedFilterActive);
  }

  _handleClosePopup() {
    store.dispatch(TracksNotInUseActions.closeDeleteConfirmationModal());
  }

  _openConfirmationForDelete() {
    store.dispatch(TracksNotInUseActions.openDeleteConfirmationModal());
  }

  _closeConfirmation() {
    store.dispatch(TracksNotInUseActions.hideConfirmationOfDeletion());
  }

  _closeCSVPOPUP() {
    store.dispatch(TracksNotInUseActions.closeCSVPopUp());
  }

  handleChangeDeleteFilter() {
    store.dispatch(TracksNotInUseActions.toggleDisplayDelete());
    tracksNotInUseApi.getTracks(this.props.userToken, 1, 100, !this.props.isDeletedFilterActive, this.props.isMonthFilterActive);
  }

  handleChangeMonthFilter() {
    store.dispatch(TracksNotInUseActions.toggleMonthFilter());
    debugger;
    tracksNotInUseApi.getTracks(this.props.userToken, 1, 100, this.props.isDeletedFilterActive, !this.props.isMonthFilterActive);
  }

  render() {
    let pageOptions = [];
    for (let i = 0; i * 100 < parseInt(this.props.total); i++) {
      pageOptions.push(
        <option key={'page-' + i} value={i + 1}>
          {i + 1}
        </option>,
      );
    }
    return (
      <React.Fragment>
        {this.props.showConfirmation ? (
          <Modal show={true}>
            <h3>The request is being sent, it is happening on the background, you don't have to wait here till it is done.</h3>
            <button className="btn btn-primary header-control__search-container__checkbox__search" onClick={this._closeConfirmation}>
              Great news bro!
            </button>
          </Modal>
        ) : null}
        {this.props.showCSVDownloadPopup ? (
          <Modal show={true}>
            {this.props.CSVDataReady ? (
              <React.Fragment>
                <h5>The CSV is ready to be downloaded!</h5>
                <h6>Remember to disable ad-blocker to be able to downlaod the .csv file</h6>
                <CSVLink data={this.props.CSVData} filename={'tracks_to_delete.csv'}>
                  <button
                    className="btn btn-primary header-control__search-container__checkbox__search"
                    style={{ borderRadius: '8px', marginRight: '1px' }}
                    onClick={this._closeCSVPOPUP}
                  >
                    Click me, and you can thank me later mate!
                  </button>
                </CSVLink>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <h5>I am creating the CSV, please give me a second!</h5>
              </React.Fragment>
            )}
          </Modal>
        ) : null}
        <ActionBar cssColClass="text-align-center">
          <div>
            <h3>Tracks not in use: {this.props.total}</h3>
            <h3>Total in the catalogue (all the tracks excluding the deleted): {this.props.totalCatalogue}</h3>
          </div>
          {this.props.tracks.length === 0 ? (
            <button className="btn btn-primary header-control__search-container__checkbox__search" style={{ borderRadius: '8px', width: '535px' }}>
              Loading, or all the tracks are used...
            </button>
          ) : (
            <React.Fragment>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                Jump to page :
                <select onChange={this._handleSearch} defaultValue={this.props.selectedFilters.page} ref="page">
                  {pageOptions}
                </select>
              </div>
            </React.Fragment>
          )}
        </ActionBar>
        <MainContainer>
          <Table
            csv
            csvName="tracks-not-in-use"
            searchbar
            borders
            headings={[
              { title: '#', width: 25 },
              { title: 'Artist', width: 100 },
              { title: 'Title', width: 110 },
              { title: 'Created at', width: 100, date: true },
              { title: 'Deleted at', width: 100, date: true },
              { title: 'Actions', width: 155 },
            ]}
            rowData={this.props.tracks.map((Track) => {
              return {
                data: [
                  Track.id,
                  Track.artist,
                  Track.title,
                  new Date(Track.created).getTime() || '',
                  new Date(Track.deleted_at).getTime() || '',
                  <React.Fragment>
                    {Track.audio != '' ? (
                      <audio controls>
                        <source src={AUDIO_PREFIX + Track.audio} type="audio/mpeg" />
                      </audio>
                    ) : (
                      <a href="#">NO SOURCE FILE FOUND</a>
                    )}
                    {/* <br/>
                      <a href={OLD_ADMIN_URL_TRACKS + Track.artist + " " + Track.title} target="_blank">Edit [OLD ADMIN]</a> */}
                  </React.Fragment>,
                ],
              };
            })}
            noDataMessage={this.props.joke}
            height={350}
            paginate={false}
            itemsCountPerPage={50}
          ></Table>
        </MainContainer>
      </React.Fragment>
    );
  }
}

const storeToProps = (store) => {
  return {
    total: store.tracksNotInUseState.total_results,
    totalCatalogue: store.tracksNotInUseState.totalCatalogue,
    tracks: store.tracksNotInUseState.notUsedTracks,
    selectedFilters: store.tracksNotInUseState.notUsedSelectedFilters,
    userToken: store.authState.userInfo.userToken,
    joke: store.layoutState.joke ? store.layoutState.joke : '',
    showDeleteConfirmationModal: store.tracksNotInUseState.showDeleteConfirmationModal,
    showConfirmation: store.tracksNotInUseState.showConfirmation,
    CSVDataReady: store.tracksNotInUseState.CSVDataReady,
    showCSVDownloadPopup: store.tracksNotInUseState.showCSVDownloadPopup,
    CSVData: store.tracksNotInUseState.CSVData,
    isDeletedFilterActive: store.tracksNotInUseState.isDeletedFilterActive,
    isMonthFilterActive: store.tracksNotInUseState.isMonthFilterActive,
  };
};

export default connect(storeToProps)(TracksNotInUseMain);
