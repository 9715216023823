import axios from "axios";
import store from "../store";
import {
  changeLocations,
  changeLocationTimezone,
  hideActivationModalButtons,
  hideActivationModal,
  changeLocationActiveState,
  changeAreaActiveState,
  updateAreaStreamingState,
  updateDeviceSkipDuplicateState,
  changeAreas,
  businessNameUpdated,
  areaNameUpdated,
} from "../actions/locations-actions";
import {
  showSpinner,
  hideSpinner,
  showNotification,
  showFlashMessage,
} from "../actions/layout-actions";
import { clientNameUpdated } from "../actions/auth-actions";
import {
  HOST_CF,
  HOST,
  PORT,
  PREFIX,
  GENERAL_SUCCESS_MESSAGE,
  GENERAL_ERROR_MESSAGE,
  GENERAL_REQUEST_ERROR_MESSAGE,
} from "../config";
import * as authApi from "./auth-api";

const _getUrlFromConfig = () => `${HOST}:${PORT}${PREFIX}`;
console.log(
  "_getUrlFromConfig",
  `${_getUrlFromConfig()}/device/c-32109/override`
);

export const getLocations = (userToken, clientId, shouldShowSpinner = true) => {
  if (shouldShowSpinner) {
    store.dispatch(showSpinner());
  }
  return axios
    .get(_getUrlFromConfig() + "/clients/" + clientId + "/locations", {
      headers: { "x-ambie-token": userToken },
    })
    .then((response) => {
      store.dispatch(hideSpinner());
      if (response.data.results) {
        store.dispatch(
          changeLocations(
            response.data.results.sort((a, b) => a.name >= b.name) || []
          )
        );
      } else {
        store.dispatch(changeLocations([]));
      }
    })
    .catch((response) => {
      store.dispatch(hideSpinner());
      store.dispatch(showNotification(GENERAL_REQUEST_ERROR_MESSAGE, true));
      _handleUnauthorized(response.data.error);
      console.error("Error: ", response.data);
    });
};

export const getAreas = async (userToken) => {
  try {
    const headers = { "x-ambie-token": userToken };
    const config = { headers };
    const response = await axios.get(`${HOST_CF}/areas`, config);
    const areas = response.data.areas;
    store.dispatch(changeAreas(areas));
    return areas;
  } catch (e) {
    console.log("Error -> getAreas: ", e);
    store.dispatch(changeAreas([]));
    store.dispatch(showNotification(GENERAL_ERROR_MESSAGE, true));
  }
};

export const updateAutoplay = (deviceId, userToken, newValue, clientId) => {
  store.dispatch(showSpinner());
  return axios
    .put(
      _getUrlFromConfig() + "/devices/" + deviceId,
      {
        autoplay: newValue,
      },
      {
        headers: {
          "x-ambie-token": userToken,
          "x-ambie-timestamp": new Date().getTime().toString(),
          "x-ambie-timezone": "Europe/London",
        },
      }
    )
    .then((response) => {
      store.dispatch(hideSpinner());
      store.dispatch(showNotification(GENERAL_SUCCESS_MESSAGE));
      getLocations(userToken, clientId);
    })
    .catch((response) => {
      store.dispatch(hideSpinner());
      store.dispatch(showNotification(GENERAL_ERROR_MESSAGE, true));
      _handleUnauthorized(response.data.error);
      console.error("Error: ", response);
    });
};

//done
export const assignScheduleToArea = async (
  userToken,
  clientId,
  areaId,
  locationId,
  scheduleId
) => {
  store.dispatch(showSpinner());
  try {
    const assignResponse = await axios.post(
      _getUrlFromConfig() + "/clients/" + clientId + "/area_schedule",
      {
        // '/week_schedules/area_schedule'
        areaId: areaId,
        locationId: locationId,
        weekScheduleId: scheduleId,
      },
      {
        headers: { "x-ambie-token": userToken },
      }
    );
    const sendScheduleResponse = await axios.get(
      _getUrlFromConfig() +
        "/clients/" +
        clientId +
        "/areas/" +
        areaId +
        "/send_schedule",
      {
        headers: { "x-ambie-token": userToken },
      }
    );
    store.dispatch(hideSpinner());
    store.dispatch(showNotification(GENERAL_SUCCESS_MESSAGE));
    getLocations(userToken, clientId);
  } catch (e) {
    store.dispatch(hideSpinner());
    store.dispatch(showNotification(GENERAL_ERROR_MESSAGE, true));
    _handleUnauthorized(response.data.error);
    console.error("Error: ", e);
  }
};

//done
export const resendSchedule = (clientId, areaId, userToken) => {
  store.dispatch(showSpinner());
  return axios
    .get(
      _getUrlFromConfig() +
        "/clients/" +
        clientId +
        "/areas/" +
        areaId +
        "/send_schedule",
      {
        headers: { "x-ambie-token": userToken },
      }
    )
    .then((response) => {
      store.dispatch(hideSpinner());
      if (response.data.success) {
        store.dispatch(showNotification(GENERAL_SUCCESS_MESSAGE));
      } else {
        store.dispatch(showNotification(GENERAL_ERROR_MESSAGE, true));
      }
    })
    .catch((response) => {
      store.dispatch(hideSpinner());
      store.dispatch(showNotification(GENERAL_REQUEST_ERROR_MESSAGE, true));
      _handleUnauthorized(response.data.error);
      console.error("Error: ", response.data);
    });
};

export const changeDevice = (
  areaId,
  deviceId,
  userToken,
  clientId,
  deviceType
) => {
  store.dispatch(showSpinner());
  return axios
    .put(
      _getUrlFromConfig() + "/devices/" + deviceId + "/assign",
      {
        data: [{ area_id: areaId, device: deviceId, deviceType }],
      },
      {
        headers: { "x-ambie-token": userToken },
      }
    )
    .then((response) => {
      store.dispatch(hideSpinner());
      store.dispatch(showNotification(GENERAL_SUCCESS_MESSAGE));
      getLocations(userToken, clientId);
    })
    .catch((response) => {
      store.dispatch(hideSpinner());
      store.dispatch(showNotification(GENERAL_REQUEST_ERROR_MESSAGE, true));
      _handleUnauthorized(response);
      console.error("Error: ", response);
    });
};

export const updateTimezoneForLocation = (locationId, timezone, userToken) => {
  //debugger;
  return axios
    .put(
      HOST_CF + "/location/" + locationId,
      {
        timezone: timezone,
      },
      {
        headers: { "x-ambie-token": userToken },
      }
    )
    .then((response) => {
      store.dispatch(
        changeLocationTimezone({
          locationId,
          timezone,
        })
      );
    })
    .catch((response) => {
      console.error("Error: ", response);
    });
};

export const activateLocation = (locationId, userToken) => {
  store.dispatch(hideActivationModalButtons());
  console.log("API - Activate locationID", locationId, userToken);
  return axios
    .put(
      HOST_CF + "/location/" + locationId + "/activate",
      {},
      {
        headers: { "x-ambie-token": userToken },
      }
    )
    .then((response) => {
      store.dispatch(changeLocationActiveState(locationId, true));
      store.dispatch(hideActivationModal());
    })
    .catch((response) => {
      console.error("Error: ", response);
    });
};

export const deactivateLocation = (locationId, userToken) => {
  console.log("API - Deactivate locationID", locationId, userToken);
  store.dispatch(hideActivationModalButtons());
  return axios
    .put(
      HOST_CF + "/location/" + locationId + "/deactivate",
      {},
      {
        headers: { "x-ambie-token": userToken },
      }
    )
    .then((response) => {
      store.dispatch(changeLocationActiveState(locationId, false));
      store.dispatch(hideActivationModal());
    })
    .catch((response) => {
      console.error("Error: ", response);
    });
};

export const activateArea = (areaId, userToken) => {
  store.dispatch(hideActivationModalButtons());
  console.log("API - Activate areaId", areaId, "userToken", userToken);
  return axios
    .put(
      HOST_CF + "/area/" + areaId + "/activate",
      {},
      {
        headers: { "x-ambie-token": userToken },
      }
    )
    .then((response) => {
      store.dispatch(changeAreaActiveState(areaId, true));
      store.dispatch(hideActivationModal());
    })
    .catch((response) => {
      console.error("Error: ", response);
    });
};

export const deactivateArea = (areaId, userToken) => {
  store.dispatch(hideActivationModalButtons());
  console.log("API - Deactivate areaId", areaId, "userToken", userToken);
  return axios
    .put(
      HOST_CF + "/area/" + areaId + "/deactivate",
      {},
      {
        headers: { "x-ambie-token": userToken },
      }
    )
    .then((response) => {
      store.dispatch(changeAreaActiveState(areaId, false));
      store.dispatch(hideActivationModal());
    })
    .catch((response) => {
      console.error("Error: ", response);
    });
};

export const enableStreaming = async (areaId, userToken) => {
  try {
    await axios.put(
      `${HOST_CF}/area/${areaId}/streaming/enable`,
      {},
      {
        headers: { "x-ambie-token": userToken },
      }
    );
    store.dispatch(updateAreaStreamingState(areaId, true));
  } catch (e) {
    store.dispatch(showNotification(GENERAL_ERROR_MESSAGE, true));
    console.log("​locations-api -> enableStreaming -> catch -> e", e);
  }
};

export const disableStreaming = async (areaId, userToken) => {
  try {
    await axios.put(
      `${HOST_CF}/area/${areaId}/streaming/disable`,
      {},
      {
        headers: { "x-ambie-token": userToken },
      }
    );
    store.dispatch(updateAreaStreamingState(areaId, false));
  } catch (e) {
    store.dispatch(showNotification(GENERAL_ERROR_MESSAGE, true));
    console.log("​locations-api -> disableStreaming -> catch -> e", e);
  }
};

export const enableDuplicatesPlay = async (areaId, deviceId, userToken) => {
  try {
    //await axios.post(`http://127.0.0.1:3000/device/${deviceId}/dontskipduplicates/enable`, {}, {
    await axios.post(
      `${HOST_CF}/device/${deviceId}/dontskipduplicates/enable`,
      {},
      {
        headers: { "x-ambie-token": userToken },
      }
    );
    store.dispatch(updateDeviceSkipDuplicateState(areaId, deviceId, true));
  } catch (e) {
    store.dispatch(showNotification(GENERAL_ERROR_MESSAGE, true));
    console.error("​locations-api -> enableStreaming -> catch -> e", e);
  }
};

export const disableDuplicatesPlay = async (areaId, deviceId, userToken) => {
  try {
    await axios.post(
      `${HOST_CF}/device/${deviceId}/dontskipduplicates/disable`,
      {},
      {
        headers: { "x-ambie-token": userToken },
      }
    );
    store.dispatch(updateDeviceSkipDuplicateState(areaId, deviceId, false));
  } catch (e) {
    store.dispatch(showNotification(GENERAL_ERROR_MESSAGE, true));
    console.error("​locations-api -> disableStreaming -> catch -> e", e);
  }
};

export const generateWebPlayerThing = async (userToken) => {
  try {
    const response = await axios.post(
      `${HOST_CF}/things/web-players`,
      {},
      {
        headers: { "x-ambie-token": userToken },
      }
    );
    return {
      deviceSerial: response.data.thingName,
      deviceCode: response.data.deviceCode,
    };
  } catch (e) {
    store.dispatch(showNotification(GENERAL_ERROR_MESSAGE, true));
    console.error("​locations-api -> generateWebPlayerThing -> catch -> e", e);
  }
};

export const updatePlayerStatus = async (userToken, deviceId, newStatus) => {
  const body = JSON.stringify({
    status: newStatus.status,
    volume: newStatus.volume.toString(),
  });
  const config = {
    headers: { "x-ambie-token": userToken },
    validateStatus: (status) => authApi.handleUnauthorized(status),
  };
  try {
    const response = await axios.put(
      `${WP_HOST_CF}/device/${deviceId}`,
      body,
      config
    );
    store.dispatch(showNotification(GENERAL_SUCCESS_MESSAGE));
    return response;
  } catch (e) {
    console.log("TCL: locations-api -> updatePlayerStatus -> e", e);
    store.dispatch(showNotification(GENERAL_REQUEST_ERROR_MESSAGE, true));
  }
};

export const playerSkip = async (userToken, deviceId) => {
  const url = `${WP_HOST_CF}/device/${deviceId}/skip`;
  try {
    const response = await axios.put(
      url,
      {},
      {
        headers: {
          "x-ambie-token": userToken,
        },
        validateStatus: (status) => authApi.handleUnauthorized(status),
      }
    );
    store.dispatch(showNotification(GENERAL_SUCCESS_MESSAGE));
    return response;
  } catch (e) {
    console.log("TCL: locations-api -> playerSkip -> e", e);
    store.dispatch(showNotification(GENERAL_REQUEST_ERROR_MESSAGE, true));
  }
};

export const sendOverride = async (
  newChannel,
  duration,
  areaId,
  deviceId,
  userToken
) => {
  store.dispatch(showSpinner());
  try {
    const response = await axios.put(
      `${WP_HOST_CF}/device/${deviceId}/override`,
      {
        area_id: areaId,
        playlist_id: newChannel.toString(),
        time: duration.toString(),
      },
      {
        headers: {
          "x-ambie-token": userToken,
          "x-ambie-timestamp": new Date().getTime().toString(),
          "x-ambie-timezone": "Europe/London",
          "Content-Type": "application/x-www-form-urlencoded",
          Accept: "application/json",
        },
      }
    );
    store.dispatch(showNotification(GENERAL_SUCCESS_MESSAGE));
    return response;
  } catch (e) {
    console.log("TCL: sendOverride -> e", e);
    store.dispatch(showNotification(GENERAL_REQUEST_ERROR_MESSAGE, true));
  }
  store.dispatch(hideSpinner());
};

export const revertOverride = async (deviceId, userToken) => {
  store.dispatch(showSpinner());
  try {
    const response = await axios.delete(
      `${_getUrlFromConfig()}/device/${deviceId}/override`,
      {},
      {
        headers: {
          "x-ambie-token": userToken,
          "Content-Type": "application/x-www-form-urlencoded",
          Accept: "application/json",
        },
      }
    );
    store.dispatch(showNotification(GENERAL_SUCCESS_MESSAGE));
    return response;
  } catch (e) {
    console.log("TCL: sendOverride -> e", e);
    store.dispatch(showNotification(GENERAL_REQUEST_ERROR_MESSAGE, true));
  }
  store.dispatch(hideSpinner());
};

export const saveClientName = async (userToken, clientId, newClientName) => {
  try {
    store.dispatch(showSpinner());
    await axios.put(
      `${HOST_CF}/clients/${clientId}`,
      {
        clientName: newClientName,
      },
      {
        headers: { "x-ambie-token": userToken },
      }
    );
    store.dispatch(clientNameUpdated(clientId, newClientName));
    store.dispatch(hideSpinner());
    store.dispatch(showNotification(GENERAL_SUCCESS_MESSAGE));
  } catch (e) {
    store.dispatch(hideSpinner());
    store.dispatch(showNotification(GENERAL_ERROR_MESSAGE, true));
    console.error("​locations-api -> saveClientName -> catch -> e", e);
  }
};

export const saveAreaName = async (
  userToken,
  clientId,
  areaId,
  businessId,
  newAreaName
) => {
  try {
    store.dispatch(showSpinner());
    await axios.put(
      `${HOST_CF}/areas/${areaId}`,
      {
        areaName: newAreaName,
      },
      {
        headers: { "x-ambie-token": userToken },
      }
    );
    store.dispatch(areaNameUpdated(clientId, businessId, areaId, newAreaName));
    store.dispatch(hideSpinner());
    store.dispatch(showNotification(GENERAL_SUCCESS_MESSAGE));
  } catch (e) {
    store.dispatch(hideSpinner());
    store.dispatch(showNotification(GENERAL_ERROR_MESSAGE, true));
    console.error("​locations-api -> saveAreaName -> catch -> e", e);
  }
};

export const saveBusinessName = async (
  userToken,
  clientId,
  businessId,
  newBusinessesName
) => {
  try {
    store.dispatch(showSpinner());
    await axios.put(
      `${HOST_CF}/location/${businessId}`,
      {
        locationName: newBusinessesName,
      },
      {
        headers: { "x-ambie-token": userToken },
      }
    );
    store.dispatch(
      businessNameUpdated(clientId, businessId, newBusinessesName)
    );
    store.dispatch(hideSpinner());
    store.dispatch(showNotification(GENERAL_SUCCESS_MESSAGE));
  } catch (e) {
    store.dispatch(hideSpinner());
    store.dispatch(showNotification(GENERAL_ERROR_MESSAGE, true));
    console.error("​locations-api -> saveBusinessName -> catch -> e", e);
  }
};

export const removeDeviceFromArea = async (
  userToken,
  clientId,
  deviceSerial
) => {
  try {
    const response = await axios.delete(
      `${WP_HOST_CF}/device/${deviceSerial}/unlink-from-area`,
      {
        headers: {
          "x-ambie-token": userToken,
          "Content-Type": "application/x-www-form-urlencoded",
          Accept: "application/json",
        },
      }
    );
    getLocations(userToken, clientId);
  } catch (e) {
    console.log("TCL: sendOverride -> e", e);
    throw new Error(e);
  }
};

const _detectUnauthorized = (response) => {
  if (response.data.error && response.data.error == "Unauthorized") {
    throw new Error(response.data.error);
  }
};

function _handleUnauthorized(error) {
  if (error === "Unauthorized" || error === 401) {
    authApi.logout();
    return false;
  }
  return true;
}
