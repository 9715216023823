import * as types from '@actions/types';

const initalState = {
  table: {
    searchQuery: '',
    rowData: [],
    selectedRows: [],
  },
};

export default (state = initalState, action) => {
  switch (action.type) {
    case types.TOGGLE_SELECT_TABLE_ROW: {
      const row = action.payload;
      const selectedRows = [...state.table.selectedRows];
      const index = selectedRows.findIndex(r => JSON.stringify(r) === JSON.stringify(row));
      if (index !== -1) {
        selectedRows.splice(index, 1);
      } else {
        selectedRows.push(row);
      }
      return {
        ...state,
        table: {
          ...state.table,
          selectedRows,
        }
      }
    }
    case types.SELECT_TABLE_ROWS: {
      const rows = action.payload;
      const selectedRows = [...state.table.selectedRows];
      rows.forEach(row => {
        const index = selectedRows.findIndex(r => JSON.stringify(r) === JSON.stringify(row));
        if (index === -1) {
          selectedRows.push(row);
        }
      });
      return {
        ...state,
        table: {
          ...state.table,
          selectedRows,
        }
      }
    }
    case types.DESELECT_ALL_TABLE_ROWS: {
      return {
        ...state,
        table: {
          ...state.table,
          selectedRows: [],
        }
      }
    }
    case types.CHANGE_TABLE_SEARCH_QUERY: {
      return {
        ...state,
        table: {
          ...state.table,
          searchQuery: action.payload || '',
        }
      }
    }
    case types.CHANGE_TABLE_ROW_DATA: {
      return {
        ...state,
        table: {
          ...state.table,
          rowData: action.payload,
        }
      }
    }
    default:
      return state
  }
};