import * as types from "../actions/types";

const initalState = {
  loginTokensList: [],
  loginTokensUpdating: false,
  showDeletePopup: false,
};

export default (state = initalState, action) => {
  switch (action.type) {
 
    case types.LOGIN_TOKEN_SHOW_DELETE_POPUP:
      return Object.assign({}, state, {
        showDeletePopup: true,
      });
    break;

    case types.LOGIN_TOKEN_HIDE_DELETE_POPUP:
      return Object.assign({}, state, {
        showDeletePopup: false,
      });
    break;

    case types.CHANGE_LOGIN_TOKEN_LIST:
      return Object.assign({}, state, {
        loginTokensList: action.payload
      });
    break;

    case types.UPDATE_LOGIN_TOKEN:
      return Object.assign({}, state, {
        loginTokensUpdating: false
      });
    break;

    case types.UPDATING_LOGIN_TOKEN:
      return Object.assign({}, state, {
        loginTokensUpdating: true
      });
      break;
  }
  return state;
};
