import * as types from './types';

export const setFeedbackTableConfig = (payload) => {
  return {
    type: types.SET_TABLE_CONFIG,
    payload: payload
  }
}

export const changeFeedback = (payload) => {
  return {
    type: types.CHANGE_FEEDBACK,
    payload: payload
  }
}

export const selectFeedback = (payload) => {
  return {
    type: types.SELECT_FEEDBACK,
    payload: payload
  };
};

export const deselectFeedback = (payload) => {
  return {
    type: types.DESELECT_FEEDBACK,
    payload: payload
  };
};

export const deselectAllFeedback = (payload) => {
  return {
    type: types.DESELECT_ALL_FEEDBACK,
    payload: payload
  };
};

export const creatingFeedbackCSV = () => {
  return {
    type: types.CREATING_FEEDBACK_CSV,
  };
};

export const feedbackCSVCreated = (url) => {
  return {
    type: types.FEEDBACK_CSV_CREATED,
    payload: url
  };
};

export const feedbackCSVCreationFailed = () => {
  return {
    type: types.FEEDBACK_CSV_CREATION_FAILED,
  };
};

export const feedbackCSVCloseModal = () => {
  return {
    type: types.FEEDBACK_CSV_CLOSE_MODAL,
  };
};