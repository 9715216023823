import axios from 'axios';
import * as authApi from '@api/auth-api';
import store from '@store';
import { HOST_CF, HOST_ROCK_API } from '@config';
import { changeAllTracksList, changeAllArtistsList, changeAllCategoriesList, changeIsSearchingTracks, trackTitleSaved } from '@actions/tracks-actions';
import axiosInterceptors from '../utils/interceptors';

import { showFlashMessage } from '@actions/layout-actions';

export const getAllTracksList = async (userToken) => {
  const url = `${HOST_CF}/tracks`;
  try {
    const response = await axios.get(url, {
      headers: { 'x-ambie-token': userToken },
      validateStatus: (status) => authApi.handleUnauthorized(status),
    });
    const tracks = [...response.data.tracks];
    store.dispatch(changeAllTracksList(tracks));
  } catch (e) {
    console.error('Error getting tracks list: ', e);
  }
};

/**
 * use rock-api endpoint
 */
export const searchTracks = async (userToken, searchQuery) => {
  store.dispatch(changeIsSearchingTracks(true));
  const params = {
    filter: searchQuery,
    itemsPerPage: 500,
    pageNumber: 1,
  };
  try {
    const response = await axiosInterceptors.get(HOST_ROCK_API + `/tracks/all`, {
      ...params,
    });
    const tracks = response.data.tracks;
    store.dispatch(changeAllTracksList(tracks));
    store.dispatch(changeIsSearchingTracks(false));
  } catch (e) {
    store.dispatch(changeAllTracksList([]));
    store.dispatch(changeIsSearchingTracks(false));
  }
};

/**
 * use rock-api endpoint
 */
export const searchArtistAndTrack = async (userToken, searchQueries) => {
  store.dispatch(changeIsSearchingTracks(true));

  const params = {
    filter: searchQueries,
    itemsPerPage: 230,
    pageNumber: 1,
  };
  try {
    const response = await axiosInterceptors.get(HOST_ROCK_API + `/tracks/all`, {
      ...params,
    });

    const tracks = response.data.tracks;
    store.dispatch(changeAllTracksList(tracks));
    store.dispatch(changeIsSearchingTracks(false));
    return tracks;
  } catch (e) {
    store.dispatch(changeAllTracksList([]));
    store.dispatch(changeIsSearchingTracks(false));
  }
};

/**
 * rock-api endpoint (----NOT NEED -----)
 */
export const getAllArtistsList = async (userToken) => {
  const url = `${HOST_CF}/tracks/artists`;
  try {
    const response = await axios.get(url, {
      headers: { 'x-ambie-token': userToken },
      validateStatus: (status) => authApi.handleUnauthorized(status),
    });
    const artists = [...response.data.artists];
    store.dispatch(changeAllArtistsList(artists));
  } catch (e) {
    console.error('Error getting artists list: ', e);
  }
};

/**
 * rock-api endpoint (----NOT NEED -----)
 */
export const getAllCategoriesList = async (userToken) => {
  const url = `${HOST_CF}/tracks/categories`;
  try {
    const response = await axios.get(url, {
      headers: { 'x-ambie-token': userToken },
      validateStatus: (status) => authApi.handleUnauthorized(status),
    });
    const categories = [...response.data.categories];
    store.dispatch(changeAllCategoriesList(categories));
  } catch (e) {
    console.error('Error getting categories list: ', e);
  }
};

/**
 * use rock-api endpoint
 */
export const saveTrackTitle = async (userToken, track) => {
  const url = `${HOST_CF}/tracks/${track.id}`;
  store.dispatch(
    showFlashMessage({
      status: 'success',
      message: 'Saving track info',
      duration: 1500,
    }),
  );

  try {
    await axiosInterceptors.patch(`${HOST_ROCK_API}/tracks/${track.id}`, { title: track.title });

    store.dispatch(
      showFlashMessage({
        status: 'success',
        message: 'Title saved!',
        duration: 1500,
      }),
    );
    store.dispatch(trackTitleSaved(track.id));
  } catch (e) {
    console.error('Error saving track title: ', e);
  }
};

export const processTrack = async (track) => {
  return axiosInterceptors.post(`${HOST_ROCK_API}/tracks/process`, track, { headers: { multipartFormData: 'multipart/form-data' } });
};

export const startFileUploadConverterTask = async () => {
  await axiosInterceptors.post(`${HOST_ROCK_API}/start-file-upload-converter-task`, {});
};

export const getStatusForProcessedTracks = () => {
  return axiosInterceptors.get(`${HOST_ROCK_API}/tracks/processed`, {});
};

export const deleteTrackFromProcessedAudioTable = (trackId) => {
  return axiosInterceptors.remove(`${HOST_ROCK_API}/tracks/processed/${trackId}`);
};

export const deleteAllTracksFromProcessedAudioTable = () => {
  return axiosInterceptors.remove(`${HOST_ROCK_API}/tracks/processed`);
};
