import * as types from '../actions/types';

const initalState = {
  accountInfo: {
    Name: "",
    Id: "",
    DashboardID : "",
    DashboardPWD : "",
    Email : "",
    Children : [],
    AmbieAccountInfo : null,
  },
  thingsToUpdateOnAccount : {
    client : {
      action: null,
    },
    business : [],
    areas : [],
  },
  savingAccountOnSF : false,
  savedAccountOnSF : false,
  isAParentAccount : true,
  selectedClient : null,
  savingDeviceInOlympus : false,
  savedDeviceInOlympus : false,
  savingErrorMessage : '',
  deviceInfo : {
    Id: null,
    SerialNumber: null,
    ParentAccount: null,
    ParentAccountSfId: null,
    AmbieId: null,
    AmbieArea: {
        name: null,
        id: null
    },
    AmbieBusiness: {
        name: null,
        id: null
    },
    currentAssignee : null,
  }
};

let getUserId = (Name) => {
  return Name.replace(/[^A-Z0-9]+/ig, "_").toLowerCase();
}

function getPwd() {
  var text = "";
  var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (var i = 0; i < 8; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  return text;
}

let accountLocal = {};
export default (state = initalState, action) => {
  switch (action.type) {
    case types.SF_NOT_A_PARENT_ACCOUNT:
      return Object.assign({}, state, {
        isAParentAccount: false
      }); 
    case types.SF_ACCOUNT_SAVED:
      return Object.assign({}, state, {
        savedAccountOnSF: true
      }); 
    case types.SF_ACCOUNT_SAVING:
      return Object.assign({}, state, {
        savingAccountOnSF: true,
        savingErrorMessage: '',
      }); 
    case types.SF_UPDATE_CHILD_DEVICE_INFO:
      accountLocal = Object.assign({},state.accountInfo);
      accountLocal.Children.forEach(A => {
        let B = A.Children.filter(D => {
          return D.Device.Id === action.payload.salesforceId
        })
        if (B.length === 1) {
          B[0].Device[action.payload.field] = action.payload.value;
        }
      });
      return Object.assign({}, state, {
        accountInfo: accountLocal
      }); 
    case types.SF_UPDATE_DEVICE_INFO:
      accountLocal = Object.assign({},state.accountInfo);
      let areaD = accountLocal.Children.filter(A => {
        return A.Device?A.Device.Id === action.payload.salesforceId:false
      });
      if (areaD.length === 1) {
        areaD[0].Device[action.payload.field] = action.payload.value;
      }
      return Object.assign({}, state, {
        accountInfo: accountLocal
      }); 
    case types.SF_UPDATE_AREA_INFO:{
      //debugger
      //let localClient;
      let accountLocal = Object.assign({},state.accountInfo);
      accountLocal.Children.forEach(B=> {
        let a = B.Children.filter(A => {
          return A.Id === action.payload.salesforceId
        });
        if (action.payload.value !== null) {
          if (a.length === 1) {
            a[0][action.payload.field] = action.payload.value;
          }
        }
      });
      //debugger
      return Object.assign({}, state, {
        accountInfo: accountLocal
      }); 
    }
    case types.SF_CONFIRM_BUSINESS: {
      let { client, business, areas } = state.thingsToUpdateOnAccount;
      console.log("thingsToUpdateOnAccountLocal", client, business, areas);
      let accountLocal = Object.assign({},state.accountInfo);
      let localBusiness = accountLocal.Children.filter(A => {
        return action.payload === A.Id
      });
      localBusiness = localBusiness[0];
      let location = {
        action: 'createNew',
        DashboardID: localBusiness.DashboardID,
        DashboardPWD: localBusiness.DashboardPWD,
        Email: localBusiness.Email,
        businessId: localBusiness.Id,
        Name: localBusiness.Name,
      }
      let businessThing = business.filter(B => B.businessId === action.payload);
      if (businessThing.length === 1) {
        businessThing = location;
      } else {
        business.push(location);
      };
      return Object.assign({}, state, {
        thingsToUpdateOnAccount : {
          client, business, areas
        },
        accountInfo : accountLocal,
      });
    }
    case types.SF_CONFIRM_AREA: {
      let { client, business, areas } = state.thingsToUpdateOnAccount;
      console.log("thingsToUpdateOnAccountLocal", client, business, areas);
      let accountLocal = Object.assign({},state.accountInfo);
      let localClient = [];
      //debugger
      for (var fu=0; fu < accountLocal.Children.length; fu++) {
        localClient = accountLocal.Children[fu].Children.filter(A => {
          return A.Id === action.payload
        });
        if (localClient.length === 1) break;
      }
      localClient = localClient[0];
      let area = {
        action: 'createNew',
        areaName: localClient.Name,
        areaEmail: `${localClient.RemoteUserName}@${localClient.RemoteUserName}`,
        areaSFID: localClient.Id,
        areaRemoteID: localClient.RemoteUserName,
        areaPasswordPWD: localClient.RemotePassword,
      };
      let areaToModify = areas.filter(A => A.areaId === action.payload.areaId)
      if (areaToModify.length === 1) {
        areaToModify = area;
      } else {
        areas.push(area);
      }
      accountLocal = Object.assign({},state.accountInfo);
      accountLocal.Children.forEach(C => {
        if (C.Children.length > 0) {
          C.Children.forEach(A => {
            if (A.Id === action.payload) {
              A.formToDisplay = 'none';
            }
          });
        }
      });
      return Object.assign({}, state, {
        thingsToUpdateOnAccount: {
          client, business, areas
        },
        accountInfo: accountLocal,
      });
    }
    case types.SF_UPDATE_BUSINESS_INFO:
      accountLocal = Object.assign({},state.accountInfo);
      let business = accountLocal.Children.filter(C => {
        return C.Id === action.payload.salesforceId
      });
      business[0][action.payload.field] = action.payload.value;
      return Object.assign({}, state, {
        accountInfo: accountLocal
      }); 
    case types.SF_UPDATE_ACCOUNT_INFO:
      accountLocal = Object.assign({},state.accountInfo);
      accountLocal[action.payload.field] = action.payload.value;
      return Object.assign({}, state, {
        accountInfo: accountLocal
      }); 
    case types.SF_SELECT_CLIENT:
      return Object.assign({}, state, {
        selectedClient: action.payload
      });
    case types.SF_ACCOUNT_CREATED_ON_OLYMPUS :
      return Object.assign({}, state, {
        savingAccountOnSF: false
      });
      case types.SF_ACCOUNT_CREATION_ERROR :{
        //debugger;
        return Object.assign({}, state, {
          savingErrorMessage: action.payload
        });  
      }
    case types.SF_CHANGE_FORM_TYPE:
      //debugger
      accountLocal = Object.assign({},state.accountInfo);
      switch (action.payload.thing) {
        case 'area' :
          accountLocal.Children.forEach(B=> {
            let a = B.Children.filter(A => {
              return A.Id === action.payload.id
            });
            if (a.length === 1) {
              a[0].formToDisplay = action.payload.formType;
            }
          });
        break;
      }
      return Object.assign({}, state, {
        accountInfo: accountLocal
      }); 
    case types.SF_ACCOUNT_LOADED:
      //debugger;
      accountLocal = Object.assign({},state.accountInfo);
      accountLocal.Name = action.payload.account.Name;
      accountLocal.AmbieName = action.payload.account.Name;
      accountLocal.Id = action.payload.account.Id;
      accountLocal.Type = action.payload.account.Type;
      accountLocal.DashboardID = getUserId(action.payload.account.Name);
      accountLocal.DashboardPWD = getPwd();
      accountLocal.AmbieAccountInfo = action.payload.account.ambieAccountInfo;
      accountLocal.Children = action.payload.account.Children;
      accountLocal.Children.forEach(C => {
        C.DashboardID = getUserId(C.Name);
        C.DashboardPWD = getPwd();
        if (C.Children.length === 0) {
          if (C.Device === null) C.Device ={};
          C.Device.AreaName = C.Name + ' Area';
          C.Device.RemoteUserName = getUserId(C.Name + "_staff");
          C.Device.RemotePassword = getPwd();
        } else {
          C.Children.forEach(A => {
            if (A.Device === null) A.Device ={};
            A.RemoteUserName = getUserId(A.Name + "_staff");
            A.RemotePassword = getPwd();
          });
        }
      })
      //debugger;
      return Object.assign({}, state, {
        accountInfo: accountLocal,
        isAParentAccount : true
      });

    case types.SF_CHANGE_LINKED_AREA: {
      debugger;
      /*
        ambieId: 577
        name: "Marco Casino"
        sfID: "0015E00000iyhaBQAQ"
      */
      accountLocal = Object.assign({},state.accountInfo);
      accountLocal.Children.forEach(C => {
        if (C.Children.length > 0) {
          //debugger
          const area = C.Children.find(A => A.Id === action.payload.sfID);
          if (area) {
              area.Ambie_ID = action.payload.ambieId;
              area.ambieAccountInfo = {
                area_id: action.payload.ambieId,
                area_name: action.payload.name,
                location_id: C.ambieAccountInfo.user_id,
                location_name: C.ambieAccountInfo.name,
              }
          }
        }
      });
      return Object.assign({}, state, {
        accountInfo : accountLocal,
      });
    }

    case types.SF_CHANGE_LINKED_BUSINESS: {
      //debugger;
      /*
      business:
        ambieId: 577
        contactName: "false"
        name: "Marco Casino"
        sfID: "0015E00000iyhaBQAQ"
      */

      accountLocal = Object.assign({},state.accountInfo);
      const business = accountLocal.Children.find(A => A.Id === action.payload.sfID);
      //debugger;
      if (business) {
        business.Ambie_ID = action.payload.ambieId;
        business.ambieAccountInfo = {
          contact_name: action.payload.contactName,
          name: action.payload.name,
          user_id: action.payload.ambieId,
        };
      }
      return Object.assign({}, state, {
        accountInfo: accountLocal,
      });
    }
    case types.SF_CHANGE_LINKED_CLIENT: {
      accountLocal = Object.assign({},state.accountInfo);
      accountLocal.AmbieAccountInfo = {
        id: action.payload.id,
        contact_name: action.payload.contact_name,
        name: action.payload.name,
      }
      return Object.assign({}, state, {
        accountInfo: accountLocal,
      });
    }
    case types.SF_CONFIRM_NEW_CLIENT: {
      let { client, business, areas } = state.thingsToUpdateOnAccount;
      console.log("thingsToUpdateOnAccountLocal", client, business, areas);
      accountLocal = Object.assign({},state.accountInfo);
      client = {
        action: 'createNewClient',
        clientName: accountLocal.AmbieName,
        clientEmail: accountLocal.Email,
        clientSFID: accountLocal.Id,
        clientDashboardID: accountLocal.DashboardID,
        clientDashboardPWD: accountLocal.DashboardPWD,
      };
      return Object.assign({}, state, {
        accountInfo: accountLocal,
        thingsToUpdateOnAccount : {
          client, business, areas
        },
      });
    }
    case types.SF_DEVICE_LOADED: {
      //debugger;
      return Object.assign({}, state, {
        deviceInfo: action.payload
      });
    }
    case types.SF_SAVING_DEVICE: {
      return Object.assign({}, state, {
        savingDeviceInOlympus: true
      }); 
    }
    case types.SF_DEVICE_SAVED: {
      return Object.assign({}, state, {
        savingDeviceInOlympus: false,
        savedDeviceInOlympus : true
      }); 
    }
  }
  return state;
};


