import * as React from 'react';
import { connect } from 'react-redux';

import autoBind from 'react-autobind';
import moment from 'moment';

import store from '@app/store';

import * as CONSTANTS from './feedback.constants';
import DateRangeCalendar from '@common/date-range-calendar';
import MainContainer from '@layout_2/main-container';
import RadioButtonGroup from '@common/radio-buttons/radio-button-group';
import Table from '@common/callback-table';
import Modal from '@layout_2/Modal/modal';
import YesNoPopUp from '@common/generic-yes-no-popup';
import OkPopUp from '@common/ok-popup';

import { deselectAllFeedback, setFeedbackTableConfig, feedbackCSVCloseModal, deselectFeedback, selectFeedback } from '@actions/feedback-actions';
import * as feedbackApi from '@api/feedback-api';

class Feedback extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  state = {
    query: '',
    marked: false,
    startDate: null,
    endDate: null,
    inputValue: '',
    modalMessage: null,
    modalTypeYesNo: true,
    modalCallback: null,
    isSearching: false,
    awaitingApproval: false,
    rpp: 50,
    activePage: 1,
    sortBy: 'Date',
    sortOrder: 'ASC',
  };

  async componentWillMount() {
    store.dispatch(deselectAllFeedback());
    await feedbackApi.getFeedback(this.props.userToken, { ack: false, page: this.props.activePage, rpp: this.props.rowsPerPage });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      JSON.stringify({ ...nextState }) !== JSON.stringify({ ...this.state }) ||
      JSON.stringify({ ...nextProps, joke: null }) !== JSON.stringify({ ...this.props, joke: null })
    );
  }

  componentWillUpdate(nextProps, nextState) {
    if (JSON.stringify(nextState) !== JSON.stringify(this.state)) {
      let nextStateCopy = { ...nextState };
      nextStateCopy.endDate = nextStateCopy.endDate || moment();
    }
    if (JSON.stringify(this.props.selectedFeedback) !== JSON.stringify(nextProps.selectedFeedback)) {
      this.selectedFeedback = nextProps.selectedFeedback.map((fb) => fb.originalObject);
    }
  }

  componentWillUnmount() {
    store.dispatch(deselectAllFeedback());
  }

  get rowData() {
    // debugger;
    if (this.state.isSearching) {
      return [];
    }
    const feedbacks = this.props.feedback;
    return feedbacks.map((feedback, idx) => {
      return {
        class: feedback.ack ? 'bg-success' : 'bg-danger',
        isSelectedClass: 'row-highlight',
        originalObject: feedback,
        data: [
          feedback.type,
          feedback.location,
          feedback.track,
          feedback.artist,
          feedback.playlist + (feedback.playlist_id ? ` (${feedback.playlist_id})` : ''),
          feedback.message,
          new Date(feedback.date).getTime(),
          feedback.rule_created ? 'true' : 'false',
        ],
      };
    });
  }

  get footerItems() {
    return {
      left: this.tableActions,
      right: (
        <div>
          <button
            type="button"
            className="btn btn-success btn-xs margin-right-sm"
            disabled={this.state.awaitingApproval || this.props.selectedFeedback.length <= 0}
            onClick={() => this.handleMarkFeedback(this.selectedFeedback)}
          >
            Mark
          </button>
          <button
            type="button"
            className="btn btn-success btn-xs"
            disabled={
              this.state.awaitingApproval ||
              this.selectedFeedback.length <= 0 ||
              this.selectedFeedback.every((fb) => fb.type.toString().toLowerCase() === 'positive') ||
              !this.selectedFeedback.every((fb) => fb.playlist_id)
            }
            onClick={() => this.handleCreateRules(this.selectedFeedback)}
          >
            Mark & Apply Rules
          </button>
        </div>
      ),
    };
  }
  set selectedFeedback(feedback) {
    return (this._selectedFeedback = feedback);
  }

  get selectedFeedback() {
    return this._selectedFeedback || [];
  }

  get tableActions() {
    return (
      <RadioButtonGroup
        buttons={[
          {
            id: CONSTANTS.INPUT_IDS.all,
            label: 'All',
            checked: this.state.marked === null,
            onChange: (e) => this._handleToggleMarkedStatus(e, null),
          },
          {
            id: CONSTANTS.INPUT_IDS.marked,
            label: 'Marked',
            checked: this.state.marked === true,
            onChange: (e) => this._handleToggleMarkedStatus(e, true),
          },
          {
            id: CONSTANTS.INPUT_IDS.unmarked,
            label: 'Unmarked',
            checked: this.state.marked === false,
            onChange: (e) => this._handleToggleMarkedStatus(e, false),
          },
          {
            id: CONSTANTS.INPUT_IDS.unapproved,
            label: 'Awaiting approval',
            checked: this.state.awaitingApproval,
            onChange: (e) => this._handleToggleApprovedStatus(e.target.value),
          },
        ]}
      >
        <DateRangeCalendar small appendToBody allowPastDate maximumDays={31} onDatesChange={this._handleDatesChanged} />
        <button className="margin-left-xs btn btn-blue btn-xs" onClick={this._handleSearchChange}>
          Search
        </button>
      </RadioButtonGroup>
    );
  }

  _handlePageChange = async (pageNumber, rowsPerPage) => {
    // debugger;
    await this.setState({
      activePage: pageNumber,
      rpp: rowsPerPage,
    });
    const params = this._constructSearchParams();
    await feedbackApi.getFeedback(this.props.userToken, params);
  };

  _handleSort = async (sortBy, sortByAscending) => {
    // debugger;
    const order = sortByAscending === true ? 'ASC' : 'DESC';
    await this.setState({
      isSearching: true,
      sortBy: sortBy,
      sortOrder: order,
    });
    const params = this._constructSearchParams();

    await feedbackApi.getFeedback(this.props.userToken, params);
    this.setState({ isSearching: false });
  };

  handleCreateRules = async (selectedFeedback) => {
    const negativeFeedback = selectedFeedback.filter((fb) => fb.type.toString().toLowerCase() !== 'positive');

    const positiveFeedbackReports = selectedFeedback
      .filter((positiveType) => positiveType.type.toString().toLowerCase() === 'positive')
      .map((fb) => ({
        _id: fb._id,
        rule_created: false,
      }));

    feedbackApi.markMultipleFeedbackAcknowledged(positiveFeedbackReports);

    const feedbackReports = {
      rules: negativeFeedback.map((fb) => ({
        _id: fb._id,
        title: fb.track,
        artist: fb.artist,
        playlist_id: fb.playlist_id,
        track_id: fb.track_id,
      })),
    };
    try {
      await feedbackApi.createPlaylistsRulesFromFeedback(feedbackReports);
    } catch (e) {
      this.setState({
        modalMessage: 'Error creating rules and marking one or more feedback. The failed feedback will remain in the table as unmarked.',
        modalTypeYesNo: false,
      });
    }
  };

  async _handleSearchChange() {
    const params = this._constructSearchParams();
    params.page = 1;
    this.setState({ isSearching: true });
    try {
      await feedbackApi.getFeedback(this.props.userToken, params);
      this.setState({ isSearching: false });
    } catch (e) {
      this.setState({ isSearching: false });
    }
  }

  _handleToggleMarkedStatus(event, marked) {
    this.setState({
      ...this.state,
      marked,
      awaitingApproval: false,
    });
  }

  _handleToggleApprovedStatus(awaitingApproval) {
    this.setState({
      ...this.state,
      awaitingApproval,
      marked: undefined,
    });
  }

  _handleDatesChanged(dateRange) {
    this.setState({
      ...this.state,
      ...dateRange,
    });
  }

  handleCloseModal() {
    this.setState({
      ...this.state,
      modalMessage: null,
      modalTypeYesNo: true,
      modalCallback: null,
    });
  }

  _constructSearchParams() {
    let params = {
      page: this.state.activePage,
      rpp: this.state.rpp,
      orderBy: this.state.sortBy,
      order: this.state.sortOrder,
    };
    if (this.props.searchQuery) {
      params.query = this.props.searchQuery;
    }
    if (this.state.startDate && this.state.endDate) {
      params.start_date = this.state.startDate.set({ hour: 0, minute: 0, second: 0 }).toDate().getTime();
      params.end_date = this.state.endDate.set({ hour: 23, minute: 59, second: 59 }).toDate().getTime();
    }
    if (this.state.awaitingApproval) {
      params.awaitingApproval = true;
    } else if (this.state.marked !== null) {
      params.ack = this.state.marked;
      // params.awaitingApproval = false;
    }
    return params;
  }

  handleMarkFeedback = (selectedFeedback) => {
    const feedbackReports = selectedFeedback.map((fb) => ({
      _id: fb._id,
      rule_created: false,
    }));
    feedbackApi.markMultipleFeedbackAcknowledged(feedbackReports);
  };

  _handleCSVDownloadClick() {
    const params = this._constructSearchParams();
    params.isCSVDownload = true;
    feedbackApi.getFeedbackCSV();
  }

  _handleCloseCSVDownloadModal() {
    store.dispatch(feedbackCSVCloseModal());
  }

  _csvModal() {
    let message;
    switch (this.props.CSVCreationState) {
      case 1:
        message = (
          <React.Fragment>
            <h3>The CSV is ready</h3>
            <br />
            <a href={this.props.csvUrl} target="_blank">
              {this.props.csvUrl}
            </a>
            Right click, save as ... to save the file
          </React.Fragment>
        );
        break;
      case 2:
        message = <h3>Something went wrong, please close this window and try again</h3>;
        break;
      case 0:
      default:
        message = <h3>The CSV is being crated, please wait a little while</h3>;
        break;
    }
    return (
      <Modal width="600px" height="375px" show={true} handleDismiss={this._handleCloseCSVDownloadModal}>
        {message}
        <div className="flex">
          <button onClick={this._handleCloseCSVDownloadModal} className="btn btn-primary">
            I am bored, close this window
          </button>
        </div>
      </Modal>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.props.showCSVDownloadModal ? this._csvModal() : null}
        {this.state.modalMessage !== null ? (
          <Modal show height="400px" width="500px">
            {this.state.modalTypeYesNo ? (
              <YesNoPopUp message={this.state.modalMessage} handleNo={this.handleCloseModal} handleYes={this.state.modalCallback} />
            ) : (
              <OkPopUp message={this.state.modalMessage} handleClose={this.handleCloseModal} />
            )}
          </Modal>
        ) : null}
        <MainContainer>
          <Table
            csv
            csvName="feedback"
            csvDownloadCallback={this._handleCSVDownloadClick}
            borders
            selectAll
            sortBy={this._handleSort}
            sortOrder={this.state.sortOrder}
            headings={CONSTANTS.TABLE_HEADINGS}
            rowData={this.rowData}
            showFooter
            footerItems={this.footerItems}
            searchbar
            noDataMessage={this.state.isSearching ? 'Searching...' : CONSTANTS.NO_DATA_MSG}
            paginate
            itemsCountPerPage={this.props.rowsPerPage}
            totalRows={this.props.totalRows}
            selectRowOnClick
            onPageChange={this._handlePageChange}
            onRowsPerPageChange={this._handlePageChange} //needs to be debounced
            activePage={this.props.activePage}
          ></Table>
        </MainContainer>
      </React.Fragment>
    );
  }
}

const storeToProps = (store) => {
  return {
    activePage: store.feedbackState.page,
    rowsPerPage: store.feedbackState.rpp,
    feedback: store.feedbackState.feedback,
    totalRows: store.feedbackState.totalFeedbacks,
    tracks: store.tracksState.tracks,
    playlists: store.playlistsState.playlists || [],
    clients: store.authState.clientsList,
    locations: store.locationsState.locations,
    userToken: store.authState.userInfo ? store.authState.userInfo.userToken : '',
    selectedFeedback: store.commonState.table.selectedRows,
    searchQuery: store.commonState.table.searchQuery,
    showCSVDownloadModal: store.feedbackState.showCSVDownloadModal,
    //0 creating, 1 created, 2 error
    CSVCreationState: store.feedbackState.CSVCreationState,
    csvUrl: store.feedbackState.csvUrl,
  };
};

export default connect(storeToProps)(Feedback);
