import React, { Fragment } from 'react';

import moment from 'moment';
import { connect } from 'react-redux';
import { CSVLink } from 'react-csv';
import Pagination from 'react-js-pagination';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import faAngleRight from '@fortawesome/fontawesome-free-solid/faAngleRight';
import faAngleLeft from '@fortawesome/fontawesome-free-solid/faAngleLeft';
import faAngleDoubleRight from '@fortawesome/fontawesome-free-solid/faAngleDoubleRight';
import faAngleDoubleLeft from '@fortawesome/fontawesome-free-solid/faAngleDoubleLeft';
import { TiArrowMinimise, TiArrowMaximise } from 'react-icons/ti';
import { FaDownload } from 'react-icons/fa';
import { Tooltip } from 'react-tippy';
import PageCounter from '@common/page-counter';
// import { isEqual } from 'lodash';
import 'react-tippy/dist/tippy.css';

import SearchBar from '@common/searchbar';
import store from '@store';
import { selectTableRows, toggleSelectTableRow, deselectAllTableRows, changeTableSearchQuery } from '@actions/common-actions';
import '@stylesheets/pagination.scss';

class CallbackTable extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      itemsCountPerPage: props.itemsCountPerPage || 50,
      sortByAscending: true,
      isExact: true,
    };
  }

  componentWillUnmount() {
    store.dispatch(deselectAllTableRows());
    store.dispatch(changeTableSearchQuery(''));
  }

  get isCompact() {
    return typeof this.props.compact === 'undefined' ? true : this.props.compact; // || document.documentElement.clientHeight <= 768;
  }

  get csvData() {
    const headings = this.props.headings && this.props.headings.length > 0 ? this.props.headings.map((heading) => heading.title) : [];
    const rows = this.props.rowData && this.props.rowData.length > 0 ? this.props.rowData.map((row) => row.data) : [];
    return [[...headings], ...rows];
  }

  get placeholder() {
    return (
      <tr className="text-center d-flex align-items-center justify-content-center">
        {this.props.loadingMessage ? (
          <td style={{ '--steps': this.props.loadingMessage.length }} className="animated infinite typewriter table-placeholder">
            {this.props.loadingMessage || 'No data'}
          </td>
        ) : (
          <td className="table-placeholder">{this.props.noDataMessage || 'No data'}</td>
        )}
      </tr>
    );
  }

  rowClass = (row) => {
    const borders = this.props.borders || false;
    let rowClass = this.isRowSelected(row) || row.isSelected ? row.isSelectedClass || 'row-highlight' : row.class ? row.class : '';
    rowClass = borders ? `${rowClass} borders` : rowClass;
    return rowClass;
  };

  get start() {
    return this.state.itemsCountPerPage * (this.state.activePage - 1);
  }

  get end() {
    const itemCount = !this.props.paginate && this.filteredRows.length > this.state.itemsCountPerPage ? this.filteredRows.length : this.state.itemsCountPerPage;
    return this.start + itemCount;
  }

  get filteredRows() {
    if (!this.props.rowData) {
      return [];
    }
    return this.props.rowData;
  }

  get paginatedRows() {
    return this.props.paginate && (this.props.itemsCountPerPage || this.state.itemsCountPerPage)
      ? this.filteredRows.slice(this.start, this.end)
      : this.filteredRows;
  }

  get rowData() {
    if (!this.props.rowData) return this.placeholder;
    const rowData = this.paginatedRows.map((row, i) => {
      return (
        <tr key={'row-' + i} className={this.rowClass(row)} onClick={() => this.handleClickRow(row)}>
          {row.data.map((cell, j) => (
            <td
              style={{ ...this.styles.td, ...this.props.styles }}
              className={this.props.borders ? 'borders' : ''}
              width={this.props.headings[j].width}
              key={'row-' + i + '-cell-' + j}
            >
              {cell && moment(cell).isValid() && this.props.headings && this.props.headings[j] && this.props.headings[j].date
                ? moment(cell).format(this.props.headings[j].format || 'DD-MM-YYYY, HH:mm')
                : cell}
            </td>
          ))}
        </tr>
      );
    });
    if (this.start > this.filteredRows.length) {
      this.handlePageChange(1);
    }
    return rowData;
  }

  get footer() {
    const footerItems = {
      left: <p className="table-footer-section"></p>,
      center: <p className="table-footer-section"></p>,
      right: <p className="table-footer-section"></p>,
      ...this.props.footerItems,
    };
    return (
      <div className="flex full-width">
        <div className="flex padding-top-sm full-width align-items-center justify-content-space-between">
          <div className="table-footer-section">{footerItems.left}</div>
          <div className="table-footer-section flex-space-evenly">{footerItems.center}</div>
          <div className="table-footer-section">{footerItems.right}</div>
        </div>
      </div>
    );
  }

  get csvDownloadButton() {
    return (
      <Tooltip title="Download page as CSV" position="top" trigger="mouseenter">
        <button className="btn btn-xs btn-primary">
          <CSVLink
            disabled={!this.props.rowData || this.props.rowData.length === 0}
            data={this.csvData}
            filename={this.props.csvName || window.location.pathname.replace(/\//g, '')}
          >
            <FaDownload size={18} color={!this.props.rowData || this.props.rowData.length === 0 ? 'grey' : 'black'} />
          </CSVLink>
        </button>
      </Tooltip>
    );
  }

  get csvDownloadCallbackButton() {
    return (
      <Tooltip title="Download entire data set as CSV" position="top" trigger="mouseenter">
        <button className="btn btn-xs btn-primary" onClick={this.props.csvDownloadCallback}>
          <FaDownload size={18} color={!this.props.rowData || this.props.rowData.length === 0 ? 'grey' : 'black'} />
        </button>
      </Tooltip>
    );
  }

  get pagination() {
    return (
      <div className="align-items-center" style={{ color: 'white' }}>
        <Pagination
          firstPageText={<FontAwesomeIcon icon={faAngleDoubleLeft} />}
          lastPageText={<FontAwesomeIcon icon={faAngleDoubleRight} />}
          prevPageText={<FontAwesomeIcon icon={faAngleLeft} />}
          nextPageText={<FontAwesomeIcon icon={faAngleRight} />}
          pageRangeDisplayed={3}
          activePage={this.props.activePage ? this.props.activePage : this.state.activePage}
          itemsCountPerPage={this.state.itemsCountPerPage}
          totalItemsCount={this.props.totalRows ? this.props.totalRows : this.filteredRows.length}
          onChange={this.handlePageChange}
        />
        <p className="margin-none" style={{ marginLeft: '10px' }}>
          Rows per page
          <select
            onChange={(e) => this.handleItemPerPageChange(e.target.value)}
            value={this.state.itemsCountPerPage}
            style={{ width: '50px', color: 'black', marginLeft: '3px' }}
          >
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </p>
        <PageCounter rows={this.props.totalRows} rpp={this.props.itemsCountPerPage} page={this.props.activePage} />
      </div>
    );
  }

  get createHeaderObjects() {
    return this.props.headerObjectsArray.map((obj, idx) => {
      return <Fragment key={idx}>{obj}</Fragment>;
    });
  }

  get selectAllButtons() {
    return (
      <Fragment>
        <Tooltip title="Deselect All" position="top" trigger="mouseenter">
          <button disabled={this.props.selectedRows.length === 0} className="btn btn-xs btn-dark" title="Deselect All" onClick={this.handleSelectNone}>
            <TiArrowMinimise color="black" size={20} />
          </button>
        </Tooltip>
        <Tooltip title="Select All" position="top" trigger="mouseenter">
          <button
            disabled={this.props.selectedRows.length === this.props.rowData.length}
            className="btn btn-xs btn-dark"
            title="Select All"
            onClick={this.handleSelectAll}
          >
            <TiArrowMaximise color="black" size={20} />
          </button>
        </Tooltip>
      </Fragment>
    );
  }

  get searchBar() {
    return (
      <SearchBar
        inputId="table-search"
        placeholder={
          this.props.placeholder ? this.props.placeholder : `Search by ${this.props.headings.map((heading) => heading.title.toLowerCase()).join(', ')}...`
        }
        searchHandler={() => null}
        onChange={this.onChangeSearchInput}
        value={this.props.searchQuery}
        checkboxTitle="Exact (match whole phrase)"
        width="90%"
        maxWidth={this.searchBarWidth}
        buttonStyle={{
          marginLeft: '-9px',
          zIndex: '99',
        }}
        clearButton
      />
    );
  }

  get searchBarWidth() {
    if (this.props.paginate && (this.props.csv || this.props.selectAll)) {
      return window.innerWidth / 2.5;
    }
    if (this.props.paginate || this.props.csv || this.props.selectAll) {
      return window.innerWidth / 2;
    }
    return window.innerWidth;
  }

  get tableActions() {
    const showSectionOne =
      this.props.searchbar || this.props.csv || this.props.selectAll || (this.props.paginate && this.props.rowData && this.props.rowData.length > 0);
    const showSectionTwo =
      this.props.actions || (this.props.selectAll && this.props.selectedRows.length > 0) || (this.props.showFooter && this.props.footerItems);
    return showSectionOne || showSectionTwo ? (
      <div style={{ ...this.styles.td, background: 'darkslategrey' }} className="flex-column text-white table-actions">
        {showSectionOne ? (
          <div
            style={{ background: 'darkslategrey', borderBottom: '1px solid rgb(175, 175, 175)' }}
            className="flex-space-between padding-bottom-sm text-white"
          >
            {this.props.csv || this.props.selectAll || this.props.addBtn ? (
              <div className={this.props.selectAll ? 'flex-space-evenly' : 'flex'} style={{ justifySelf: 'flex-this.start', flex: '0 1 150px' }}>
                {this.props.csv ? this.csvDownloadButton : null}
                {this.props.csvDownloadCallback ? this.csvDownloadCallbackButton : null}
                {this.props.selectAll ? this.selectAllButtons : null}
                {this.props.headerObjectsArray && Array.isArray(this.props.headerObjectsArray) ? this.createHeaderObjects : null}
              </div>
            ) : null}
            {this.props.searchbar ? (
              <div className="flex-center-center" style={{ justifySelf: 'center', flex: '1 0 auto' }}>
                {this.searchBar}
              </div>
            ) : null}
            {this.props.paginate && this.props.rowData && this.props.rowData.length > 0 ? (
              <div className="flex" style={{ justifySelf: 'flex-this.end' }}>
                {this.pagination}
              </div>
            ) : null}
          </div>
        ) : null}
        {this.props.selectAll && this.props.selectedRows.length > 0 ? (
          <p style={{ paddingLeft: '5px' }} className="text-left">
            <strong>{this.props.selectedRows.length}</strong> rows selected
          </p>
        ) : null}
        {!this.props.actions ? null : (
          <div style={{ background: 'darkslategrey' }} className="flex-space-evenly text-white padding-bottom-sm padding-top-sm">
            {this.props.actions}
          </div>
        )}
        {this.props.showFooter && this.props.footerItems ? (
          <div style={{ background: 'darkslategrey' }} className="flex-space-between text-white">
            {this.footer}
          </div>
        ) : null}
      </div>
    ) : null;
  }

  get styles() {
    const propStyles = this.props.style || {};
    return {
      table: {
        ...propStyles,
        width: this.props.width || '100%',
        maxWidth: this.props.maxWidth || '100%',
      },
      td: {
        padding: this.isCompact ? '10px' : '15px',
        fontSize: this.isCompact ? '11px' : '13px',
        textAlign: this.props.textAlign || 'center',
        wordBreak: 'break-word',
      },
      tbody: {
        height: typeof this.props.height === 'number' ? `${this.props.height}px` : this.props.height || 'auto',
      },
    };
  }

  handleClickRow = (row) => {
    if (row.onClick) {
      row.onClick();
    } else if (this.props.selectRowOnClick) {
      this.handleSelectRow(row);
    }
  };

  handleSelectAll = () => {
    // const rows = this.paginatedRows.map(row => row.data);
    store.dispatch(deselectAllTableRows());
    store.dispatch(selectTableRows(this.paginatedRows));
  };

  handleSelectRow = (row) => store.dispatch(toggleSelectTableRow(row));

  handleSelectNone = () => store.dispatch(deselectAllTableRows());

  // TODO: implement. could be tricky alongside handleClickRow
  handleClickCell = (cell) => {};

  handleToggleIsLoose = () => {
    const isExact = !this.state.isExact;
    this.setState({
      ...this.state,
      isExact,
    });
  };

  onChangeSearchInput = (e) => {
    store.dispatch(changeTableSearchQuery(e.target.value));
    if (this.props.onSearchChange) this.props.onSearchChange(e.target.value);
  };

  handlePageChange = (activePage) => {
    // debugger;
    this.props.onPageChange(activePage, this.state.itemsCountPerPage);
    store.dispatch(deselectAllTableRows());
  };

  handleItemPerPageChange = (value) => {
    if (value > 0) {
      const itemsCountPerPage = value === '' || value === 0 ? 1 : parseInt(value, 10);
      this.props.onPageChange(1, itemsCountPerPage);
      this.setState({
        ...this.state,
        itemsCountPerPage,
      });
    }
  };

  handleChangeSortOrder = (heading) => {
    this.setState({ sortByAscending: !this.state.sortByAscending });
    this.props.sortBy(heading.title, this.state.sortByAscending);
  };

  createHeaders = (headings) => {
    return headings.map((heading, idx) => {
      const headingInfo = headings.filter((h) => h.title === heading.title);
      const isSortable = headingInfo[0].sortable;
      if (isSortable) {
        return (
          <th
            onClick={() => this.handleChangeSortOrder(heading)}
            style={{ ...this.styles.td, cursor: 'pointer' }}
            width={heading.width}
            key={`table-header-${heading.title}-${idx}`}
          >
            {heading.title}
          </th>
        );
      } else {
        return (
          <th style={{ ...this.styles.td, cursor: 'no-drop' }} width={heading.width} key={`table-header-${heading.title}-${idx}`}>
            {heading.title}
          </th>
        );
      }
    });
  };

  isRowSelected = (row) => {
    return this.props.selectedRows.some((selectedRow) => JSON.stringify(selectedRow) === JSON.stringify(row));
  };

  render() {
    return (
      <Fragment>
        <div className="table-container margin-top-md">
          <table
            ref={(table) => {
              this.tableRef = table;
            }}
            style={this.styles.table}
            className="table-hover table-shadow table-fixed-head animated-no-fill delay04s fadeInDown margin-bottom-md"
          >
            {this.tableActions}
            <thead className="table-header">
              <tr>{this.createHeaders(this.props.headings)}</tr>
            </thead>
            <tbody style={this.styles.tbody}>{this.props.rowData && this.props.rowData.length === 0 ? this.placeholder : this.rowData}</tbody>
          </table>
        </div>
      </Fragment>
    );
  }
}

const storeToProps = (store) => {
  return {
    searchQuery: store.commonState.table.searchQuery,
    selectedRows: store.commonState.table.selectedRows,
    // showSpinner: store.layoutState.showSpinner,
  };
};

export default connect(storeToProps)(CallbackTable);
