import * as types from './types';

export const getCoreChannels = (payload) => {
  return {
    type: types.GET_CORE_CHANNELS,
    payload: payload
  }
};


export const getCoreChannelInfo = (payload) => {
  return {
    type: types.GET_CORE_CHANNEL_INFO,
    payload: payload
  }
}

export const changeCoreChannelSampler = (coreChannelId, samplers) => {
  return {
    type: types.CHANGE_CORE_CHANNEL_SAMPLERS,
    payload: {
      coreChannelId,
      samplers
    }
  }
}

export const changeCoreChannelsFilter = (payload) => {
  return {
    type: types.CHANGE_CORE_CHANNEL_FILTER,
    payload: payload
  }
}

export const startCheckForBatchUpdateFile = () => {
  return {
    type: types.START_CHECK_FOR_BATCH_UPDATE_FILE
  }
}

export const checkForBatchUpdateDone = (payload) => {
  return {
    type: types.CHECK_FOR_BATCH_UPDATE_FILE_DONE,
    payload: payload
  }
}

export const updateXlsFileName = (payload) => {
  return {
    type: types.UPDATE_XLS_FILE_NAME,
    payload: payload
  }
}

export const updateXlsJsonData = (payload) => {
  return {
    type: types.UPDATE_XLS_JSON_DATA,
    payload: payload
  }
}

export const batchUpdateStarted = () => {
  return {
    type: types.BATCH_UPDATE_STARTED
  }
}

export const batchUpdateSent = (payload) => {
  return {
    type: types.BATCH_UPDATE_SENT,
    payload: payload
  }
}

export const batchUpdateDone = ({ result, error }) => {
  let message = 'Tracks uploaded successfully'
  if (!result) message = `Upload failed, error message is: "${error}"`
  return {
    type: types.BATCH_UPDATE_DONE,
    payload: { result, message }
  }
}

export const batchUpdateSuccess = (payload) => {
  return {
    type: types.BATCH_UPDATE_SUCCESS,
    payload: payload,
  }
}

export const addTrackToCoreChannel = (payload) => {
  return {
    type: types.ADD_TRACK_TO_CORE_CHANNEL,
    payload: payload
  }
}

export const toggleActiveTrack = (payload) => {
  return {
    type: types.TOGGLE_ACTIVE_TRACK,
    payload: payload
  }
}

export const getSelectedCoreChannel = (payload) => {
  return {
    type: types.GET_SELECTED_CORE_CHANNEL_INFO,
    payload: payload
  }
}

export const getSelectedCoreChannelTracks = (payload) => {
  return {
    type: types.GET_SELECTED_CORE_CHANNEL_TRACKS,
    payload: payload
  }
}

export const activateCoreChannel = (payload) => {
  return {
    type: types.ACTIVATE_CORE_CHANNEL,
    payload: payload
  }
}

export const deactivateCoreChannel = (payload) => {
  return {
    type: types.DEACTIVATE_CORE_CHANNEL,
    payload: payload
  }
}

export const resetSelectedCoreChannel = () => {
  return {
    type: types.RESET_SELECTED_CORE_CHANNEL
  }
}

export const savingCoreChannel = () => {
  return {
    type: types.SAVING_CORE_CHANNEL
  }
}

export const savedCoreChannel = (error) => {
  return {
    type: types.SAVED_CORE_CHANNEL,
    payload: error
  }
}

export const dismissSavedCoreChannel = (error) => {
  return {
    type: types.DISMISS_SAVED_CORE_CHANNEL,
    payload: error
  }
}

export const onAddTrackToSamplers = (payload) => {
  return {
    type: types.ADD_TRACK_TO_SAMPLERS,
    payload: payload
  }
}

export const removeSampler = (payload) => {
  return {
    type: types.REMOVE_SAMPLER,
    payload: payload
  }
}

export const changeCoreChannelName = (payload) => {
  return {
    type: types.CHANGE_CORE_CHANNEL_NAME,
    payload: payload
  }
}

export const changeCoreChannelEnergyLevel = (payload) => {
  return {
    type: types.CHANGE_CORE_CHANNEL_ENERGY,
    payload: payload
  }
}

export const changeCoreChannelDescription = (payload) => {
  return {
    type: types.CHANGE_CORE_CHANNEL_DESCRIPTION,
    payload: payload
  }
}

export const creatingNewCoreChannel = (payload) => {
  return {
    type: types.CREATING_NEW_CORE_CHANNEL,
    payload: payload
  }
}

export const newCoreChannelCreated = (payload) => {
  return {
    type: types.NEW_CORE_CHANNEL_CREATED,
    payload: payload
  }
}

export const newCoreChannelFailed = (payload) => {
  return {
    type: types.NEW_CORE_CHANNEL_FAILED,
    payload: payload
  }
}

export const nameInUse = (payload) => {
  return {
    type: types.NEW_CORE_CHANNEL_NAME_IN_USE,
    payload: payload
  }
}

export const resetCoreChannelCreation = () => {
  return {
    type: types.RESET_CORE_CHANNEL_CREATION,
  }
}

export const updateEnergyLevels = (payload) => {
  return {
    type: types.UPDATE_ENERGY_LEVELS,
    payload: payload
  }
}

export const openSearchTrackModal = (payload) => {
  return {
    type: types.SHOW_TRACK_SEARCH_MODAL,
    payload: payload
  }
}

export const closeSearchTrackModal = () => {
  return {
    type: types.HIDE_TRACK_SEARCH_MODAL
  }
}