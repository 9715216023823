import * as types from './types';

export const loginSuccess = (payload) => {
  return {
    type: types.LOGIN_SUCCESS,
    payload: payload
  }
}

export const logoutSuccess = () => {
  return {
    type: types.LOGOUT_SUCCESS
  }
}

export const changeCurrentClientId = (payload) => {
  return {
    type: types.CHANGE_CURRENT_CLIENT_ID,
    payload: payload
  }
}

export const changeClientsList = (payload) => {
  return {
    type: types.CHANGE_CLIENTS_LIST,
    payload: payload
  }
}

export const updateClientRule = (rule, id) => {
  return ({
    type: types.UPDATE_CLIENT_COMMERCIAL_RULE,
    payload: {
      id,
      rule,
    }
  });
}
export const changeClientEmail = (id, email) => ({
  type: types.CHANGE_CLIENT_EMAIL,
  payload: {
    id,
    email,
  }
});

export const clientNameUpdated = (clientId, newName) => ({
  type: types.AUTH_CLIENT_NAME_UPDATED,
  payload: {
    clientId,
    newName,
  },
});