import * as types from '../actions/types';

const initalState = {
  feedback: [],
  selectedFeedback: [],
  page: 1,
  rpp: 50,
  totalFeedbacks: 0,
  showCSVDownloadModal: false,
  //0 creating, 1 created, 2 error
  CSVCreationState: 0,
  csvUrl: '',
};

export default (state = initalState, action) => {
  let selectedFeedback = new Set(state.selectedFeedback);
  const feedback = [...state.feedback];
  switch (action.type) {
    case types.SET_TABLE_CONFIG:
      return {
        ...state,
        rpp: action.payload.rpp,
        page: action.payload.page,
        totalFeedbacks: action.payload.totalResults
      }
    case types.CHANGE_FEEDBACK:
      return Object.assign({}, state, {
        feedback: action.payload,
      });
    case types.SELECT_FEEDBACK:
      const newValues = Array.isArray(action.payload) ? action.payload : [action.payload];
      const values = new Set([...state.selectedFeedback, ...newValues]);
      return Object.assign({}, state, {
        selectedFeedback: Array.from(values)
      });
    case types.DESELECT_FEEDBACK:
      selectedFeedback.delete(action.payload);
      return Object.assign({}, state, {
        selectedFeedback: Array.from(selectedFeedback)
      });
    case types.DESELECT_ALL_FEEDBACK:
      return Object.assign({}, state, {
        selectedFeedback: []
      });
    case types.CREATING_FEEDBACK_CSV: {
      return Object.assign({}, state, {
        CSVCreationState: 0,
        showCSVDownloadModal: true,
      });
    }
    case types.FEEDBACK_CSV_CREATED: {
      return Object.assign({}, state, {
        CSVCreationState: 1,
        showCSVDownloadModal: true,
        csvUrl: action.payload,
      });
    }
    case types.FEEDBACK_CSV_CREATION_FAILED: {
      return Object.assign({}, state, {
        CSVCreationState: 2,
      });
    }
    case types.FEEDBACK_CSV_CLOSE_MODAL: {
      return Object.assign({}, state, {
        showCSVDownloadModal: false,
      });
    }
    default:
      return state;
  }

};
