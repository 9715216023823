import React, {Component, Fragment} from 'react';
import Select from 'react-select';
import { CLONE_OPTIONS } from '../../../config';

import Backdrop from '@layout_2/Backdrop/backdrop';
class ScheduleAddSchedulePopup extends Component {
  constructor(props) {
    super(props);
    let initialAreas = [];
    this.state = {
      isCloneFromSource: false,
      cloneSource: CLONE_OPTIONS.SCHEDULE,
      selectedSchedule: '',
      selectedArea: ''
    };

    this._handleAdd = this._handleAdd.bind(this);
    this._toggleIsCloneFromSource = this._toggleIsCloneFromSource.bind(this);
    this._setSelectedSchedule = this._setSelectedSchedule.bind(this);
    this._setSelectedArea = this._setSelectedArea.bind(this);
  }

  _handleAdd() {
    if (this.refs.newScheduleName.value) {

      if (!this.state.isCloneFromSource) {
        this.props.handleAddSchedule(this.refs.newScheduleName.value);
        this._resetDialog();
      } else {
        if (this.state.cloneSource === CLONE_OPTIONS.SCHEDULE && !this.state.selectedSchedule ||
            this.state.cloneSource === CLONE_OPTIONS.AREA && !this.state.selectedArea) {
          alert('Please make sure to specify where to clone from');
        } else {
          this.props.handleAddSchedule(this.refs.newScheduleName.value, this.state.cloneSource, this.state.cloneSource === CLONE_OPTIONS.SCHEDULE ? this.state.selectedSchedule : this.state.selectedArea );
          this._resetDialog();
        }
      }
      
    } else {
      alert('Please provide a name');
    } 
  }

  _resetDialog() {
    this.refs.newScheduleName.value = '';
    this.setState({
      isCloneFromSource: false,
      cloneSource: CLONE_OPTIONS.SCHEDULE,
      selectedSchedule: '',
      selectedArea: ''
    });
  }

  _setSelectedSchedule(selected) {
    this.setState({ selectedSchedule: selected.value });
  }

  _setSelectedArea(selected) {
    this.setState({ selectedArea: selected.value });
  }

  _toggleIsCloneFromSource() {
    this.setState({ isCloneFromSource: !this.state.isCloneFromSource });
  }

  _setCloneSource(newValue) {
    this.setState({ cloneSource: newValue || CLONE_OPTIONS.SCHEDULE, selectedSchedule: '', selectedArea: '' });
  }

  render() {

    let areasList = [].concat(...this.props.locations.map((item) =>  item.areas.map((i) => { i.parent_location = item.name; return i; }) ));
    return (
      <Fragment>
        <Backdrop show={this.props.isVisible} hide={this.props.handleCloseAddSchedule} />
        <div style={{ marginTop: '-100px' }} className={this.props.isVisible ? "confirmation-dialog modal-alert dark animated flipInX" : "confirmation-dialog modal-alert dark animated flipOutX"}>
          <h2 className="text-center">Create a new weekly schedule for current client</h2>
          <div className="row">
            <div className="col-sm-6 add-schedule-popup-form-element">Name</div>
            <div className="col-sm-6 add-schedule-popup-form-element"><input autoFocus ref="newScheduleName" id="add-schedule-name" type="text" placeholder="Type the schedule name here" /></div>
            <div className="col-sm-6 add-schedule-popup-form-element">Import from existing source </div>
            <div className="col-sm-6 add-schedule-popup-form-element">
              <input type="checkbox" checked={this.state.isCloneFromSource} onChange={this._toggleIsCloneFromSource} />
            </div>
            {this.state.isCloneFromSource && (
              <div>
                <div className="col-sm-6 add-schedule-popup-form-element">Choose source to clone from</div>
                <div className="col-sm-6 add-schedule-popup-form-element">
                  <label>
                    <input type="radio" value="schedule" checked={this.state.cloneSource === CLONE_OPTIONS.SCHEDULE} onChange={() => this._setCloneSource(CLONE_OPTIONS.SCHEDULE)} name="clone-source" />
                    <span>Schedule</span>
                  </label>
                  <label>
                    <input type="radio" value="area" checked={this.state.cloneSource === CLONE_OPTIONS.AREA} onChange={() => this._setCloneSource(CLONE_OPTIONS.AREA)} name="clone-source" />
                    <span>Area</span>
                  </label>
                </div>

                {this.state.cloneSource === CLONE_OPTIONS.SCHEDULE && (
                  <div className="col-sm-6 add-schedule-popup-form-element">Clone from this schedule</div>
                )}
                {this.state.cloneSource === CLONE_OPTIONS.SCHEDULE && (  
                  <div className="col-sm-6 add-schedule-popup-form-element">
                    <Select options={this.props.schedules.map((item, index) => ({value: item._id, label: item.name}))}
                          clearable={false}
                          searchable={true}
                          value={this.state.selectedSchedule}
                          onChange={this._setSelectedSchedule} />
                  </div>
                )}
                
                {this.state.cloneSource === CLONE_OPTIONS.AREA && (
                  <div className="col-sm-6 add-schedule-popup-form-element">Clone the old schedule for this area</div>
                )}
                {this.state.cloneSource === CLONE_OPTIONS.AREA && (  
                  <div className="col-sm-6 add-schedule-popup-form-element">
                    <Select options={areasList.map((item, index) => ({value: item.area_id, label: `${item.name} (${item.parent_location})` }))}
                          clearable={false}
                          searchable={true}
                          value={this.state.selectedArea}
                          onChange={this._setSelectedArea} />
                  </div>
                )}
              </div>
            )}
          </div>
          
          
          <button style={{ marginRight: '10px' }} onClick={this._handleAdd} className="btn btn-primary btn-save">Save</button>
          <button onClick={this.props.handleCloseAddSchedule} className="btn btn-default">Cancel</button>
        </div>
      </Fragment>
    );
  }
}

export default ScheduleAddSchedulePopup;