import * as React from 'react';
const Loading = (props) => {
  if (props.error) {
    console.log(props.error);
    return (
      <div className="screen-container flex-center-center">
        <p className="margin-bottom-xs">Error</p>
        <button className="btn" onClick={props.retry}>Retry</button>
      </div>
    );
  }
  if (props.timedOut) {
    return (
      <div className="screen-container loading flex-center-center">
        <p className="margin-bottom-xs">Taking a long time...</p>
        <button className="btn" onClick={props.retry}>Retry</button>
      </div>
    );
  }
  if (props.pastDelay) {
    return (
      <div className="screen-container loading flex-center-center">
        <p className="margin-bottom-xs">Loading...</p>
      </div>
    );
  }
  return null;
};

export default Loading;