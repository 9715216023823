
import React from 'react';

export default (props) => (
  <section className="main">
    <div className="container">
      { props.children }
    </div>
  </section>
)
