import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import store from './store';
import router from './router';
import { Router } from 'react-router-dom';
import history from './history';
import 'react-dates/initialize';



//
// Boootstrap the application using redux and react-router
//
render(
  <Router history={history}>
    <Provider store={store}>{router}</Provider>
  </Router>,
  document.getElementById('app')
);
