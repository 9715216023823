
import React from 'react';

const PlayingBars = props => (
  <div title={props.title || 'Music is playing'} className="playing-container flex" >
    <ol className="playing-column">
      <li className="playing-bar"></li>
    </ol>
    <ol className="playing-column">
      <li className="playing-bar"></li>
    </ol>
    <ol className="playing-column">
      <li className="playing-bar"></li>
    </ol>
  </div>
);

export default PlayingBars;
