import * as React from "react";

import { connect } from "react-redux";
import autoBind from "react-autobind";
import moment from "moment";
import { AiFillFileAdd } from "react-icons/ai";
import { FaUnlock, FaBan, FaEnvelope } from "react-icons/fa";
import {
  MdLink,
  MdHourglassFull,
  MdVpnKey,
  MdEdit,
  MdClose,
  MdSave,
} from "react-icons/md";
import { Typeahead } from "react-bootstrap-typeahead";
import TimezoneHelper from "@ambie/ambie-npm-timezone-helper";

import browserHistory from "@history";
import store from "@store";
// component imports
import YesNoPopUp from "@common/generic-yes-no-popup";
import Modal from "@layout_2/Modal/modal";
import AreasListItem from "./areas-list-item";
import LocationsChangeSchedulePopup from "./locations-change-schedule";
import ChangeDevicePopup from "./locations-change-device-popup";
import ChangeDeviceConfirmationPopup from "./locations-change-device-confirmation-popup";
import CommercialRulesPopup from "@client/locations/commercial-rules-popup";
import SfExpansionPopup from "./sf-expansion-popup";
// api imports
import * as authApi from "@api/auth-api";
import * as locationsApi from "@api/locations-api";
import * as schedulesApi from "@api/schedules-api";
import * as deviceStatusApi from "@api/device-status-api";
import * as coreChannelApi from "@api/core-channels-api";
import { postCommercialRule } from "@api/commercial-rules-api";

// react actions
import {
  changeLocationSerachQuery,
  changeLocations,
  changeShowChangeScheduleDialog,
  changeAreaScheduleInfo,
  changeLocationEmail,
  changeSelectedOverridePlaylist,
  changeSelectedOverrideDuration,
} from "@actions/locations-actions";
import * as locationActions from "@actions/locations-actions";
import {
  showSpinner,
  hideSpinner,
  showFlashMessage,
  showModal,
  hideModal,
} from "@actions/layout-actions";
import { changeTimezone } from "@actions/schedules-actions";
import {
  changeClientEmail,
  changeCurrentClientId,
} from "@actions/auth-actions";
import * as commercialRulesActions from "@actions/commercial-rules-actions";

import WebSocketHelper from "@helpers/WebSocketHelper/WebSocketHelper";

const DURATIONS = [0.25, 0.5, 1, 2, 3, 4, 5, 6, 7, 8];

class LocationsMain extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      curentArea: {},
      commercialRulesData: null,
      type: "client",
      id: null,
      name: null,
      selectedUser: {},
      showExpansionModal: false,
      showTimezoneModal: false,
      showChangeEmailModal: false,
      showToggleStreamingModal: false,
      locationToEdit: null,
      deviceType: null,
      showToggleDuplicatePlays: false,
      currentDeviceId: "",
      displayHelpMessage: false,
      showOverrideModal: false,
      isEditingClientName: false,
      newClientName: "",
      isEditingBusinessName: false,
      newBusinessName: "",
      businessNameInputBorderColor: "#CCCCCC",
      clientNameInputBorderColor: "#CCCCCC",
      saving: false,
    };
    this.getLocationsInterval = null;
  }

  // Lifecycle functions
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.currentClientId !== nextProps.currentClientId) {
      locationsApi.getLocations(
        this.props.userToken,
        nextProps.currentClientId
      );
      schedulesApi.getSchedules(
        this.props.userToken,
        nextProps.currentClientId
      );
    }
  }

  async componentDidMount() {
    if (this.props.userToken) {
      WebSocketHelper.init(this.props.userToken);
      await locationsApi.getLocations(
        this.props.userToken,
        this.props.currentClientId
      );
      await schedulesApi.getSchedules(
        this.props.userToken,
        this.props.currentClientId
      );
      coreChannelApi.getCoreChannelsList(this.props.userToken);
      if (this.props.match.params.clientId) {
        this._changeClientIdFromUrl();
        schedulesApi.getPlaylistsByClientId(
          this.props.userToken,
          this.props.currentClientId
        );
      } else if (this.props.clientsList && this.props.clientsList[0]) {
        const clientId = this.props.clientsList[0].id;
        store.dispatch(changeCurrentClientId(clientId));
      }
    }
    this.getLocationsInterval = this.initGetLocationsInterval();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.match.params.clientId &&
      this.props.match.params.clientId !== prevProps.match.params.clientId
    ) {
      this._changeClientIdFromUrl();
      schedulesApi.getPlaylistsByClientId(
        this.props.userToken,
        this.props.currentClientId
      );
    }
  }

  componentWillUnmount() {
    window.clearInterval(this.getLocationsInterval);
  }

  _changeClientIdFromUrl() {
    if (
      this.props.match.params.clientId &&
      parseInt(this.props.match.params.clientId) !==
        parseInt(this.props.currentClientId)
    ) {
      const clientId = parseInt(this.props.match.params.clientId);
      store.dispatch(changeCurrentClientId(clientId));
    }
  }

  _handleUpdatePlayer(playerId) {
    deviceStatusApi.updateDevice(this.props.userToken, playerId);
  }

  // Commercial rules functions for when to open close the modal and
  // retrienve data from the store
  async _sendCommercialRulesToS3(id, type, rule) {
    this.setState({ ...this.state, commercialRulesData: rule, saving: true });
    await postCommercialRule(this.props.userToken, id, type, rule);
    this.setState({ saving: false });
  }

  _closeExpansionModal() {
    this.setState({ showExpansionModal: false });
  }

  _openExpansionModal() {
    this.setState({ showExpansionModal: true });
  }

  _closeModal() {
    this.setState({ commercialRulesData: null });
    store.dispatch(commercialRulesActions.closeCommercialRulesModal());
    this.getLocationsInterval = this.initGetLocationsInterval();
  }

  _openModal() {
    store.dispatch(commercialRulesActions.openCommercialRulesModal());
  }

  _handleCommercialRulesPopup = (area) => {
    // debugger;
    this.setState(
      {
        commercialRulesData:
          area.commercialRule && area.commercialRule[0]
            ? area.commercialRule
            : null,
        type: "area",
        id: area.area_id,
        name: area.name,
        isFromClient: false,
      },
      () => {
        this._openModal();
      }
    );
    window.clearInterval(this.getLocationsInterval);
  };

  _handleCommercialRulesPopupClient = (client) => {
    // debugger;
    this.setState(
      {
        commercialRulesData:
          client.rules && client.rules[0] ? client.rules : null,
        type: "client",
        id: client.id,
        name: client.name,
        isFromClient: true,
      },
      () => {
        this._openModal();
      }
    );
    window.clearInterval(this.getLocationsInterval);
  };

  _handleOpenPapertrail(playerId, isStreaming = false) {
    window.open(
      `https://papertrailapp.com/systems/${playerId}/events`,
      "_blank"
    );
  }

  _handleLocationsSearch() {
    store.dispatch(
      changeLocationSerachQuery(this.refs.searchLocations.value || "")
    );
  }

  _handleAutoplayChange(locationIndex, areaIndex, area, isActive) {
    if (isActive) {
      const updatedLocations = this.props.locations.slice();
      const newValue =
        updatedLocations[locationIndex].areas[areaIndex].device.autoplay ===
        "on"
          ? "off"
          : "on";
      updatedLocations[locationIndex].areas[areaIndex].device.autoplay =
        newValue;
      store.dispatch(changeLocations(updatedLocations));
      locationsApi.updateAutoplay(
        updatedLocations[locationIndex].areas[areaIndex].device.serial,
        this.props.userToken,
        newValue,
        this.props.currentClientId
      );
    }
  }

  _handleViewSchedule(scheduleId, timezone) {
    schedulesApi.getSelectedSchedule(scheduleId, this.props.userToken);
    if (timezone && timezone.name) {
      store.dispatch(
        changeTimezone({
          timezoneName: timezone.name,
        })
      );
    }
    browserHistory.push(`/schedules/${this.props.currentClientId}`);
  }

  _checkIfQueryMatches(item) {
    if (
      item.name &&
      item.name.toLowerCase().indexOf(this.props.searchQuery.toLowerCase()) >= 0
    )
      return true;

    if (item.areas) {
      for (let i = item.areas.length - 1; i >= 0; i--) {
        if (
          item.areas[i].name &&
          item.areas[i].name
            .toLowerCase()
            .indexOf(this.props.searchQuery.toLowerCase()) >= 0
        ) {
          return true;
        }
      }
    }

    return false;
  }

  _handleChangeSchedule(e, area, locationId) {
    store.dispatch(
      changeAreaScheduleInfo({
        scheduleId: e.target.value || "",
        scheduleName: e.target.selectedOptions[0].innerHTML || "",
        areaId: area.area_id || "",
        areaName: area.name || "",
        locationId: locationId || "",
      })
    );
    store.dispatch(changeShowChangeScheduleDialog(true));
  }

  _handleScheduleChangeCancel(e) {
    e.preventDefault();
    store.dispatch(changeShowChangeScheduleDialog(false));
  }

  _handleScheduleChangeSubmit(e) {
    e.preventDefault();
    store.dispatch(changeShowChangeScheduleDialog(false));
    locationsApi.assignScheduleToArea(
      this.props.userToken,
      this.props.currentClientId,
      this.props.areaScheduleInfo.areaId,
      this.props.areaScheduleInfo.locationId,
      this.props.areaScheduleInfo.scheduleId
    );
  }

  _handleResendSchedule(e, areaId, isActive, queue) {
    e.preventDefault();
    if (isActive) {
      locationsApi.resendSchedule(
        this.props.currentClientId,
        areaId,
        this.props.userToken
      );
    }
  }

  _handleOpenChangeTimeZone(locationID) {
    // debugger;
    this.setState({
      showTimezoneModal: true,
      locationToEdit: locationID,
    });
  }

  _handleChangeTimezone(timezone) {
    // debugger;
    locationsApi.updateTimezoneForLocation(
      this.state.locationToEdit,
      timezone[0],
      this.props.userToken
    );
    this._hideTimezoneModal();
  }

  _hideTimezoneModal() {
    this.setState({
      showTimezoneModal: false,
      locationToEdit: null,
    });
  }

  _handleChangeDeviceType(e) {
    this.setState({ deviceType: e.target.value });
  }

  _handleChangeDevice(e, areaId, areaName) {
    e.preventDefault();
    this.setState({
      curentArea: { areaId, areaName },
      showChangeDevicePopup: true,
    });
  }

  _handleCancelChangeDevice() {
    this.setState({
      ...this.state,
      currentDeviceId: "",
      showChangeDevicePopup: false,
    });
  }

  _handleChangeDeviceSave() {
    if (!this.state.currentDeviceId) {
      alert("Device serial number is mandatory");
    } else {
      this.setState({
        ...this.state,
        showChangeDeviceConfirmationPopup: true,
        webPlayerDeviceCode: "",
      });
    }
  }

  _handleChangeDeviceInput(e) {
    this.setState({ ...this.state, currentDeviceId: e.target.value });
  }

  _handleDisplayHelpMessage(bool) {
    console.log("_handleDisplayHelpMessage", bool);
    this.setState({ displayHelpMessage: bool });
  }

  async _handleChangeDeviceSaveConfirmed() {
    this.setState({
      ...this.state,
      showChangeDevicePopup: false,
      showChangeDeviceConfirmationPopup: false,
      currentDeviceId: "",
      deviceType: null,
    });
    const { curentArea, currentDeviceId, deviceType } = this.state;
    const { userToken, currentClientId } = this.props;
    await locationsApi.changeDevice(
      curentArea.areaId,
      currentDeviceId,
      userToken,
      currentClientId,
      deviceType
    );
    this.setState({
      ...this.state,
      currentDeviceId: "",
    });
  }

  _handleCancelChangeDeviceConfrimation() {
    this.setState({
      ...this.state,
      showChangeDeviceConfirmationPopup: false,
      currentDeviceId: "",
    });
  }

  _handleActivateLocation(locationID) {
    store.dispatch(
      locationActions.updateActivationModal(
        "Are you sure you want to activate the location?",
        true,
        locationsApi.activateLocation.bind(
          this,
          locationID,
          this.props.userToken
        )
      )
    );
  }

  _handleDeactivateLocation(locationID) {
    store.dispatch(
      locationActions.updateActivationModal(
        "Are you sure you want to deactivate the location?",
        true,
        locationsApi.deactivateLocation.bind(
          this,
          locationID,
          this.props.userToken
        )
      )
    );
  }

  handleSendResetEmail = (location, superuser = false) => {
    const email = location.email;
    const id = location.id;
    const type = superuser ? "admin" : "manager";
    const expiry = 1; // days
    if (email) {
      store.dispatch(
        locationActions.updateActivationModal(
          `Do you want to send a password reset email to ${email}?`,
          true,
          () => {
            const hostname = window.location.hostname.split(".");
            const prefix = hostname[0] === "olympus" ? "" : `${hostname[0]}.`;
            const username = this.props.userInfo.user;
            authApi.requestPasswordReset(
              email,
              id,
              type,
              expiry,
              true,
              `${prefix}dashboard.ambie.fm`,
              username
            );
            this._hideActivationModal();
          }
        )
      );
    } else {
      store.dispatch(
        locationActions.updateActivationModal(
          `No email address on file for this ${
            superuser ? "client" : "location"
          }. Would you like to add one now?`,
          true,
          () => {
            this._hideActivationModal();
            this.handleShowChangeEmailModal(location, superuser);
          }
        )
      );
    }
  };

  handleGenerateResetLink = (location, superuser = false) => {
    const email = location.email || null;
    const id = location.id;
    const type = superuser ? "admin" : "manager";
    const expiry = 14; // days
    store.dispatch(
      locationActions.updateActivationModal(
        `Do you want to generate a password reset link for ${location.name}?`,
        true,
        async () => {
          const hostname = window.location.hostname.split(".");
          const prefix = hostname[0] === "olympus" ? "" : `${hostname[0]}.`;
          const username = this.props.userInfo.user;
          try {
            this._hideActivationModal();
            store.dispatch(showSpinner());
            const token = await authApi.requestPasswordReset(
              email,
              id,
              type,
              expiry,
              false,
              `${prefix}dashboard.ambie.fm`,
              username
            );
            store.dispatch(hideSpinner());
            if (!token) {
              throw new Error("Error generating token");
            }
            const link = `http://${prefix}dashboard.ambie.fm/reset-password?token=${token}`;
            store.dispatch(
              showModal({
                title: `Reset password link for ${location.name}`,
                message: link,
                noText: "Close",
                yesText: "Copy",
                handleYes: async () => {
                  await navigator.clipboard.writeText(link);
                  store.dispatch(
                    showFlashMessage({
                      status: "success",
                      message: "Link copied!",
                      duration: 3000,
                    })
                  );
                },
                type: "yesNo",
              })
            );
          } catch (e) {
            store.dispatch(hideSpinner());
            this._hideActivationModal();
            store.dispatch(
              showModal({
                title: `Error getting new password link`,
                message: `Please try again.`,
                type: "ok",
              })
            );
          }
        }
      )
    );
  };

  handleChangeEmail = async () => {
    const userId = this.state.selectedUser.id;
    const email = this.state.selectedUser.email;
    const superuser = this.state.selectedUser.superuser;
    const userToken = this.props.userToken;
    this.handleHideChangeEmailModal();
    const changed = await authApi.changeEmailAddress(
      userToken,
      userId,
      email,
      superuser
    );
    if (changed) {
      store.dispatch(
        showFlashMessage({
          status: "success",
          message: "Email address updated",
          duration: 5000,
        })
      );
      superuser
        ? store.dispatch(changeClientEmail(userId, email))
        : store.dispatch(changeLocationEmail(userId, email));
    } else {
      store.dispatch(
        showModal({
          animation: "fade-in",
          title: "Error updating email address",
          message:
            "There was a problem updating the email address, or the email address may already be in use",
          handleYes: null,
          handleNo: null,
          handleDismiss: null,
          type: "ok",
        })
      );
    }
  };

  isEmailValid(email = this.state.selectedUser.email) {
    return /.+@.+\.\w{2,}/.test(email);
  }

  getCommercialRuleStatus = (rules) => {
    if (rules === undefined || (rules && rules.length === 0)) {
      return <MdHourglassFull style={{ color: "grey" }} />;
    }

    let isActive = false;
    const now = Date.now();

    for (let rule of rules) {
      const { startDate, endDate } = rule;
      if (startDate && endDate) {
        const start = new Date(
          startDate.toString().length === 10 ? startDate * 1000 : startDate
        );
        const end = new Date(
          endDate.toString().length === 10 ? endDate * 1000 : endDate
        );
        if (start < now && now < end) {
          isActive = true;
          break;
        }
      }
    }
    return isActive ? (
      <MdHourglassFull style={{ color: "forestgreen" }} />
    ) : (
      <MdHourglassFull style={{ color: "#f26d80" }} />
    );
  };

  handleShowChangeEmailModal = (user, superuser = false) => {
    const selectedUser = {
      ...user,
      superuser,
    };
    this.setState({
      ...this.state,
      selectedUser,
      showChangeEmailModal: true,
    });
  };

  handleHideChangeEmailModal = () => {
    this.setState({
      ...this.state,
      selectedUser: {},
      showChangeEmailModal: false,
    });
  };

  _handleActivateArea(areaId) {
    store.dispatch(
      locationActions.updateActivationModal(
        "Are you sure you want to activate the area?",
        true,
        locationsApi.activateArea.bind(this, areaId, this.props.userToken)
      )
    );
  }

  _handleDeactivateArea(areaId) {
    // console.log("Deactivate areaId",areaId);
    store.dispatch(
      locationActions.updateActivationModal(
        "Are you sure you want to deactivate the area?",
        true,
        locationsApi.deactivateArea.bind(this, areaId, this.props.userToken)
      )
    );
  }

  _hideActivationModal() {
    store.dispatch(locationActions.hideActivationModal());
  }

  _sortByName(a, b) {
    const nameA = a.name.toUpperCase(); // ignore upper and lowercase
    const nameB = b.name.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  }

  handleToggleStreaming = async (area) => {
    // TODO: add streaming status to area object
    area && area.streaming && area.streaming.enabled
      ? await locationsApi.disableStreaming(area.area_id, this.props.userToken)
      : await locationsApi.enableStreaming(area.area_id, this.props.userToken);
    this.handleToggleStreamingModal();
  };

  handleToggleStreamingModal = (currentArea) => {
    const showToggleStreamingModal = !this.state.showToggleStreamingModal;
    this.setState({
      ...this.state,
      currentArea,
      showToggleStreamingModal,
    });
  };

  handleToggleDuplicatePlayModal = (currentArea) => {
    const showToggleDuplicatePlays = !this.state.showToggleDuplicatePlays;
    this.setState({
      ...this.state,
      currentArea,
      showToggleDuplicatePlays,
    });
  };

  handleChangeDuplicatePlay = async (currentArea, allow) => {
    //console.log(currentArea, allow);
    currentArea && currentArea.device && allow
      ? await locationsApi.enableDuplicatesPlay(
          currentArea.area_id,
          currentArea.device.serial,
          this.props.userToken
        )
      : await locationsApi.disableDuplicatesPlay(
          currentArea.area_id,
          currentArea.device.serial,
          this.props.userToken
        );
    this.handleToggleDuplicatePlayModal();
  };

  handleGetWebPlayerThingName = async () => {
    const thing = await locationsApi.generateWebPlayerThing(
      this.props.userToken
    );
    this.setState({
      ...this.state,
      currentDeviceId: thing.deviceSerial,
      webPlayerDeviceCode: thing.deviceCode,
    });
  };

  handleToggleOverrideModal = (currentArea, currentDeviceId) => {
    const showOverrideModal = !this.state.showOverrideModal;
    this.setState({
      ...this.state,
      showOverrideModal,
      currentDeviceId,
      currentArea,
    });
  };

  handleSelectOverridePlaylist = (playlistId) => {
    store.dispatch(changeSelectedOverridePlaylist(playlistId));
  };

  handleSelectOverrideDuration = (duration) => {
    store.dispatch(changeSelectedOverrideDuration(duration));
  };

  handleOverride = async () => {
    await locationsApi.sendOverride(
      this.props.selectedOverridePlaylist,
      this.props.selectedOverrideDuration,
      this.state.currentArea.area_id,
      this.state.currentDeviceId,
      this.props.userToken
    );
    this.setState({
      ...this.state,
      showOverrideModal: false,
      currentArea: {},
    });
  };

  getLocations = () =>
    locationsApi.getLocations(
      this.props.userToken,
      this.props.currentClientId,
      false
    );

  initGetLocationsInterval = () =>
    window.setInterval(() => this.getLocations(), 60000);

  handleUpdatePlayerStatus = async (status, volume, deviceSerial) => {
    await locationsApi.updatePlayerStatus(this.props.userToken, deviceSerial, {
      status,
      volume,
    });
    locationsApi.getLocations(
      this.props.userToken,
      this.props.currentClientId,
      false
    );
  };

  isDeviceOnline = (device) => {
    if (!device || (device && !device.last_seen)) {
      return false;
    }
    const now = moment();
    const lastSeen = moment(device.last_seen);
    return now.diff(lastSeen, "minutes") < 10;
  };

  handleShowRemoveDeviceModal = (deviceSerial) => {
    store.dispatch(
      showModal({
        title: `Remove device from area`,
        message: `Are you sure you want to remove the device from this area?`,
        type: "yesNo",
        handleYes: async () => {
          try {
            await this.handleRemoveDevice(deviceSerial);
            store.dispatch(hideModal());
            store.dispatch(
              showFlashMessage({
                status: "success",
                message: "Device removed succesfully",
                duration: 3000,
              })
            );
          } catch (e) {
            store.dispatch(hideModal());
            store.dispatch(
              showFlashMessage({
                status: "error",
                message:
                  "Error removing the device form this area, please try again",
                duration: 3000,
              })
            );
          }
        },
      })
    );
  };

  handleShowUpdateSoftwareModal = (playerId) => {
    store.dispatch(
      showModal({
        title: `Update software version?`,
        message: `Are you sure you want to update? This will cause disruption to the music for a few minutes.`,
        type: "yesNo",
        handleYes: async () => {
          try {
            await this.handleUpdateSoftware(playerId);
            store.dispatch(hideModal());
            store.dispatch(
              showFlashMessage({
                status: "success",
                message: "Update software command sent to player",
                duration: 3000,
              })
            );
          } catch (e) {
            store.dispatch(hideModal());
            store.dispatch(
              showFlashMessage({
                status: "error",
                message: "Error sending update software command to player",
                duration: 3000,
              })
            );
          }
        },
      })
    );
  };

  handleUpdateSoftware = (playerId) =>
    deviceStatusApi.updateDevice(this.props.userToken, playerId);

  handleRemoveDevice = (deviceSerial) =>
    locationsApi.removeDeviceFromArea(
      this.props.userToken,
      this.props.currentClientId,
      deviceSerial
    );

  get clientHeader() {
    const currentClient = this.props.clientsList.find(
      (item) => item.id == this.props.currentClientId
    );
    return (
      <React.Fragment>
        {currentClient ? (
          <React.Fragment>
            {this.state.isEditingClientName ? (
              <h1
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                You are managing
                <input
                  type="text"
                  style={{ borderColor: this.state.clientNameInputBorderColor }}
                  className="form-control change-client-name"
                  value={this.state.newClientName}
                  onChange={this.onUpdateClientName}
                />
                <MdClose
                  size={22}
                  color="black"
                  onClick={this.closeEditClientName}
                  style={{ cursor: "hand" }}
                />
                {this.state.newClientName.length > 0 && (
                  <MdSave
                    size={22}
                    color="black"
                    style={{ cursor: "hand" }}
                    onClick={this.saveClientName}
                  />
                )}
              </h1>
            ) : (
              <h1 className="client-header">
                You are managing {currentClient.name}
                <div style={{ marginLeft: "5px", marginRight: "5px" }}>
                  {IS_OLYMPUS ? (
                    <MdEdit
                      size={22}
                      color="black"
                      onClick={(e) => {
                        this.openEditClientName(currentClient.name);
                      }}
                      style={{ cursor: "hand" }}
                    />
                  ) : null}
                  {IS_OLYMPUS ? (
                    <button
                      className="btn-clear"
                      onClick={() =>
                        this._handleCommercialRulesPopupClient(currentClient)
                      }
                      title="Open Commercial Injection Rules"
                    >
                      {/* commercial rules button */}
                      {this.getCommercialRuleStatus(currentClient.rules)}
                    </button>
                  ) : null}
                  <button
                    className="btn-clear"
                    onClick={() => {
                      this.handleShowChangeEmailModal(currentClient, true);
                    }}
                    title="Change email address"
                  >
                    <FaEnvelope
                      style={{
                        color: currentClient.email ? "forestgreen" : "gray",
                      }}
                    />
                  </button>
                  <button
                    className="btn-clear"
                    onClick={() => {
                      this.handleSendResetEmail(currentClient, true);
                    }}
                    title="Send reset password email"
                  >
                    <MdVpnKey
                      style={{
                        color: currentClient.email ? "forestgreen" : "gray",
                      }}
                    />
                  </button>
                  <button
                    className="btn-clear"
                    onClick={() => {
                      this.handleGenerateResetLink(currentClient, true);
                    }}
                    title="Generate set password link"
                  >
                    <MdLink
                      style={{
                        color: currentClient.email ? "forestgreen" : "gray",
                      }}
                    />
                  </button>
                  <AiFillFileAdd
                    size={24}
                    color="forestgreen"
                    onClick={this._openExpansionModal}
                    onClose={this._closeExpansionModal}
                    token={this.props.userToken}
                    style={{ cursor: "hand" }}
                    title="Start expansion process"
                  />
                </div>
              </h1>
            )}
          </React.Fragment>
        ) : (
          "<no client>"
        )}
        {IS_OLYMPUS ? (
          <button
            className="btn btn-primary btn-border btn-rounded"
            onClick={() => {
              window.location = "/aphrodite/accounts/new";
            }}
            title="Create a new account"
          >
            Create new account
          </button>
        ) : null}
      </React.Fragment>
    );
  }

  closeEditClientName() {
    this.setState({
      isEditingClientName: false,
    });
  }

  openEditClientName(startName) {
    this.setState({
      isEditingClientName: true,
      newClientName: startName,
      clientNameInputBorderColor: startName.length > 0 ? "#CCC" : "red",
    });
  }

  onUpdateClientName(e) {
    this.setState({
      newClientName: e.currentTarget.value,
      clientNameInputBorderColor:
        e.currentTarget.value.length > 0 ? "#CCC" : "red",
    });
  }

  saveClientName() {
    if (this.state.newClientName.length > 1) {
      this.setState({
        isEditingClientName: false,
      });
      locationsApi.saveClientName(
        this.props.userToken,
        this.props.currentClientId,
        this.state.newClientName
      );
    } else {
      this.setState({
        clientNameInputBorderColor: "red",
      });
    }
  }

  saveAreaName(areaId, businessId, newAreaName) {
    locationsApi.saveAreaName(
      this.props.userToken,
      this.props.currentClientId,
      areaId,
      businessId,
      newAreaName
    );
  }

  openEditBusinessName(startName, businessId) {
    this.setState({
      isEditingBusinessName: true,
      newBusinessName: startName,
      editingBusinessId: businessId,
      businessNameInputBorderColor: startName.length > 0 ? "#CCC" : "red",
    });
  }

  closeEditBusinessName() {
    this.setState({
      isEditingBusinessName: false,
    });
  }

  onUpdateBusinessName(e) {
    this.setState({
      newBusinessName: e.currentTarget.value,
      businessNameInputBorderColor:
        e.currentTarget.value.length > 0 ? "#CCC" : "red",
    });
  }

  saveBusinessName() {
    if (this.state.newBusinessName.length > 0) {
      locationsApi.saveBusinessName(
        this.props.userToken,
        this.props.currentClientId,
        this.state.editingBusinessId,
        this.state.newBusinessName
      );
      this.setState({
        isEditingBusinessName: false,
      });
    } else {
      this.setState({
        businessNameInputBorderColor: "red",
      });
    }
  }

  render() {
    const { commercialRulesData } = this.state;
    const locationsFiltered = this.props.locations
      .filter((item) => this._checkIfQueryMatches(item))
      .sort(this._sortByName);
    const locationsList = locationsFiltered.map((location, index) => {
      const areasList = location.areas
        .sort(this._sortByName)
        .map((area, areaIndex) => (
          <AreasListItem
            key={areaIndex}
            area={area}
            toggleStreaming={this.toggleStreaming}
            timezone={location.timezone}
            locationId={location.id}
            locationIndex={index}
            areaIndex={areaIndex}
            // key={`area${index}${areaIndex}`}
            clientId={this.props.currentClientId}
            playlists={this.props.currentChannelsForClient}
            handleAutoplayChange={this._handleAutoplayChange}
            handleViewSchedule={this._handleViewSchedule}
            schedules={this.props.schedules}
            userToken={this.props.userToken}
            isStreaming={
              area.streaming &&
              area.streaming.online &&
              (!this.isDeviceOnline(area.device) ||
                area.device.status !== "play")
            }
            handleUpdatePlayerStatus={this.handleUpdatePlayerStatus}
            handlePlayPause={this.handleUpdatePlayerStatus}
            handleSkip={this.handleSkip}
            getLocations={this.getLocations}
            handleToggleOverrideModal={this.handleToggleOverrideModal}
            handleChangeSchedule={this._handleChangeSchedule}
            handleResendSchedule={this._handleResendSchedule}
            handleChangeDevice={this._handleChangeDevice}
            handleCommercialRulesPopup={this._handleCommercialRulesPopup}
            handleOpenPapertrail={this._handleOpenPapertrail}
            handleActivateArea={this._handleActivateArea}
            handleDeactivateArea={this._handleDeactivateArea}
            handleToggleStreamingModal={this.handleToggleStreamingModal}
            handleToggleDuplicatePlayModal={this.handleToggleDuplicatePlayModal}
            handleShowUpdateSoftwareModal={this.handleShowUpdateSoftwareModal}
            handleShowRemoveDeviceModal={this.handleShowRemoveDeviceModal}
            handleSaveAreaName={this.saveAreaName}
          />
        ));

      return (
        <React.Fragment key={index}>
          {location.is_active !== false ? (
            <div className="row locations-list-item" key={`location${index}`}>
              {this.state.isEditingBusinessName &&
              location.id === this.state.editingBusinessId ? (
                <div
                  className="col-md-12 locations-list-item-name animated delay05s fadeInDown"
                  style={{ display: "flex" }}
                >
                  <input
                    type="text"
                    style={{
                      fontSize: "25px",
                      width: "400px",
                      borderColor: this.state.businessNameInputBorderColor,
                    }}
                    className="form-control"
                    value={this.state.newBusinessName}
                    onChange={this.onUpdateBusinessName}
                  />
                  <MdClose
                    size={22}
                    color="black"
                    onClick={this.closeEditBusinessName}
                    style={{ cursor: "hand", marginTop: "5px" }}
                  />
                  {this.state.newBusinessName.length > 0 ? (
                    <MdSave
                      size={22}
                      color="black"
                      onClick={this.saveBusinessName}
                      style={{ cursor: "hand", marginTop: "5px" }}
                    />
                  ) : null}
                </div>
              ) : (
                <div
                  className="col-md-12 locations-list-item-name animated delay05s fadeInDown"
                  style={{ display: "flex" }}
                >
                  {location.name}
                  {IS_OLYMPUS ? (
                    <MdEdit
                      size={22}
                      color="black"
                      onClick={(e) => {
                        this.openEditBusinessName(location.name, location.id);
                      }}
                      style={{
                        cursor: "hand",
                        marginTop: "5px",
                        fontSize: "25px",
                      }}
                    />
                  ) : null}
                  <a
                    title="Change time zone"
                    href="#"
                    onClick={() => {
                      this._handleOpenChangeTimeZone(location.id);
                    }}
                    className="--tmz"
                  >
                    {location.timezone
                      ? `${location.timezone.name.replace("_", " ")} (${
                          TimezoneHelper.generateTimezoneObj(
                            location.timezone.name.replace(" ", "_")
                          ).offsetDisplay
                        })`
                      : `${this.props.defaultTimezoneName} (${this.props.defaultTimezoneOffsetDisplay})`}
                  </a>
                  <button
                    className="btn-clear"
                    onClick={() => {
                      this._handleDeactivateLocation(location.id);
                    }}
                    title="Deactivate Location"
                  >
                    <FaBan
                      className="button__icon --button-no-com-rule"
                      style={{ color: "red" }}
                    />
                  </button>
                  <button
                    className="btn-clear"
                    onClick={() => {
                      this.handleShowChangeEmailModal(location, false);
                    }}
                    title="Change email address"
                  >
                    <FaEnvelope
                      style={{ color: location.email ? "forestgreen" : "gray" }}
                    />
                  </button>
                  <button
                    className="btn-clear"
                    onClick={() => {
                      this.handleSendResetEmail(location, false);
                    }}
                    title="Send reset password email"
                  >
                    <MdVpnKey
                      style={{ color: location.email ? "forestgreen" : "gray" }}
                    />
                  </button>
                  <button
                    className="btn-clear"
                    onClick={() => {
                      this.handleGenerateResetLink(location, false);
                    }}
                    title="Generate set password link"
                  >
                    <MdLink
                      style={{ color: location.email ? "forestgreen" : "gray" }}
                    />
                  </button>
                </div>
              )}
              <div className="col-md-12 locations-list-item-areas animated delay08s fadeInDown flex-column">
                {areasList}
              </div>
            </div>
          ) : (
            <div
              className="row locations-list-item flex"
              key={`location${index}`}
              style={{ backgroundColor: "lightgrey" }}
            >
              <div
                className="col-md-12 locations-list-item-name animated delay05s fadeInDown"
                style={{ color: "tomato", marginTop: "20px" }}
              >
                Inactive location:
                {location.name}{" "}
              </div>
              <a
                onClick={() => {
                  this._handleActivateLocation(location.id);
                }}
                title="Activate"
                className="location__button"
              >
                <FaUnlock className="button__icon" />
              </a>
            </div>
          )}
        </React.Fragment>
      );
    });

    const searchInput = (
      <div
        className="col-md-6 animated delay02s fadeInDown"
        id="locations-search"
      >
        <i className="icon-search" />
        <input
          type="text"
          ref="searchLocations"
          onKeyUp={this._handleLocationsSearch}
          placeholder="Search for a location"
        />
      </div>
    );

    const nothingMatchedStyle = {
      display: locationsFiltered.length === 0 ? "block" : "none",
    };

    // console.log('currentClient:', currentClient);
    return (
      <div>
        <div
          className="col-md-12 text-center default-header animated delay02s fadeInDown"
          id="locations-header"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {this.clientHeader}
        </div>
        <div id="locations-body" className="container">
          <div className="row">{searchInput}</div>
          <div className="row">
            <div className="col-md-12">
              <div id="locations-list">{locationsList}</div>
              <h2 id="location-no-match" style={nothingMatchedStyle}>
                No location matched your search
              </h2>
            </div>
          </div>
        </div>

        {!this.state.showOverrideModal ? null : (
          <Modal
            flexSpacing="flex-start"
            show={this.state.showOverrideModal}
            handleDismiss={this.handleToggleOverrideModal}
            width="600px"
            height="420px"
          >
            <div className="modal-body full-width">
              <div>
                <h3>Select a Playlist</h3>
                <p className="margin-bottom-md">
                  This action will not affect the schedule
                </p>
                {this.props.isOverwritten ? (
                  <div className="player-change-channel-revert-wrapper">
                    <div
                      onClick={this._handleReverse}
                      className="player-change-channel-item"
                    >
                      REVERT BACK TO SCHEDULE
                    </div>
                    <hr />
                  </div>
                ) : (
                  ""
                )}
                <Typeahead
                  ref={(typeahead) =>
                    (this.overridePlaylistsSearch = typeahead)
                  }
                  onFocus={() =>
                    schedulesApi.getPlaylistsByClientId(
                      this.props.userToken,
                      this.props.currentClientId,
                      false
                    )
                  }
                  className="margin-top-sm margin-bottom-sm"
                  clearButton
                  id="override-playlist-search"
                  maxResults={8}
                  emptyLabel={""}
                  highlightOnlyResult
                  labelKey="name"
                  positionFixed
                  onChange={(playlists) =>
                    this.handleSelectOverridePlaylist(playlists[0].id)
                  }
                  options={this.props.currentChannelsForClient || []}
                  placeholder="Select a playlist..."
                  selectHintOnEnter
                />
                {DURATIONS.map((d) => (
                  <div key={`${d}-container`} className="checkbox pill">
                    <input
                      onChange={() => this.handleSelectOverrideDuration(d)}
                      checked={this.props.selectedOverrideDuration === d}
                      type="checkbox"
                      id={`${d}-radio`}
                      name={`${d}-radio`}
                    />
                    <label htmlFor={`${d}-radio`}>
                      {d < 1 ? `${60 * d} mins` : `${d} hours`}
                    </label>
                  </div>
                ))}
              </div>
              <button
                disabled={
                  !this.props.selectedOverridePlaylist ||
                  !this.props.selectedOverrideDuration
                }
                className="btn btn-primary btn-border btn-rounded margin-top-md"
                onClick={this.handleOverride}
              >
                Override
              </button>
            </div>
          </Modal>
        )}

        {!this.state.showToggleDuplicatePlays ? null : (
          <Modal
            flexSpacing={3}
            show={this.state.currentArea && this.state.showToggleDuplicatePlays}
            handleDismiss={this.handleToggleDuplicatePlay}
            width="350px"
            height="275px"
          >
            <div className="flex flex-column">
              <h3>
                {this.state.currentArea && this.state.currentArea.name
                  ? this.state.currentArea.name
                  : ""}
              </h3>
              <h4>
                Do you want to allow the device to play the same song
                back-to-back on this device?{" "}
              </h4>
              <h5>It might be usefull for mixes.</h5>
              <div className="flex-center-center">
                <button
                  className="btn btn-danger margin-right-xs"
                  onClick={() =>
                    this.handleChangeDuplicatePlay(
                      this.state.currentArea,
                      false
                    )
                  }
                >
                  No
                </button>
                <button
                  className="btn btn-primary"
                  onClick={() =>
                    this.handleChangeDuplicatePlay(this.state.currentArea, true)
                  }
                >
                  Yes
                </button>
              </div>
            </div>
          </Modal>
        )}

        {!this.state.showToggleStreamingModal ? null : (
          <Modal
            flexSpacing={3}
            show={this.state.currentArea && this.state.showToggleStreamingModal}
            handleDismiss={this.handleToggleStreamingModal}
            width="300px"
            height="250px"
          >
            <div className="flex flex-column">
              <h3>
                {this.state.currentArea && this.state.currentArea.name
                  ? this.state.currentArea.name
                  : ""}
              </h3>
              <h4>
                {this.state.currentArea &&
                this.state.currentArea.streaming &&
                this.state.currentArea.streaming.enabled
                  ? "Disable"
                  : "Enable"}{" "}
                Streaming?
              </h4>
              <div className="flex-center-center">
                <button
                  className="btn btn-danger margin-right-xs"
                  onClick={this.handleToggleStreamingModal}
                >
                  No
                </button>
                <button
                  className="btn btn-primary"
                  onClick={() =>
                    this.handleToggleStreaming(this.state.currentArea)
                  }
                >
                  Yes
                </button>
              </div>
            </div>
          </Modal>
        )}

        {!this.state.showTimezoneModal ? null : (
          <Modal
            flexSpacing="flex-start"
            show={this.state.showTimezoneModal}
            handleDismiss={this._hideTimezoneModal}
            width="300px"
            height="400px"
          >
            <Typeahead
              bsSize="large"
              clearButton
              id="timezone-typeahead"
              maxResults={this.props.timezones.length / 2}
              emptyLabel=""
              highlightOnlyResult
              labelKey="name"
              autoFocus
              filterBy={["name", "offsetDisplay"]}
              onChange={(timezone) => this._handleChangeTimezone(timezone)}
              options={this.props.timezones.filter(
                (t) => !this.props.timezoneOffset !== t.offsetDisplay
              )}
              placeholder="Select a timezone..."
              selectHintOnEnter
              renderMenuItemChildren={(option) => (
                <div>
                  {option.offsetDisplay}
                  <div>
                    <small>{option.name.replace("_", " ")}</small>
                  </div>
                </div>
              )}
            />
          </Modal>
        )}

        {!this.state.showChangeEmailModal ? null : (
          <Modal
            flexSpacing="center"
            show={this.state.showChangeEmailModal}
            handleDismiss={this.handleHideChangeEmailModal}
            width="500px"
            height="300px"
          >
            <h4>{this.state.selectedUser.name}</h4>
            <h3 className="margin-bottom-sm">Change email address</h3>
            <div
              className="flex full-width"
              onKeyPress={(e) => {
                e.charCode === 13 && this.isEmailValid()
                  ? this.handleChangeEmail()
                  : null;
              }}
            >
              <input
                className="form-control margin-right-xs padding-smmd"
                type="email"
                placeholder="Enter email address..."
                value={this.state.selectedUser.email || ""}
                onChange={(e) =>
                  this.setState({
                    ...this.state,
                    selectedUser: {
                      ...this.state.selectedUser,
                      email: e.target.value,
                    },
                  })
                }
              />
              <button
                className="btn btn-primary"
                disabled={!this.isEmailValid()}
                onClick={() => this.handleChangeEmail()}
              >
                Submit
              </button>
            </div>
          </Modal>
        )}

        {this.props.activationModal.isVisible ? (
          <Modal
            flexSpacing="center"
            show
            handleDismiss={this._hideActivationModal}
            width="600px"
            height="300px"
          >
            {this.props.activationModal.showButtons ? (
              <YesNoPopUp
                message={this.props.activationModal.message}
                handleNo={this._hideActivationModal}
                handleYes={this.props.activationModal.callback}
              />
            ) : (
              <div>
                {" "}
                Doing some very complicated calculations, please wait a sec{" "}
              </div>
            )}
          </Modal>
        ) : null}

        <ChangeDevicePopup
          isVisible={this.state.showChangeDevicePopup}
          area={this.state.curentArea}
          deviceId={this.state.currentDeviceId || ""}
          deviceType={this.state.deviceType}
          displayHelpMessage={this.state.displayHelpMessage}
          webPlayerDeviceCode={this.state.webPlayerDeviceCode || ""}
          handleGetWebPlayerThingName={this.handleGetWebPlayerThingName}
          handleChangeDeviceInput={this._handleChangeDeviceInput}
          handleCancelChangeDevice={this._handleCancelChangeDevice}
          handleDisplayHelpMessage={this._handleDisplayHelpMessage}
          handleChangeDeviceSave={this._handleChangeDeviceSave}
          handleChangeDeviceType={this._handleChangeDeviceType}
        />

        <ChangeDeviceConfirmationPopup
          isVisible={this.state.showChangeDeviceConfirmationPopup}
          handleConfirmed={this._handleChangeDeviceSaveConfirmed}
          handleCancel={this._handleCancelChangeDeviceConfrimation}
          areaName={this.state.curentArea.areaName}
        />

        <div className="animated-no-fill delay1s fadeInDown">
          <LocationsChangeSchedulePopup
            isVisible={this.props.showChangeScheduleDialog}
            handleSave={this._handleScheduleChangeSubmit}
            handleCancel={this._handleScheduleChangeCancel}
            areaScheduleInfo={this.props.areaScheduleInfo}
          />
        </div>
        <CommercialRulesPopup
          type={this.state.type}
          id={this.state.id}
          name={this.state.name}
          coreChannels={this.props.coreChannels}
          show={this.props.comRulesModalIsVisible}
          handleSave={this._sendCommercialRulesToS3}
          closeModal={this._closeModal}
          rules={commercialRulesData}
          saving={this.state.saving}
        />

        <SfExpansionPopup
          show={this.state.showExpansionModal}
          clientName={this.props.clientsList.find(
            (item) => item.id == this.props.currentClientId
          )}
          closeModal={this._closeExpansionModal}
          saving={this.state.saving}
          token={this.props.userToken}
        />
      </div>
    );
  }
}

const storeToProps = (store) => ({
  locations: store.locationsState.locations,
  schedules: store.schedulesState.schedules,
  searchQuery: store.locationsState.searchQuery,
  showChangeScheduleDialog: store.locationsState.showChangeScheduleDialog,
  userInfo: store.authState.userInfo ? store.authState.userInfo : {},
  userToken: store.authState.userInfo ? store.authState.userInfo.userToken : "",
  currentClientId: store.authState.currentClientId,
  clientsList: store.authState.clientsList,
  areaScheduleInfo: store.locationsState.areaScheduleInfo,
  comRulesModalIsVisible: store.commercialRulesState.modalIsVisible,
  coreChannels: store.commercialRulesState.coreChannels,
  channelSearchQuery: store.commercialRulesState.channelSearchQuery,
  timezones: store.schedulesState.timezones,
  currentChannelsForClient: store.schedulesState.currentChannelsForClient,
  defaultTimezoneName: store.schedulesState.defaultTimezoneName,
  defaultTimezoneOffsetDisplay:
    store.schedulesState.defaultTimezoneOffsetDisplay,
  activationModal: store.locationsState.activationModal,
  selectedOverridePlaylist: store.locationsState.selectedOverridePlaylist,
  selectedOverrideDuration: store.locationsState.selectedOverrideDuration,
});

export default connect(storeToProps)(LocationsMain);
