import * as types from '../actions/types';

import { DEFAULT_NOTIFICATION_HIDE_TIMEOUT } from '../config';

const initalState = {
  containerHeight: document.documentElement.clientHeight,
  activeMenuItem: 'locations',
  isUserMenuOpen: false,
  isMoreMenuOpen: false,
  isClientMenuOpen: false,
  isEuterpeMenuOpen: false,
  isMinervaMenuOpen: false,
  isMobileMenuOpen: false,
  showSpinner: false,
  noOfActions: 0,
  isForgotActive: false,
  isMediaPlaying: null,
  notification: {
    message: '',
    isVisible: false,
    isError: false,
    isSticky: false,
    hideAfter: DEFAULT_NOTIFICATION_HIDE_TIMEOUT,
  },
  flashMessage: {
    duration: 2000,
    status: '',
    show: false,
    message: '',
    subMessage: '',
    width: '100%',
  },
  modal: {
    animation: 'fade-in',
    show: false,
    styles: {},
    title: '',
    message: '',
    yesText: 'Yes',
    noText: 'No',
    okText: 'OK',
    handleYes: null,
    handleNo: null,
    handleDismiss: null,
    type: 'ok',
    content: null,
  },
  aphroditeSelectedMenu: 'Euterpe',
  aphroditeMenu: [
    {
      name: 'Client',
      show: false,
      submenu: [
        {
          name: 'Locations',
          url: '/locations',
          type: 'href',
          show: true,
        },
        {
          name: 'Schedules',
          url: '/schedules',
          type: 'href',
          show: true,
        },
        {
          name: 'Device Status',
          url: '/device-status',
          type: 'href',
          show: true,
        },
        {
          name: 'Web Leads',
          url: '/web-leads',
          type: 'href',
          show: IS_OLYMPUS,
        },
        {
          name: 'Mass updater',
          url: '/aphrodite/mass-updater',
          type: 'linkTo',
          show: IS_OLYMPUS,
        },
        {
          name: 'Users',
          url: '/aphrodite/users',
          type: 'linkTo',
          show: IS_OLYMPUS,
        },
      ],
    },
    {
      name: 'Euterpe',
      show: true,
      submenu: [
        {
          name: 'Core Channels',
          url: '/aphrodite/euterpe/core-channels',
          type: 'linkTo',
          show: IS_OLYMPUS,
        },
        {
          name: 'Tracks',
          url: '/aphrodite/euterpe/tracks',
          type: 'linkTo',
          show: IS_OLYMPUS || IS_SUPPORT_PORTAL,
        },
        {
          name: 'Feedback',
          url: '/aphrodite/euterpe/feedback',
          type: 'linkTo',
          show: IS_OLYMPUS,
        },
        {
          name: 'Tool Box',
          url: '/aphrodite/tool-box',
          type: 'linkTo',
          show: IS_OLYMPUS,
        },
      ],
    },
    {
      name: 'Sf',
      show: false,
      submenu: [],
    },
    {
      name: 'Mass updater',
      show: false,
      submenu: [],
    },
  ],
  menuItems: [
    {
      name: 'locations',
      url: '/locations',
    },
    {
      name: 'schedules',
      url: '/schedules',
    },
  ],
  joke: '',
};

export default (state = initalState, action) => {
  switch (action.type) {
    case types.CHANGE_JOKE:
      return Object.assign({}, state, {
        joke: action.payload,
      });
    case types.CHANGE_CONTAINER_HEIGHT:
      return Object.assign({}, state, {
        containerHeight: action.payload,
      });
    case types.CHANGE_USER_MENU:
      return Object.assign({}, state, {
        isUserMenuOpen: action.payload,
      });
    case types.CHANGE_MORE_MENU:
      return Object.assign({}, state, {
        isMoreMenuOpen: action.payload,
      });
    case types.CHANGE_CLIENT_MENU:
      return Object.assign({}, state, {
        isClientMenuOpen: action.payload,
      });
    case types.CHANGE_APHRODITE_SELECTED_MENU:
      return Object.assign({}, state, {
        aphroditeSelectedMenu: action.payload,
      });
    case types.CHANGE_EUTERPE_MENU:
      return Object.assign({}, state, {
        isEuterpeMenuOpen: action.payload,
      });
    case types.CHANGE_MINERVA_MENU:
      return Object.assign({}, state, {
        isMinervaMenuOpen: action.payload,
      });
    case types.CHANGE_MOBILE_MENU:
      return Object.assign({}, state, {
        isMobileMenuOpen: action.payload,
      });
    case types.CHANGE_FORGOT:
      return Object.assign({}, state, {
        isForgotActive: action.payload,
      });
    case types.SHOW_SPINNER:
      return Object.assign({}, state, {
        showSpinner: true,
        noOfActions: state.noOfActions + 1,
      });
    case types.HIDE_SPINNER:
      //console.log(state.noOfActions);
      return Object.assign({}, state, {
        showSpinner: state.noOfActions === 1 || state.noOfActions === 0 ? false : true,
        noOfActions: state.noOfActions - 1 < 0 ? 0 : state.noOfActions - 1,
      });
    case types.SHOW_NOTIFICATION:
      return Object.assign({}, state, {
        notification: { ...state.notification, ...action.payload, isVisible: true },
      });
    case types.HIDE_NOTIFICATION:
      return Object.assign({}, state, {
        notification: { ...state.notification, isVisible: false },
      });
    case types.CHANGE_IS_MEDIA_PLAYING:
      return {
        ...state,
        isMediaPlaying: action.payload,
      };
    case types.SHOW_FLASH_MESSAGE:
      return {
        ...state,
        flashMessage: {
          duration: action.payload.duration || 2000,
          status: action.payload.status,
          message: action.payload.message,
          subMessage: action.payload.subMessage,
          width: action.payload.width,
          show: true,
        },
      };
    case types.HIDE_FLASH_MESSAGE:
      return {
        ...state,
        flashMessage: {
          ...state.flashMessage,
          show: false,
        },
      };
    case types.SHOW_MODAL:
      return {
        ...state,
        modal: {
          show: true,
          animation: action.payload.animation,
          styles: action.payload.styles || '',
          title: action.payload.title,
          message: action.payload.message,
          yesText: action.payload.yesText || 'Yes',
          noText: action.payload.noText || 'No',
          okText: action.payload.okText || 'OK',
          handleYes: action.payload.handleYes,
          handleNo: action.payload.handleNo,
          handleDismiss: action.payload.handleDismiss,
          type: action.payload.type,
          content: action.payload.content,
        },
      };
    case types.HIDE_MODAL:
      return {
        ...state,
        modal: {
          ...state.modal,
          show: false,
        },
      };
    default:
      return state;
  }
};
