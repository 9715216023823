import { combineReducers } from 'redux';

// Reducers
import trackUploaderReducer from './track-uploader-reducer';
import authReducer from './auth-reducer';
import loginReducer from './login-reducer';
import layoutReducer from './layout-reducer';
import locationsReducer from './locations-reducer';
import schedulesReducer from './schedules-reducer';
import channelsReducer from './channels-reducer';
import feedbackReducer from './feedback-reducer';
import trackRepeatsReducer from './track-repeats-reducer';
import tracksNotInUseReducer from './tracks-no-in-use-reducer';
import tracksReducer from './tracks-reducer';
import deviceStatusReducer from './device-status-reducer';
import webLeadsReducer from './web-leads-reducer';
import coreChannelsReducer from './core-channels-reducer';
import commercialRulesReducer from './commercial-rules-reducer';
import playlistsReducer from './playlists-reducer';
import playlistInfoReducer from './playlist-info-reducer';
import sfReducer from './sf-reducer';
import commonReducer from './common-reducer';
import updaterReducer from './updater-reducer';
import tracklistAnalyzerReducer from './tracklist-analyzer-reducer';
import loginTokensReducer from './login-tokens-reducer';
import artistsReducer from './artists-reducer';
import accountsReducer from './accounts-reducer';

// Combine Reducers
export default combineReducers({
  trackUploaderState: trackUploaderReducer,
  artistsState: artistsReducer,
  authState: authReducer,
  loginState: loginReducer,
  layoutState: layoutReducer,
  locationsState: locationsReducer,
  schedulesState: schedulesReducer,
  channelsState: channelsReducer,
  feedbackState: feedbackReducer,
  trackRepeatsState: trackRepeatsReducer,
  tracksNotInUseState: tracksNotInUseReducer,
  tracksState: tracksReducer,
  deviceStatusState: deviceStatusReducer,
	webLeadsState: webLeadsReducer,
	coreChannelsState: coreChannelsReducer,
	commercialRulesState: commercialRulesReducer,
  playlistsState: playlistsReducer,
  playlistInfoState: playlistInfoReducer,
  sfState : sfReducer,
  commonState: commonReducer,
  updaterState: updaterReducer,
  tracklistAnalyzerState: tracklistAnalyzerReducer,
  loginTokensState: loginTokensReducer,
  accountsState: accountsReducer,
});
