import React from 'react';
import { FaRegQuestionCircle } from 'react-icons/fa';

import Modal from '@layout_2/Modal/modal';

export default ({
  area,
  deviceId,
  deviceType,
  displayHelpMessage,
  isVisible,
  webPlayerDeviceCode,
  handleCancelChangeDevice,
  handleChangeDeviceSave,
  handleChangeDeviceInput,
  handleChangeDeviceType,
  handleDisplayHelpMessage,
  handleGetWebPlayerThingName,
}) => {

  const validate = () => {
    let validated = true;

    if (!deviceType) validated = false;
    if (deviceId.length <= 6) validated = false;
    if (deviceType === 'fake-device') {
      const regex = /^f-[0-9]{5}$/
      if (!regex.test(deviceId)) validated = false
    };
    return validated;
  };

  const HelpMessage = `
    The device serial should be a letter followed by a 5 digit number, e.g. c-01234. 
    For a fake device de same applies but has to start with letter f, e.g. f-01234`

  return (
    <Modal
      width="600px"
      height="375px"
      show={isVisible}
      handleDismiss={handleCancelChangeDevice} >
      <h3>
        Assign device for
        <span className="highlight">
          {area && area.areaName
            ? ` ${area.areaName} [${area.areaId}]`
            : ''
          }
        </span>
      </h3>
      <div style={inlineStyles.inputContainer}>
        <span className="flex">
          <input
            onChange={handleChangeDeviceInput}
            value={deviceId}
            type="text"
            placeholder="Device Serial"
            style={inlineStyles.input}
          />
          <FaRegQuestionCircle
            size={30}
            onMouseEnter={() => handleDisplayHelpMessage(true)}
            onMouseLeave={() => handleDisplayHelpMessage(false)}
            style={inlineStyles.question}
          />
          <select
            className="form-control form-control-m margin-xs"
            name="device_type"
            onChange={handleChangeDeviceType}
            value={deviceType ? deviceType : ''}
          >
            <option value="" disabled>Select your option</option>
            <option value='zoetrope-device'>ambie-player</option>
            <option value='fake-device'>fake-device</option>
          </select>
        </span>
        {
          displayHelpMessage
            ? <p style={inlineStyles.help}>{HelpMessage}</p>
            : <p style={inlineStyles.help}>{ }</p>
        }
      </div>
      {
        webPlayerDeviceCode && <h5>
          <strong>Web Player device code:</strong>
          {webPlayerDeviceCode}
        </h5>
      }
      <div className="flex-center-center">
        <button
          onClick={handleCancelChangeDevice}
          className="btn btn-default"
          style={inlineStyles.button}>
          Dismiss
        </button>
        <button
          onClick={handleChangeDeviceSave}
          className="btn btn-primary"
          disabled={!validate()}
          style={inlineStyles.button}>
          Assign
        </button>
        <p style={inlineStyles.text}>or</p>
        <button
          onClick={handleGetWebPlayerThingName}
          className="btn btn-dark-grey"
          style={inlineStyles.button}>
          Generate Web Player
        </button>

      </div>
    </Modal >
  );
};

const inlineStyles = {
  inputContainer: {
    width: '100%'
  },
  help: {
    textAlign: 'left',
    color: 'grey',
    margin: '0 0 0 10px',
    height: '20px'
  },
  question: {
    color: 'grey',
    margin: '7px'
  },
  input: {
    borderRadius: '10px',
    border: '1px solid #ccc',
    paddingLeft: '10px',
    outline: 'none',
    width: '270px',
    margin: '5px',
  },
  button: {
    margin: '5px'
  },
  text: {
    padding: 0,
    margin: "0px 10px"
  }
};
