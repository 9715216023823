import React, {Component, Fragment} from 'react';

export default (props) => {

    return (
      <Fragment>
        <h4 className="track-title">{props.selectedTrack.title}</h4>
        <h5><strong>Artist: </strong>{props.selectedTrack.artist}</h5>
        <h5><strong>Device ID: </strong>{props.selectedTrack.device_id}</h5>
        <table className="table default-table table-flex-scrollable table-modal-repeats">
          <thead>
            <tr>
              <th className="half-width">Date</th>
              <th className="half-width">Playlist</th>
              <th className="half-width">Seconds played</th>
              <th className="half-width">Player</th>
            </tr>
          </thead>
          <tbody>
            {props.selectedTrack.plays.map((occurrence, i) => {
              return <tr key={'occurrence' + i}>
                        <td className="half-width">{new Date(occurrence.date).toLocaleString()}</td>
                        <td className="half-width">{occurrence.playlist}</td>
                        <td className="half-width">{occurrence.seconds_played}</td>
                        <td className="half-width">{occurrence.streaming ? 'Web Player' : 'Ambie Player'}</td>
                     </tr>
            })}
          </tbody>
        </table>
      </Fragment>
    );
  };
