import axios from 'axios';
import store from '../store';
import { changeSchedules, changeSelectedSchedule, changeChannelsForClient } from '../actions/schedules-actions';
import { showSpinner, hideSpinner, showNotification } from '../actions/layout-actions';
import { HOST, PORT, PREFIX, GENERAL_SUCCESS_MESSAGE, GENERAL_ERROR_MESSAGE, GENERAL_REQUEST_ERROR_MESSAGE } from '../config';
import * as authApi from './auth-api';

const _getUrlFromConfig = () => {
  return `${HOST}:${PORT}${PREFIX}`;
};

export const getSchedules = (userToken, clientId, selectedScheduleName) => {
  store.dispatch(showSpinner());
  return axios
    .get(_getUrlFromConfig() + '/clients/' + clientId + '/weekly_schedules', {
      headers: { 'x-ambie-token': userToken },
    })
    .then((response) => {
      store.dispatch(hideSpinner());
      _detectUnauthorized(response);
      if (!response.data.error) {
        store.dispatch(changeSchedules(response.data));
        if (response.data && response.data.length > 0) {
          if (!selectedScheduleName) {
            getSelectedSchedule(response.data[0]._id, userToken);
          }
        } else {
          store.dispatch(
            changeSelectedSchedule({
              name: '',
              schedule: [[], [], [], [], [], [], []],
            }),
          );
        }
      } else {
        store.dispatch(showNotification(GENERAL_REQUEST_ERROR_MESSAGE, true));
        _handleUnauthorized(response.message);
        console.error('Error: ', response.data.error);
      }
    })
    .catch((response) => {
      store.dispatch(hideSpinner());
      store.dispatch(showNotification(GENERAL_REQUEST_ERROR_MESSAGE, true));
      _handleUnauthorized(response.message);
      console.error('Error: ', response.message);
    });
};

export const getPlaylistsByClientId = (userToken, clientId, shouldShowSpinner = true) => {
  if (shouldShowSpinner) {
    store.dispatch(showSpinner());
  }
  return axios
    .get(_getUrlFromConfig() + '/clients/' + clientId + '/playlists', {
      headers: { 'x-ambie-token': userToken },
    })
    .then((response) => {
      _detectUnauthorized(response);
      store.dispatch(hideSpinner());
      store.dispatch(changeChannelsForClient(response.data.results));
      return response.data.results;
    })
    .catch((response) => {
      store.dispatch(hideSpinner());
      store.dispatch(showNotification(GENERAL_REQUEST_ERROR_MESSAGE, true));
      _handleUnauthorized(response.message);
      console.error('Error: ', response.message);
    });
};

export const getSelectedSchedule = (scheduleId, userToken) => {
  store.dispatch(showSpinner());

  return axios
    .get(_getUrlFromConfig() + '/week_schedules/' + scheduleId, {
      headers: { 'x-ambie-token': userToken },
    })
    .then((response) => {
      _detectUnauthorized(response);
      store.dispatch(hideSpinner());
      store.dispatch(changeSelectedSchedule(response.data));
    })
    .catch((response) => {
      store.dispatch(hideSpinner());
      store.dispatch(showNotification(GENERAL_REQUEST_ERROR_MESSAGE, true));
      _handleUnauthorized(response.message);
      console.error('Error: ', response.message);
    });
};

export const addSchedule = (scheduleData, clientId, userToken) => {
  store.dispatch(showSpinner());

  return axios
    .post(
      _getUrlFromConfig() + '/week_schedules',
      {
        data: scheduleData,
        clientId: clientId,
      },
      {
        headers: { 'x-ambie-token': userToken },
      },
    )
    .then((response) => {
      _detectUnauthorized(response);
      store.dispatch(hideSpinner());
      if (!response.data.error) {
        store.dispatch(showNotification(GENERAL_SUCCESS_MESSAGE));
        getSchedules(userToken, clientId);
      } else {
        store.dispatch(showNotification(GENERAL_ERROR_MESSAGE, true));
      }
    })
    .catch((response) => {
      store.dispatch(hideSpinner());
      store.dispatch(showNotification(GENERAL_REQUEST_ERROR_MESSAGE, true));
      _handleUnauthorized(response.message);
      console.error('Error: ', response.message);
    });
};

export const deleteSchedule = (scheduleId, clientId, userToken) => {
  store.dispatch(showSpinner());

  return axios
    .delete(_getUrlFromConfig() + '/week_schedules/' + scheduleId, {
      headers: { 'x-ambie-token': userToken },
    })
    .then((response) => {
      _detectUnauthorized(response);
      store.dispatch(hideSpinner());
      if (!response.data.error) {
        store.dispatch(showNotification(GENERAL_SUCCESS_MESSAGE));
        getSchedules(userToken, clientId);
      } else {
        if (response.data.error == 'Schedule is in use') {
          store.dispatch(showNotification('The schedule cannot be deleted because it is used in an area', true));
        } else {
          store.dispatch(showNotification(GENERAL_ERROR_MESSAGE, true));
        }
      }
    })
    .catch((response) => {
      store.dispatch(hideSpinner());
      store.dispatch(showNotification(GENERAL_REQUEST_ERROR_MESSAGE, true));
      _handleUnauthorized(response.message);
      console.error('Error: ', response.message);
    });
};

export const updateSchedule = (schedule, userToken, affectedAreas) => {
  //1. https://kruo3njzbf.execute-api.eu-west-1.amazonaws.com/staging/week_schedules/5e68f33bf018921bfb920be3 PUT
  //2. https://kruo3njzbf.execute-api.eu-west-1.amazonaws.com/staging/clients/81/area_schedule POST
  //3. https://kruo3njzbf.execute-api.eu-west-1.amazonaws.com/staging/clients/81/areas/159/send_schedule GET
  //4. https://kruo3njzbf.execute-api.eu-west-1.amazonaws.com/staging/week_schedules/5e68f33bf018921bfb920be3 GET

  console.log('You are saving: ', JSON.stringify(schedule));
  // _cleanSchedule(schedule.schedule); // removed due to safari issues - should never have incorrect date format anyway with new schedule implementation
  store.dispatch(showSpinner());

  //save the weekly schedule
  return axios
    .put(_getUrlFromConfig() + '/week_schedules/' + schedule._id, schedule, {
      headers: { 'x-ambie-token': userToken },
    })
    .then((response) => {
      _detectUnauthorized(response);

      //console.log(affectedAreas);
      //console.log(schedule);
      //console.log(affectedAreas);

      //assignScheduleToArea
      //creating an array of request for all the areas
      //debugger;
      let assignScheduleToAreaRequestList = affectedAreas.map((AA) => {
        //debugger;
        return axios.post(
          _getUrlFromConfig() + '/clients/' + schedule.clientId + '/area_schedule',
          {
            areaId: AA.area_id,
            locationId: AA.location_id,
            weekScheduleId: schedule._id,
          },
          {
            headers: { 'x-ambie-token': userToken },
          },
        );
      });

      //debugger;
      axios
        .all(assignScheduleToAreaRequestList)
        .then((responses) => {
          //the new schedule are saved
          //debugger;

          //wait couple of seconds
          setTimeout(() => {
            //resend the schedule
            let resendScheduleRequestList = affectedAreas
              //filter out the undefined devices
              .filter((AA) => {
                return typeof AA.device !== 'undefined' && AA.device.device_type === 'zoetrope-device';
              })
              .map((AA) => {
                return axios.get(_getUrlFromConfig() + '/clients/' + schedule.clientId + '/areas/' + AA.area_id + '/send_schedule', {
                  headers: { 'x-ambie-token': userToken },
                });
              });

            axios
              .all(resendScheduleRequestList)
              .then((responses) => {
                store.dispatch(hideSpinner());
                store.dispatch(showNotification(GENERAL_SUCCESS_MESSAGE));
                getSelectedSchedule(schedule._id, userToken);
                //but this callback will be executed only when both requests are complete.
              })
              .catch((response) => {
                store.dispatch(hideSpinner());
                store.dispatch(showNotification(GENERAL_ERROR_MESSAGE, true));
                _handleUnauthorized(response.message);
                console.error('Error: ', response.message);
              });
          }, 2000);
        })
        .catch((response) => {
          store.dispatch(hideSpinner());
          store.dispatch(showNotification(GENERAL_ERROR_MESSAGE, true));
          _handleUnauthorized(response.message);
          console.error('Error: ', response.message);
        });

      //resendSchedule

      /*
      return axios.post(_getUrlFromConfig() + '/clients/' + clientId + '/area_schedule', { // '/week_schedules/area_schedule'
        areaId: areaId,
        locationId: locationId,
        weekScheduleId: scheduleId
      }, {
        headers: { 'x-ambie-token': userToken }
      })
        .then(response => {
          store.dispatch(hideSpinner());
          store.dispatch(showNotification(GENERAL_SUCCESS_MESSAGE));
          getLocations(userToken, clientId);
        })
        .catch(response => {
          store.dispatch(hideSpinner());
          store.dispatch(showNotification(GENERAL_ERROR_MESSAGE, true));
          _handleUnauthorized(response.data.error);
          console.error('Error: ', response);
        });
      */
    })
    .catch((response) => {
      store.dispatch(hideSpinner());
      store.dispatch(showNotification(GENERAL_ERROR_MESSAGE, true));
      _handleUnauthorized(response.message);
      console.error('Error: ', response.message);
    });
};

const _cleanSchedule = (schedule) => {
  //eliminate playlists that have invalid start/end times
  for (let day = 0; day < 7; day++) {
    schedule[day] = schedule[day].filter(
      (playlist) => !isNaN(new Date(playlist.start_time.replace(/-/g, '/')).getTime()) && !isNaN(new Date(playlist.end_time.replace(/-/g, '/')).getTime()),
    );
  }

  return schedule;
};

const _handleUnauthorized = (error) => {
  if (error === 'Unauthorized') {
    authApi.logout();
  }
};

const _detectUnauthorized = (response) => {
  if (response.data.error && response.data.error == 'Unauthorized') {
    throw new Error(response.data.error);
  }
};
