import React, { PureComponent, Fragment } from 'react';

import autoBind from 'react-autobind';

import Backdrop from '../Backdrop/backdrop';

class Modal extends PureComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      height: document.documentElement.clientHeight * 0.9 + 'px',
      width: document.documentElement.clientWidth * 0.9 + 'px'
    };
  }

  componentWillMount() {
    this.updateDimensions();
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions() {
    this.setState({
      ...this.state,
      height: window.innerHeight * 0.9 + 'px',
      width: window.innerWidth * 0.9 + 'px',
    });
  }

  render() {
    const flexJustifyOptions = [
      'flex-start',
      'flex-end',
      'space-between',
      'space-around',
      'space-evenly'
    ];
    const modalStyle = {
      '--height': this.props.height || this.state.height || (window.innerHeight * 0.9) + 'px',
      '--width': this.props.width || this.state.width || (window.innerWidth * 0.9) + 'px',
      textAlign: this.props.textAlign || 'center',
      fontFamily: 'sans-serif',
      overflowY: 'auto',
      padding: '2.5em',
      justifyContent: flexJustifyOptions[this.props.flexSpacing] || this.props.flexSpacing || flexJustifyOptions[4]
    };
    const animation = this.props.animation || 'fadeIn';
    let cssClasses = `animated duration04 ${animation} modal-container`;
    if (this.props.cssClass) cssClasses += ` ${this.props.cssClass}`;
    if (this.props.dark) cssClasses += ' dark';
    return !this.props.show ? null : (
      <Fragment>
        <Backdrop show={this.props.show} hide={this.props.handleDismiss} />
        <div style={modalStyle} className={cssClasses}>
          {this.props.children}
        </div>
      </Fragment>
    );
  }
};

export default Modal;
