import React from 'react';

const ruleStatus = (rule) => {
    
    let status = '';
    if (rule === undefined || rule && rule.length === 0) {
        status = '--no-comm-rule';
    } else {
        const now = Date.now();
        const { startDate, endDate } = rule;
        let isActive = false;
        if (startDate && endDate) {
            const start = new Date(startDate.toString().length === 10 ? startDate * 1000 : startDate);
            const end = new Date(endDate.toString().length === 10 ? endDate * 1000 : endDate);
            isActive = start < now && now < end;
        } 
        isActive
            ? status = '--active'
            : status = '--inactive'
    }
    return status;
};


const CommercialRuleButton = ({ rule, disabled, title, onClick, icon }) => (
    <button
        disabled={disabled}
        title={title}
        className={`location__button ${ruleStatus(rule)}`}
        onClick={onClick}
    >
        {icon}
    </button>
);

export default CommercialRuleButton;