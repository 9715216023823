import React, { Component, Fragment } from 'react';

import autoBind from 'react-autobind'
import {
  DateRangePicker,
  isInclusivelyBeforeDay,
  isInclusivelyAfterDay,
  SingleDatePicker,
} from 'react-dates';
import moment from 'moment';

class DateRangeCalendar extends Component {

  constructor(props) {
    super(props);
    autoBind(this);
    moment.locale('en-gb');
  }

  state = {
    focusedInput: null,
    startDate: null,
    endDate: null,
    date: null,
    focused: false
  }

  UNSAFE_componentWillMount() {
    if (this.props.startDate && this.props.endDate) {
      this.setState({
        ...this.state,
        startDate: this.props.startDate,
        endDate: this.props.endDate,
      });
    } else if (this.props.startDate) {
      this.setState({
        ...this.state,
        startDate: this.props.startDate,
      });
    } else if (this.props.date) {
      this.setState({
        ...this.state,
        date: this.props.date,
      });
    }
  }

  get singleDatePicker() {
    return (
      <SingleDatePicker
        daySize={this.props.daySize || 39}
        small={this.props.small || false}
        withPortal={this.props.withPortal || false}
        numberOfMonths={this.props.numberOfMonths || 2}
        appendToBody={this.props.appendToBody || false}
        date={this.state.date}
        onDateChange={date => { this.onDateChange(date) }}
        focused={this.state.focused}
        showClearDate={this.props.showClearDate || false}
        onFocusChange={({ focused }) => { this.setState({ focused }) }}
        isOutsideRange={this.isOutsideRange}
      />
    );
  }

  get doubleDatePicker() {
    return (
      <Fragment>
        <DateRangePicker
          daySize={this.props.daySize || 39}
          small={this.props.small || false}
          withPortal={this.props.withPortal || false}
          numberOfMonths={this.props.numberOfMonths || 2}
          appendToBody={this.props.appendToBody || false}
          initialVisibleMonth={this.initialVisibleMonth}
          startDate={this.state.startDate}
          startDateId={this.props.startDateId || "start-date"}
          endDate={this.state.endDate}
          endDateId={this.props.endDateId || "end-date"}
          onDatesChange={({ startDate, endDate }) => { this.onDatesChange({ startDate, endDate }) }}
          focusedInput={this.state.focusedInput}
          onFocusChange={focusedInput => { this.onFocusChange(focusedInput) }}
          showClearDates={this.props.showClearDates || false}
          isOutsideRange={this.isOutsideRange}
        />
      </Fragment>
    );
  }

  get datePicker() {
    return this.props.single ? this.singleDatePicker : this.doubleDatePicker;
  }

  get dateSelectorIdentifier() {
    return this.state.focusedInput === 'startDate' ? 'Start Date' : 'End Date';
  }

  resetDates = () => this.onDatesChange({ startDate: null, endDate: null });

  onDatesChange = ({ startDate, endDate }) => {
    const focusedInput = this.state.focusedInput === 'endDate' ? null : 'endDate';
    this.setState({
      ...this.state,
      focusedInput,
      startDate,
      endDate,
    });
    this.props.onDatesChange({ startDate, endDate });
  }

  onDateChange = (date) => {
    this.setState({
      ...this.state,
      date,
    });
    this.props.onDatesChange(date);
  }

  onFocusChange = (focusedInput) => {
    if (this.props.clearOnOpen && focusedInput === 'startDate') {
      this.resetState(focusedInput);
    } else if (!this.state.startDate && focusedInput === 'endDate') {
      this.resetState('startDate');
    } else {
      this.setState({
        ...this.state,
        focusedInput,
      });
    }
  }

  initialVisibleMonth = () => {
    if (this.state.focusedInput === 'startDate') {
      if (this.state.startDate) {
        return this.state.startDate instanceof moment ? this.state.startDate : moment(this.state.startDate);
      } else {
        return moment();
      }
    } else if (this.state.focusedInput === 'endDate') {
      if (this.state.endDate) {
        return this.state.endDate instanceof moment ? this.state.endDate : moment(this.state.endDate);
      } else if (this.state.startDate) {
        return this.state.startDate instanceof moment ? this.state.startDate : moment(this.state.startDate);
      } else {
        return moment();
      }
    }
  }

  resetState = (focusedInput) => {
    this.setState({
      ...this.state,
      focusedInput,
      startDate: null,
      endDate: null
    });
  }

  isInclusive = (day, startDate, maximumEndDate) => {
    if (this.props.allowPastDate && this.props.allowFutureStartDate) {
      if (maximumEndDate.isAfter(startDate)) {
        if (this.state.focusedInput === 'endDate') {
          return isInclusivelyBeforeDay(day, startDate) || isInclusivelyAfterDay(day, maximumEndDate);
        } else {
          return isInclusivelyAfterDay(day, maximumEndDate);
        }
      } else {
        if (this.state.focusedInput === 'endDate') {
          return isInclusivelyBeforeDay(day, startDate);
        } else {
          return false;
        }
      }
    } else if (this.props.allowPastDate) {
      if (this.state.focusedInput === 'endDate') {
        if (maximumEndDate.isAfter(startDate)) {
          return isInclusivelyBeforeDay(day, startDate) || isInclusivelyAfterDay(day, maximumEndDate);
        } else {
          return isInclusivelyBeforeDay(day, startDate);
        }
      } else {
        return isInclusivelyAfterDay(day, maximumEndDate);
      }
    } else if (this.props.allowFutureStartDate) {
      if (maximumEndDate.isAfter(startDate) && this.state.focusedInput === 'endDate') {
        return isInclusivelyBeforeDay(day, startDate) || isInclusivelyAfterDay(day, maximumEndDate);
      } else {
        return isInclusivelyBeforeDay(day, startDate);
      }
    } else {
      if (this.state.focusedInput === 'endDate') {
        if (maximumEndDate.isAfter(startDate)) {
          return isInclusivelyBeforeDay(day, startDate) || isInclusivelyAfterDay(day, maximumEndDate);
        } else {
          return isInclusivelyBeforeDay(day, startDate);
        }
      } else {
        return isInclusivelyBeforeDay(day, startDate);
      }
    }
  }

  isOutsideRange = (day) => {
    const today = this.props.allowPastDate ? moment().add(1, 'days') : moment().subtract(1, 'days');
    const startDate = this.props.single ? this.state.date : this.state.startDate;
    if (!startDate || this.state.focusedInput === 'startDate') {
      return this.isInclusive(day, today, today);
    }
    const maximumDays = this.props.maximumDays;
    const maximumEndDate = startDate.clone().add(maximumDays + 1, 'days');
    return this.isInclusive(day, startDate, maximumEndDate);
  }

  render() {
    return (
      <Fragment>
        <div className="date-range">
          {this.datePicker}
        </div>
      </Fragment>
    );
  }
}

export default DateRangeCalendar;
